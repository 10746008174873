import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import electronicTagIcon from '../../assets/images/electronic-tag/MarbeteElectronico.svg';
import { formatMonthAndYear } from '../../core/Utils';
import { tagPreboardingRegister, tagPreboardingUnregister } from '../../core/api-resources/tag';
import { getTagPreboardingData, setPreboardingVehicle } from '../../redux/electronicTag/electronicTag.combine';
import Button from '../ui/Button';
import ConfirmationModal from '../ui/ConfirmationModal';
import Icon from '../ui/Icon';
import PillHeaderModal from '../ui/PillHeaderModal';

const ElectronicTagModal = (props) => {

    const { toggle, action, vehicleId, setUnbindSuccess, revalidationAction } = props

    const dispatch = useDispatch()

    const user = useSelector((state) => state?.login?.user);
    const userEmail = useSelector(s => s?.login?.user?.principal?.email)

    const [vehicle, setVehicle] = useState(null)
    const [disableRevalidate, setDisableRevalidate] = useState(false)

    const pending = vehicle?.tagPreboarding?.status === "pending"

    useEffect(() => {
        const preboardedVehicle = user?.vehicles.filter(v => v.id === vehicleId)
        if (preboardedVehicle?.length > 0) setVehicle(preboardedVehicle[0])
    }, [vehicleId, user])

    const history = useHistory()
    const { t } = useTranslation()

    const [confirmation, setConfirmation] = useState(false)

    const handleUnbind = async () => {

        const onSuccess = () => {
            setUnbindSuccess(true)
        }
        const onError = () => setUnbindSuccess(false)

        await tagPreboardingUnregister(vehicleId, onSuccess, onError)
    }

    const handleRevalidate = async () => {
        setDisableRevalidate(true)

        const handleSuccess = (res) => {
            revalidationAction(res?.payload)
            dispatch(getTagPreboardingData())
        }

        const handleError = (err) => {
            revalidationAction({ status: "error" })
        }

        await tagPreboardingRegister(vehicleId, vehicle?.tagPreboarding?.tagNumber, { email: userEmail }, handleSuccess, handleError)
        setDisableRevalidate(false)
    }


    const navToInstructions = () => {
        dispatch(setPreboardingVehicle(vehicle))
        history.push('/preboarding/instructions')
    }

    return (
        <>
            {
                confirmation &&
                <ConfirmationModal
                    title={t("vehicleDetails:electronicTag.confirmation.title")}
                    text={t("vehicleDetails:electronicTag.confirmation.text")}
                    action={() => {
                        handleUnbind()
                        action()
                    }}
                    toggle={setConfirmation}
                />
            }

            <PillHeaderModal
                title={
                    pending
                        ? t("vehicleDetails:electronicTag.modal.pendingTitle")
                        : t("vehicleDetails:electronicTag.modal.title")
                }
                actionText={t("vehicleDetails:electronicTag.modal.unbind")}
                action={() => setConfirmation(true)}
                toggle={toggle}
                pillContent={vehicle?.tagPreboarding?.tagNumber}
                pending={pending}
                noPadding
            >
                {
                    !pending &&
                    <div
                        onClick={() => navToInstructions()}
                        className="p-3 d-flex justify-content-between align-items-center hoverable">
                        <div className="d-flex align-items-center">
                            <Icon
                                src={electronicTagIcon}
                                alt=""
                                aria-hidden="true"
                            />
                            <span className="ml-3">{t("vehicleDetails:electronicTag.modal.date")}:</span>
                            <strong className='ml-1'>{formatMonthAndYear(vehicle?.tagPreboarding?.registrationDate)}</strong>
                        </div>
                        <div className="caret-right" />
                    </div>
                }

                {
                    pending &&
                    <div className="p-3 d-flex flex-column">
                        <div className='mb-3'>
                            <span>{t("vehicleDetails:electronicTag.modal.pendingText.0")}</span>
                            <strong>{t("vehicleDetails:electronicTag.modal.pendingText.1")}</strong>
                            <span>{t("vehicleDetails:electronicTag.modal.pendingText.2")}</span>
                        </div>
                        <div className='w-full d-flex flex-column justify-content-center align-items-center'>
                            <Button
                                className="btn-primary w-40"
                                onClick={() => handleRevalidate()}
                                disabled={disableRevalidate}
                            >
                                {t("vehicleDetails:electronicTag.checkStatus")}
                            </Button>
                            <Button
                                className="btn-outline-primary w-40 mt-2"
                                onClick={() => navToInstructions()}
                                disabled={false}
                            >
                                {t("vehicleDetails:electronicTag.moreInfo")}
                            </Button>
                        </div>
                    </div>
                }

                <div style={{ borderBottom: '1px solid lightgray ' }} />

                <div className="p-3">
                    <strong>{t("vehicleDetails:electronicTag.modal.options")}</strong>
                </div>

                <div style={{ borderBottom: '1px solid lightgray ' }} />

                <div className="p-3 lh-md">
                    <span>{t("vehicleDetails:electronicTag.modal.unbindText.0")}</span>
                    <strong>{t("vehicleDetails:electronicTag.modal.unbindText.1")}</strong>
                    <span>{t("vehicleDetails:electronicTag.modal.unbindText.2")}</span>
                </div>
            </PillHeaderModal>
        </>
    )
}

ElectronicTagModal.propTypes = {
    toggle: PropTypes.func,
    action: PropTypes.func,
    vehicleId: PropTypes.string
}

export default ElectronicTagModal