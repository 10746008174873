import React, {useState} from "react";
import Skeleton from "react-skeleton-loader";
import {useDispatch, useSelector} from "react-redux";
import {Collapse} from "reactstrap";
import _ from "lodash";

import Checkbox from "../ui/Checkbox";
import Text from "../ui/Text";
import * as Utils from "../../core/Utils";
import {formatCurrencyLabel} from "../../core/Utils";
import {Colors} from "../../Colors";
import {FontWeight} from "../ui/FontSize";
import {fineRemove, fineSelected} from "../../redux/fines/actions";
import {useTranslation} from "react-i18next";
import DateSquare from "../ui/DateSquare";

const FineListItem = (props) => {
    const [dropDown, handleDropDown] = useState(false);
    const dispatch = useDispatch();
    const acceptPayment = useSelector(s => s?.features?.payments?.enabled)

    const {t} = useTranslation();
    const {
        id,
        date,
        city,
        amount,
        ticket,
        law,
        points,
        type,
        discount,
        checked,
        lawNo
    } = props.fine;

    const manageCheckbox = async (checked) => {
        if (checked) dispatch(fineSelected(props.fine));
        else dispatch(fineRemove(props.fine));
    };

    const {loading} = props;

    const fineListItemStyle = {
        display: "flex",
        borderBottom: `1px solid ${Colors.lightGray}`,
        backgroundColor: dropDown ? "#eee" : null,
        color: dropDown ? "#000" : Colors.gray,
        padding: "15px",
        ...props.style,
    };
    let dateStr = Utils.format(date);
    let amountStr = formatCurrencyLabel(amount);

    return (
        <div
            id="fineListItem"
            className="accordion card-collapse hoverable"
            key={id}
            aria-expanded={dropDown}
            style={{
                ...buttonWrapperStyle,
                // border: dropDown
                //     ? `solid 1px ${Colors.borderDarkGray}`
                //     : "none",
            }}
        >
            <div>
                <div style={fineListItemStyle}>
                    <div className="col-4" style={rowListItems}>
                        {!loading ? (
                            <Checkbox
                                disabled={!acceptPayment}
                                checked={checked}
                                ariaLabel={`${t(
                                    "fineList:fineListItem.arias.fineInWords",
                                    {
                                        typeStr: t(
                                            type === "l" ? "license" : "vehicle"
                                        ),
                                        ...props.fine,
                                        dateStr: dateStr,
                                        amountStr: amountStr,
                                    }
                                )} ${t(
                                    "fineList:fineListItem.arias.selectFine"
                                )}`}
                                handleCheckbox={manageCheckbox}
                            />
                        ) : (
                            <Skeleton width="1rem"/>
                        )}
                        {!loading ? (
                            <span aria-hidden="true">
                                <DateSquare date={date}/>
                            </span>
                        ) : (
                            <Skeleton/>
                        )}
                    </div>
                    <div
                        className="col-5"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                        aria-hidden="true"
                    >
                        {!loading ? (
                            <Text className="text">{_.capitalize(city)}</Text>
                        ) : (
                            <Skeleton/>
                        )}
                        <a
                            style={{
                                color: Colors.primary,
                                textDecoration: "underline",
                            }}
                        >
                            {discount || " "}
                        </a>
                    </div>

                    <div
                        className="col-3"
                        style={{
                            padding: 0,
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                        onClick={() => handleDropDown(!dropDown)}
                    >
                        <div
                            style={{
                                ...rowListItems,
                                justifyContent: "flex-end",
                            }}
                        >
                            <span aria-hidden="true">
                                <Text
                                    style={{
                                        color: Colors.primary,
                                        fontWeight: FontWeight.bold,
                                        marginRight: "10px",
                                    }}
                                    className="text"
                                >
                                    {!loading ? (
                                        amountStr
                                    ) : (
                                        <Skeleton width="2rem"/>
                                    )}
                                </Text>
                            </span>
                            <button
                                id="toggleButton"
                                onClick={() => handleDropDown(!dropDown)}
                                role="button"
                                aria-label={t(
                                    "fineList:fineListItem.arias.toggleButton"
                                )}
                                aria-controls="fineListItem"
                                style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                }}
                            >
                                <div
                                    className="caret-right"
                                    style={{
                                        transform: dropDown && "rotate(90deg)",
                                        transition: "all 0.4s ease",
                                    }}
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Collapse isOpen={dropDown}>
                <div
                    style={{
                        ...fineListItemStyle,
                        borderTop: dropDown
                            ? `1px solid ${Colors.borderDarkGray}`
                            : null,
                    }}
                >
                    {props.withCheckbox && <div/>}
                    <div
                        className="col-4"
                        style={fineCol}
                        role="text"
                        aria-label={`${t(
                            "fineList:fineListItem.ticketNo"
                        )} ${ticket}`}
                    >
                        <span style={fineListSmallText}>
                            {t("fineList:fineListItem.ticketNo")}
                        </span>
                        <br/>
                        <Text className="text">{ticket}</Text>
                    </div>
                    <div
                        className="col-4"
                        style={fineCol}
                        role="text"
                        aria-label={`${t(
                            "fineList:fineListItem.lawNo"
                        )} ${ticket}`}
                    >
                        <span style={fineListSmallText}>
                            {t("fineList:fineListItem.lawNo")}
                        </span>
                        <br/>
                        <Text className="text">{lawNo}</Text>
                    </div>
                    {!loading && props.fine.points > 0 && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: 0,
                            }}
                            className="col-4"
                            role="text"
                            aria-label={`${t(
                                "fineList:fineListItem.points"
                            )} ${points}`}
                        >
                            <div style={fineCol}>
                                <span style={fineListSmallText}>
                                    {t("fineList:fineListItem.points")}
                                </span>
                                <br/>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        aria-hidden={true}
                                        data-tip={t(
                                            "fineList:fineListItem.pointTooltip",
                                            {
                                                points: props.fine.points,
                                            }
                                        )}
                                        data-for={props.fine.id + ""}
                                        className="badge badge-sm badge-warning rounded-circle"
                                        style={{
                                            width: "12px",
                                            height: "12px",
                                            fontSize: "8px",
                                            fontWeight: FontWeight.bold,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginRight: "4px",
                                        }}
                                    >
                                        P
                                    </span>
                                    <Text className="text">{points}</Text>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div style={{...fineListItemStyle, paddingTop: 0}}>
                    <div
                        className="col-12"
                        style={fineCol}
                        role="text"
                        aria-label={`${t("fineList:fineListItem.desc")} ${law}`}
                    >
                        <span style={fineListSmallText}>
                            {t("fineList:fineListItem.desc")}
                        </span>
                        <br/>
                        <Text className="text" style={{color: Colors.gray}}>
                            {law}
                        </Text>
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

const buttonWrapperStyle = {
    width: "100%",
    padding: 0,
};

const fineListSmallText = {
    fontSize: "13px",
    textTransform: "uppercase",
    color: Colors.gray,
};

const fineCol = {
    padding: 0,
    marginRight: "30px",
};

const rowListItems = {
    padding: 0,
    display: "flex",
    alignItems: "center",
};

export default FineListItem;
