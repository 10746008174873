import React from "react";
import Text from "../../components/ui/Text";
import Icon from "../../components/ui/Icon";

import sexoIcon from "../../assets/images/sexo-icon.svg";
import estaturaIcon from "../../assets/images/estatura-icon.svg";
import pesoIcon from "../../assets/images/peso-icon.svg";
import ojosIcon from "../../assets/images/ojos-icon.svg";
import donanteIcon from "../../assets/images/donante-icon.svg";
import tipoSangreIcon from "../../assets/images/tipo-sangre-icon.svg";
import nacimientoIcon from "../../assets/images/nacimiento-icon.svg";
import fechaEmisionIcon from "../../assets/images/fecha-emision-icon.svg";
import PageTitle from "../../components/ui/PageTitle";
import { Colors } from "../../Colors";
import { FontSize } from "../../components/ui/FontSize";
import { fixHeight, format } from "../../core/Utils";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SubHeaderIconRow = (props) => {
    const {
        sex,
        height,
        weight,
        eyeColor,
        hairColor,
        donor,
        bloodType,
        dateOfBirth,
        expeditionDate,
    } = props;


    const user = useSelector((state) => state?.login?.user);
    const isDonor = useSelector((state) => state?.card?.personalInfo?.donor);

    const { t } = useTranslation();

    const item = (key, icon, value) => (
        <div
            style={IconRowItem}
            role="text"
            aria-label={`${t(`profile:header.${key}.text`)} ${value}`}
        >
            <img src={icon} alt={""} aria-hidden={true} />
            <Text i18nKey={`profile:header.${key}.text`} style={ItemTextSm} />
            <Text
                style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textTransform: "capitalize",
                }}
            >
                {value}
            </Text>
        </div>
    );

    const valueByType = (val) => {
        if (typeof val === "boolean") return val ? "SI" : "NO";
        else if (_.isEmpty(val)) return "-";
        return val;
    };

    return (
        <div className="container" style={{ paddingRight: 0, paddingLeft: 0 }}>
            <PageTitle
                i18nKey={
                    user?.license?.type === "ID"
                        ? "layout:navPage.myId"
                        : "layout:navPage.myLicense"
                }
            />
            <div
                style={SubHeaderIconRowStyle}
                className="sub-header-icon-row scrollbar-horizontal"
            >
                {item("gender", sexoIcon, valueByType(sex))}
                {item("height", estaturaIcon, fixHeight(height))}
                {item("weight", pesoIcon, weight + " LBS")}
                {item("eyes", ojosIcon, valueByType(eyeColor))}
                {
                    isDonor && item("donor", donanteIcon, valueByType(isDonor))
                }
                {item("blood", tipoSangreIcon, valueByType(bloodType))}
                {item(
                    "dob",
                    nacimientoIcon,
                    format(dateOfBirth, "DD.MMM.YYYY")
                )}
                {item(
                    "issue",
                    fechaEmisionIcon,
                    format(expeditionDate, "DD.MMM.YYYY")
                )}
            </div>
        </div>
    );
};

const SubHeaderIconRowStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    overflowX: "auto",
};

const IconRowItem = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem",
    marginBottom: "1rem",
    paddingRight: "25px",
    textAlign: "center",
    whiteSpace: "nowrap",
};

const ItemTextSm = {
    fontSize: "13px",
    color: Colors.gray,
    textTransform: "uppercase",
    marginTop: FontSize.sm,
};

export default SubHeaderIconRow;
