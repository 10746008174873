import React, { useEffect } from "react";
import MenuArt from "../../assets/images/menu-art.svg";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LoadConfig = (props) => {
    const menuWrapperStyle = {
        display: "flex",
        justifyContent: "center",
    };

    const artStyle = {
        width: "100%",
        // marginLeft: "10%"
    };

    const config = useSelector((state) => state.config);
    const maintenance = useSelector((state) => state.features?.config?.web?.maintenance);
    const { authenticated } = useSelector((state) => state.login);
    let location = useLocation();
    let history = useHistory();

    const { t } = useTranslation();

    useEffect(() => {
        // debugger
        let live = JSON.parse(localStorage.getItem("live"));
        if (live === false) history.push("/maintenance");
        if (!config || authenticated === undefined) return;
        // debugger
        let { from } = location.state || { from: { pathname: "/" } };
        if (maintenance) history.push("/maintenance");
        else history.replace(from);

        /*//check if there's a session available restore the redux default store here
let ping = (async () => await ping())();
if (ping)
    props.history.push('/landing');*/
    }, [config, authenticated]);

    return (
        <div
            style={menuWrapperStyle}
            className={`u-fullscreen u-fullscreen--showed u-fullscreen__overlay-cesco-menu`}
        >
            <div className="container">
                <div className={"row"} style={{ marginTop: "15%" }}>
                    <div className={"col-md-7 text-center"}>
                        <div className="spinner-grow-huge" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div className={"col-md-5"}>
                        <img
                            src={MenuArt}
                            style={artStyle}
                            alt={t("loadConfig:imgAlt")}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadConfig;
