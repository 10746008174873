import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import CircleChart from "../../../components/ui/CircleChart";
import {calculatePointColor} from "../../../core/Utils";
import Text from "../../../components/ui/Text";
import {FontSize} from "../../../components/ui/FontSize";
import {Colors} from "../../../Colors";
import {useSelector} from "react-redux";
import goodStanding from "../images/icon_badge_puntos.svg";
import goodStandingEn from "../../../assets/images/icon_Badge_Puntos_Excelente_EN.svg";
import warningIcon from "../../../assets/images/icon_Warning_Red.svg";

const TOTAL_LIMIT = 25;

const PointScore = () => {
    const {t, i18n} = useTranslation();
    const [lang, setLang] = useState(i18n.language || "");

    const points = useSelector((state) => state.login?.user?.license?.points);
    let input = points && points / 25;
    let progress = input > 1 ? 100 : input * 100;
    let textColor = {
        color: points >= TOTAL_LIMIT ? Colors.error : Colors.gray,
    };

    useEffect(() => {
        setLang(i18n.language);
    }, [i18n.language]);

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "inherit",
                width: '100%'
            }}
            className="sub-header-container"
        >
            {points >= TOTAL_LIMIT ? (
                <img
                    src={warningIcon}
                    alt="Warning Icon"
                    className="d-none d-lg-block"
                    style={{marginRight: "8px"}}
                />
            ) : null}
            <div
                style={{color: Colors.gray, lineHeight: "1"}}
                className="sub-header-chart-text"
            >
                <Text
                    i18nKey="main:subHeader.pointScore"
                    style={textColor}
                />
            </div>
            <div>
                {points === 0 && (
                    <div>
                        <img
                            src={lang === "en-US" ? goodStandingEn : goodStanding}
                            alt={t("main:noNegativePoints")}
                            className="sub-header-icon"
                        />
                    </div>
                )}
                {points > 0 && (
                    <>
                        <div className="d-none d-lg-flex">
                            <CircleChart
                                progress={progress}
                                progressColor={calculatePointColor(progress)}
                            >
                                <div style={circleChartStyle}>
                                    <Text
                                        style={{
                                            ...textColor,
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                        }}
                                    >
                                        {points}/25
                                    </Text>
                                    <Text
                                        style={{
                                            ...textColor,
                                            fontSize: "11px",
                                            textTransform: "uppercase",
                                        }}
                                        i18nKey="main:subHeader.points"
                                    />
                                </div>
                            </CircleChart>
                        </div>
                        <div className="d-lg-none">
                            <CircleChart
                                progress={progress}
                                progressColor={calculatePointColor(progress)}
                                mobile
                            >
                                <div style={circleChartStyle}>
                                    <Text
                                        style={{
                                            ...textColor,
                                            fontWeight: "bold",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {points}/25
                                    </Text>
                                    <Text
                                        style={{
                                            ...textColor,
                                            fontSize: "8px",
                                            textTransform: "uppercase",
                                        }}
                                        i18nKey="main:subHeader.points"
                                    />
                                </div>
                            </CircleChart>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const circleChartStyle = {
    color: Colors.gray,
    marginTop: "8px",
    display: "flex",
    flexDirection: "column",
    fontSize: FontSize.sm,
};

export default PointScore;
