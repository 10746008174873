import React from "react";

import { Colors } from "../../Colors";

const TextPill = (props) => (
    <div
        className={`px-2 py-1 mr-3`}
        style={{ 
            borderRadius: 100, 
            color: props.error ? "white" : (props.grayed ? 'white' : 'black'),
            backgroundColor: props.error ? Colors.error : props.grayed ? Colors.gray : Colors.yellow
        }}
    >
        <small>
            <strong>{props.pillContent || "000"}</strong>
        </small>
    </div>
);

export default TextPill;