import React, { useState } from "react";

import Text from "../../../components/ui/Text";
import PageTitle from "../../../components/ui/PageTitle";
import SubHeader from "../../../layout/SubHeader";
import WizardFooter from "../../../components/widgets/WizardFooter";
import RenewalWizard from "../components/RenewalWizard";
import { Colors } from "../../../Colors";
import { FontWeight } from "../../../components/ui/FontSize";

import LogoCesco from "../../../assets/images/LogoCescoGreen.svg";
import IconDownload from "../../../assets/images/icon_download.svg";
import VoucherBody from "../../../components/ui/VoucherBody";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openLicenseVoucher } from "../../../redux/renewal/actions";
import { openVehicleVoucher } from "../../../redux/vehicleRenewal/vehicleRenewal.combine";
import DaysLeftSquare from "../../../components/ui/DaysLeftSquare";

import { useTranslation } from "react-i18next";
// TODO
// Connect to db

const Voucher = () => {
    const history = useHistory();
    let dispatch = useDispatch();

    const flow = useSelector((state) => state?.flow);

    let renewal = useSelector((state) => state.renewal);
    let vehicleRenewal = useSelector((state) => state.vehicleRenewal);
    let voucherData =
        flow === "RENEWAL" ? renewal?.voucherData : vehicleRenewal?.voucherData;
    let instructions = voucherData?.requiredDocuments;

    const onNext = () => {
        history.push("/landing");
    };

    const openReport = () => {
        if (flow === "RENEWAL") dispatch(openLicenseVoucher());
        else if (flow === "RENEWAL_VEHICLE") dispatch(openVehicleVoucher());
    };

    const { t } = useTranslation();

    // TODO: Validate with design guys if there exists differences between license and vehicle tag vouchers
    return (
        <>
            <SubHeader>
                <div className="container">
                    <PageTitle i18nKey="voucher:pageTitle" />
                    <RenewalWizard step={4} />
                </div>
            </SubHeader>

            <div
                className="container d-flex justify-content-center"
                style={{ marginBottom: "30px", textAlign: "center" }}
            >
                <Text i18nKey="voucher:printDocument" className="text" />
            </div>

            <div className="container" style={receiptStyle}>
                <div className="container" style={headerStyle}>
                    <div style={logoRow}>
                        <div>
                            <img
                                src={LogoCesco}
                                aria-hidden="true"
                                alt=""
                                style={{ width: "80%" }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={IconDownload}
                                aria-hidden="true"
                                alt=""
                                onClick={openReport}
                            />
                            <Text
                                style={{ color: Colors.primary }}
                                i18nKey="voucher:downloadButton"
                                className="text"
                            />
                        </div>
                    </div>

                    <div className="mt-4 mb-4" style={{ textAlign: "center" }}>
                        <Text
                            i18nKey="voucher:documentTitle"
                            className="text-md"
                            style={titleTextStyle}
                        />
                    </div>
                </div>

                <div style={idcStyle}>
                    <Text
                        i18nKey={`IDC# ${voucherData?.idcNumber}`}
                        className="text-md"
                        style={titleTextStyle}
                    />
                </div>

                <div style={subHeaderStyle} className="container">
                    <div style={subHeaderContent} className="receiptSubHeader">
                        <div
                            style={subHeaderCol}
                            className="receiptSubHeaderCol"
                        >
                            <Text
                                i18nKey="voucher:date"
                                style={subHeaderHeading}
                                className="text"
                            />
                            <Text style={bold} className="text">
                                {voucherData?.licenseRenewalDate}
                            </Text>
                        </div>

                        {flow === "RENEWAL_VEHICLE" || (
                            <div
                                style={subHeaderCol}
                                className="receiptSubHeaderCol"
                            >
                                <Text
                                    i18nKey="voucher:cesco"
                                    className="text"
                                    style={subHeaderHeading}
                                />
                                <Text style={bold} className="text">
                                    {voucherData?.cesco}
                                </Text>
                            </div>
                        )}

                        <div
                            style={subHeaderCol}
                            className="receiptSubHeaderCol"
                        >
                            <Text
                                i18nKey="voucher:expiration"
                                style={subHeaderHeading}
                                className="text"
                            />
                            <Text style={bold} className="text">
                                {voucherData?.digitalExpirationDate}
                            </Text>
                        </div>
                    </div>

                    <div className="d-flex d-lg-none">
                        <DaysLeftSquare days={10} />
                    </div>
                </div>
            </div>

            {flow === "RENEWAL_VEHICLE" && (
                <div
                    style={subHeaderStyle}
                    className="container voucher-vehicle-renewal"
                >
                    <div>
                        <div>
                            <Text
                                i18nKey="voucher:vehicleRenewal.vehicle"
                                style={subHeaderHeading}
                                className="text"
                            />
                            <Text style={bold} className="text">
                                1998 Harley Davidson Standard
                            </Text>
                        </div>

                        <div style={{ marginBottom: "10px" }}>
                            <Text
                                i18nKey="voucher:vehicleRenewal.plate"
                                style={subHeaderHeading}
                                className="text"
                            />
                            <Text style={bold} className="text">
                                WEBE8520146
                            </Text>
                        </div>

                        <div>
                            <Text style={subHeaderHeading} className="text">
                                VIN:
                            </Text>
                            <Text style={bold} className="text">
                                46B8521 JR0112F893
                            </Text>
                        </div>

                        <div>
                            <Text
                                i18nKey="voucher:vehicleRenewal.registrationNumber"
                                style={subHeaderHeading}
                                className="text"
                            />
                            <Text style={bold} className="text">
                                3358740
                            </Text>
                        </div>
                    </div>

                    <div style={insuranceBlock} className="insurance-block">
                        <Text
                            className="text-sm"
                            i18nKey="voucher:vehicleRenewal.insurance"
                            style={{ textTransform: "uppercase" }}
                        />
                        <Text className="text">ASEGURADORA IMAGE</Text>
                    </div>
                </div>
            )}
            <VoucherBody instructions={instructions} />

            <div
                className="container"
                style={{
                    height: "100px",
                    with: "100px",
                    backgroundColor: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        marginBottom: "15px",
                        height: "80px",
                        width: "80px",
                        backgroundColor: "#ccc",
                    }}
                ></div>
            </div>

            <div className="container">
                <WizardFooter
                    disabled={false}
                    singleButton={true}
                    handleNext={onNext}
                    nextText={t("voucher:finish")}
                />
            </div>
        </>
    );
};

const insuranceBlock = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
};

const receiptStyle = {
    backgroundColor: "#fff",
    padding: 0,
};

const headerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    paddingTop: "25px",
};

const subHeaderStyle = {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    borderBottom: `1px solid ${Colors.borderGray}`,
    backgroundColor: "#fff",
};

const subHeaderContent = {
    display: "flex",
    justifyContent: "space-between",
};

const subHeaderCol = {
    display: "flex",
    marginBottom: "2px",
};

const bold = {
    fontWeight: FontWeight.bold,
};

const subHeaderHeading = {
    textTransform: "uppercase",
    marginRight: "10px",
};

const bodyRow = {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 20px",
};

const liStyle = {
    marginTop: "4px",
    color: Colors.primary,
};

const daysOrangeBlock = {
    textAlign: "center",
    height: "65px",
    width: "65px",
    backgroundColor: Colors.orange,
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3px",
};

const logoRow = {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
};

const titleTextStyle = {
    fontWeight: FontWeight.bold,
    textTransform: "uppercase",
};

const idcStyle = {
    width: "100%",
    backgroundColor: Colors.lightGray,
    padding: "15px",
    display: "flex",
    justifyContent: "center",
};

export default Voucher;
