import React, { useEffect } from "react";

import CertificationsComponent from "./Certifications";
import renovacionMedico from "../../assets/images/renewal/medico.svg";
import renovacionLicencia from "../../assets/images/renewal/licencia.svg";
import formularioRenovacion from "../../assets/images/icon_Formulario_Renovacion.svg";
import {openDialog} from "../../core/Utils";



const FormCertifications = props => {


    const navigateTo = (location) =>{
        openDialog(location);
        // history.push(location);
    };

    const certifications = [
        // Renovación licencia
        {
            name: "certifications:forms.DTOP-DIS-256",
            icon: formularioRenovacion,
            action: ()=>navigateTo("https://assets-global.website-files.com/6130e2b84cbb4e6dc25d5f94/613b7de5834b2b5961574755_DTOP-D~2.PDF"),
            enable: true
        },
        // Renovación médico
        {
            name: "certifications:forms.DTOP-DIS-260",
            icon: renovacionMedico,
            action: ()=>navigateTo("https://assets-global.website-files.com/6130e2b84cbb4e6dc25d5f94/613b7ea78a9b4fe3e7696833_DTOP-DIS-260%20Certificacion%20Medica%20para%20Certificado%20de%20Licencia%20de%20Conducir%20%20Rev.%20%2024ago2017.pdf"),
            enable: true
        },
        // Solicitud licencia aprendizaje
        {
            name: "certifications:forms.DTOP-DIS-255",
            icon: renovacionLicencia,
            action: ()=>navigateTo("https://assets-global.website-files.com/6130e2b84cbb4e6dc25d5f94/613b7daacf77f0af0f3602ba_DTOP-D~1.PDF"),
            enable: true,
            infoLink: "https://www.dtop.pr.gov/formularios-de-solicitud-licencias/#1540480443733-f6f5dc23-0430"
        },
        // Solicitud licencia por reciprocidad
        {
            name: "certifications:forms.DTOP-DIS-257",
            icon: renovacionLicencia,
            action: ()=>navigateTo("https://assets-global.website-files.com/6130e2b84cbb4e6dc25d5f94/613b7e6bcc8d809111788afc_DTOP-DIS-257%20%20POR%20RECIPROCIDAD%20%20Rev.%2004nov2019.pdf"),
            enable: true,
            infoLink: "https://www.dtop.pr.gov/formularios-de-solicitud-licencias/#1540481098692-ddc7cd96-fb00"
        },
        // Solicitud de cambio de categoría de licencia
        {
            name: "certifications:forms.DTOP-DIS-258",
            icon: renovacionLicencia,
            action: ()=>navigateTo("https://assets-global.website-files.com/6130e2b84cbb4e6dc25d5f94/613b7e93cfaac7755e28b7b7_DTOP-DIS-258%20%20CAMBIO%20DE%20CATEGORIA%20Rev.%2026ene2018.pdf"),
            enable: true,
            infoLink: "https://www.dtop.pr.gov/formularios-de-solicitud-licencias/#1540480806182-2d0c1a4d-1b2e"
        },
    ];


    return (
        <CertificationsComponent
            certifications={certifications}
            source={"forms"}
            formCertifications
        />
    );
};

export default FormCertifications;
