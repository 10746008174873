import React from "react";
import {ReCaptcha} from "react-recaptcha-v3";
import {useDispatch} from "react-redux";
import {setRecaptcha} from "../../redux/app/app.combine";

const Recaptcha = props => {

    const dispatch = useDispatch();

    const verifyCallback = (recaptchaToken) => {
        dispatch(setRecaptcha(recaptchaToken));
        if(props.doAuth)
            props.doAuth();
    };

    const { forwardRef } = props;

    return (
        <ReCaptcha
            ref={forwardRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            action={props.action}
            verifyCallback={verifyCallback}
        />

    );
};




export default Recaptcha;
