import React from "react";

import Icon from "../../components/ui/Icon";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Card from "../ui/Card";
import { Colors } from "../../Colors";
import Text from "../ui/Text";
import { FontWeight } from "../ui/FontSize";

const CourseAccordion = (props) => {

    let { items } = props;
    let { t } = useTranslation();

    return (
        <Card noPadding>
            <ul
                className="list-group"
                type={"Listado de certificaciones para descargar"}
            >
                {items &&
                    items.map((item, idx) => {
                        return (
                            <button
                                key={idx}
                                className={`list-group-item d-flex justify-content-between align-items-center hoverable ${!item.enable && "disabled"}`}
                                style={{
                                        opacity: item.enable
                                            ? 1
                                            : 0.5,
                                    borderRadius: 0,
                                    padding: "1rem",
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                }}
                                onClick={
                                    item.enable
                                        ? () => item.action()
                                        : _.noop
                                }
                            >
                                <div
                                    style={{
                                        color: item.enable
                                            ? "#000"
                                            : Colors.gray,
                                        display: "flex",
                                        width: "80%",
                                        alignItems: 'center'
                                    }}
                                >
                                    <Icon
                                        alt={""}
                                        src={item.icon}
                                        style={{ marginRight: "1rem" }}
                                    />

                                    <Text
                                        className="text"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            textAlign: 'left',
                                            fontWeight: FontWeight.medium,
                                        }}
                                    >
                                        {t(`${item.name}`)}
                                    </Text>
                                </div>

                                <div className="caret-right" />
                            </button>
                        );
                    })}
            </ul>
        </Card>
    );
};

export default CourseAccordion;
