import React from "react";

import Skeleton from "react-skeleton-loader";

import {format, formatCurrencyLabel, getReceiptMotive} from "../../core/Utils";

import Text from "../../components/ui/Text";
import CreditCardIcon from "../../components/ui/CreditCardIcon";
import {Colors} from "../../Colors";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {useHistory} from "react-router-dom";
import DateSquare from "../../components/ui/DateSquare";
import TextPill from "../../components/ui/TextPill";


const PaymentHistoryListItem = props => {
    const {index} = props
    const {
        transactionId,
        processedDate,
        receiptNo,
        paymentMethod,
        paymentType,
        processed,
        total,
        message,
        status,
    } = props.payment;

    const {t} = useTranslation();

    const history = useHistory();

    const {loading} = props;

    const onClick = (transactionId, paymentType) => {
        if (transactionId) history.push(`/payment-history?id=${transactionId}${paymentType === "AUTOEXPRESO_FINES" ? "&autoexpreso=true" : ""}`)
    }

    return (
        <button
            key={index}
            onClick={() => onClick(transactionId, paymentType)}
            style={{...paymentHistoryStyle, backgroundColor: status === "DISPUTED" ? Colors.lightGray : 'transparent'}}
            className="hoverable alert-padding"
            aria-label={`${t("paymentHistory:alt")} ${format(processedDate, "DD/MMMM/YYYY")} ${formatCurrencyLabel(total)}`}
        >
            <div className="col-md-1 col-3 text-left">
                {!loading ? <DateSquare date={processedDate} grayed={status === "DISPUTED"} error={status === "ERROR"}/> : <Skeleton/>}
            </div>
            <div className="col-md-3 col-5 text-left">
                {!loading ? 
                    <Text className="text" style={{color: status === "ERROR" ? Colors.error : Colors.gray}}>
                        {_.defaultTo(transactionId, "-")}
                    </Text> 
                    : <Skeleton/>
                }
            </div>
            {!props.small && (
                <div className="col-md-4 d-none d-sm-block text-left">
                    {!loading ? 
                        <span style={{
                            color: status === "DISPUTED" ? 
                                Colors.gray : 
                                (processed ? Colors.primary : Colors.error),
                        }}>
                            {t(getReceiptMotive(paymentType))}
                        </span> 
                        : <Skeleton/>
                    }
                </div>
            )}
            {!props.small && 
                <div className="col-md-1 col-5 text-center d-flex justify-content-left">
                    {status !== "COMPLETE" && !loading ? (
                        <TextPill pillContent={t(`paymentHistory:${_.toLower(status)}`)} grayed={status === "DISPUTED"} error={status === "ERROR"}/>
                    ) : (
                        null
                    )}
                </div>
            }
            <div
                className="col-md-2 d-flex d-sm-block justify-content-center p-0"
                style={{textAlign: "center"}}
            >
                {!loading ? (
                    <CreditCardIcon paymentMethod={paymentMethod} noPadding/>
                ) : (
                    <Skeleton width="3rem"/>
                )}
            </div>
            <div
                className="col-md-1"
                style={{textAlign: "right", padding: 0}}
            >
                <Text
                    className="text"
                    style={{
                        color: status === "DISPUTED" ? Colors.gray : (processed ? Colors.primary : Colors.error),
                        fontWeight: "bold",
                        textDecoration: status === "DISPUTED" ? "line-through" : "none"
                    }}
                >
                    {!loading ? formatCurrencyLabel(total) : <Skeleton width="3rem"/>}
                </Text>
            </div>
        </button>
    );
};

const paymentHistoryStyle = {
    width: "100%",
    // padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    border: 'none',
    borderBottom: `1px solid ${Colors.lightGray}`,
    cursor: "pointer",
    color: Colors.gray,
    alignItems:'center'

};

export default PaymentHistoryListItem;
