import React from 'react'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../../Colors'

import iconCursos from "../../../\assets/images/icon_Cursos_Chart.svg"
import { useHistory } from 'react-router-dom'

const CourseChart = () => {

    const { t } = useTranslation()
    const history = useHistory()

    const action = () => {
        history.push('/courses')
    }

    return (
        <div
            style={courseChartStyle}
            className="d-flex flex-column-reverse flex-lg-row justify-content-between align-items-center"
            onClick={() => action()}
        >
            <span
                style={{ color: Colors.gray }}
                className="text">{t("main:subHeader.onlineCourses.0")}
                <br />
                {t("main:subHeader.onlineCourses.1")}
            </span>
            <div>
                <img src={iconCursos} className="sub-header-icon" alt="" aria-hidden />
            </div>
        </div>
    )
}
const courseChartStyle = {
    width: '100%',
    height: '100%',
    lineHeight: 1,
}

export default CourseChart