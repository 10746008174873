import * as Api from "../../core/APICaller";
import * as Utils from "../../core/Utils";
import {toastr} from "react-redux-toastr";
import _ from "lodash"
import {fetchAcaaReportAsB64} from "../../core/APICaller";
import PDFMerger from 'pdf-merger-js/browser';

export const LOADING_START = "LOADING_START";
export const LOADING_END = "LOADING_END";

//TODO: check if this action need to be moved to another redux
export const openVehicleFinesReport = (vehicleId) => (dispatch) => {
    dispatch({type: LOADING_START});
    Api.fetchVehicleFinesReports(
        vehicleId,
        data => {
            Utils.openDialog(data);
        },
        reportError
    ).finally(() => dispatch({type: LOADING_END}));
};

export const openVehicleLicenseReport = (vehicleId) => (dispatch) => {
    dispatch({type: LOADING_START});
    Api.fetchVehicleLicenseReport(
        vehicleId,
        data => {
            Utils.openDialog(data);
        },
        reportError
    ).finally(() => dispatch({type: LOADING_END}));

};

export const openVehicleCertificationReport = (vehicleId) => (dispatch) => {
    dispatch({type: LOADING_START});
    Api.fetchVehicleCertificationReport(
        vehicleId,
        data => {
            Utils.openDialog(data);
        },
        reportError
    ).finally(() => dispatch({type: LOADING_END}));

};


export const openAcaaVehicle = (vehicleId) => async (dispatch, getState) => {
    dispatch({type: LOADING_START});
    const vehicleLicenseMergeFines = getState().features?.vehicleRenewal?.vehicleLicenseMergeFines
    if (!vehicleLicenseMergeFines) {
        Api.fetchAcaaReportAsB64(vehicleId, (data) => {
            Utils.openDialog(data);
        }).finally(() => dispatch({type: LOADING_END}));
        return;
    }
    const [vehicleLicense, finesCert] = await Promise.allSettled([
        Api.fetchAcaaReportAsB64(vehicleId),
        Api.fetchFinesReportAsB64(vehicleId)
    ]).finally(() => dispatch({type: LOADING_END}));
    for (const outcome of [vehicleLicense, finesCert]) {
        if (outcome.status === "rejected") {
            // handle the error
            console.error('error fetching report in openAcaaVehicle: ', outcome.reason); // log the error
            throw outcome.reason; // rethrow the error
        }
    }
    const fetch1 = await fetch(vehicleLicense.value)
    const blob1 = await fetch1.blob()
    const fetch2 = await fetch(finesCert.value)
    const blob2 = await fetch2.blob()
    const merger = new PDFMerger();
    await merger.add(blob1)
    await merger.add(blob2)
    const mergedPdf = await merger.saveAsBlob();
    let reader = new FileReader ();
    reader.readAsDataURL (mergedPdf);
    reader.onloadend = function () {
        const base64data = reader.result; // this is your base64 string
        Utils.openDialog(base64data);
    }
};

export const reportError = () => {
    showError('Ocurrio un error, generando el reporte')();
};

export const showError = (msg) => {
    return dispatch => toastr.error('Error', msg || 'Ocurrio un error, disculpe el incoveniente')
};

export const fullPageLoadingOn = () =>(dispatch)=>{
    dispatch({type: LOADING_START});
};

export const fullPageLoadingOff = () =>(dispatch)=>{
    dispatch({type: LOADING_END});
};


export const all = (...actions) => async (dispatch) => {
    dispatch({type: LOADING_START});

    actions.forEach(a => {
        dispatch(a())

    });
    dispatch({type: LOADING_START});

};

export const caller = (...promises) => async (dispatch) => {
    dispatch({type: LOADING_START});
    let promiseArr = Promise.all(promises).then(() => {
        dispatch({type: LOADING_END})
    });
    Promise
        .race([promiseArr/*, timeoutPromise*/])
        .finally((res) => {
            console.log(res)
        })

};

export function loadingState(state = null, action) {

    switch (action.type) {
        case LOADING_START:
            return {loading: true};
        case  LOADING_END:
            return {loading: false};
        default:
            return state
    }
}
