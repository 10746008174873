import React, {Component} from 'react';

class ErrorHandler extends Component {

    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        console.error(`error = ${error} with info = ${info}`)
        this.setState({hasError: true});
        // window.location = "/error"

    }

    render() {
        if (this.state.hasError) {
            return null;
        }
        return this.props.children;
    }
}

export default ErrorHandler;