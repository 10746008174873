import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {Router} from "react-router-dom";
import "./i18n";
import {Provider} from "react-redux";
import store from "./store";
import Init from "./Init";
import ReduxToastr from "react-redux-toastr";
import {configureStore} from "./core/APICaller";
import {createBrowserHistory} from "history";
import {resetFlow} from "./redux/app/app.combine";
import ErrorHandler from "./ErrorHandler";
import ServiceWorkerWrapper from "./core/ServiceWorkerWrapper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

configureStore(store);

const context = process.env.CONTEXT;
const history = createBrowserHistory();

// TODO remove conditional comments for prod env
history.listen(location => {
    let {pathname} = location;
    let {dispatch} = store;

    if (!pathname.startsWith("/renewal") && !pathname.includes("/pay/")) {
        // dispatch(resetCart());
        dispatch(resetFlow());
    }
});


ReactDOM.render(
    <Provider store={store}>
        <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            getState={state => state.toastr}
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            closeOnToastrClick
        />

        <Init pathname={history?.location?.pathname}>
            <Router basename={context} history={history}>
                <ErrorHandler>
                    <App/>
                </ErrorHandler>
            </Router>
        </Init>
        <ServiceWorkerWrapper/>
    </Provider>,
    document.getElementById("root")
);

let styles = [
    ,
    "border: 1px solid #3E0E02",
    "color: white",
    "font-size: 60px",
    "display: block",
    "line-height: 100px",
    "text-align: center",
    "font-weight: bold"
].join(";");

let caption = [
    ,
    "border: 1px solid #3E0E02",
    "color: white",
    "font-size: 18px",
    "display: block",
    "line-height: 100px",
    "text-align: center"
].join(";");

console.log("%c CESCO Digital", styles);
console.log("%c Hello developers!!! ", caption);

if (process.env.NODE_ENV !== "DEV" && process.env.NODE_ENV !== "development") {
    console.warn = () => { };
    console.log = () => { };
    console.error = () => { };
}
