import React, { useState, useEffect } from "react";

import "./WizardPayment.css";
import PageTitle from "../ui/PageTitle";
import { FontWeight } from "../ui/FontSize";
import { Colors } from "../../Colors";
import Text from "../ui/Text";
import { useTranslation } from "react-i18next";

import checkIcon from "../../assets/images/ic_check_48px.svg";
import errorIcon from "../../assets/images/icon_Wizard_Unsucessful.svg";

const WizardPayment = (props) => {
    const { step, labels, error } = props;
    const [wizardStep, toggleWizardStep] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        if (step) {
            toggleWizardStep(step);
        }
    }, []);

    const wizardDot = (step, active = false, useLine = true, index) => {
        const done = step < wizardStep;

        return (
            <React.Fragment key={index}>
                {error ? (
                    <div style={{ ...dot, backgroundColor: Colors.error }}>
                        {error && (
                            <img
                                src={errorIcon}
                                alt={"Checked"}
                                style={{ width: "15px" }}
                            />
                        )}
                    </div>
                ) : (
                    <div
                        style={{
                            ...dot,
                            backgroundColor: active ? Colors.primary : "#fff",
                            border: active
                                ? "none"
                                : `3px solid ${Colors.primary}`,
                        }}
                    >
                        {done && (
                            <img
                                src={checkIcon}
                                alt={"Error"}
                                style={{ width: "25px" }}
                            />
                        )}
                    </div>
                )}
                {useLine && (
                    <div
                        style={{
                            ...line,
                            backgroundColor: error ? Colors.error : done ? Colors.primary : "#fff",
                        }}
                    ></div>
                )}
            </React.Fragment>
        );
    };

    return (
        <div className={"container"} style={{ marginBottom: "40px" }}>
            {/* Page title should be on page SubHeader */}
            {/* <PageTitle i18nKey="payFines:pageTitle" /> */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "25px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                    }}
                    className="wizard-wrapper"
                >
                    {labels && labels.length > 0
                        ? labels.map((l, i) => (
                              <div
                                  key={i}
                                  style={labelStyle}
                                  aria-hidden={
                                      step === i + 1 ? "false" : "true"
                                  }
                                  role="text"
                                  aria-label={`${t(
                                      "payFines:wizard.aria.stepAria"
                                  )}: ${l}`}
                              >
                                  <Text
                                      style={{
                                          ...textStyle,
                                          color:
                                          error ? Colors.error :
                                              step >= i + 1
                                                  ? Colors.primary
                                                  : null,
                                      }}
                                      i18nKey={l}
                                  />
                              </div>
                          ))
                        : null}
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                    className="wizard-stepper"
                    aria-hidden="true"
                >
                    {labels && labels.length > 0
                        ? labels.map((l, i) =>
                              wizardDot(
                                  i + 1,
                                  wizardStep >= i + 1,
                                  labels.length - 1 === i ? false : true,
                                  i
                              )
                          )
                        : null}
                </div>
            </div>
        </div>
    );
};

const labelStyle = {
    width: "200px",
    display: "flex",
    justifyContent: "center",
};

const dot = {
    height: 35,
    width: 35,
    borderRadius: 35 / 2,
    padding: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
};

const line = {
    height: 10,
    flexGrow: 1,
    margin: "0 -1px",
    zIndex: 0,
};

const textStyle = {
    fontSize: "16px",
    fontWeight: FontWeight.medium,
    textTransform: "uppercase",
};

export default WizardPayment;
