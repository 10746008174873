import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    addPaymentMethod,
    completePayment,
    submitPayAllAutoexpreso,
    submitPayAutoexpreso,
} from "../../../redux/payment/payment.combine";

import TotalSummary from "../../../components/widgets/TotalSummary";
import InformationRow from "../../../components/ui/InformationRow";
import PaymentDetailsForm from "../components/PaymentDetailsForm";
import PayFinesWizard from "../components/PayFinesWizard";
import RenewalWizard from "../../Renewal/components/RenewalWizard";
import ComponentTitle from "../../../components/ui/ComponentTitle";
import FineTotalBadge from "../../../components/ui/FineTotalBadge";
import { useHistory } from "react-router-dom";
import WizardFooter from "../../../components/widgets/WizardFooter";
import InfoModal from "../../../components/ui/InfoModal";
import SubHeader from "../../../layout/SubHeader";
import { submitLicenseRenewal } from "../../../redux/renewal/actions";
import { submitVehicleRenewal } from "../../../redux/vehicleRenewal/vehicleRenewal.combine";
// Payment details form should work with state and console log its data on button click
import ConfirmationModal from "../../../components/ui/ConfirmationModal";
import { resetCart } from "../../../redux/fines/actions";
import { resetFlow } from "../../../redux/app/app.combine";
import AutoexpresoTotalSummary from "../../../components/widgets/AutoexpresoTotalSummary";
import ErrorModal from "../../../components/ui/ErrorModal";

const PaymentDetail = (props) => {

    const [valid, setValid] = useState(false);
    const [creditCard, setCreditCard] = useState(null);

    const { cart } = useSelector((state) => state?.fines);
    const flow = useSelector((state) => state?.flow);
    const profile = useSelector((state) => state.profile);

    const [infoModal, toggleInfoModal] = useState(false);

    const [confirmationModal, toggleConfirmation] = useState(false);

    const [showAddressInput, setShowAddressInput] = useState(false);

    const [validCardForm, setValidCardForm] = useState(false);

    const [submitPaymentDisabled, setSubmitPaymentDisabled] = useState(false);

    const [paymentError, setPaymentError] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        // If invalid email navigate to /email/validation
        if (!profile?.validEmail) history.push("/pay/email/validation");

        // if (valid) {
            // dispatch(addPaymentMethod(creditCard));
        // }
    }, [valid, creditCard, profile]);

    const handleSetCreditCardAndValid = (creditCard, valid) => {
        delete creditCard.zipcode;
        if (valid && creditCard) setCreditCard(creditCard);

        let validAmount = false;

        if (flow && flow.startsWith("RENEWAL")) {
            validAmount = paymentTotal?.total > 0
        } else {
            validAmount = paymentTotal?.finesTotal > 0
        }

        setValid(valid && validAmount);
    };
    const onSuccess = () => {
        if (flow && flow === "RENEWAL") {
            history.push("/renewal/completed");
        } else history.push("/pay/complete");

        setSubmitPaymentDisabled(false);
    };

    const notProcessedError = () => {

        // close confirmation modal
        toggleConfirmation(false);
        // return to credit card input
        setShowAddressInput(false);
        setPaymentError(true);

        setSubmitPaymentDisabled(false);
    };
    
    const onError = () => {
        history.push("/pay/error");

        setSubmitPaymentDisabled(false);
    };

    const submitPayment = () => {
        dispatch(completePayment(onSuccess, onError, notProcessedError));
    };

    const toggleModal = () => {
        toggleInfoModal(!infoModal);
    };

    let { paymentTotal } = cart;

    const onNext = () => {

        if (!valid) return;

        setSubmitPaymentDisabled(true);

        if (flow === "RENEWAL") {
            dispatch(submitLicenseRenewal(onSuccess, onError));
        } else if (flow === "RENEWAL_VEHICLE") {
            dispatch(submitVehicleRenewal(onSuccess, onError));
        } else if (flow === "AUTOEXPRESO") {
            dispatch(submitPayAutoexpreso(onSuccess, onError, notProcessedError));
        } else if (flow === "AUTOEXPRESO_PAY_ALL") {
            dispatch(submitPayAllAutoexpreso(onSuccess, onError, notProcessedError));
        } else submitPayment();

    };

    const cancelAction = () => {
        dispatch(resetCart());
        if (flow && flow.startsWith("RENEWAL")) {
            dispatch(resetFlow());
        }
        history.push("/landing");
    };

    const handleButtonDisabled = () => {
        if (!showAddressInput) return !validCardForm;

        return !valid;
    };

    const handleNext = () => {

        showAddressInput ? toggleConfirmation(true) : setShowAddressInput(true)
    }

    return (
        <>
            <SubHeader>
                {flow === "RENEWAL" ? (
                    <RenewalWizard step={2} />
                ) : (
                    <PayFinesWizard step={2} />
                )}
            </SubHeader>

            {infoModal ? <InfoModal toggle={toggleModal} /> : null}

            {
                paymentError && 
                <ErrorModal 
                    title={t("payFines:payment.errorModal.title")}
                    toggle={() => setPaymentError(false)}
                    action={() => setPaymentError(false)}
                    actionText={t("payFines:payment.errorModal.action")}
                >
                    <span>{t("payFines:payment.errorModal.text")}</span>
                </ErrorModal>
            }

            <div className={"container"}>
                <div className={"row"}>
                    <div
                        className={"col-md-5 col-12"}
                        style={{ marginBottom: "1rem" }}
                    >
                        <ComponentTitle
                            i18nKey={"payFines:total"}
                            amount={cart?.fines.length}
                        >
                            <FineTotalBadge amount={cart?.fines.length} />
                        </ComponentTitle>
                        {
                            flow?.includes("AUTOEXPRESO") ? (
                                <AutoexpresoTotalSummary
                                    finesTotal={paymentTotal?.finesTotal}
                                    tollAmount={paymentTotal?.tollAmount}
                                    total={paymentTotal?.total}
                                />
                            ) : (
                                <TotalSummary
                                    finesTotal={paymentTotal?.finesTotal}
                                    finesPenalties={paymentTotal?.finesPenalties}
                                    finesCredits={paymentTotal?.finesCredits}
                                    total={paymentTotal?.total}
                                    renewal={
                                        flow === "RENEWAL" || flow === "RENEWAL_VEHICLE"
                                    }
                                />
                            )}
                    </div>

                    <div className={"col-md-7 col-12"}>
                        <ComponentTitle
                            noBorder
                            i18nKey={showAddressInput ? "payFines:addressForm" : "payFines:paymentDetails"}
                        />
                        <PaymentDetailsForm
                            handleSetCreditCardAndValid={handleSetCreditCardAndValid}
                            toggleInfoModal={toggleInfoModal}
                            showAddressInput={showAddressInput}
                            setValidCardForm={setValidCardForm}
                        />
                    </div>
                </div>

                {confirmationModal ? (
                    <ConfirmationModal
                        action={onNext}
                        toggle={toggleConfirmation}
                        text={t("payFines:confirmationModalDetail")}
                        title={t("payFines:confirmationModalDetailTitle")}
                        actionDisabled={submitPaymentDisabled}
                    />
                ) : null}

                <WizardFooter
                    // disabled={!valid}
                    disabled={handleButtonDisabled()}
                    cancelAction={cancelAction}
                    customBackAction={
                        showAddressInput
                            ? () => setShowAddressInput(false)
                            : null
                    }
                    handleNext={() => handleNext()}
                    nextText={
                        !showAddressInput
                            ? t("payFines:footer.continue")
                            : t("payFines:footer.submitPayment")
                    }
                />
            </div>

            <InformationRow textKey={showAddressInput ? "payFines:footer.address" : "payFines:footer.text"} />
        </>
    );
};

export default PaymentDetail;
