import { api, handleResponse } from "../APICaller";

const MOCK = false

const base = MOCK ? '/cd-fines/mock/autoexpreso/fines' : '/cd-fines/autoexpreso/fines'

const URL = {
    finesSummary: (vehicleId) => `${base}/${vehicleId}/summary`,
    finesFetch: (vehicleId, pageNumber, pageSize) => `${base}/${vehicleId}/fetch?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    eligibility: (vehicleId) => `${base}/${vehicleId}/eligibility`,
    pay: (vehicleId) => `${base}/${vehicleId}/pay`,
    payAllSummary: (vehicleId) => `${base}/${vehicleId}/pay/all/summary`,
    payAll: (vehicleId) => `${base}/${vehicleId}/pay/all`,
    payAutoexpreso: (vehicleId) => `${base}/${vehicleId}/pay`,
}

export function getAutoexpresoFinesMock(vehicleId, onSuccess, onError) {

    if (!vehicleId) throw new Error('No vehicleId provided to getAutoexpresoFines.')

    return api
        .get(`/cd-fines/autoexpreso/fines/1234/fetch`)
        .then((response) => handleResponse(response, onSuccess, onError));
}


export function autoexpresoFinesSummary(vehicleId, payload = {}, onSuccess, onError) {

    if (!vehicleId) throw new Error('No vehicleId provided to autoexpresoFinesSummary.')

    return api
        .post(URL.finesSummary(vehicleId), payload)
        .then((response) => handleResponse(response, onSuccess, onError));
}


export function autoexpresoFinesFetch(vehicleId, pageNumber, pageSize, onSuccess, onError) {

    if (!vehicleId) throw new Error('No vehicleId provided to autoexpresoFinesFetch.')

    return api
        .get(URL.finesFetch(vehicleId, pageNumber, pageSize))
        .then((response) => handleResponse(response, onSuccess, onError));
}


export function autoexpresoEligibility(vehicleId, onSuccess, onError) {

    if (!vehicleId) throw new Error('No vehicleId provided to autoexpresoEligibility.')

    return api
        .get(URL.eligibility(vehicleId))
        .then((response) => handleResponse(response, onSuccess, onError));
}


export function autoexpresoFinesPay(vehicleId, payload, onSuccess, onError) {

    if (!vehicleId) throw new Error('No vehicleId provided to autoexpresoFinesPay.')

    return api
        .post(URL.pay(vehicleId), payload)
        .then((response) => handleResponse(response, onSuccess, onError));
}


export function autoexpresoPayAllSummary(vehicleId, onSuccess, onError) {

    if (!vehicleId) throw new Error('No vehicleId provided to autoexpresoFinesPay.')

    return api
        .get(URL.payAllSummary(vehicleId))
        .then((response) => handleResponse(response, onSuccess, onError));
}


export function autoexpresoPayAll(vehicleId, payload, onSuccess, onError) {

    if (!vehicleId) throw new Error('No vehicleId provided to autoexpresoPayAll.')

    return api
        .post(URL.payAll(vehicleId), payload)
        .then((response) => handleResponse(response, onSuccess, onError));
}

export function autoexpresoPay(vehicleId, payload, onSuccess, onError) {

    if (!vehicleId) throw new Error('No vehicleId provided to autoexpresoPay.')

    return api
        .post(URL.payAutoexpreso(vehicleId), payload)
        .then((response) => handleResponse(response, onSuccess, onError));
}
