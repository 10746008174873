import React from "react";
import { useDispatch, useSelector } from "react-redux";
import FinesControlRowMobile from "./FinesControlRowMobile";
import { fineSelectAll } from "../../redux/fines/actions";
import { selectFines } from "../../redux/payment/payment.combine";
import { useHistory } from "react-router-dom";
import PayWithAmountButton from "../ui/PayWithAmountButton";
import Checkbox from "../ui/Checkbox";
import PropTypes from 'prop-types';
import { autoexpresoFlow } from "../../redux/app/app.combine";
import payAllIcon from '../../assets/images/icon_Checkbox_PagarTodos.svg'
import PayAllButton from "../ui/PayAllButton";

const FineControlRow = (props) => {

    const { vehicle, loading, noSelectAll, customDeselectAll, payAll, autoexpreso, payAllDisabled, payDisabled } = props

    const history = useHistory()
    const dispatch = useDispatch()

    let userFines = useSelector((state) => state.fines);

    const selectAllClick = (checked) => {
        dispatch(fineSelectAll(checked));
    };

    const deselectAll = () => {
        if (customDeselectAll) {
            customDeselectAll()
        } else {
            dispatch(fineSelectAll());
        }
    };

    const handlePayFines = () => {
        if (autoexpreso) {
            dispatch(autoexpresoFlow())
            history.push(`/pay/fines/autoexpreso/${vehicle.id}`);
        } else {

            history.push("/pay/fines");
        }
    };

    return (
        <div
            style={{
                margin: "1rem 0 1rem 0",
                display: "flex",
                justifyContent: "space-between",
                marginTop: 15,
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: 17,
                }}
            >
                {
                    payAll && (
                        <PayAllButton action={payAll} disabled={payAllDisabled} />
                    )
                }
                {!noSelectAll && (
                    <Checkbox
                        checked={userFines.cart.all}
                        ariaLabel={"profile:selectAllArial"}
                        disabled={vehicle?.unpaidFines === 0}
                        handleCheckbox={selectAllClick}
                        label={"profile:selectAll"}
                    />
                )}
            </div>
            <PayWithAmountButton
                deselectAll={deselectAll}
                userFines={userFines}
                handlePayFines={handlePayFines}
                payDisabled={payDisabled}
            />
        </div>
    )
}

FineControlRow.propTypes = {
    vehicle: PropTypes.object,
    loading: PropTypes.bool,
    noSelectAll: PropTypes.bool,
    customDeselectAll: PropTypes.func,
    payAll: PropTypes.func
}

export default FineControlRow

