import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Colors } from "../../Colors";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import InfoModal from "../../components/ui/InfoModal";
import PageTitle from "../../components/ui/PageTitle";
import Text from "../../components/ui/Text";
import TextInput from "../../components/ui/TextInput";

import importeArt from '../../assets/images/vehicleLicense/importeTotal.svg'
import numControlArt from '../../assets/images/vehicleLicense/numControl.svg'
import numRegistracionArt from '../../assets/images/vehicleLicense/numRegistracion.svg'
import initialStateArt from '../../assets/images/vehicleLicense/instrucciones.svg'
import vehicleArt from '../../assets/images/vehicleLicense/vehiculo.svg'
import completadoArt from '../../assets/images/vehicleLicense/completado.svg'
import invalidArt from '../../assets/images/vehicleLicense/noValidado.svg'
import { useEffect } from "react";
import { postVehicleLicense } from "../../core/api-resources/vehicleLicense";
import { Controller, useForm } from "react-hook-form";
import { useRef } from "react";
import { loadReCaptcha } from "react-recaptcha-v3";
import Recaptcha from "../Login/Recaptcha";

import CurrencyFormat from 'react-currency-format';


const VehicleLicense = () => {

    const { t } = useTranslation()

    const [initialState, setInitialState] = useState(true)
    const [result, setResult] = useState(false)
    const [success, setSuccess] = useState(true)

    const [focusedInput, setFocusedInput] = useState(1)
    const [instructions, setInstructions] = useState(null)

    const [modal, setModal] = useState(false)

    const [submitted, setSubmitted] = useState(false)

    const recaptchaRef = useRef();
    const formRef = useRef(null)

    const { register, handleSubmit, formState: { errors }, reset, control, watch } = useForm();

    const formState = watch()

    useEffect(() => {
        loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY);
    }, []);

    useEffect(() => {

    }, []);

    // Set instruction text
    useEffect(() => {
        handleInstructions()
    }, [focusedInput])

    const handleInstructions = () => {

        const text = {
            registration: (
                <div style={{ lineHeight: 1.3 }}>
                    <span>{t('vehicleLicense:instructions.registration.0')}</span>
                    <strong>{t('vehicleLicense:instructions.registration.1')}</strong>
                    <span>{t('vehicleLicense:instructions.registration.2')}</span>
                    <strong>{t('vehicleLicense:instructions.registration.3')}</strong>
                    <span>{t('vehicleLicense:instructions.registration.4')}</span>
                    <strong><i>{t('vehicleLicense:instructions.registration.5')}</i></strong>
                    <br /><br />
                    <span>{t('vehicleLicense:instructions.registration.6')}</span>
                    <strong style={{ color: Colors.error }}>{t('vehicleLicense:instructions.registration.7')}</strong>
                </div>
            ),
            controlNumber: (
                <div style={{ lineHeight: 1.3 }}>
                    <span>{t('vehicleLicense:instructions.controlNumber.0')}</span>
                    <strong>{t('vehicleLicense:instructions.controlNumber.1')}</strong>
                    <span>{t('vehicleLicense:instructions.controlNumber.2')}</span>
                    <strong>{t('vehicleLicense:instructions.controlNumber.3')}</strong>
                    <span>{t('vehicleLicense:instructions.controlNumber.4')}</span>
                    <strong><i>{t('vehicleLicense:instructions.controlNumber.5')}</i></strong>
                    <br /><br />
                    <span>{t('vehicleLicense:instructions.controlNumber.6')}</span>
                    <strong style={{ color: Colors.error }}>{t('vehicleLicense:instructions.controlNumber.7')}</strong>
                </div>
            ),
            totalImport: (
                <div style={{ lineHeight: 1.3 }}>
                    <span>{t('vehicleLicense:instructions.totalImport.0')}</span>
                    <strong>{t('vehicleLicense:instructions.totalImport.1')}</strong>
                    <span>{t('vehicleLicense:instructions.totalImport.2')}</span>
                    <strong>{t('vehicleLicense:instructions.totalImport.3')}</strong>
                    <span>{t('vehicleLicense:instructions.totalImport.4')}</span>
                    <strong><i>{t('vehicleLicense:instructions.totalImport.5')}</i></strong>
                    <br /><br />
                    <span>{t('vehicleLicense:instructions.totalImport.6')}</span>
                    <strong style={{ color: Colors.error }}>{t('vehicleLicense:instructions.totalImport.7')}</strong>
                </div>
            ),
        }

        switch (focusedInput) {
            case 1:
                return setInstructions(text.registration)
            case 2:
                return setInstructions(text.controlNumber)
            case 3:
                return setInstructions(text.totalImport)
        }
    }

    const handleSubmission = async (data) => {

        setSubmitted(true)

        const payload = {
            total: data.total,
            registrationNumber: data.registrationNumber,
            controlNumber: data.controlNumber
        }

        const success = () => {
            setResult(true)
            setSuccess(true)
            setSubmitted(false)
        }
        const error = () => {
            setResult(true)
            setSuccess(false)
            setSubmitted(false)
        }

        const { current } = recaptchaRef;
        current.execute();

        await postVehicleLicense(payload, success, error)
    }

    const handleContinue = () => {

        if (result) {
            setInitialState(true)
            setResult(false)
            setSuccess(true)
            reset()
        }

        if (initialState) {
            setInitialState(false)
        }
        else {
            submit()
        }
    }

    const submit = () => {
        if (formRef?.current) {
            formRef.current.dispatchEvent(
                new Event('submit', { cancelable: true, bubbles: true })
            )
        }
    }

    const InfoButton = () => {
        return (
            <button
                onClick={() => setModal(true)}
                style={{ width: 24, height: 24, padding: 0, border: 'none', backgroundColor: 'transparent' }}
                className="mx-1"
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" fill="currentColor">
                    <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd" />
                </svg>
            </button>
        )
    }

    const InfoModal = () => {
        return (
            <Modal
                isOpen={true}
                size="md"
                style={{ padding: "15px" }}
                toggle={() => setModal(false)}
                role="dialog"
                aria-modal={true}
                autoFocus={true}
            >
                <ModalHeader
                    style={{ backgroundColor: Colors.lightGray }}
                    toggle={() => setModal(false)}
                >
                    <Text className="text">{t("vehicleLicense:whereNumber")}</Text>
                </ModalHeader>
                <ModalBody
                    style={{
                        display: "flex",
                        flexDirection: 'column',
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        padding: 0,
                    }}
                >
                    <div className="w-100" style={{ backgroundColor: Colors.bgGray }}>
                        <div className="my-4">
                            {
                                focusedInput === 1 &&
                                <img
                                    src={numRegistracionArt}
                                    alt="" aria-hidden="true"
                                />
                            }
                            {
                                focusedInput === 2 &&
                                <img
                                    src={numControlArt}
                                    alt="" aria-hidden="true"
                                />
                            }
                            {
                                focusedInput === 3 &&
                                <img
                                    src={importeArt}
                                    alt="" aria-hidden="true"
                                />
                            }
                        </div>
                    </div>

                    <div className="p-3">
                        <span>{instructions}</span>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    const RenderInputs = () => {
        return (
            <form ref={formRef} onSubmit={handleSubmit(handleSubmission)}
                style={{
                    borderBottom: `7px solid ${Colors.borderGray}`,
                    borderTop: `1px solid ${Colors.borderDarkGray}`
                }}
            >
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span
                            className="input-group-text rounded-0 border-top-0 border-left-0"
                            style={{ background: Colors.lightGray }}
                        >
                            1
                        </span>
                    </div>
                    <input
                        onFocus={() => setFocusedInput(1)}
                        placeholder={t('vehicleLicense:instructions.registration.input')}
                        className="form-control border-top-0 rounded-0 border-right-0"
                        maxLength={12}
                        style={{
                            textTransform: "capitalize",
                            backgroundColor: errors.registrationNumber && Colors.errorBg,
                            border: errors.registrationNumber && `1px solid ${Colors.error}`
                        }}
                        {...register('registrationNumber', {
                            required: true,
                            minLength: 6,
                            maxLength: 12
                        })}
                    />
                </div>

                <div className="input-group">
                    <div className="input-group-prepend">
                        <span
                            className="input-group-text rounded-0 border-top-0 border-left-0"
                            style={{ background: Colors.lightGray }}
                        >
                            2
                        </span>
                    </div>
                    <input
                        onFocus={() => setFocusedInput(2)}
                        placeholder={t('vehicleLicense:instructions.controlNumber.input')}
                        className="form-control border-top-0 rounded-0 border-right-0"
                        maxLength={12}
                        style={{
                            textTransform: "capitalize",
                            backgroundColor: errors.controlNumber && Colors.errorBg,
                            border: errors.controlNumber && `1px solid ${Colors.error}`
                        }}
                        {...register('controlNumber', {
                            required: true,
                            minLength: 8,
                            maxLength: 12
                        })}
                    />
                </div>

                <div className="input-group" style={{ flexWrap: 'nowrap' }}>
                    <div className="input-group-prepend">
                        <span
                            className="input-group-text rounded-0 border-top-0 border-left-0"
                            style={{ background: Colors.lightGray, borderBottom: 'none' }}
                        >
                            3
                        </span>
                    </div>

                    <Controller
                        render={({ field }) => (
                            <CurrencyFormat
                                value={field.value}
                                thousandSeparator={true}
                                prefix={'$'}
                                placeholder={t('vehicleLicense:instructions.totalImport.input')}
                                onFocus={() => setFocusedInput(3)}
                                maxLength={12}
                                decimalScale={2}
                                style={{
                                    border: 'none',
                                    paddingLeft: 20,
                                    width: '100%',
                                    borderBottom: errors.total ? `1px solid ${Colors.error}` : 'none',
                                    borderLeft: errors.total ? `1px solid ${Colors.error}` : `1px solid ${Colors.borderDarkGray}`,
                                    backgroundColor: errors.total ? Colors.errorBg : 'white'
                                }}
                                onValueChange={(values) => {
                                    const { formattedValue, value } = values;
                                    field.onChange(value)
                                }} />
                        )}
                        control={control}
                        name="total"
                        rules={{
                            required: { value: true },
                            minLength: 1,
                            maxLength: 9
                        }}
                    />
                </div>
            </form>
        )
    }

    const RenderResult = () => {
        return (
            <div
                className="container"
                style={{
                    backgroundColor: 'white',
                    borderBottom: `7px solid ${Colors.borderGray}`,
                }}>
                {
                    success ?
                        (
                            <div className="row">
                                <div className="col">
                                    <div
                                        className="my-5"
                                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                    >
                                        <img
                                            className="w-75 w-lg-50 mb-3"
                                            src={completadoArt}
                                            alt=""
                                            aria-hidden="true"
                                        />
                                        <span className="text-md text-bold text-uppercase">{t("vehicleLicense:result.valid")}</span>
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className="row">
                                <div className="col-12 col-lg-5">
                                    <div className="d-flex align-items-center justify-content-center pt-3 pt-lg-5">
                                        <img
                                            src={invalidArt}
                                            alt="" aria-hidden="true"
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-lg-7 py-5">
                                    <div className="container">

                                        <div className="row mb-3">
                                            <div className="col">
                                                <div className="mb-2">
                                                    <span className="text-md text-bold text-uppercase">{t("vehicleLicense:result.invalid.0")}</span>
                                                </div>
                                                <span style={{ color: Colors.gray }}>{t("vehicleLicense:result.invalid.1")}</span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="mb-2">
                                                    <small>{t("vehicleLicense:registrationNumber")}</small>
                                                    <br />
                                                    <strong>{formState?.registrationNumber}</strong>
                                                </div>
                                                <div>
                                                    <small>{t("vehicleLicense:controlNumber")}</small>
                                                    <br />
                                                    <strong>{formState?.controlNumber}</strong>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div>
                                                    <small>{t("vehicleLicense:totalImport")}</small>
                                                    <br />
                                                    <strong>${formState?.total}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                }
            </div>
        )
    }

    return (
        <div>
            <div style={{ backgroundColor: Colors.lightGray }} className="mb-5">
                <div className="container py-3 d-flex">
                    <div>
                        <PageTitle
                            style={{ fontWeight: "bold" }}
                            i18nKey={"vehicleLicense:title"}
                        />
                        <div className="mt-2">
                            <Text
                                i18nKey="vehicleLicense:subTitle" />
                        </div>
                    </div>
                    <img
                        style={{ width: 200 }}
                        src={vehicleArt}
                        alt="" aria-hidden="true"
                        className="d-none d-lg-flex"
                    />
                </div>
            </div>

            {
                modal &&
                <InfoModal />
            }

            {
                initialState &&
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    className="p-5 w-100 w-lg-50"
                                    style={{
                                        backgroundColor: 'white',
                                        borderBottom: `7px solid ${Colors.borderGray}`,
                                        borderTop: `1px solid ${Colors.borderGray}`,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img
                                        src={initialStateArt}
                                        alt="" aria-hidden="true"
                                    />
                                    <br />
                                    <Text i18nKey="vehicleLicense:vehicleLicense" className="text-bold" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                !initialState &&
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-5 mb-3 px-3 px-lg-0">
                            <div
                                className="p-5"
                                style={{
                                    backgroundColor: 'white',
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 8,
                                    border: success ? `2px solid ${Colors.primary}` : `2px solid ${Colors.error}`
                                }}>
                                <div>
                                    {
                                        focusedInput === 1 &&
                                        <img
                                            src={numRegistracionArt}
                                            alt="" aria-hidden="true"
                                        />
                                    }
                                    {
                                        focusedInput === 2 &&
                                        <img
                                            src={numControlArt}
                                            alt="" aria-hidden="true"
                                        />
                                    }
                                    {
                                        focusedInput === 3 &&
                                        <img
                                            src={importeArt}
                                            alt="" aria-hidden="true"
                                        />
                                    }
                                </div>
                                <br />
                                <div className="d-flex align-items-center justify-content-center">
                                    <Text
                                        className="text-bold"
                                        i18nKey="vehicleLicense:whereNumber" />
                                    <InfoButton />
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-lg-7">
                            <div className="mb-2 px-3 px-lg-0">
                                <Text i18nKey={'vehicleLicense:enterNumber'} className="text-bold" />
                            </div>
                            {
                                result ?
                                    RenderResult()
                                    :
                                    RenderInputs()
                            }
                        </div>
                    </div>
                </div>
            }

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="d-flex justify-content-center p-3">
                            <button
                                className="btn btn-primary w-75 w-lg-20"
                                onClick={() => handleContinue()}
                                disabled={submitted}
                            >{t('vehicleLicense:continue')}</button>
                        </div>
                    </div>
                </div>
            </div>

            <Recaptcha forwardRef={recaptchaRef} action="" />
        </div>
    );
};


export default VehicleLicense;
