import React, {useEffect, useRef, useState} from 'react';
import SVGInjector from "svg-injector";
import PropTypes from "prop-types";

const SvgImage = (props) => {
    const el = useRef(null);
    const [hidden,setHidden] = useState(true);

    useEffect(() => {
        SVGInjector(el.current);
        setHidden(props.hidden);
    }, []);

    useEffect(() => {
        setHidden(props.hidden);
    }, [props.hidden]);

    return (
        <div className={`${hidden?'d-none':'d-block'}`}>
        <img ref={el}
             aria-hidden={true}
             src={props.src}
             alt={props.alt}

             />
        </div>
    );
};

SvgImage.propTypes ={
  src:PropTypes.any,
  hidden:PropTypes.bool
};
SvgImage.defaultProps={
    alt:""
};
export default SvgImage;