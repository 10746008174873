import React from "react";

import checkmark from '../../assets/images/checkmark.svg'
import { Colors } from "../../Colors";

const Radio = (props) => (
    <div
        style={{
            height: "24px",
            width: "24px",
            backgroundColor: props.state ? Colors.blue : "transparent",
            border: props.state ? "none" : `1px solid ${Colors.borderDarkGray}`,
            borderRadius: "50%",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}
    >{
        props.state && <img src={checkmark} style={{ width: '50%', height: 'auto', marginTop: '2px'}} aria-hidden="true" alt="" />
    }</div>
);

export default Radio;
