import React from "react";

import AlertIcon from "../../components/ui/AlertIcon";

import Text from "../../components/ui/Text";
import {Colors} from "../../Colors";
import Skeleton from "react-skeleton-loader";
import * as Utils from "../../core/Utils";
import {FontWeight} from "../../components/ui/FontSize";

const MessageListItem = (props) => {
    const {id, type = "NOTIFICATION", notificationDate, title, seen} = props.message;
    const {loading} = props;

    const onMessageClick = (props) => {
        props.selectMessage(props.message)
        Utils.scrollToDiv('message')
    }
    return (
        <button
            key={id}
            className={`list-group-item list-group-item-action ${id === props.selectedId ? "active" : null}`}
            style={MessageItemStyle}
            onClick={() => onMessageClick(props)}
        >
            {!loading ? (
                <AlertIcon type={seen ? type?.toUpperCase() + "_GRAY" : type?.toUpperCase()}/>
            ) : (
                <Skeleton width="1rem"/>
            )}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: "1rem",
                    color: seen ? Colors.gray : "#000",
                }}
            >
                <Text
                    className="text"
                    style={{
                        marginBottom: 0,
                        color: Colors.gray,
                        fontWeight: seen
                            ? FontWeight.regular
                            : FontWeight.bold,
                    }}
                >
                    {!loading ? (
                        Utils.format(notificationDate, "DD.MMM.YYYY")
                    ) : (
                        <Skeleton/>
                    )}
                </Text>
                <Text
                    className="text"
                    style={{
                        color: seen ? Colors.gray : "#000",
                        fontWeight: seen
                            ? FontWeight.regular
                            : FontWeight.bold,
                    }}
                >
                    {!loading ? title : <Skeleton/>}
                </Text>
            </div>
        </button>
    );
};

const MessageItemStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "20px",
    border: "none",
    borderBottom: `1px solid ${Colors.borderGray}`,
    cursor: "pointer",
    textDecoration: 'none',
};

export default MessageListItem;
