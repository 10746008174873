import React, { useEffect } from "react";
import CourseAccordion from "../../../components/widgets/CourseAccordion";
import alcoholIcon from '../../../assets/images/icon_Cursos_Alcohol.svg'

const CoursesList = (props) => {

    const { action, isAuth } = props

    const items = [
        {
            name: isAuth ? "courses:courseList.substancesAuth" : "courses:courseList.substances",
            icon: alcoholIcon,
            action: action ? () => action() : null,
            enable: true
        }
    ];

    return (
        <CourseAccordion items={items} />
    );
}

export default CoursesList;
