import React, { useEffect, useState } from "react";
import Card from "../../components/ui/Card";
import ComponentTitle from "../../components/ui/ComponentTitle";

import Pagination from "react-js-pagination";

import arrowBack from "../../assets/images/arrow-back-icon.svg";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import FineList from "../../components/widgets/FineList";
import VehiclePageTitle from "../VehicleDetails/VehiclePageTitle";
import * as Api from "../../core/APICaller";
import { caller, showError } from "../../redux/loading/loadingState.combine";
import { fetchAutoexpresoFinesAction, payAllSummary, removeAllAutoexpresoFines, resetCart } from "../../redux/fines/actions";
import FineControlRow from "../../components/widgets/FinesControlRow";
import FinesControlRowMobile from "../../components/widgets/FinesControlRowMobile";
import VehicleList from "../../components/widgets/VehicleList";
import * as Utils from "../../core/Utils";
import { autoexpresoPayAllFlow, resetFlow } from "../../redux/app/app.combine";
import { Colors } from "../../Colors";
import autoexpresoIcon from '../../assets/images/noti-eligibility/Autoexpreso.svg';
import InfoModal from "../../components/ui/InfoModal";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import Modal from "../../components/ui/AppModal";
import AppModal from "../../components/ui/AppModal";
import limitArt from '../../assets/images/art_limit_exceed.svg'

const Autoexpreso = () => {
    const { t } = useTranslation();
    const { vehicleId } = useParams();
    const history = useHistory();

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false);
    const [vehicle, setVehicle] = useState(null);
    const [otherVehicles, setOtherVehicles] = useState(Utils.emptyObjArray(5));

    const [data, setData] = useState(null);
    const autoexpresoFines = useSelector((s) => s.fines?.workingFines);

    const [showAutoexpresoLimit, setShowAutoexpresoLimit] = useState(false);
    const [showAutoexpresoLimitModal, setShowAutoexpresoLimitModal] = useState(false);
    
    const [currentPage, setCurrentPage] = useState(0);
    const PAGE_SIZE = 10

    const pageOnChange = (page) => {
        setCurrentPage(page);
    }

    const user = useSelector((state) => state?.login?.user);

    let userFines = useSelector((state) => state.fines);
    const autoexpresoLimit = useSelector((state) => state?.features?.autoExpreso?.autoexpresoLimit);

    useEffect(() => {
        // Clear cart and flow on page load
        dispatch(resetCart());
        dispatch(resetFlow());
    }, [vehicleId])

    useEffect(() => {
        fetchVehicleDetails()
    }, [vehicleId]);

    useEffect(() => {
        vehicleId && fetchAutoexpresoFines()
    }, [vehicleId, currentPage])

    const handleFetchError = (error, errorMessage) => {
        console.error("Error: ", error)
        dispatch(showError(errorMessage))
        setTimeout(() => { history.push("/landing") }, 2000);
    }

    const fetchVehicleDetails = () => {
        let others = [];
        if (user)
            others = user?.vehicles?.filter(v => v.id !== vehicleId);
        setOtherVehicles(others);
        dispatch(
            caller(
                Api.fetchVehicleInfo(
                    vehicleId,
                    onVehicleFetchSuccess,
                    (err) => {
                        handleFetchError(err, t("payFines:autoexpreso.fetchVehicleError"))
                    }
                )
            )
        );
    }

    const fetchAutoexpresoFines = async () => {
        await dispatch(fetchAutoexpresoFinesAction(
            vehicleId,
            currentPage,
            PAGE_SIZE,
            (data) => {
                setData(data);
            },
            (err) => {
                handleFetchError(err, t("payFines:autoexpreso.fetchFinesError"))
            }
        ))
    }

    const onVehicleFetchSuccess = (data) => {
        let { vehicle } = data;
        setVehicle(vehicle);
        setLoading(false);
    };

    const handlePayAll = async () => {

        dispatch(autoexpresoPayAllFlow())
        history.push(`/pay/fines/autoexpreso/${vehicleId}`)
    }

    useEffect(() => {
        fetchAutoexpresoEligibility()
    }, [vehicleId])

    const fetchAutoexpresoEligibility = async () => {

        await Api.autoexpresoEligibility(
            vehicleId,
            data => {
                if (data?.valid) return

                // If valid = false and reasons = ["AMOUNT_EXCEEDED"] show banner and disable pay all
                if (data?.reasons?.length > 0 && data?.reasons[0] === "AMOUNT_EXCEEDED") {
                    setShowAutoexpresoLimit(true)
                }
            },
            err => {
                handleFetchError(err, t("payFines:autoexpreso.fetchEligibilityError"))
            }
        )
    }

    const RenderOtherVehicles = () => {
        if (!otherVehicles || otherVehicles.length < 1) return null;

        return (
            <div>
                <ComponentTitle i18nKey={"vehicleDetails:otherVehicles"} />
                <VehicleList
                    vehicles={otherVehicles}
                    refetch={fetchVehicleDetails}
                    autoexpreso={true}
                />
            </div>
        )
    }

    // Autoexpreso limit 
    let payDisabled = userFines.cart.sumAmount > autoexpresoLimit;

    useEffect(() => {
        if (!showAutoexpresoLimit) return

        if (payDisabled) {
            setShowAutoexpresoLimitModal(true)
        }
    }, [payDisabled])

    return (
        <div>

            {
                showAutoexpresoLimitModal &&
                <AppModal
                    toggle={() => setShowAutoexpresoLimitModal(false)}
                    action={() => setShowAutoexpresoLimitModal(false)}
                    actionText={t("autoexpreso:limitModal.button")}
                    title={"autoexpreso:limitModal.title"}
                    body={
                        <div className="lh-md text-center">
                            <div className="mb-2">
                                <img src={limitArt} alt="limit-exceed" />
                            </div>
                            <div className="mb-2">
                                <strong className="text-uppercase">{t("autoexpreso:limitModal.subTitle")}</strong>
                            </div>
                            <div>
                                <span>{t("autoexpreso:limitModal.text")}</span>
                            </div>
                        </div>
                    }
                    singleAction
                    size="md"
                />
            }

            <div className="container pb-4 mb-5 border-bottom">
                <div className="row">
                    <div className="col">
                        {/* <PageTitle i18nKey={"autoexpreso:title"} /> */}
                        <VehiclePageTitle
                            year={vehicle?.year}
                            make={vehicle?.make}
                            plate={vehicle?.plate}
                            model={vehicle?.model}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-5">
                        <div className="row no-gutters mb-3">
                            <div className="col">
                                <ComponentTitle
                                    i18nKey={"autoexpreso:menu"}
                                />
                                <Card
                                    hoverable
                                    onClick={() => history.push(`/vehicle-details/${vehicleId}`)}
                                >
                                    <div className="d-flex justify-content-between">
                                        <span className="text-medium">
                                            {t("autoexpreso:back")}
                                        </span>

                                        <img
                                            aria-hidden
                                            alt=""
                                            src={arrowBack}
                                            style={{ width: 20 }}
                                        />
                                    </div>
                                </Card>

                                <div className="d-none d-lg-block" style={{ marginTop: 25 }}>
                                    <RenderOtherVehicles />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-12 col-lg-7">
                        <ComponentTitle i18nKey={"autoexpreso:fines"} />

                        <div>
                            {showAutoexpresoLimit && <MaxPaymentLimitBanner />}
                            <div className="d-none d-lg-block">
                                <FineControlRow
                                    loading={loading}
                                    vehicle={vehicle}
                                    noSelectAll={true}
                                    customDeselectAll={() => {
                                        dispatch(removeAllAutoexpresoFines())
                                    }}
                                    payAll={handlePayAll}
                                    autoexpreso={true}
                                    payAllDisabled={autoexpresoFines?.length === 0 || showAutoexpresoLimit}
                                    payDisabled={payDisabled}
                                />
                            </div>
                            <div className="d-lg-none">
                                <FinesControlRowMobile
                                    loading={loading}
                                    vehicle={vehicle}
                                    noSelectAll={true}
                                    customDeselectAll={() => {
                                        dispatch(removeAllAutoexpresoFines())
                                    }}
                                    payAll={handlePayAll}
                                    autoexpreso={true}
                                    payAllDisabled={autoexpresoFines?.length === 0 || showAutoexpresoLimit}
                                    payDisabled={payDisabled}
                                />
                            </div>
                            <FineList
                                fines={autoexpresoFines}
                                loading={loading}
                                noIcon
                                noPagination
                                autoexpreso
                            />

                            {data?.pageable?.totalPages > 1 && (
                                <nav style={{ marginTop: 20, marginBottom: 40 }}>
                                    <Pagination
                                        innerClass={"pagination justify-content-center"}
                                        activePage={currentPage}
                                        itemsCountPerPage={PAGE_SIZE}
                                        totalItemsCount={data?.pageable?.totalElements}
                                        pageRangeDisplayed={Utils.isMobile() ? 2 : 3}
                                        onChange={pageOnChange}
                                        itemClass={"page-item"}
                                        linkClass={"page-link"}
                                    />
                                </nav>
                            )}
                        </div>
                    </div>

                    <div className="d-lg-none col-12">
                        <RenderOtherVehicles />
                    </div>
                </div>
            </div>
        </div>
    );
};

const MaxPaymentLimitBanner = () => {

    const { t } = useTranslation()

    const autoexpresoLimit = useSelector((state) => state?.features?.autoExpreso?.autoexpresoLimit);

    return (
        <div
            className="rounded p-3 d-flex align-items-center mb-3 mb-lg-0"
            style={{
                backgroundColor: Colors.orangeLight,
                border: `2px solid ${Colors.orangeBorder}`
            }}
        >
            <div className="mr-3">
                <img src={autoexpresoIcon} alt="autoexpreso" style={{ width: 24 }} />
            </div>
            <div>
                <span className="text"><strong>{t("autoexpreso:maxPaymentLimitBanner.text.0")}{Utils.formatCurrencyLabel(autoexpresoLimit)}</strong> {t("autoexpreso:maxPaymentLimitBanner.text.1")}</span>
            </div>
        </div>
    )
}

export default Autoexpreso;
