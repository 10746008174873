import React from "react";
import { Link } from "react-router-dom";
import ComponentTitle from "../../../components/ui/ComponentTitle";
import { Colors } from "../../../Colors";
import { FontWeight } from "../../../components/ui/FontSize";
import Card from "../../../components/ui/Card";
import Text from "../../../components/ui/Text";
import { format } from "../../../core/Utils";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import * as Utils from "../../../core/Utils";
import isCleanIcon from "../../../assets/images/icon_Badge_Licencia2.svg";

const MobileLicenseView = () => {
    let card = useSelector((state) => state.card);
    const user = useSelector((state) => state?.login?.user);
    const { t } = useTranslation();

    const titleKey = (userType) => {
        switch (userType) {
            case "LICENSE":
            case "LEY97":
            case "ID":
                return user?.license?.type === "ID"
                    ? "layout:navPage.myId"
                    : "layout:navPage.myLicense";
            default:
                return "layout:navPage.profile";
        }
    };

    const renderBasedOnUser = (userType) => {
        switch (userType) {
            case "LICENSE":
            case "LEY97":
            case "ID":
                return user?.license?.type === "ID"
                    ? "main:mobileLicense.idNo"
                    : "main:mobileLicense.license";
            default:
                return "layout:navPage.profile";
        }
    };

    return (
        <div
            className={"col col-12 d-lg-none"}
            style={{ marginBottom: "25px" }}
        >
            <Link
                to={"/profile"}
                role="button"
                aria-label={`${t("main:profileNavButton")} ${
                    card.license?.unpaidFines
                } ${t("main:profileNavButtonFines")}`}
            >
                {/* <ComponentTitle i18nKey={"main:identityHeader.myProfile"}> */}
                <ComponentTitle i18nKey={titleKey(user?.license?.type)}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "15px",
                        }}
                    >
                        {card?.license?.unpaidFines > 0 ? (
                            <span
                                className="badge badge-sm badge-primary rounded-circle"
                                style={{
                                    backgroundColor: Colors.error,
                                    marginRight: "15px",
                                    fontWeight: FontWeight.bold,
                                    height: "24px",
                                    width: "24px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "14px",
                                }}
                            >
                                {card.license?.unpaidFines}
                            </span>
                        ) : (
                            <img
                                src={isCleanIcon}
                                style={{ marginRight: "15px", height: "26px" }}
                                alt=""
                                aria-hidden="true"
                            />
                        )}
                        <div className={"icon-arrow"} />
                    </div>
                </ComponentTitle>
            </Link>
            <Card
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "15px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    role="text"
                    aria-label={`${t("main:identityHeader.arias.license")} ${
                        card.license?.id
                    }`}
                >
                    <Text
                        style={{
                            textTransform: "uppercase",
                            fontSize: "12px",
                            color: Colors.rgbGray
                        }}
                        i18nKey={renderBasedOnUser(user?.license?.type)}
                    />
                    <Text style={{ fontWeight: FontWeight.bold }}>
                        {card.license?.id || "N/A"}
                    </Text>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    role="text"
                    aria-label={`${t("main:identityHeader.arias.expiryDate")} ${
                        card.license?.expirationDate
                    }`}
                >
                    <Text
                        style={{
                            textTransform: "uppercase",
                            fontSize: "12px",
                            color: Colors.rgbGray
                        }}
                        i18nKey={"main:mobileLicense.expiryDate"}
                    />
                    <Text style={{ fontWeight: FontWeight.bold }}>
                        {Utils.format(
                            card.license?.expirationDate,
                            "DD.MMM.YYYY"
                        ) || "N/A"}
                    </Text>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    role="text"
                    aria-label={`${t("main:identityHeader.arias.category")} ${
                        card.license?.category
                    }`}
                >
                    <Text
                        style={{
                            textTransform: "uppercase",
                            fontSize: "12px",
                            color: Colors.rgbGray
                        }}
                        i18nKey={"main:mobileLicense.category"}
                    />
                    <Text style={{ fontWeight: FontWeight.bold }}>
                        {_.defaultTo(card.license?.category, "N/A")}
                    </Text>
                </div>
            </Card>
        </div>
    );
};

/*MobileLicenseView.propTypes ={
    card:PropTypes.object
}*/

export default MobileLicenseView;
