import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";

import Icon from "../../../components/ui/Icon";
import iconCardExpiration from "../../../assets/images/icon-card-expiration.svg";
import iconCardCvc from "../../../assets/images/icon-card-cvc.svg";
import iconCardName from "../../../assets/images/icon-card-name.svg";
import iconCardEmail from "../../../assets/images/icon-card-email.svg";
import iconZipCode from "../../../assets/images/icon_TarjetaZipCode.svg";
import TextInput from "../../../components/ui/TextInput";
import MonthAndYearInput from "../../../components/ui/MonthAndYearInput";
import CreditCardInput from "../../../components/ui/CreditCardInput";
import { Colors } from "../../../Colors";

import iconInformationCvc from "../../../assets/images/icon-information-cvc.svg";
import { useTranslation } from "react-i18next";
import creditcardutils from "creditcardutils";
import { emailValid } from "../../../core/Utils";
import PropTypes from "prop-types";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import iconLine1 from "../../../assets/images/address/line1.svg";
import iconLine2 from "../../../assets/images/address/line2.svg";
import iconCountry from "../../../assets/images/address/country.svg";
import iconCity from "../../../assets/images/address/city.svg";
import iconZip from "../../../assets/images/address/zip.svg";
import { addPaymentMethod } from "../../../redux/payment/payment.combine";

const PaymentDetailsForm = props => {

    const { showAddressInput, setValidCardForm, handleSetCreditCardAndValid } = props

    const user = useSelector(s => s.login.user)
    let name = `${user.principal.firstName} ${user.principal.lastName}`

    let profile = useSelector((state) => state.profile);

    const [cardNumber, handleCardNumber] = useState("");
    const [monthYear, handleDate] = useState({
        month: "",
        year: ""
    });
    const [cvc, handleCvc] = useState(props.cvc);
    const [cardName, handleCardName] = useState('');
    const [email, handleEmail] = useState('');
    const [zip, handleZip] = useState('');
    const [valid, setValid] = useState(false);
    const { t } = useTranslation();

    // Error states
    const [dateError, toggleDateError] = useState(false)
    const [cvcError, toggleCvcError] = useState(false)
    const [cardNameError, toggleCardNameError] = useState(false)

    const [addressFormData, setAddressFormData] = useState({
        line1: "",
        line2: "",
        city: "",
        state: "",
        zipCode: ""
    })

    console.log("Address form data: ", addressFormData)

    const [validAddress, setValidAddress] = useState(false)

    const [cardOnChange, setCardOnChange] = useState(false)

    const dispatch = useDispatch()

    let type = creditcardutils.parseCardType(cardNumber);
    let creditCard = {
        cvc: cvc,
        name: cardName,
        number: cardNumber,
        expiryMonth: monthYear.month,
        expiryYear: monthYear.year,
        email: email,
        zipcode: zip,
        type
    };

    creditCard.billingAddress = addressFormData

    useEffect(() => {
        dispatch(addPaymentMethod(creditCard));
    }, [cvc, cardName, cardNumber, monthYear, email, zip, addressFormData])

    useEffect(() => {
        handleSetCreditCardAndValid(creditCard, valid);
    }, [valid]);

    useEffect(() => {
        let validCVC = cvc?.startsWith(' ') ? false : creditcardutils.validateCardCVC(cvc);
        let validExpiry = creditcardutils.validateCardExpiry(
            _.defaultTo(monthYear.month, ""),
            _.defaultTo(monthYear.year, "")
        );

        // Already validated
        let validCard = true
        let validEmail = true;
        let validZip = addressFormData.zipCode.length === 5

        // Address fields min lengths
        let validAddress1 = addressFormData.line1.length >= 2
        // let validAddress2 = addressFormData.line2.length >= 2
        let validCity = addressFormData.city.length >= 3
        let validState = addressFormData.state.length >= 2

        const cardNameMinLength = 2

        setValidCardForm(
            validCVC &&
            !cardNameError &&
            cardName?.length > cardNameMinLength &&
            validExpiry &&
            validEmail &&
            (cardOnChange.length === 15 || cardOnChange.length === 16) &&
            validCard
        )

        setValid(
            validCVC &&
            cardName?.length > cardNameMinLength &&
            validCard &&
            validExpiry &&
            validEmail &&
            validZip &&
            validAddress1 &&
            // validAddress2 &&
            validCity &&
            validState &&
            validAddress
        );

    }, [cvc, cardName, cardNumber, monthYear.month, monthYear.year, email, zip, addressFormData, cardOnChange, validAddress]);

    // Fill email with user data from redux
    useEffect(() => {
        let userEmail = `${profile?.validEmail ? profile?.email : ""}`
        handleEmail(userEmail)
    }, [profile])

    const inputWrapperStyle = (error) => ({
        borderTop: error && `1px solid ${Colors.error}`,
        borderBottom: error && `1px solid ${Colors.error}`,
        backgroundColor: error ? Colors.error : Colors.lightGray
    })

    // Toggle error based on child input validated state
    const toggleError = (errorToggler, bool) => errorToggler(bool)

    return (
        <div className="row card-input" style={{ marginBottom: "20px", width: '100%', margin: 0 }}>
            {
                !showAddressInput ?
                    <div className="col-md-12" style={{ padding: 0 }}>
                        <CreditCardInput
                            value={cardNumber}
                            onValid={value => handleCardNumber(value)}
                            onChange={setCardOnChange}
                        />
                        <div className="row no-gutters">
                            <div className="col-md-7 col-12">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text rounded-0 border-top-0 border-left-0"
                                            style={inputWrapperStyle(dateError)}
                                        >
                                            <Icon src={iconCardExpiration} alt={""} />
                                        </span>
                                    </div>

                                    <MonthAndYearInput
                                        type={"text"}
                                        placeholder={t("payFines:form.expirationDate")}
                                        className="form-control border-top-0 rounded-0 border-right-0"
                                        onValid={value => handleDate(value)}
                                        toggleError={toggleError}
                                        errorToggler={toggleDateError}
                                        value={`${monthYear.month}${monthYear.year}`}
                                    />
                                </div>
                            </div>

                            <div className="col-md-5 col-12">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span
                                            className="input-group-text rounded-0 border-top-0 border-left-0"
                                            style={inputWrapperStyle(cvcError)}
                                        >
                                            <Icon src={iconCardCvc} alt={""} />
                                        </span>
                                    </div>

                                    <TextInput
                                        id="cvc"
                                        placeholder="CVC/CVV"
                                        value={cvc}
                                        code="cvc"
                                        onChange={e => handleCvc(e.target.value)}
                                        className="form-control border-top-0 rounded-0 border-right-0"
                                        toggleError={toggleError}
                                        errorToggler={toggleCvcError}
                                    />
                                    <span data-tip={t(`payFines:cvv`)} data-for={"cvc"}>
                                        <img
                                            src={iconInformationCvc}
                                            className="creditCardPadding"
                                            alt={""}
                                            style={{
                                                position: "absolute",
                                                right: 0,
                                                top: 17,
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => props.toggleInfoModal(true)}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span
                                    className="input-group-text rounded-0 border-top-0 border-left-0"
                                    style={inputWrapperStyle(cardNameError)}
                                >
                                    <Icon src={iconCardName} alt={""} />
                                </span>
                            </div>
                            <TextInput
                                placeholder={t("payFines:form.cardName")}
                                code="name"
                                className="form-control border-top-0 rounded-0 border-right-0"
                                value={cardName}
                                onChange={e => handleCardName(e.target.value)}
                                style={{ textTransform: "capitalize" }}
                                toggleError={toggleError}
                                errorToggler={toggleCardNameError}
                            />
                        </div>

                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span
                                    className="input-group-text border-top-0 border-left-0 rounded-0"
                                    style={grayBackground}
                                >
                                    <Icon src={iconCardEmail} alt={""} />
                                </span>
                            </div>
                            <TextInput
                                placeholder={t("payFines:form.email")}
                                code="email"
                                className="form-control border-top-0 border-right-0 rounded-0"
                                style={{ borderTopRightRadius: 0 }}
                                value={email}
                                onChange={e => handleEmail(e.target.value)}
                                readonly
                            />
                        </div>

                    </div>
                    :
                    <PaymentAddressForm
                        addressFormData={addressFormData}
                        setAddressFormData={setAddressFormData}
                        setValidAddress={setValidAddress}
                    />
            }
        </div>
    );
};

const PaymentAddressForm = (props) => {

    const { t } = useTranslation()

    const {
        addressFormData,
        setAddressFormData,
        setValidAddress
    } = props


    const [line1Error, setLine1Error] = useState(false)
    const [line2Error, setLine2Error] = useState(false)
    const [cityError, setCityError] = useState(false)
    const [zipError, setZipError] = useState(false)
    const [stateError, setStateError] = useState(false)

    const toggleError = (errorToggler, bool) => errorToggler(bool)

    useEffect(() => {

        let valid = (!line1Error && !line2Error && !cityError && !zipError && !stateError)
        console.log("Valid add: ", line1Error, line2Error, cityError, zipError, stateError)
        setValidAddress(valid)

    }, [line1Error, line2Error, cityError, zipError, stateError])

    return (
        <div className="w-100 p-0">
            <div className="input-group">
                <div className="input-group-prepend">
                    <span
                        className="input-group-text border-top-0 border-left-0 rounded-0"
                        style={grayBackground}
                    >
                        <Icon src={iconLine1} alt={""} />
                    </span>
                </div>
                <TextInput
                    placeholder={t("payFines:form.line1")}
                    code="addressLine"
                    className="form-control border-top-0 border-right-0 rounded-0"
                    style={{ borderTopRightRadius: 0 }}
                    value={addressFormData.line1}
                    onChange={e => setAddressFormData({ ...addressFormData, line1: e.target.value })}
                    toggleError={toggleError}
                    errorToggler={setLine1Error}
                />
            </div>

            <div className="input-group">
                <div className="input-group-prepend">
                    <span
                        className="input-group-text border-top-0 border-left-0 rounded-0"
                        style={grayBackground}
                    >
                        <Icon src={iconLine2} alt={""} />
                    </span>
                </div>
                <TextInput
                    placeholder={t("payFines:form.line2")}
                    code="addressLine2"
                    className="form-control border-top-0 border-right-0 rounded-0"
                    style={{ borderTopRightRadius: 0 }}
                    value={addressFormData.line2}
                    onChange={e => setAddressFormData({ ...addressFormData, line2: e.target.value })}
                    toggleError={toggleError}
                    errorToggler={setLine2Error}
                />
            </div>

            <div className="input-group">
                <div className="input-group-prepend">
                    <span
                        className="input-group-text border-top-0 border-left-0 rounded-0"
                        style={grayBackground}
                    >
                        <Icon src={iconCity} alt={""} />
                    </span>
                </div>
                <TextInput
                    placeholder={t("payFines:form.city")}
                    code="city"
                    className="form-control border-top-0 border-right-0 rounded-0"
                    style={{ borderTopRightRadius: 0 }}
                    value={addressFormData.city}
                    onChange={e => setAddressFormData({ ...addressFormData, city: e.target.value })}
                    toggleError={toggleError}
                    errorToggler={setCityError}
                />
            </div>

            <div className="row no-gutters">
                <div className="col-md-6 col-12">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span
                                className="input-group-text border-top-0 border-left-0 rounded-0"
                                style={grayBackground}
                            >
                                <Icon src={iconCountry} alt={""} />
                            </span>
                        </div>
                        <TextInput
                            placeholder={t("payFines:form.state")}
                            code="state"
                            className="form-control border-top-0 border-right-0 rounded-0"
                            style={{ borderTopRightRadius: 0, textTransform: 'uppercase' }}
                            value={addressFormData.state}
                            onChange={e => setAddressFormData({ ...addressFormData, state: e.target.value })}
                            toggleError={toggleError}
                            errorToggler={setStateError}
                        />
                    </div>
                </div>

                <div className="col-md-6 col-12">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span
                                className="input-group-text border-top-0 border-left-0 rounded-0"
                                style={grayBackground}
                            >
                                <Icon src={iconZip} alt={""} />
                            </span>
                        </div>
                        <TextInput
                            placeholder={t("payFines:form.zip")}
                            code="zipcode"
                            className="form-control border-top-0 border-right-0 rounded-0"
                            style={{ borderTopRightRadius: 0 }}
                            value={addressFormData.zipCode}
                            onChange={e => setAddressFormData({ ...addressFormData, zipCode: e.target.value })}
                            toggleError={toggleError}
                            errorToggler={setZipError}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const grayBackground = {
    backgroundColor: Colors.lightGray
};

PaymentDetailsForm.propTypes = {
    handleSetCreditCardAndValid: PropTypes.func,
    showAddressInput: PropTypes.bool
};
export default PaymentDetailsForm;
