import React from 'react'
import { useTranslation } from 'react-i18next'

import basicInsuranceArt from '../assets/images/acaa/ACAA_02b.svg'
import basicInsuranceArtEn from '../assets/images/acaa/ACAA_02_EN.svg'
import plusInsuranceArt from '../assets/images/acaa/ACAA_03b.svg'
import premiumInsuranceArt from '../assets/images/acaa/ACAA_04b.svg'

export const RenderPolicyWithArt = (policies, lang) => {

    if (!policies || !policies?.length > 0 || !lang) return

    let withArt = policies?.map((item, i) => {
        switch (i) {
            case 0:
                item.artSrc = lang === "es-US" ? basicInsuranceArt : basicInsuranceArtEn
                break
            case 1:
                item.artSrc = plusInsuranceArt
                break
            case 2:
                item.artSrc = premiumInsuranceArt
                break
        }
        return item
    })

    return withArt
}
