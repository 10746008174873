import React, {useState} from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {useTranslation} from "react-i18next";

import {Colors} from "../../Colors";
import Text from "../../components/ui/Text";
import infoIcon from '../../assets/images/icon-information-cvc.svg'
import {submitValidationCode} from "../../core/api-resources/profile";
import {FontWeight} from "../../components/ui/FontSize";
import {useDispatch} from "react-redux";
import {getProfile} from "../../redux/profile/profile.combine";

const EmailValidationModal = (props) => {
    const {toggle, email, resendEmail} = props;

    const [confirmationCodeError, setConfirmationCodeError] = useState(false)

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const [firstValue, setFirstValue] = useState("")
    const [secondValue, setSecondValue] = useState("")
    const [thirdValue, setThirdValue] = useState("")
    const [fourthValue, setFourthValue] = useState("")

    const [loading, setLoading] = useState(false)

    const validateCode = async () => {
        let code = `${firstValue}${secondValue}${thirdValue}${fourthValue}`
        setLoading(true)
        await submitValidationCode(code, submitValidationCodeSuccess, submitValidationCodeError)
    }

    const submitValidationCodeSuccess = async (data) => {
        if (data) {
            await dispatch(getProfile())
            toggle()
        } else submitValidationCodeError()

        setLoading(false)
    }

    const submitValidationCodeError = () => {
        setConfirmationCodeError(true)
        let element = document.getElementById('firstValue')
        setLoading(false)

        setTimeout(() => {
            setConfirmationCodeError(false)
            setFirstValue('')
            setSecondValue('')
            setThirdValue('')
            setFourthValue('')

            if (element) element.focus()
        }, 3000)
    }

    const inputValueValidation = (e) => {
        let value = e.target.value
        if (!value || value === "") return false

        let int = parseInt(value)

        if (Number.isInteger(int)) return true
        else return false
    }

    const handleFirstInput = (e) => {
        let nextElement = document.getElementById('secondValue')

        if (inputValueValidation(e)) nextElement.focus()
        else return false
    }

    const handleSecondInput = (e) => {
        let nextElement = document.getElementById('thirdValue')
        let previousElement = document.getElementById('firstValue')

        if (e.keyCode === 8) {
            previousElement.focus()
            setFirstValue('')
        } else if (inputValueValidation(e)) nextElement.focus()
        else return false
    }

    const handleThirdInput = (e) => {
        let nextElement = document.getElementById('fourthValue')
        let previousElement = document.getElementById('secondValue')

        if (e.keyCode === 8) {
            previousElement.focus()
            setSecondValue('')
        } else if (inputValueValidation(e)) nextElement.focus()
        else return false
    }

    const handleFourthInput = (e) => {
        let previousElement = document.getElementById('thirdValue')

        if (e.keyCode === 8) {
            previousElement.focus()
            setThirdValue('')
        } else if (inputValueValidation(e)) {
            validateCode()
        } else return false
    }

    const resendMail = async () => {
        setLoading(true)
        await resendEmail().then(() => {
            setLoading(false)
        })
    }

    const inputStyle = {
        width: "50px",
        margin: "5px",
        border: confirmationCodeError ? `2px solid ${Colors.error}` : 'none',
        fontSize: 24,
        fontWeight: FontWeight.bold
    };

    return (
        <Modal isOpen={true} size="md" toggle={toggle}>
            <ModalHeader
                toggle={toggle}
                style={{color: Colors.gray, backgroundColor: Colors.bgGray}}
            >
                <Text i18nKey="emailValidation:modal.header" className="text"/>
            </ModalHeader>

            <ModalBody
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 0,
                }}
            >
                <div className="container mt-3">
                    <Text
                        i18nKey="emailValidation:modal.body1"
                        className="text"
                    />
                    <span className="text text-bold">
                        {email}&nbsp;
                    </span>
                    <Text
                        i18nKey="emailValidation:modal.body2"
                        className="text"
                    />
                </div>

                <div style={{...bodyContainer, backgroundColor: Colors.darkGreen}}>

                    <div className="d-flex" id="code-validation-inputs">
                        <input
                            id="firstValue"
                            type="number"
                            inputMode="numeric"
                            className="form-control"
                            style={inputStyle}
                            aria-label={t("emailValidation:accessibility.firstValue")}
                            aria-required={true}
                            maxLength={1}
                            value={firstValue}
                            onChange={(e) => setFirstValue(e.target.value)}
                            onKeyUp={(e) => handleFirstInput(e)}
                        />

                        <input
                            id="secondValue"
                            type="number"
                            inputMode="numeric"
                            className="form-control"
                            style={inputStyle}
                            aria-label={t("emailValidation:accessibility.secondValue")}
                            aria-required={true}
                            maxLength={1}
                            value={secondValue}
                            onChange={(e) => setSecondValue(e.target.value)}
                            onKeyUp={(e) => handleSecondInput(e)}
                        />

                        <input
                            id="thirdValue"
                            type="number"
                            inputMode="numeric"
                            className="form-control"
                            style={inputStyle}
                            aria-label={t("emailValidation:accessibility.thirdValue")}
                            aria-required={true}
                            maxLength={1}
                            value={thirdValue}
                            onChange={(e) => setThirdValue(e.target.value)}
                            onKeyUp={(e) => handleThirdInput(e)}
                        />

                        <input
                            id="fourthValue"
                            type="number"
                            inputMode="numeric"
                            className="form-control"
                            style={inputStyle}
                            aria-label={t("emailValidation:accessibility.fourthValue")}
                            aria-required={true}
                            maxLength={1}
                            value={fourthValue}
                            onChange={(e) => setFourthValue(e.target.value)}
                            onKeyUp={(e) => handleFourthInput(e)}
                        />
                    </div>

                    <div
                        style={{
                            margin: "20px 20px 0 20px",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <Text
                            i18nKey="emailValidation:modal.notReceived"
                            className="text text-white"
                        />
                        {
                            loading ?
                                <div className='spinner-border-loading' role="status" style={{top: 14, ...loadingStyle}}
                                     aria-hidden="true">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <a className="text text-white text-bold mt-1"
                                   style={{cursor: 'pointer'}}
                                   onClick={() => resendMail()}
                                >{t("emailValidation:modal.resendCode")}</a>
                        }
                    </div>
                </div>

                {
                    confirmationCodeError &&
                    <div className="container mt-0" style={{
                        height: 60,
                        backgroundColor: Colors.error,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        textAlign: 'center'
                    }}>
                        <Text
                            i18nKey="emailValidation:modal.error"
                            className="text"
                        />
                    </div>
                }

                <div className="container mt-4 mb-5">
                    <img src={infoIcon} alt="" aria-hidden='true' style={{width: 18, marginRight: 6}}/>
                    <Text
                        i18nKey="emailValidation:modal.inputText"
                        className="text"
                        style={{marginTop: "10px"}}
                    />
                </div>

            </ModalBody>
        </Modal>
    );
};

const bodyContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "20px 0",
    margin: "20px 0",
    marginBottom: '0'
};


const loadingStyle = {
    color: Colors.blue,
    width: 24,
    height: 24,
    marginTop: 10
}

export default EmailValidationModal;
