import {FETCH_CARD_INFO} from "./actions";
import {RESET_STATE} from "../app/app.combine";

export function card(state = {}, action) {
    if (action.type === FETCH_CARD_INFO) {
        return action.payload
    }else if (action.type === RESET_STATE) {
        return {}
    }else {
        return state;
    }
}