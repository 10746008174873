import React, {useState} from "react";
import Text from "../ui/Text";
import {FontWeight} from "../ui/FontSize";
import {Colors} from "../../Colors";
import {formatCurrencyLabel} from "../../core/Utils";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const TotalSummary = (props) => {
    const flow = useSelector((state) => state?.flow);
    let renewal = useSelector((state) => state.renewal);
    let vehicleRenewal = useSelector((state) => state.vehicleRenewal);

    const {t} = useTranslation();

    const {
        finesTotal,
        finesPenalties,
        finesCredits,
        total,
        insurance,
        noLines,
    } = props;
    const [extraFields, setExtraFields] = useState(
        (flow === "RENEWAL" && renewal?.fees?.charges) ||
        (flow === "RENEWAL_VEHICLE" && vehicleRenewal.charges)
    );

    return (
        <div
            className="card card-frame"
            style={{
                ...totalCard,
                backgroundColor: noLines ? 'transparent' : '#fff',
                borderBottom: !noLines
                    ? `6px solid ${Colors.lightGray}`
                    : "none",
            }}
        >
            <div className="alert-padding totalSummaryPadding">
                <table style={tableStyle} role="presentation">
                    <tbody>
                    {insurance && (
                        <tr
                            role="text"
                            aria-label={`${t(
                                "paymentHistory:insurance"
                            )}: ${insurance}`}
                        >
                            <th scope="row" style={tableHead}>
                                <Text
                                    className="text-sm"
                                    i18nKey={"paymentHistory:insurance"}
                                />
                            </th>
                            <td aria-hidden="true">
                                <img
                                    src={insurance}
                                    style={{width: "90px"}}
                                    aria-hidden="true"
                                    alt=""
                                />
                            </td>
                        </tr>
                    )}
                    <tr
                        role="text"
                        aria-label={`${t(
                            "paymentHistory:modal.totalSummary.finesTotal"
                        )}: ${formatCurrencyLabel(finesTotal)}`}
                    >
                        <th scope="row" style={tableHead}>
                            <Text
                                className="text-alert"
                                i18nKey={
                                    "paymentHistory:modal.totalSummary.finesTotal"
                                }
                            />
                        </th>
                        <td>
                            <Text className="text" style={ammountStyle}>
                                {formatCurrencyLabel(finesTotal)}
                            </Text>
                        </td>
                    </tr>
                    <tr
                        role="text"
                        aria-label={`${t(
                            "paymentHistory:modal.totalSummary.surchargeTotal"
                        )}: ${formatCurrencyLabel(finesPenalties)}`}
                    >
                        <th scope="row" style={tableHead}>
                            <Text
                                className="text-alert"
                                i18nKey={
                                    "paymentHistory:modal.totalSummary.surchargeTotal"
                                }
                            />
                        </th>
                        <td>
                            <Text
                                style={props.ignoreSurcharge ? ignoreSurchargeAmount : ammountStyle}
                                className="text"
                            >
                                {formatCurrencyLabel(finesPenalties)}
                            </Text>
                        </td>
                    </tr>
                    <tr
                        role="text"
                        aria-label={`${t(
                            "paymentHistory:modal.totalSummary.discounts"
                        )}: ${formatCurrencyLabel(finesCredits)}`}
                    >
                        <th scope="row" style={tableHead}>
                            <Text
                                className="text-alert"
                                i18nKey={
                                    "paymentHistory:modal.totalSummary.discounts"
                                }
                            />
                        </th>
                        <td>
                            <Text className="text" style={ammountStyle}>
                                {formatCurrencyLabel(finesCredits)}
                            </Text>
                        </td>
                    </tr>

                    {props.renewal ? (
                        <>
                            {extraFields && extraFields.length > 0
                                ? extraFields.map((f) => (
                                    <tr
                                        role="text"
                                        aria-label={`${f.title}, ${
                                            f.caption
                                        }: ${formatCurrencyLabel(
                                            f.amount
                                        )}`}
                                    >
                                        <th
                                            scope="row"
                                            style={extraFieldInfo}
                                        >
                                            <Text
                                                className="text-alert totalSummaryPadding"
                                                style={tableHead}
                                                i18nKey={`${f.title}`}
                                            />
                                            <Text
                                                className="text-xs"
                                                style={tableHead}
                                                i18nKey={`${f.caption}`}
                                            />
                                        </th>

                                        <td
                                            scope="row"
                                            style={{paddingTop: "20px"}}
                                        >
                                            <Text
                                                className="text"
                                                style={ammountStyle}
                                            >
                                                {formatCurrencyLabel(
                                                    f.amount
                                                )}
                                            </Text>
                                        </td>
                                    </tr>
                                ))
                                : null}
                        </>
                    ) : null}
                    </tbody>
                </table>
            </div>

            <div
                style={{
                    ...finalTotal,
                    borderTop: !noLines
                        ? `1px solid ${Colors.lightGray}`
                        : "none",
                }}
                className="alert-padding"
                role="text"
                aria-label={`${t(
                    "paymentHistory:modal.totalSummary.total"
                )}: ${formatCurrencyLabel(total)}`}
            >
                <Text
                    style={totalText}
                    className="text-md"
                    i18nKey={"paymentHistory:modal.totalSummary.total"}
                />
                <Text style={totalText} className="text-md">
                    {formatCurrencyLabel(total)}
                </Text>
            </div>
        </div>
    );
};

TotalSummary.defaultProps = {
    ignoreSurcharge: false,
};

const totalCard = {
    margin: 0,
    border: "none",
    borderRadius: 0,
    backgroundColor: 'transparent'
};

const finalTotal = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
};

const extraFieldInfo = {
    display: "flex",
    flexDirection: "column",
    paddingTop: "20px",
};

const tableStyle = {
    width: "100%",
    textAlign: "right",
    color: Colors.gray,
    fontWeight: FontWeight.medium,
};

const tableHead = {
    fontWeight: FontWeight.regular,
    textTransform: "uppercase",
};

const totalText = {
    fontWeight: "bold",
    color: "#000",
    marginLeft: "10px",
};

const ignoreSurchargeAmount = {
    color: "red",
    textDecoration: "line-through",
    fontWeight: FontWeight.medium,
};

const ammountStyle = {
    marginLeft: "10px"
};

export default TotalSummary;
