import {api, handleResponse, version} from "../APICaller";
const uuid= 1234567890;

export const getConfig = async (success,error) => {
    return handleResponse(await api.get(`/config?platform=web&uuid=${uuid}&version=${version}&rnd=${Math.random()}`),success,error);
};

export const fetchCescos = (onSuccess, onError) => {
    return api
        .get('/config/cescos')
        .then((response) => handleResponse(response, onSuccess, onError));
};

export const fetchCescoDetails = (cescoId, onSuccess, onError) => {
    return api
        .get(`/config/cescos/${cescoId}`)
        .then((response) => handleResponse(response, onSuccess, onError));
};
export const ping = () => {
    return api.get(`/ping?uuid=${uuid}&rnd=${Math.random()}`).then(response => {
        return response.status === 200;
    });
};
