import React from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import Button from "../ui/Button"
import { useHistory } from "react-router-dom"

import invalidClassification from '../../assets/images/noti-eligibility/FechaFabricado.svg'
import invalidRenewalPeriod from '../../assets/images/noti-eligibility/PeriodoExpiracion.svg'
import othersIcon from '../../assets/images/noti-eligibility/Other.svg'
import containsLiens from '../../assets/images/noti-eligibility/Gravamen.svg'
import renewalInProgress from '../../assets/images/noti-eligibility/MarbeteProceso.svg'
import { useTranslation } from "react-i18next"
import PropTypes from 'prop-types'
import { useSelector } from "react-redux"

const VehicleNotiEligibilityModal = ({ toggle }) => {

    const history = useHistory()
    const { t } = useTranslation()

    const notiReasons = useSelector(s => s?.vehicleRenewal?.notiReasons)

    const renderIcon = (name) => {
        switch (name) {
            case 'INVALID_CLASSIFICATION':
                return invalidClassification;
            case 'INVALID_RENEWAL_PERIOD':
                return invalidRenewalPeriod;
            case 'OTHERS':
                return othersIcon;
            case 'CONTAINS_LIENS':
                return containsLiens;
            case 'VEHICLE_RENEWAL_IN_PROGRESS':
                return renewalInProgress;
        }
    }

    const renderText = (name) => {
        switch (name) {
            case 'INVALID_CLASSIFICATION':
                return {
                    title: t("vehicleDetails:eligibility.INVALID_CLASSIFICATION.title"),
                    text: t("vehicleDetails:eligibility.INVALID_CLASSIFICATION.text")
                };
            case 'INVALID_RENEWAL_PERIOD':
                return {
                    title: t("vehicleDetails:eligibility.INVALID_RENEWAL_PERIOD.title"),
                    text: t("vehicleDetails:eligibility.INVALID_RENEWAL_PERIOD.text")
                };
            case 'OTHERS':
                return {
                    title: t("vehicleDetails:eligibility.OTHERS.title"),
                    text: t("vehicleDetails:eligibility.OTHERS.text")
                };
            case 'CONTAINS_LIENS':
                return {
                    title: t("vehicleDetails:eligibility.CONTAINS_LIENS.title"),
                    text: t("vehicleDetails:eligibility.CONTAINS_LIENS.text")
                };
            case 'VEHICLE_RENEWAL_IN_PROGRESS':
                return {
                    title: t("vehicleDetails:eligibility.VEHICLE_RENEWAL_IN_PROGRESS.title"),
                    text: t("vehicleDetails:eligibility.VEHICLE_RENEWAL_IN_PROGRESS.text")
                };
        }
    }

    return (
        <Modal
            isOpen={true}
            size="lg"
            toggle={() => toggle && toggle()}
            role="dialog"
            aria-modal={true}
            autoFocus={true}
        >
            <ModalBody className="p-0">
                <div className="p-3 py-4 border-bottom">
                    <strong>{t("vehicleDetails:eligibility.modal.title")}</strong>
                </div>

                <div className="p-3">
                    <span>{t("vehicleDetails:eligibility.modal.description")}</span>
                </div>

                <div>
                    {notiReasons && notiReasons?.length > 0 && notiReasons.map((item, index) => (
                        <div
                            className="py-3 border-top"
                            key={index}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <div className="px-3">
                                <img src={renderIcon(item)} alt="" aria-hidden="true" />
                            </div>
                            <div>
                                <strong>{renderText(item).title}</strong>
                                <br />
                                <span>{renderText(item).text}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
                <Button
                    className="btn-primary w-40"
                    onClick={() => {
                        toggle()
                    }}
                    disabled={false}
                >
                    {t("vehicleDetails:eligibility.modal.button")}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

VehicleNotiEligibilityModal.propTypes = {
    toggle: PropTypes.func.isRequired
}

export default VehicleNotiEligibilityModal