import React from "react";

import ErrorRefresh from "../ui/ErrorRefresh";
import { useTranslation } from "react-i18next";
import CescoSearchBar from "./CescoSearchBar";
import { FontWeight } from "../ui/FontSize";
import CescoListItem from "./CescoListItem";

import { Colors } from "../../Colors";
import Text from "../ui/Text";

const CescoList = props => {
    const { t } = useTranslation();
    const {
        error,
        loading,
        cescosFilter,
        fetchCescos,
        selectCesco,
        filterCescos,
        checkList,
        selectedCesco,
        scrollable
    } = props;

    return (
        <>
            {/* <div className="d-lg-none">
                <CescoSearchBar onChange={filterCescos} />
            </div> */}

            <div
                style={{
                    color: Colors.darkGray,
                    textTransform: "uppercase",
                    fontWeight: FontWeight.bold,
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "60px",
                    borderTop: `1px solid ${Colors.borderGray}`,
                    borderBottom: `1px solid ${Colors.borderGray}`
                }}
            >
                {checkList || (
                    <div className="col-3" style={tableHead}>
                        <Text className="text">{t("cescos:header.status")}</Text>
                    </div>
                )}

                <div
                    className="col-3"
                    style={{
                        ...tableHead,
                        justifyContent: "center",
                        whiteSpace: 'nowrap'
                    }}
                >
                    <Text className="text">{t("cescos:header.realId")}</Text>
                </div>
                <div className="col-2" style={tableHead}>
                    <Text className="text">{t("cescos:header.city")}</Text>
                </div>
            </div>

            <div
                style={{
                    display: "block",
                    overflow: "auto",
                    width: "100%",
                    marginBottom: "40px",
                    height: scrollable ? '250px' : 'inherit'
                }}
                className="cesco-list"
            >
                {!error ? (
                    cescosFilter.map(c => (
                        <CescoListItem
                            key={c.id}
                            cesco={c}
                            loading={loading}
                            selectCesco={selectCesco}
                            checkList={checkList}
                            selectedCesco={selectedCesco}
                        />
                    ))
                ) : (
                    <tr>
                        <td colSpan={3}>
                            <ErrorRefresh refresh={fetchCescos} />
                        </td>
                    </tr>
                )}
            </div>
        </>
    );
};

const tableHead = {
    border: "none",
    display: 'flex'
};

export default CescoList;
