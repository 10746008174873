import React from "react";
import appStoreBadge from "../../pages/Login/images/appstore-badge.svg";
import playStoreBadge from "../../pages/Login/images/play-store-badge.png";

const AppStoresBadges = () => {
    return (
        <div style={StoreBadgesStyle} className="appStoreBadges">
            <a
                href={
                    "https://apps.apple.com/us/app/cesco-digital/id1389951990?mt=8"
                }
                rel="noreferrer"
                target={"_blank"}
            >
                <img src={appStoreBadge} alt={"Apple Store badge Icon"} />
            </a>
            <a
                href={
                    "https://play.google.com/store/apps/details?id=com.dtop_mobile"
                }
                rel="noreferrer"
                target={"_blank"}
            >
                <img
                    src={playStoreBadge}
                    style={{ width: 150, display: "block" }}
                    alt={"Google Play Store badge Icon"}
                />
            </a>
        </div>
    );
};

const StoreBadgesStyle = {
    // width: '14%',
    height: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: "1rem 0 1rem 0",
};
export default AppStoresBadges;
