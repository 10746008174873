// import moment from "moment";
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import _ from "lodash";

const DEFAULT_FORMAT = 'DD-MMM-YY';
const DEFAULT_DATETIME_FORMAT = 'DD-MMM-YYYY h:mm:ss a';

const SUCCESS = "#24AAFC";
const WARNING = "#ebba42";
const ALERT = "orange";
const DANGER = "#E30303";


export function format(date, format = DEFAULT_FORMAT) {
    if (date)
        return moment(date)/*.tz('Etc/GMT')*/.format(format);
    return '';
}

export function formatAlertDate(date) {
    return format(date, "DD.MMM.YY")
}

export function formatDateTime(date) {
    return format(date, DEFAULT_DATETIME_FORMAT)
}

export function formatMonthAndYear(date) {
    return format(date, "MMMM YYYY")
}

export const formatCurrencyLabel = (currency = 0, decimals = 2) => {
    currency = Number(currency).toFixed(decimals);
    currency = currency.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return "$" + currency;
};

export function validDateForDDMMYYYY_Format(dateString) {
    let formatted = (dateString && dateString.replace(/\s/g, '')) || 'empty';
    return moment(formatted, "DDMMYYYY").isValid() && formatted.length === 8;
}

export function isEmpty(value) {
    return (!value || 0 === value.length);
}

export function openDialog(data) {
    if (!data)
        console.error("Invalid data supplied");
    if (data.startsWith("http") || data.startsWith("/"))
        openDialogWithUrl(data);
    else if (data.startsWith("data:application/pdf"))
        openDialogWithBlob(b64toBlob(data.replace("data:application/pdf;base64,", ""), 'application/pdf'));
    else
        openDialogWithBlob(data);
}

function openDialogWithUrl(url) {
    let left = window.innerWidth / 2 - 200, top = window.innerHeight / 2 - 250;
    window.open(url, "_blank", "top=" + top + ",left=" + left + ",toolbar=no,scrollbars=no,location=no");
}

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
}


export function maskLicense(lic = "") {
    let str = String(lic);
    if (str?.length <= 0) return;
    return `${str.substr(0, 1)}${_.repeat('x', 4)}${str.substr(-3, 3)}`
}

export function openDialogWithBlob(bytes) {
    let left = window.innerWidth / 2 - 200, top = window.innerHeight / 2 - 250;
    let fileBlob;

    if (bytes instanceof Blob)
        fileBlob = bytes;
    else
        fileBlob = new Blob([bytes], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(fileBlob);
    if (isSafari_iOS()) {
        let link = document.createElement('a');
        link.target = "_blank";
        link.href = fileURL;
        link.download = "file.pdf";
        link.click();
    } else
        window.open(fileURL, "_blank", "top=" + top + ",left=" + left + ",toolbar=no,scrollbars=no,location=no");

    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(fileURL);
    }, 100);
}

export function fromEntity(entity) {
    if (!entity || _.isEmpty(entity))
        return;
    let { principal, license } = entity;
    if (!principal)
        return {};
    if (!license)
        license = {};
    let fn = principal.firstName.split(" ");
    let ln = principal.lastName.split(" ");
    // debugger
    fn = _.remove(fn, n => n !== "");
    ln = _.remove(ln, n => n !== "");
    return {
        category: parseInt(license.category),
        licenseNumber: license.id,
        identityType: license.type,
        expirationDate: license.expirationDate,
        firstName: `${_.capitalize(fn[0])} ${fn.length > 1 ? _.capitalize(fn[1]) : ''}`,
        lastName: `${_.capitalize(ln[0])} ${ln.length > 1 ? _.capitalize(ln[1]) : ''}`,
        under21: license.under21,
        realId: license.realId
    };
}

export const emptyObjArray = size => (_.fill(Array(size), {}));

export const calculatePointColor = (progress) => {
    if (progress >= 0 && progress < 48)
        return SUCCESS;
    else if (progress >= 48 && progress < 64)
        return WARNING;
    else if (progress > 63 && progress < 97)
        return ALERT;
    else if (progress > 96)
        return DANGER;
    else
        return '#777';

};

export const calculateTicketsColor = (progress) => {
    if (progress >= 0 && progress <= 25)
        return SUCCESS;
    else if (progress > 25 && progress <= 50)
        return WARNING;
    else if (progress > 50 && progress <= 75)
        return ALERT;
    else if (progress > 75)
        return DANGER;
    else
        return '#777';

};
/**
 * fixes dtop height text from ie. 511 5'11"
 * @param height
 */
export const fixHeight = (height = "0.0") => {
    if (!height)
        return "-"
    // debugger
    let h = height.slice(0, 1);
    let i = height.slice(1);
    return `${h}' ${i}"`
};

export const emailValid = email =>
    (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email));

//TODO: Any better solution is welcome
export const unloadRecaptcha = (recaptchaSiteKey) => {
    const nodeBadge = document.querySelector('.grecaptcha-badge');
    if (nodeBadge) {
        document.body.removeChild(nodeBadge.parentNode);
    }

    const scriptSelector = 'script[src=\'https://www.google.com/recaptcha/api.js?render=' +
        recaptchaSiteKey + '\']';
    const script = document.querySelector(scriptSelector);
    if (script) {
        script.remove();
    }
};

export function scrollToDiv(elementId, scrollOnlyOnMobile = true, otherwiseScrollToTop = false) {
    if ((scrollOnlyOnMobile && isMobile()) || !scrollOnlyOnMobile) {
        let element = document.getElementById(elementId);
        element.scrollIntoView({ behavior: 'smooth' });
    } else {
        if (otherwiseScrollToTop)
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
    }
}

export function isMobile() {
    let a = navigator.userAgent || navigator.vendor || window.opera;
    let check = false;
    // eslint-disable-next-line
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    return check;
}

export function isSafari_iOS() {
    let userAgent = window.navigator.userAgent;
    return userAgent.match(/iPad/i) || userAgent.match(/iPhone/i);
}

export function generateIdForUI(list) {
    let index = 1;
    if (list)
        for (const element of list) {
            if (_.isEmpty(element.id))
                element.id = index++;
        }

}

export function joiner(tokens, sep) {
    return tokens
        .filter(x => typeof x === 'string' && x.length > 0)
        .join(sep)
}

export function cityZipFormat(address) {
    let stateZip = joiner([address.state, address.zipCode], ", ");
    return joiner([address.city, stateZip], " ")
}

export function isTimestampExpired(timestamp) {
    if (!timestamp) return true

    const currentDate = moment();
    const providedDate = moment(timestamp);

    return providedDate.isBefore(currentDate);
}

export function getReceiptMotive(type) {
    switch (type) {
        case "FINES":
            return "paymentHistory:receiptMotive.fines";
        case "LICENSE_FINES":
            return "paymentHistory:receiptMotive.licenseFines";
        case "VEHICLE_FINES":
            return "paymentHistory:receiptMotive.vehicleFines";
        case "LICENSE_RENEWAL":
            return "paymentHistory:receiptMotive.licenseRenewal";
        case "VEHICLE_RENEWAL":
            return "paymentHistory:receiptMotive.vehicleRenewal";
        case "CARD_PRINT":
            return "paymentHistory:receiptMotive.cardPrintCesco";
        case "CARD_PRINT_MAIL":
            return "paymentHistory:receiptMotive.cardPrintCesco";
        case "VEHICLE_TRANSFER":
            return "paymentHistory:receiptMotive.vehicleTransfer";
        case "AUTOEXPRESO_FINES":
            return "paymentHistory:receiptMotive.autoexpresoFines";
    }
}