import React, {Component, useEffect, useRef, useState} from 'react';
import SVGInjector from "svg-injector";
import {useLocation} from "react-router-dom";
import _ from "lodash";
import imageWhite from "./images/wave-1-top-sm-white.svg";
import imageBoneWhite from "./images/wave-1-top-sm-bone.svg";
import SvgImage from "../components/ui/SVGImage";

const SvgFooterImg = props => {
    const [image, setImage] = useState("imageBoneWhite");
    const location = useLocation();

    useEffect(() => {
        setImage(locationHeader(location));
    }, [location]);

    return (
        <>
            <SvgImage src={imageWhite} hidden={image !== "imageWhite"}/>
            <SvgImage src={imageBoneWhite} hidden={image !== "imageBoneWhite"}/>
        </>
    );
};

const locationHeader = (loc) => {
    let img = "imageBoneWhite";
    let { pathname } = loc;
    if(pathname === '/login')
        img = "imageWhite";
    return img;

};

export default SvgFooterImg;