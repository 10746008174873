import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { setConfig, setFeatures } from "./redux/app/app.combine";
import _ from "lodash";
import { firebaseDb, remoteConfig } from "./core/firebase";
import { onValue, ref } from 'firebase/database';
import { fetchAndActivate, getBoolean, getString, getNumber } from "firebase/remote-config";
import { useState } from 'react';
import { getJSON } from 'jquery';

const dbRef = ref(firebaseDb, '/')

const Init = (props) => {
    // Remote config
    const [rc, setRc] = useState(null)

    const dispatch = useDispatch();

    useEffect(() => {
        if (process.env.NODE_ENV === "DEV" || process.env.NODE_ENV === "development") {
            remoteConfig.settings = {
                minimumFetchIntervalMillis: 1000
            }
        }
        fetchAndActivate(remoteConfig).then(res => {
            const config = {
                acaa: {
                    ...JSON.parse(getString(remoteConfig, "ACAA_Policy")),
                    ...JSON.parse(getString(remoteConfig, "acaaConfig"))
                },
                organDonors: {
                    enabled: getBoolean(remoteConfig, "organDonorsEnabled")
                },
                reports: {
                    printDriverCertification: getBoolean(remoteConfig, "printDriverCertification")
                },
                vehicleInspection: {
                    ...JSON.parse(getString(remoteConfig, "vehicleInspection"))
                },
                payments: {
                    // requirePaymentAddress: getBoolean(remoteConfig, "requirePaymentAddressV2")
                    acceptPayment: getBoolean(remoteConfig, "acceptPayment"),
                    paymentCardHolderNameLength: getNumber(remoteConfig, "paymentCardHolderNameLength")
                },
                titleTransfer: {
                    printVehicleLicense: getBoolean(remoteConfig, "printVehicleLicense")
                },
                preBoarding: {
                    disableStatistics: getBoolean(remoteConfig, "disableStatistics"),
                    preBoardingRegistrationEnabled: getBoolean(remoteConfig, "preBoardingRegistrationEnabled"),
                    highlightsEnabled: getBoolean(remoteConfig, "highlightsEnabled"),
                    errorFormUrl: getString(remoteConfig, "errorFormUrl"),
                    allowForceRegister: getBoolean(remoteConfig, "allowForceRegister"),
                },
                electronicTag: {
                    digitalTagUnlink_component_display: getBoolean(remoteConfig, "vehicleRenewal_digitalTagUnlink_component_display")
                },
                maintenance: {
                    ...JSON.parse(getString(remoteConfig, "maintenance")),
                    maintenance_passcode_drawer_show: getBoolean(remoteConfig, "maintenance_passcode_drawer_show")
                },
                vehicleRenewal: {
                    showAcaaInstructions: getBoolean(remoteConfig, "vehicleRenewal_acaa_instructions_display"),
                    vehicleLicenseMergeFines: getBoolean(remoteConfig, "vehicleLicenseMergeFines"),
                },
                autoExpreso: {
                    autoexpresoLimit: getNumber(remoteConfig, "autoExpresoLimit")
                }
            }
            setRc(config)
        })
    }, [])

    useEffect(() => {
        // Listen for value events on ref / Retrieves config data as snapshot
        onValue(dbRef, (snapshot) => {

            const data = snapshot.val()

            const { config, realTime, web } = data

            const mergedConfig = _.merge(config, web)
            const mergedFeatures = _.merge(realTime, rc)

            config && dispatch(setConfig(mergedConfig));
            realTime && dispatch(setFeatures(mergedFeatures));
        })
    }, [rc]);

    return props.children
};

export default Init;