import React from "react";

import Text from "../../../components/ui/Text";
import {Colors} from "../../../Colors";
import cescoLogo from "../../../assets/images/LogoCescoGreen.svg";
import govLogo from "../../../assets/images/Footer_LogoSelloGob.svg";
import * as Utils from "../../../core/Utils";
import {useTranslation} from "react-i18next";
import downloadIcon from "../../../assets/images/icon_download.svg";
// import {openVoucherPdf} from "../../../core/api-resources/voucher";

const TransferVoucherBody = (props) => {
    let {data, loading, download} = props;

    const {t} = useTranslation();

    let parsedSaleDate = Utils.format(data?.saleDate, "DD.MMM.YY");

    // const openPdf = async () => {
    //
    //     const pdf = await openVoucherPdf()
    //     if (pdf) Utils.openDialog(pdf)
    // }

    const {
        buyerFirstName,
        buyerLastName,
        sellerFirstName,
        sellerLastName,
        vehicleDescription,
        cesco
    } = data

    return (
        <div>
            <div className="container" style={resultStyle}>
                <div style={resultHeading}>
                    <div className="d-flex mt-3 ml-3">
                        <img src={cescoLogo} alt="logo" style={{height: 50}}/>
                        <img src={govLogo} alt="logo"
                             style={{height: 50, marginLeft: 15, borderLeft: '1px solid lightgray', paddingLeft: 15}}/>
                    </div>

                    {
                        download &&
                        <div className="mr-3">
                            <a
                                href={"#"}
                                style={{color: "black"}}
                                // onClick={() => openPdf()}
                            >
                                <img
                                    src={downloadIcon}
                                    alt={t("disclosure:download")}
                                />
                            </a>
                        </div>
                    }

                </div>
                <div className="my-4 mx-3 text-center">
                    <Text className="text-bold">
                        {t("vehicleTransferVoucher:pageTitle")}
                    </Text>
                </div>

                <div style={resultIdc}>
                    <Text className="text-md text-bold">
                        IDC#&nbsp;
                    </Text>
                    <Text className="text-md text-bold">
                        {data?.idcNumber || 'N/A'}
                    </Text>
                </div>

                {/* DESKTOP TABLE */}
                <table className="table table-bordered text-uppercase mb-0 d-none d-md-table">
                    <tbody className="text">
                    <tr>
                        <td scope="row">
                            {t("vehicleTransferVoucher:buyer")}:
                            <br/>
                            <span className="text-bold">
                                {buyerFirstName || 'N/A'} {buyerLastName}
                            </span>
                        </td>

                        <td colSpan={2}>
                            {t("vehicleTransferVoucher:transferedVehicle")}:
                            <br/>
                            <span className="text-bold">
                                {vehicleDescription || 'N/A'}
                            </span>
                        </td>
                    </tr>

                    <tr>
                        <td scope="row">
                            {t("vehicleTransferVoucher:seller")}:
                            <br/>
                            <span className="text-bold">
                                {sellerFirstName || 'N/A'} {sellerLastName}
                            </span>
                        </td>

                        <td>
                            {t("vehicleTransferVoucher:transferDate")}:
                            <br/>
                            <span className="text-bold">
                                {parsedSaleDate || 'N/A'}
                            </span>
                        </td>

                        <td>
                            {t("vehicleTransferVoucher:pickup")}:
                            <br/>
                            <span className="text-bold">
                                {cesco || 'N/A'}
                            </span>
                        </td>

                    </tr>
                    </tbody>
                </table>

                {/* MOBILE TABLE */}
                <table className="table table-bordered text-uppercase mb-0 d-md-none d-table">
                    <tbody className="text">
                    <tr>
                        <td scope="row">
                            {t("vehicleTransferVoucher:buyer")}:
                            <br/>
                            <span className="text-bold">
                                {buyerFirstName || 'N/A'} {buyerLastName}
                            </span>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            {t("vehicleTransferVoucher:transferedVehicle")}:
                            <br/>
                            <span className="text-bold">
                                {vehicleDescription || 'N/A'}
                            </span>
                        </td>
                    </tr>
                    <tr>

                        <td scope="row">
                            {t("vehicleTransferVoucher:seller")}:
                            <br/>
                            <span className="text-bold">
                                {sellerFirstName || 'N/A'} {sellerLastName}
                            </span>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            {t("vehicleTransferVoucher:transferDate")}:
                            <br/>
                            <span className="text-bold">
                            {parsedSaleDate || 'N/A'}
                            </span>
                        </td>

                    </tr>

                    <tr>
                        <td>
                            {t("vehicleTransferVoucher:pickup")}:
                            <br/>
                            <span className="text-bold">
                                {cesco || 'N/A'}
                            </span>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
    );
};

const resultIdc = {
    backgroundColor: Colors.lightGray,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
};

const resultHeading = {
    display: "flex",
    padding: "15px",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
};

const resultStyle = {
    backgroundColor: "#fff",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "1000px",
};

export default TransferVoucherBody;
