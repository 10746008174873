import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Utils from "../../core/Utils";
import { Colors } from "../../Colors";
import { useSelector } from "react-redux";



// [^0-9~!@#$%^&*()_+={}[]|\\"':;?.<>]
let rangeFrom0to15 = /^([0-9]|[1][0-5])$/;
let onlyLetter = /^[a-zA-Z]*$/;
let onlyLetterAndSpace = /^[a-zA-Z ]*$/;
let namesRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ ]*$/;
let onlyNumber = /^[0-9]*$/;
let onlyLetterAndNumber = /^[a-zA-Z0-9 ]*$/;
let addressLines = /^[a-zA-Z0-9 \-]*$/;
let emailAcceptedChar = /^\S*$/;
let emailValidation = /[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})/;

const TextInput = (props) => {

    const [localValue, setLocalValue] = useState('')
    const [error, setError] = useState()
    const [config, setConfig] = useState()

    const paymentCardHolderNameLength = useSelector(state => state?.features?.payments?.paymentCardHolderNameLength)

    let fields = {
        string: { min: 1, max: 128, regex: onlyLetter },
        number: { min: 1, max: 6, regex: onlyNumber },
        name: { min: 3, max: paymentCardHolderNameLength || 25, regex: namesRegex },
        lastName: { min: 1, max: 128, regex: namesRegex },
        email: { regex: emailAcceptedChar, validation: emailValidation, max: 200 },
        lastSSN: { max: 4, regex: onlyNumber },
        day: { max: 2, regex: onlyNumber },
        year: { max: 4, regex: onlyNumber },
        zipcode: { length: 5, min: 5, max: 5, regex: onlyNumber },
        addressLine: { min: 2, max: 100, regex: addressLines },
        addressLine2: { min: 0, max: 100, regex: addressLines },
        password: { min: 4, max: 20, regex: emailAcceptedChar, validation: onlyLetterAndNumber, canShowToggleEye: true },
        text: { max: 50, min: 10, regex: onlyLetterAndNumber },
        cvc: { max: 4, min: 3, regex: onlyNumber },
        phone: { max: 11, min: 10, regex: onlyNumber },
        registrationNumber: { max: 12, min: 6, regex: onlyNumber },
        controlNumber: { max: 12, min: 6, regex: onlyNumber },
        importTotal: { max: 12, min: 6, regex: onlyNumber },
        city: { max: 20, min: 3, regex: onlyLetterAndSpace },
        state: { max: 2, min: 2, regex: onlyLetter },
    };

    useEffect(() => {
        if (props.code && fields[props.code]) {
            setConfig(fields[props.code]);
        }

        if (props?.value?.length > 1) {
            setLocalValue(props.value)
        }

        // setError(hasError(props.value))
    }, [props.code, props.value])

    const inputHandler = (e) => {
        e.persist();

        let value = e.target.value;

        // value = config.regex.test(value) || value === ""
        //     ? value.length === 1 ? value.trim() : value
        //     : localValue;

        // value = (config.regex.test(value) || value === "") ? value.length === 1 ? value.trim() : value : null;

        setLocalValue(value)
        setError(hasError(value))

        if (props.toggleError) {
            props.toggleError(props.errorToggler, hasError(value))
        }

        if (props.onChange) props.onChange(e);
    }

    const hasError = (value) => {
        return (config.min && value.length < config.min)
            || (config.max && value.length > config.max)
            || (config.length && value.length !== config.length)
            || (config.validation && !config.validation.test(value))
            || (config.regex && !config.regex.test(value))
    }


    let calcProps = {};
    if (config) calcProps.maxLength = config.max;

    return (<input {...props}
        {...calcProps}
        type={props.type || "text"}
        autoCorrect="none"
        autoCapitalize="none"
        onChange={inputHandler}
        value={localValue}
        style={{
            ...props.style,
            border: error && `1px solid ${Colors.error}`,
            backgroundColor: error ? Colors.errorBg : 'white',
            color: error && Colors.errorDark
        }}
        readOnly={props.readonly}
    />
    );
}

TextInput.propTypes = {
    required: PropTypes.bool,
    includeLabel: PropTypes.bool,
    iconName: PropTypes.string,
    grouped: PropTypes.bool,
    plainInput: PropTypes.bool,
    toggleError: PropTypes.func,
    errorToggler: PropTypes.func,
    code: PropTypes.oneOf([
        'string',
        'number',
        'name',
        'lastName',
        'email',
        'lastSSN',
        'day',
        'year',
        'zipcode',
        'addressLine',
        'password',
        'text',
        'cvc',
        'phone',
        'registrationNumber',
        'controlNumber',
        'importTotal'
    ]),
};

export default TextInput;
