import React, { useState } from "react";

import PageTitle from "../../../components/ui/PageTitle";
import ComponentTitle from "../../../components/ui/ComponentTitle";
import Text from "../../../components/ui/Text";
import SubHeader from "../../../layout/SubHeader";
import WizardFooter from "../../../components/widgets/WizardFooter";
import { Colors } from "../../../Colors";

import { useTranslation } from "react-i18next";

import iconDad from "../../../assets/images/icon-dad.svg";
import iconMom from "../../../assets/images/icon-mom.svg";
import birthCertificateIcon from "../../../assets/images/icon-birth-certificate.svg";
import { FontWeight } from "../../../components/ui/FontSize";
import ToggleSwitch from "../../../components/ui/ToggleSwitch";
import Card from "../../../components/ui/Card";

import iconInformationCvc from "../../../assets/images/icon-information-cvc.svg";
import { ModalHeader, Modal, ModalBody, ModalFooter } from "reactstrap";
import iconBirthCertNumber from "../../../assets/images/icon-birth-cert-number.svg";

const BirthCertificate = () => {
    const [fatherLastName, setFatherLastName] = useState("");
    const [motherLastName, setMotherLastName] = useState("");
    const [certificateNumber, setCertificateNumber] = useState("");
    const [notFound, toggleNotFound] = useState(true);
    const [toggleState, setToggleState] = useState(false);

    const [modal, toggleModal] = useState(false);

    const { t } = useTranslation();

    return (
        <>
            <SubHeader>
                <div className="container">
                    <PageTitle
                        style={{ fontWeight: "bold" }}
                        i18nKey={"birthCertificate:pageTitle"}
                    />
                    <div className="d-none d-lg-flex">
                        <hr />
                    </div>
                    <Text
                        className="text"
                        i18nKey="birthCertificate:subTitle"
                        style={{ color: Colors.gray }}
                    />
                </div>
            </SubHeader>

            <div className="container" style={{ padding: 0 }}>
                <ComponentTitle
                    i18nKey="birthCertificate:componentTitle"
                    noBorder
                />

                <div style={{ display: "flex" }} className="birth-certificate">
                    <div
                        className="col-12 col-lg-7"
                        style={{ marginBottom: "20px", paddingLeft: 0 }}
                    >
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span
                                    className="input-group-text"
                                    style={{
                                        backgroundColor: Colors.lightGray,
                                        borderRadius: 0,
                                    }}
                                >
                                    <img
                                        src={iconDad}
                                        alt={""}
                                        style={{ width: "24px" }}
                                    />
                                </span>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                aria-label={t(
                                    "birthCertificate:fatherLastName"
                                )}
                                placeholder={t(
                                    "birthCertificate:fatherLastName"
                                )}
                                value={fatherLastName}
                                onChange={(e) =>
                                    setFatherLastName(e.target.value)
                                }
                                style={{
                                    height: "auto",
                                    borderRadius: 0,
                                }}
                            />
                        </div>

                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span
                                    className="input-group-text"
                                    style={{
                                        backgroundColor: Colors.lightGray,
                                        borderRadius: 0,
                                        borderTop: "none",
                                        borderBottom: "none",
                                    }}
                                >
                                    <img
                                        src={iconMom}
                                        alt={""}
                                        style={{ width: "24px" }}
                                    />
                                </span>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                aria-label={t(
                                    "birthCertificate:motherLastName"
                                )}
                                placeholder={t(
                                    "birthCertificate:motherLastName"
                                )}
                                value={motherLastName}
                                onChange={(e) =>
                                    setMotherLastName(e.target.value)
                                }
                                style={{
                                    height: "auto",
                                    borderRadius: 0,
                                    borderTop: "none",
                                    borderBottom: "none",
                                }}
                            />
                        </div>

                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span
                                    className="input-group-text"
                                    style={{
                                        backgroundColor: Colors.lightGray,
                                        borderRadius: 0,
                                    }}
                                >
                                    <Text
                                        style={{
                                            width: "24px",
                                            fontWeight: FontWeight.bold,
                                        }}
                                    >
                                        #
                                    </Text>
                                </span>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                aria-label={t(
                                    "birthCertificate:certificateNumber"
                                )}
                                placeholder={t(
                                    "birthCertificate:certificateNumber"
                                )}
                                value={certificateNumber}
                                onChange={(e) =>
                                    setCertificateNumber(e.target.value)
                                }
                                style={{
                                    height: "auto",
                                    borderRadius: 0,
                                }}
                            />
                            <span>
                                <img
                                    src={iconInformationCvc}
                                    className="creditCardPadding"
                                    alt={""}
                                    style={{
                                        position: "absolute",
                                        right: 0,
                                        top: 17,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => toggleModal(!modal)}
                                />
                            </span>
                        </div>
                    </div>
                    <Card
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 0
                        }}
                    >
                        {notFound ? (
                            <div style={notFoundWrapper}>
                                <div style={notFoundStyle}>
                                    <Text
                                        i18nKey="birthCertificate:notFoundTitle"
                                        style={{
                                            color: Colors.error,
                                            textTransform: "uppercase",
                                        }}
                                        className="text-sm"
                                    />
                                    <Text
                                        i18nKey="birthCertificate:notFoundDescription"
                                        className="text-sm"
                                    />
                                </div>
                                <div style={notFoundToggle}>
                                    <Text
                                        i18nKey="birthCertificate:notFoundToggle"
                                        className="text-sm"
                                        style={{
                                            width: "80%",
                                            marginRight: "15px",
                                        }}
                                    />
                                    <div>
                                        <ToggleSwitch
                                            state={toggleState}
                                            setState={setToggleState}
                                            // disabled={toggleState} //REAL-ID enabled can not be change
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <img
                                src={birthCertificateIcon}
                                alt=""
                                aria-hidden={true}
                                style={{ opacity: "50%", padding: "20px" }}
                            />
                        )}
                    </Card>
                </div>
            </div>

            <Modal
                isOpen={modal}
                size="md"
                style={{ padding: "15px" }}
                toggle={() => toggleModal(!modal)}
            >
                <ModalHeader
                    style={{ backgroundColor: Colors.lightGray }}
                    toggle={() => toggleModal(!modal)}
                >
                    <Text
                        className="text"
                        i18nKey="birthCertificate:modalTitle"
                    />
                </ModalHeader>
                <ModalBody
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "justify",
                        margin: "10px 0",
                    }}
                >
                    <img
                        src={iconBirthCertNumber}
                        aria-hidden={true}
                        alt=""
                        style={{ width: "100%", marginBottom: "20px" }}
                    />
                    <Text
                        className="text"
                        i18nKey="birthCertificate:modalDescription"
                    />
                </ModalBody>
            </Modal>

            <div className="container">
                <WizardFooter
                // {...props}
                // disabled={!checked}
                // handleNext={onNextAction}
                />
            </div>
        </>
    );
};

const notFoundWrapper = {
    display: "flex",
    flexDirection: "column",
    borderTop: `1px solid ${Colors.lightGray}`
};

const notFoundToggle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px",
};

const notFoundStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderBottom: `1px solid ${Colors.lightGray}`,
    padding: "15px",
};

export default BirthCertificate;
