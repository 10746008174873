import React from "react";
import { Colors } from "../../Colors";

const SubHeaderContainer = (props) => {
    const { children, clickable, action } = props;

    return (
        <>
            <div
                aria-hidden="true"
                style={{
                    ...SubHeaderContainerStyle,
                    borderBottom: clickable && `4px solid ${Colors.primary}`,
                    cursor: clickable && 'pointer'
                }}
                className={`col-lg-12 sub-header-container d-none d-lg-flex ${clickable && 'clickable-sub-header'}`}
                onClick={() => action && action()}
            >
                {children}
            </div>

            <div
                aria-hidden="true"
                style={{
                    ...MobileSubHeaderContainerStyle,
                    borderBottom: clickable && `4px solid ${Colors.primary}`,
                    cursor: clickable && 'pointer'
                }}
                className="d-lg-none"
                onClick={() => action && action()}
            >
                <div style={{
                    height: '100%',
                    width: 110,
                    margin: '15px 0'
                }}>
                    {children}
                </div>
            </div>
        </>
    );
};

const SubHeaderContainerStyle = {
    maxWidth: 300,
    height: 135,
    padding: "1rem 2rem",
    marginRight: "1rem",
    marginTop: "0",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px",
    border: "solid 1px #e7eaf3",
    fontSize: "20px",
};

const MobileSubHeaderContainerStyle = {
    height: 135,
    margin: "7.5px",
    marginTop: "0",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    borderRadius: "4px",
    border: "solid 1px #e7eaf3",
    fontSize: "20px",
    textAlign: "center",
};

export default SubHeaderContainer;
