import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Colors } from "../../Colors"
import { useTranslation } from "react-i18next"
import Text from '../ui/Text'
import AppStoresBadges from '../ui/AppStoresBadges'
import art from '../../assets/images/electronic-tag/MarbeteElectronico_Pre-registro.svg'
import { isMobile } from '../../core/Utils'

const TagRenewalModal = (props) => {

    const { toggle } = props

    const { t } = useTranslation()

    const mobile = isMobile()

    return (
        <Modal
            isOpen={true}
            size="md"
            toggle={() => toggle && toggle(false)}
            role="dialog"
            aria-modal={true}
            autoFocus={true}
        >
            <ModalHeader
                style={{ backgroundColor: Colors.lightGray }}
                toggle={() => toggle && toggle(false)}
            >
                <Text className="text">{t("tagRenewal:modal.title")}</Text>
            </ModalHeader>
            <ModalBody
                style={{
                    display: "flex",
                    flexDirection: 'column',
                    borderBottom: 0
                }}
            >
                <div className='d-flex justify-content-center w-100 mb-5'>
                    <img src={art} alt="" aria-hidden="true" style={{ height: 140 }} />
                </div>
                <Text className="text text-md-left text-center" style={{ lineHeight: 1.3 }}>{t("tagRenewal:modal.body")}</Text>
                <AppStoresBadges />
            </ModalBody>
        </Modal>
    )
}

export default TagRenewalModal