import {api, handleResponse} from "../APICaller";
import store from "../../store";
import {useEffect} from "react";

// DEPRECATED
// export function validateIdc(idc, onSuccess, onError) {
//     return api
//         .get(`/validate/renewal/${idc}`)
//         .then((response) => handleResponse(response, onSuccess, onError));
// }


export function validateIdc(idc, onSuccess, onError){
    let recaptcha = store.getState().recaptcha;
    let config = { headers: { 'recaptcha-token': recaptcha.token } };

    return api
        .get(`/validate/voucher/${idc}`, null, config)
        .then((response) => handleResponse( response, onSuccess, onError));
}
