import React from 'react'
import { useTranslation } from "react-i18next";
import { Colors } from "../../Colors";
import { formatCurrencyLabel } from "../../core/Utils";
import { FontWeight } from "../ui/FontSize";
import Text from '../ui/Text';

const AutoexpresoTotalSummary = (props) => {

    const {t} = useTranslation();

    const {finesTotal, tollAmount, total, noLines} = props

    return (
        <div
            className="card card-frame"
            style={{
                ...totalCard,
                backgroundColor: noLines ? 'transparent' : '#fff',
                borderBottom: !noLines
                    ? `6px solid ${Colors.lightGray}`
                    : "none",
            }}
        >
            <div className="alert-padding totalSummaryPadding">
                <table style={tableStyle} role="presentation">
                    <tbody>
                    <tr
                        role="text"
                        aria-label={`${t(
                            "paymentHistory:modal.totalSummaryPayAll.finesTotal"
                        )}: ${formatCurrencyLabel(finesTotal)}`}
                    >
                        <th scope="row" style={tableHead}>
                            <Text
                                className="text-alert"
                                i18nKey={
                                    "paymentHistory:modal.totalSummaryPayAll.finesTotal"
                                }
                            />
                        </th>
                        <td>
                            <Text className="text" style={ammountStyle}>
                                {formatCurrencyLabel(finesTotal)}
                            </Text>
                        </td>
                    </tr>
                    <tr
                        role="text"
                        aria-label={`${t(
                            "paymentHistory:modal.totalSummaryPayAll.tollAmount"
                        )}: ${formatCurrencyLabel(tollAmount)}`}
                    >
                        <th scope="row" style={tableHead}>
                            <Text
                                className="text-alert"
                                i18nKey={
                                    "paymentHistory:modal.totalSummaryPayAll.tollAmount"
                                }
                            />
                        </th>
                        <td>
                            <Text className="text" style={ammountStyle}>
                                {formatCurrencyLabel(tollAmount)}
                            </Text>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div
                style={{
                    ...finalTotal,
                    borderTop: `1px solid ${Colors.lightGray}`
                }}
                className="alert-padding"
                role="text"
                aria-label={`${t(
                    "paymentHistory:modal.totalSummaryPayAll.total"
                )}: ${formatCurrencyLabel(total)}`}
            >
                <Text
                    style={totalText}
                    className="text-md"
                    i18nKey={"paymentHistory:modal.totalSummaryPayAll.total"}
                />
                <Text style={totalText} className="text-md">
                    {formatCurrencyLabel(total)}
                </Text>
            </div>
        </div>
    )
}


const totalCard = {
    margin: 0,
    border: "none",
    borderRadius: 0,
    backgroundColor: 'transparent'
};

const finalTotal = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
};

const extraFieldInfo = {
    display: "flex",
    flexDirection: "column",
    paddingTop: "20px",
};

const tableStyle = {
    width: "100%",
    textAlign: "right",
    color: Colors.gray,
    fontWeight: FontWeight.medium,
};

const tableHead = {
    fontWeight: FontWeight.regular,
    textTransform: "uppercase",
};

const totalText = {
    fontWeight: "bold",
    color: "#000",
    marginLeft: "10px",
};

const ignoreSurchargeAmount = {
    color: "red",
    textDecoration: "line-through",
    fontWeight: FontWeight.medium,
};

const ammountStyle = {
    marginLeft: "10px"
};

export default AutoexpresoTotalSummary