import React from "react";
import _ from "lodash";

import IdentityBadge from "./IdentityBadge";
import * as Utils from "../../core/Utils";
import { FontWeight } from "../ui/FontSize";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const IdentityRowMobile = (props) => {
    const user = useSelector((state) => state?.login?.user);
    const card = useSelector((state) => state?.card);
    const { t } = useTranslation();

    const {
        under21,
        realId,
        identityType,
        firstName,
        lastName,
        licenseNumber,
        expirationDate,
        category,
    } = props.user;

    return (
        <div
            role="region"
            style={{
                color: "#fff",
                width: "100%",
            }}
            aria-label={`${firstName} ${lastName}`}
        >
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: 30 }}>
                    <IdentityBadge
                        under21={under21}
                        realId={realId}
                        identityType={identityType}
                    veteran={card?.personalInfo?.veteran}
                    />
                </div>
                <div
                    aria-hidden="true"
                    style={{
                        fontWeight: FontWeight.bold,
                        fontSize: "18px",
                    }}
                >
                    {" "}
                    {firstName}
                    <br />
                    {lastName}
                </div>
            </div>

            <div
                style={{
                    fontSize: "17px",
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "space-between",
                }}
            >
                <div
                    role="text"
                    aria-label={`${
                        user?.license?.type === "ID"
                            ? t("main:identityHeader.arias.idNo")
                            : t("main:identityHeader.arias.license")
                    } ${licenseNumber}`}
                >
                <span>
                    {user?.license?.type === "ID"
                        ? t("main:identityHeader.idNoShort")
                        : t("main:identityHeader.license")}
                </span>
                <br />
                <span style={{ fontWeight: "bold" }}>{licenseNumber || "N/A"}</span>
                </div>
                <div
                    role="text"
                    aria-label={`${t(
                        "main:identityHeader.arias.expiryDate"
                    )} ${Utils.format(expirationDate, "DD/MMMM/YYYY")}`}
                >
                    {t("main:identityHeader.expiryDate")} <br />
                    <span style={{ fontWeight: "bold" }}>
                        {Utils.format(expirationDate, "DD.MMM.YYYY") || "N/A"}
                    </span>
                </div>
                <div
                    role="text"
                    style={{ textAlign: "center" }}
                    aria-label={`${t(
                        "main:identityHeader.category"
                    )} ${category}`}
                >
                    <span>{t("main:identityHeader.categorySmall")}</span> <br />
                    <span style={{ fontWeight: "bold" }}>{category || "N/A"}</span>
                </div>
            </div>
        </div>
    );
};

export default IdentityRowMobile;
