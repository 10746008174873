import React, { Fragment } from "react";

import Text from "../../components/ui/Text";
import * as Utils from "../../core/Utils";
import { FontWeight } from "../../components/ui/FontSize";
import { Colors } from "../../Colors";
import ReactMarkdown from "react-markdown";

const MessageDetails = (props) => {

    const { title, date, caption } = props?.selectedMessage;

    const RenderMessageBody = () => {
        const format = props?.selectedMessage?.format

        if (!format) return console.log('Message format undefined')

        switch (format) {
            case 'MARKDOWN':
                return <ReactMarkdown>{caption}</ReactMarkdown>

            case 'HTML':
                return (
                    <Text style={{ color: Colors.gray, lineHeight: 1.5 }} className="text">
                        {caption
                            ? caption.split("\n").map((item, key) => {
                                return (
                                    <Fragment key={key}>
                                        <div dangerouslySetInnerHTML={{ __html: item }}></div>
                                        <br />
                                    </Fragment>
                                );
                            })
                            : null}
                    </Text>
                )

            default:
                return <div>{caption}</div>
        }
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
            className="p-mob-only"
        >
            <Text
                className="text-lg"
                style={{
                    fontWeight: FontWeight.bold,
                    marginBottom: "4px",
                }}
            >
                {title}
            </Text>
            <Text
                className="text"
                style={{
                    borderBottom: `1px solid ${Colors.borderDarkGray}`,
                    color: Colors.gray,
                    marginBottom: "10px",
                    paddingBottom: "10px",
                }}
            >
                {Utils.formatDateTime(date, "DD.MMM.YYYY")}
            </Text>

            { RenderMessageBody()}
        </div>
    );
};

export default MessageDetails;
