import { api, handleResponse } from "../APICaller";

export function vehicleRenewalTagHistory(vehicleId, onSuccess, onError) {

    if (!vehicleId) throw new Error('No vehicleId provided to tagPreboardingEligibility.')

    return api
        .get(`/vehicle/renewal/${vehicleId}/tag/history`)
        .then((response) => handleResponse(response, onSuccess, onError));
}

export function vehicleRenewalInventorySerialNumber(vehicleId, onSuccess, onError) {

    // if (!vehicleId) throw new Error('No vehicleId provided to vehicleRenewalInventorySerialNumber.')

    return api
        .get(`/pre-boarding/vehicle/${vehicleId}/renewal/serialNumber`)
        .then((response) => handleResponse(response, onSuccess, onError));
}
