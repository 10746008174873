import React, {useEffect, useState} from "react";

import searchIcon from "../../assets/images/search-icon.svg";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const CescoSearchBar = (props) => {
    const [value, setValue] = useState("");
    const {t} = useTranslation();

    useEffect(() => {
        setValue(props.value)
    }, [props.value]);

    const localOnChange = event =>{
      setValue(event.target.value);
      props.onChange(event.target.value);
    };
    return (
        <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend" aria-hidden="true">
                <span className="input-group-text" id="basic-addon1">
                    <img src={searchIcon} alt=""/>
                </span>
            </div>
            <input className="form-control"
                   value={value}
                   role={"search"}
                   aria-label={t("cescos:searchAria")}
                   placeholder={t("cescos:search")} onChange={localOnChange}/>
        </div>
    );
};

CescoSearchBar.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default CescoSearchBar;
