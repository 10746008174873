import React, { useEffect, useState } from "react";

import PaymentFineListItem from "../../components/widgets/PaymentFineListItem";
import PaymentFineListItemMobile from "../../components/widgets/PaymentFineListItemMobile";

import WizardFooter from "../../components/widgets/WizardFooter";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ComponentTitle from "../../components/ui/ComponentTitle";
import Pagination from "react-js-pagination";
import PayFinesWizard from "./components/PayFinesWizard";
import RenewalWizard from "../Renewal/components/RenewalWizard";

import { CALC_CART_TOTAL, calcCart, fetchAutoexpresoFinesAction, fineRemove, payAllSummary, paySelectionSummary, resetCart } from "../../redux/fines/actions";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import InformationRow from "../../components/ui/InformationRow";
import FineTotalBadge from "../../components/ui/FineTotalBadge";

import noFinesIcon from "../../assets/images/icon_Badge_Renovacion_Excelente.svg";
import Text from "../../components/ui/Text";
import SubHeader from "../../layout/SubHeader";

import { useTranslation } from "react-i18next";
import { resetFlow } from "../../redux/app/app.combine";
import { isMobile } from "../../core/Utils";
import { showError } from "../../redux/loading/loadingState.combine";
import AutoexpresoTotalSummary from "../../components/widgets/AutoexpresoTotalSummary";

const PayAutoexpresoFines = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { vehicleId } = useParams();

    const flow = useSelector((state) => state?.flow);
    const finesCart = useSelector((state) => state?.fines?.cart?.fines);
    const paymentTotal = useSelector((state) => state?.fines?.cart?.paymentTotal);

    const PAGE_SIZE = 10;
    const [currentPage, setCurrentPage] = useState(0);

    const [confirmationModal, toggleConfirmation] = useState(false);
    const [fineToRemove, setFineToRemove] = useState("");

    const [data, setData] = useState();
    const [autoexpresoFines, setAutoexpresoFines] = useState([]);

    const { t } = useTranslation();

    // TODO if !vehicleId or !cart.length > 0, redirect to /landing

    // If PAY_ALL fetch autoexpreso fines and set on state
    useEffect(() => {
        if (flow === "AUTOEXPRESO_PAY_ALL") {
            vehicleId && fetchAutoexpresoFines()
        }
    }, [flow, vehicleId, currentPage])

    // If AUTOEXPRESO set autoexpreso fines on state from redux cart
    useEffect(() => {
        if (flow === "AUTOEXPRESO") {
            setAutoexpresoFines(finesCart)
        }
    }, [finesCart])

    useEffect(() => {
        fetchSummary()
    }, [flow === "AUTOEXPRESO" && finesCart?.length])

    const fetchSummary = async () => {

        const fetchError = (error) => {
            console.error("Error. autoexpresoFinesSummary: ", error)
            dispatch(showError(t("payFines:autoexpreso.summaryError")))
            setTimeout(() => { history.push("/landing") }, 2000);
        }

        if (flow === "AUTOEXPRESO_PAY_ALL") {
            dispatch(payAllSummary(
                vehicleId,
                null,
                err => fetchError(err)
            ))
        }
        if (flow === "AUTOEXPRESO") {
            dispatch(paySelectionSummary(
                vehicleId,
                { selectedFines: finesCart },
                null,
                err => fetchError(err)
            ))
        }
    }

    const pageOnChange = (page) => {
        setCurrentPage(page);
    }

    const handleRemoveFine = (fine) => {
        toggleConfirmation(true);
        setFineToRemove(fine);
    };

    const removeFine = () => {
        dispatch(fineRemove(fineToRemove))
        toggleConfirmation(false);
        if (finesCart?.length === 1) {
            history.goBack()
        }
    };

    const cancelAction = () => {
        dispatch(resetCart());
        dispatch(resetFlow());
        history.push("/landing");
    };

    const fetchAutoexpresoFines = async () => {
        await dispatch(fetchAutoexpresoFinesAction(
            vehicleId,
            currentPage,
            PAGE_SIZE,
            (data) => {
                setData(data)
                setAutoexpresoFines(data?.content);
            },
            (error) => {
                console.error("Error. fetchAutoexpresoFines: ", error)
                dispatch(showError(t("payFines:autoexpreso.summaryError")))
                history.push("/landing")
            }
        ))
    }

    const handleNext = () => {
        history.push("/pay/method")
    }

    return (
        <>
            <SubHeader>
                <PayFinesWizard step={1} />
            </SubHeader>

            {confirmationModal ? (
                <ConfirmationModal
                    action={removeFine}
                    toggle={toggleConfirmation}
                    text={t("payFines:confirmationModal")}
                    title={t("payFines:confirmationModalTitle")}
                />
            ) : null}
            <div className="container">
                <div className="row">
                    <div
                        className="col-lg-5 col-12"
                        style={{ marginBottom: "1rem" }}
                    >
                        <ComponentTitle
                            i18nKey={"payFines:total"}
                            amount={autoexpresoFines && autoexpresoFines?.length}
                            noBorder
                        >
                            <FineTotalBadge amount={paymentTotal?.finesCount || '0'} />
                        </ComponentTitle>
                        <AutoexpresoTotalSummary
                            finesTotal={paymentTotal?.finesTotal}
                            tollAmount={paymentTotal?.tollAmount}
                            total={paymentTotal?.total}
                        />
                    </div>

                    <div
                        className="col-lg-7 col-12"
                        style={{ paddingRight: 0 }}
                    >
                        <ComponentTitle i18nKey={"payFines:fineList"} />
                        <table className="table">
                            <tbody>
                                {autoexpresoFines && autoexpresoFines.length > 0 ? (
                                    autoexpresoFines.map((f, i) => (
                                        <React.Fragment key={i}>
                                            <div className="d-none d-lg-block">
                                                <PaymentFineListItem
                                                    fine={f}
                                                    handleRemoveFine={handleRemoveFine}
                                                    renewal={flow === "AUTOEXPRESO_PAY_ALL"}
                                                    autoexpreso
                                                />
                                            </div>
                                            <div className="d-lg-none">
                                                <PaymentFineListItemMobile
                                                    fine={f}
                                                    handleRemoveFine={handleRemoveFine}
                                                    renewal={flow === "AUTOEXPRESO_PAY_ALL"}
                                                    autoexpreso
                                                />
                                            </div>
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <div style={noFines}>
                                        <img
                                            src={noFinesIcon}
                                            aria-hidden="true"
                                            alt=""
                                            style={{
                                                marginBottom: "20px",
                                                opacity: "0.5",
                                            }}
                                        />
                                        <Text i18nKey="payFines:noFines" />
                                    </div>
                                )}
                            </tbody>
                        </table>
                        {
                            autoexpresoFines && data?.pageable?.totalPages > 1 && (
                                <nav style={{ marginTop: 20, marginBottom: 40 }}>
                                    <Pagination
                                        innerClass={"pagination justify-content-center"}
                                        activePage={currentPage}
                                        itemsCountPerPage={PAGE_SIZE}
                                        totalItemsCount={data?.pageable?.totalElements}
                                        pageRangeDisplayed={isMobile() ? 2 : 3}
                                        onChange={pageOnChange}
                                        itemClass={"page-item"}
                                        linkClass={"page-link"}
                                    />
                                </nav>
                            )
                        }
                    </div>
                    <div className="container">
                        <WizardFooter
                            cancelAction={cancelAction}
                            disabled={!autoexpresoFines?.length > 0}
                            handleNext={handleNext}
                        />
                    </div>
                </div>
            </div>
            <InformationRow textKey="payFines:footer.text" />
        </>
    );
};

const noFines = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    width: "100%",
    height: "auto",
    padding: "60px",
    textAlign: "center",
};

export default PayAutoexpresoFines;
