import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'

import Icon from "../../components/ui/Icon";
import dropDownIcon from "../../assets/images/icon_download.svg";
import disableDropDownIcon from "../../assets/images/icon_download-disabled.svg";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import Card from "../ui/Card";
import { Colors } from "../../Colors";
import iconInfo from "../../assets/images/icon-info-blue.svg";
import Text from "../ui/Text";
import { FontWeight } from "../ui/FontSize";

import SelectableVehicleCertifications from "./SelectableVehicleCertifications";

const Certifications = (props) => {
    let { certifications, source, formCertifications, vehicleSelection } = props;
    let { t } = useTranslation();

    if (vehicleSelection) {
        return (
            <SelectableVehicleCertifications withEmptyState={vehicleSelection} />
        )
    }

    return (
        <Card noPadding>
            <ul
                className="list-group"
                type={"Listado de certificaciones para descargar"}
            >
                {certifications &&
                    certifications.map((cert, idx) => {
                        return (
                            <button
                                key={idx}
                                className={`list-group-item d-flex justify-content-between align-items-center hoverable ${!cert.enable && "disabled"}`}
                                style={{
                                    opacity: cert.enable
                                        ? 1
                                        : 0.5,
                                    borderRadius: 0,
                                    padding: "1rem",
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                }}
                                onClick={
                                    cert.enable
                                        ? () => cert.action()
                                        : _.noop
                                }
                            >
                                <div
                                    style={{
                                        color: cert.enable
                                            ? "#000"
                                            : Colors.gray,
                                        display: "flex",
                                        width: "80%",
                                        alignItems: 'center'
                                    }}
                                >
                                    <Icon
                                        alt={""}
                                        src={cert.icon}
                                        style={{
                                            marginRight: "1rem",
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    />
                                    <Text
                                        className="text"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            textAlign: 'left',
                                            fontWeight: FontWeight.medium,
                                        }}
                                    >
                                        {t(`${cert.name}.text`)}
                                    </Text>
                                </div>

                                <ReactTooltip
                                    id={cert.name}
                                    place="left"
                                    type="dark"
                                    effect="float"
                                    disable={cert.enable}
                                />
                                <span
                                    data-tip={t(
                                        `${source}:certifications.disabled`
                                    )}
                                    data-for={cert.name}
                                    style={{ display: "flex" }}
                                >
                                    {props.formCertifications && (
                                        <a
                                            href={
                                                cert.infoLink ? cert.infoLink :
                                                    "https://www.dtop.pr.gov/formularios-de-solicitud-licencias/#1540480991864-022fbe3c-dac6"
                                            }
                                            target="_blank"
                                        >
                                            <img
                                                src={iconInfo}
                                                style={{
                                                    marginRight: "15px",
                                                    height: "20px",
                                                    width: "20px",
                                                }}
                                                aria-hidden="true"
                                                alt=""
                                            />
                                        </a>
                                    )}
                                    <img
                                        src={
                                            cert.enable
                                                ? dropDownIcon
                                                : disableDropDownIcon
                                        }
                                        alt={t(`${cert.name}.alt`)}
                                        className={"pointer"}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                        }}
                                    />
                                </span>
                            </button>
                        );
                    })}
            </ul>
        </Card>
    );
};

Certifications.propTypes = {
    vehicleSelection: PropTypes.bool
}

export default Certifications;
