import React from 'react'
import NoVehicleIcon from "../../assets/images/no-vehiculo-icon.svg";
import { useTranslation } from 'react-i18next';

const NoVehicles = () => {

    const { t } = useTranslation()

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                minHeight: "12rem",
            }}
        >
            <img
                src={NoVehicleIcon}
                style={{ margin: "1rem" }}
                alt={""}
            />
            <p>{t("vehicleList:noVehicles")}</p>
        </div>
    )
}

export default NoVehicles