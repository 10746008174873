import React from "react";
import Text from "../../components/ui/Text";
import { Colors } from "../../Colors";
import { FontWeight } from "../../components/ui/FontSize";
import Loadable from "../../components/ui/Loadable";

const VehiclePageTitle = (props) => {
    return (
        <div style={PageTitleStyle}>
            <div
                style={{
                    width: "4px",
                    height: "16px",
                    backgroundColor: Colors.blue,
                    marginRight: "6px",
                    marginBottom: "6px",
                    alignSelf: "flex-end",
                }}
            />
            <Text
                style={titleText}
                i18nKey={"vehicleDetails:title"}
                className="text-lg page-title"
            />
            <span className="d-none d-lg-block">
                <Text style={titleText}>&nbsp;-&nbsp;</Text>
                <Loadable loading={props.loading}>
                    <Text className="text-lg" style={{ ...titleText }}>
                        {props.year}&nbsp;
                    </Text>
                    <Text className="text-lg" style={{ ...titleText }}>
                        {props.make}&nbsp;
                    </Text>
                    <Text className="text-lg" style={{ ...titleText }}>
                        {props.model}
                    </Text>
                    <Text
                        className="text-lg"
                        style={{
                            ...titleText,
                            fontWeight: FontWeight.regular,
                            marginLeft: "8px",
                        }}
                        i18nKey={`(${props.plate})`}
                    />
                </Loadable>
            </span>
        </div>
    );
};

const PageTitleStyle = {
    display: "flex",
    marginTop: "4px",
};

const titleText = {
    fontWeight: FontWeight.bold,
    letterSpacing: "0.03rem",
};

export default VehiclePageTitle;
