import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { Colors } from '../../Colors'
import numSelloArt from '../../assets/images/electronic-tag/Autoexpreso_NumSello.svg'
import Text from '../ui/Text'

const ValidationInputInfoModal = (props) => {

    const { toggle } = props
    const { t } = useTranslation()

    return (
        <Modal
            isOpen={true}
            size="md"
            toggle={() => toggle && toggle(false)}
        >
            <ModalHeader
                toggle={() => toggle && toggle(false)}
            >
                <Text i18nKey="preboarding:infoModal.title" className="text" />
            </ModalHeader>

            <ModalBody
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 0
                }}
            >
                <div
                    style={{ backgroundColor: Colors.bgGray }}
                    className="w-100 d-flex justify-content-center py-5"
                >
                    <img
                        alt=""
                        aria-hidden
                        src={numSelloArt}
                    />
                </div>

                <div className="p-3 border-top lh-md">
                    <span>{t("preboarding:infoModal.0")}</span>
                    <strong>{t("preboarding:infoModal.1")}</strong>
                    <span>{t("preboarding:infoModal.2")}</span>
                    <strong>{t("preboarding:infoModal.3")}</strong>
                    <br /> <br />
                    <span>{t("preboarding:infoModal.4")}</span>
                    <strong style={{ color: Colors.error }}>{t("preboarding:infoModal.5")}</strong>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ValidationInputInfoModal