import {LOADING_END, LOADING_START, reportError} from "../loading/loadingState.combine";
import * as Api from "../../core/APICaller";
import {handleResponse} from "../../core/APICaller";
import {vehicleRenewalFlow} from "../app/app.combine";
import {setFinesForRenewal} from "../fines/actions";
import * as Utils from "../../core/Utils";

export const SET_VEHICLE_FOR_RENEW = "SET_VEHICLE_FOR_RENEW";
export const FETCH_VEHICLE_RENEWAL_ELIGIBILITY = "FETCH_VEHICLE_RENEWAL_ELIGIBILITY";
export const SET_VEHICLE_RENEWAL_INSURANCE = "SET_VEHICLE_RENEWAL_INSURANCE";
export const FETCH_VEHICLE_RENEWAL_CHARGES = "FETCH_VEHICLE_RENEWAL_CHARGES";
export const FETCH_VEHICLE_VOUCHER_DATA = "FETCH_VEHICLE_VOUCHER_DATA";
export const SET_VEHICLE_NOTI_REASONS = "SET_VEHICLE_NOTI_REASONS";

const defaultState = {
    eligible: false,
    eligibleRequirements: [],
    insuranceSelected: null,
    voucherData: null
};

export const fetchVehicleRenewalEligibility = (vehicle, onSuccess) => async (dispatch) => {
    dispatch(vehicleRenewalFlow());
    dispatch({"type": LOADING_START});
    let response = handleResponse(await Api.vehicleRenewalRequirements(vehicle.id));
    dispatch({
        "type": SET_VEHICLE_FOR_RENEW,
        payload: {vehicle}
    });
    dispatch({
        "type": FETCH_VEHICLE_RENEWAL_ELIGIBILITY,
        payload: response
    });
    onSuccess(response);
    dispatch({"type": LOADING_END});
};

export const setInsuranceCompany = (insuranceInfo, onSuccess) => async (dispatch, getState) => {
    let {vehicleRenewal} = getState();
    let {vehicle} = vehicleRenewal;
    dispatch({
        "type": SET_VEHICLE_RENEWAL_INSURANCE,
        payload: insuranceInfo
    });

    dispatch(await fetchVehicleRenewalCharges(vehicle.id, onSuccess))
};

const fetchVehicleRenewalCharges = (vehicleId, onSuccess) => async (dispatch) => {
    dispatch({"type": LOADING_START});
    let response = handleResponse(await Api.vehicleRenewalCharges(vehicleId));
    dispatch(await setFinesForRenewal(response));
    dispatch({
        "type": FETCH_VEHICLE_RENEWAL_CHARGES,
        payload: response
    });
    dispatch({"type": LOADING_END});
    onSuccess();
};

export const submitVehicleRenewal = (onSuccess, onError) => async (dispatch, getState) => {
    let {vehicleRenewal, payment} = getState();
    let {creditCard} = payment;
    let form = {};
    form.insurance = vehicleRenewal.insuranceSelected;
    form.creditCard = creditCard;
    form.email = creditCard.email;
    delete creditCard.email;
    dispatch({"type": LOADING_START});
    handleResponse(await Api.renewVehicleTag(form), ()=>{
        if(onSuccess)
            onSuccess();
        dispatch(fetchVehicleVoucherData());
    }, onError);
    dispatch({"type": LOADING_END});
}

export const fetchVehicleVoucherData = () => async (dispatch, getState) =>{
    let {vehicleRenewal} = getState();
    let {vehicle} = vehicleRenewal;
    dispatch({"type": LOADING_START});
    let response = handleResponse(await Api.fetchVehicleVoucherData(vehicle.id));
    dispatch({
        "type": FETCH_VEHICLE_VOUCHER_DATA,
        payload: response
    });
    dispatch({"type": LOADING_END});
};

export const openVehicleVoucher = () => async (dispatch, getState) => {
    let {vehicleRenewal} = getState();
    let {vehicle} = vehicleRenewal;
    dispatch({type: LOADING_START});
    Api.openVehicleVoucher(
        vehicle.id,
        data => {
            Utils.openDialog(data);
        },
        reportError
    ).finally(() => dispatch({type: LOADING_END}));
}

export const setVehicleNotiReasons = (showModal, reasons = []) => (dispatch) => {
    dispatch({
        "type": SET_VEHICLE_NOTI_REASONS,
        payload: { showModal, reasons }
    });
};

export function vehicleRenewal(state = defaultState, action) {

    let {payload} = action;
    switch (action.type) {
        case  FETCH_VEHICLE_RENEWAL_ELIGIBILITY:
            return {...state, ...payload};
        case  SET_VEHICLE_FOR_RENEW:
            return {...state, ...payload};
        case  SET_VEHICLE_RENEWAL_INSURANCE:
            return {...state, insuranceSelected: payload};
        case FETCH_VEHICLE_RENEWAL_CHARGES:
            return {...state, ...payload};
        case FETCH_VEHICLE_VOUCHER_DATA:
            return {...state, voucherData: payload};
        case SET_VEHICLE_NOTI_REASONS:
            return {...state, showModal: payload?.showModal, notiReasons: payload?.reasons};
        default:
            return state
    }
}

