import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const Button = (props) => {
    return (
        <button
            type="button"
            role="button"
            style={{
                height: "45px",
                borderRadius: "4px",
                minWidth: "3rem",
                minHeight: "2rem",
                ...props.style,
            }}
            aria-disabled={props.disabled}
            className={`btn btn-sm text ${props.className}`}
            disabled={props.disabled}
            aria-label={props.label}
            onClick={() =>
                !props.disabled && props.onClick ? props.onClick() : () => {}
            }
        >
            {props.children}
        </button>
    );
};
Button.propTypes = {
    disabled: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.any,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    disabled: true,
};

export default Button;
