import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

import {formatCurrencyLabel} from "../../../core/Utils";
import Text from "../../../components/ui/Text";
import {Colors} from "../../../Colors";

import congrats from "../images/icon_Badge_MultasVeh_Excelente.svg";
import congratsEn from "../../..//assets/images/icon_Bagde_NoMultasVehiculos2_EN.svg";

const FineBigNumber = (props) => {
    const [fineTotal, handleFineTotal] = useState(0);
    const {i18n} = useTranslation();
    const [lang, setLang] = useState(i18n.language);

    useEffect(() => {
        setLang(i18n.language);
        let {fines} = props;
        let filtered =
            fines && fines.filter((f) => f.type === "p" || f.type === "a");
        if (fines && filtered.length > 0) {
            let total = filtered.map((f) => f.amount).reduce((a, b) => a + b);
            handleFineTotal(total);
        }
    });

    return (
        <>
            {fineTotal === 0 && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        height: "inherit",
                        width: '100%'
                    }}
                    className="sub-header-container"
                >
                    <div
                        style={{lineHeight: 1}}
                        className="sub-header-chart-text"
                    >
                        <Text
                            style={{color: Colors.gray}}
                            i18nKey={"main:subHeader.fineBigNumber"}
                            className="text"
                        />
                    </div>
                    <div>
                        <img
                            src={lang === "en-US" ? congratsEn : congrats}
                            alt={"main:congratulationImgAlt"}
                            className="sub-header-icon"
                        />
                    </div>
                </div>
            )}

            {fineTotal > 0 && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                        alignItems: "center",
                        height: "inherit",
                        width: "100%",
                    }}
                    className="fine-big-number"
                >
                    <div style={{lineHeight: 1}}>
                        <Text
                            style={{color: Colors.gray}}
                            i18nKey="main:subHeader.fineBigNumber"
                        />
                    </div>
                    <div className="fine-big-number-text mt-4 mt-lg-0">
                        <span
                            className="font-weight-semi-bold"
                            style={{color: Colors.error}}
                        >
                            {formatCurrencyLabel(fineTotal)}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};
FineBigNumber.propTypes = {
    fines: PropTypes.array,
};

FineBigNumber.defaultProps = {
    fines: [],
};

export default FineBigNumber;
