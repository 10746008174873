import {LOADING_END, LOADING_START, reportError} from "../loading/loadingState.combine";
import * as Api from "../../core/APICaller";
import {handleResponse} from "../../core/APICaller";
import {setFinesForRenewal} from "../fines/actions";
import {licenseRenewalFlow} from "../app/app.combine";
import * as Utils from "../../core/Utils";

export const FETCH_RENEWAL_ELIGIBILITY = "FETCH_RENEWAL_ELIGIBILITY";
export const FETCH_RENEWAL_CHARGES = "FETCH_RENEWAL_CHARGES";
export const ANSWERED_QUESTIONS = "ANSWERED_QUESTIONS";
export const REAL_ID_VALUE = "REAL_ID_VALUE";
export const MISSING_DOCS = "MISSING_DOCS";
export const FETCH_VOUCHER_DATA = "FETCH_VOUCHER_DATA";

export const fetchRenewalEligibility = (onSuccess) => async (dispatch) => {
    dispatch(licenseRenewalFlow());
    dispatch({"type": LOADING_START});
    let response = handleResponse(await Api.licenseRenewalRequirements());
    dispatch({
        "type": FETCH_RENEWAL_ELIGIBILITY,
        payload: response
    });

    if (response && response.eligibleRequirements) {
        let doc = response.eligibleRequirements.filter(d => d.type === "DOCUMENTS")[0];
        if (doc && doc.failedDocuments) {
            dispatch({
                "type": MISSING_DOCS,
                payload: doc.failedDocuments
            });
        }
    }
    onSuccess(response);
    dispatch({"type": LOADING_END});
};

export const acceptAgreement = (key, onSuccess) => async (dispatch) => {
    dispatch({"type": LOADING_START});
    handleResponse(await Api.acceptAgreement(key), onSuccess);
    dispatch({"type": LOADING_END});

};

export const setRealIdValue = (value) => (dispatch) => {
    dispatch({
        "type": REAL_ID_VALUE,
        payload: value

    });

};

export const setAnswersToQuestions = (form, onSuccess) => (dispatch) => {
    dispatch({
        "type": ANSWERED_QUESTIONS,
        payload: form
    });
    //calculating charges
    dispatch(fetchRenewalCharges(onSuccess));

};

export const fetchRenewalCharges = (onSuccess) => async (dispatch, getState) => {
    let {renewal} = getState();
    dispatch({"type": LOADING_START});
    let response = handleResponse(await Api.licenseRenewalCharges(renewal.realId));
    dispatch(await setFinesForRenewal(response));
    dispatch({
        "type": FETCH_RENEWAL_CHARGES,
        payload: response
    });
    dispatch({"type": LOADING_END});
    if (onSuccess) onSuccess();

};

export const submitLicenseRenewal = (onSuccess, onError) => async (dispatch, getState) => {
    let {renewal, payment} = getState();
    let {renewalForm} =renewal;
    let {creditCard} = payment;
    let form = {};
    form.renewal = {
        realId: renewalForm.realId,
        donor: renewalForm.donor,
        bloodType: renewalForm.bloodType
    };
    let email = creditCard.email;
    delete creditCard.email;
    form.paymentInfo = {creditCard: creditCard, email: email};
    dispatch({"type": LOADING_START});
    handleResponse(await Api.renewLicense(form), onSuccess, onError);
    dispatch({"type": LOADING_END});

};

export const fetchLicenseVoucherData = (onSuccess) => async (dispatch) => {
    dispatch({"type": LOADING_START});
    let response = handleResponse(await Api.getLicenseRenewalVoucherData());
    dispatch({
        "type": FETCH_VOUCHER_DATA,
        payload: response
    });
    dispatch({"type": LOADING_END});
    if (onSuccess) onSuccess();

};

export const openLicenseVoucher = () => async (dispatch, getState) => {
    dispatch({type: LOADING_START});
    Api.openLicenseRenewalVoucher(
        data => {
            Utils.openDialog(data);
        },
        reportError
    ).finally(() => dispatch({type: LOADING_END}));
};

export const savePickupCesco = (cescoId, onSuccess) => async (dispatch) => {
    let form = {cescoId};
    dispatch({"type": LOADING_START});
    handleResponse(await Api.savePickup(form), () => {
        dispatch(fetchLicenseVoucherData(onSuccess));
    });
    dispatch({"type": LOADING_END});
};

/*licencia
{
    email: email,
        creditCard: {name: 'Test', number: '1211111111111111', cvc: '1111', expiry: '1111',payment:'50'},
    renewal:{
        realId:false,
            donor:true,
            blodType:"A+"
    }
}*/

/*
vehiculo
{
    email: email,
        creditCard: {name: 'Test', number: '1211111111111111', cvc: '1111', expiry: '1111',payment:'140'},
    renewal:{
        uprDonation:1
        inssurance:"UNIVERSAL",
    }
} */