import { api, handleResponse } from "../APICaller";

const URL = {
    vehicleCharges: (id) => `/acaa/vehicle/${id}/charges`,
    policyChoice: (vehicleId) => `acaa/vehicle/${vehicleId}/policy/choice`,
    currentPolicy: (vehicleId) => `/acaa/vehicle/${vehicleId}/policy/current`
};

export async function getVehicleCharges(id, onSuccess, onError) {
    return api
        .get(URL.vehicleCharges(id))
        .then((response) => handleResponse(response, onSuccess, onError));
}

export async function postPolicyChoice(id, payload, onSuccess, onError) {
    return api
        .post(URL.policyChoice(id), payload)
        .then((response) => handleResponse(response, onSuccess, onError));
}

export async function getCurrentPolicy(id, onSuccess, onError) {

    let allow404 = true

    return api
        .get(URL.currentPolicy(id))
        .then((response) => handleResponse(response, onSuccess, onError, allow404));
}
