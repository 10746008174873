import {api, handleResponse} from "../APICaller";

const reportAsb64Resource = "reports";
const reportAsBytesResource = "reportbytes";
const context = process.env.REACT_APP_API_BASE;

export function fetchClientIdentity(onSuccess, onError) {
    return fetchClientIdentityAsUrl(onSuccess, onError)
}

export function fetchLicenseFinesReport(onSuccess, onError) {
    return fetchLicenseFinesReportAsUrl(onSuccess, onError)
}

export function fetchVehicleFinesReports(vehicleId, onSuccess, onError) {
    return fetchVehicleFinesReportsAsUrl(vehicleId, onSuccess, onError)
}

export function fetchVehicleLicenseReport(vehicleId, onSuccess, onError) {
    return fetchVehicleLicenseReportAsUrl(vehicleId, onSuccess, onError)
}

export function fetchVehicleCertificationReport(vehicleId, onSuccess, onError) {
    return fetchVehicleCertificationReportAsUrl(vehicleId, onSuccess, onError)
}

export function fetchAcaaReport(vehicleId, onSuccess, onError) {
    return fetchAcaaReportAsUrl(vehicleId, onSuccess, onError)
}

export function fetchAcaaReportAsB64(vehicleId, onSuccess, onError) {
    return api.get(`/acaa/vehicle/${vehicleId}/report/base64`, null, {
        headers: {
            Accept: "application/pdf",
        }
    })
        .then((response) => handleResponse(response, onSuccess, onError));
}

export function fetchFinesReportAsB64(vehicleId, onSuccess, onError) {
    return api.get(`/reports/fines/vehicle/${vehicleId}`)
        .then((response) => handleResponse(response, onSuccess, onError));
}

//bytes
function fetchClientIdentityAsUrl(onSuccess, onError) {
    onSuccess(`${context}/${reportAsBytesResource}/clientIdentity`);
    return Promise.resolve();
}

function fetchLicenseFinesReportAsUrl(onSuccess, onError) {
    onSuccess(`${context}/${reportAsBytesResource}/fines/license`);
    return Promise.resolve();
}

function fetchVehicleFinesReportsAsUrl(vehicleId, onSuccess, onError) {
    onSuccess(`${context}/${reportAsBytesResource}/fines/vehicle/${vehicleId}`);
    return Promise.resolve();
}

function fetchVehicleLicenseReportAsUrl(vehicleId, onSuccess, onError) {
    onSuccess(`${context}/${reportAsBytesResource}/notification/vehicle/${vehicleId}`);
    return Promise.resolve();
}

function fetchVehicleCertificationReportAsUrl(vehicleId, onSuccess, onError) {
    onSuccess(`${context}/${reportAsBytesResource}/vehicleByOwner/vehicle/${vehicleId}`);
    return Promise.resolve();
}

function fetchAcaaReportAsUrl(vehicleId, onSuccess, onError) {
    onSuccess(`${context}/acaa/vehicle/${vehicleId}/report/${reportAsBytesResource}`);
    return Promise.resolve();
}

//base64 should be DEPRECATED later
function fetchClientIdentityAsB64(onSuccess, onError) {
    return api.get(`/${reportAsb64Resource}/clientIdentity`)
        .then((response) => handleResponse(response, onSuccess, onError));
}

function fetchLicenseFinesReportAsB64(onSuccess, onError) {
    return api.get(`/${reportAsb64Resource}/fines/license`)
        .then((response) => handleResponse(response, onSuccess, onError));
}

function fetchVehicleFinesReportsAsB64(vehicleId, onSuccess, onError) {
    return api
        .get(`/${reportAsb64Resource}/fines/vehicle/${vehicleId}`)
        .then((response) => handleResponse(response, onSuccess, onError));
}

function fetchVehicleLicenseReportAsB64(vehicleId, onSuccess, onError) {
    return api
        .get(`/${reportAsb64Resource}/notification/vehicle/${vehicleId}`)
        .then((response) => handleResponse(response, onSuccess, onError));
}

function fetchVehicleCertificationReportAsB64(vehicleId, onSuccess, onError) {
    return api
        .get(`/${reportAsb64Resource}/vehicleByOwner/vehicle/${vehicleId}`)
        .then((response) => handleResponse(response, onSuccess, onError));
}