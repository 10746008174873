import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../../components/ui/Button';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../Colors';
import icon from '../../../assets/images/acaa/ACAA_Licencia.svg'

const InstructionsModal = ({ toggle }) => {

    const { t } = useTranslation()

    return (
        <Modal
            isOpen={true}
            size="md"
            toggle={() => toggle && toggle()}
            role="dialog"
            aria-modal={true}
            autoFocus={true}
        >
            <ModalBody className="p-0">
                <div className="p-3 py-4 border-bottom">
                    <strong className='text-md text-uppercase'>{t("insuranceTotal:instructions.title")}</strong>
                </div>
                <div
                    className='border-bottom'
                    style={{ background: Colors.bgGray, display: 'flex', justifyContent: 'center' }}
                >
                    <img
                        src={icon}
                        aria-hidden='true'
                        alt=""
                        style={{ width: '50%' }}
                        className='p-3'
                    />
                </div>

                <div className='p-3'>
                    <div className='text-center mb-3'>
                        <strong className='text-md'>{t("insuranceTotal:instructions.subTitle")}</strong>
                    </div>
                    <div>
                        <span>{t("insuranceTotal:instructions.text.0")}</span>
                        <strong>{t("insuranceTotal:instructions.text.1")}</strong>
                        <span>{t("insuranceTotal:instructions.text.2")}</span>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
                <Button
                    className="btn btn-primary w-40"
                    onClick={() => {
                        toggle()
                    }}
                    disabled={false}
                >
                    {t("insuranceTotal:continue")}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

InstructionsModal.propTypes = {
    toggle: PropTypes.func.isRequired,
};

export default InstructionsModal