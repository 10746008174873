import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Colors } from '../../../Colors'
import ComponentTitle from '../../../components/ui/ComponentTitle'
import LeadingDots from '../../../components/ui/LeadingDots'
import PageTitle from "../../../components/ui/PageTitle"
import Text from "../../../components/ui/Text"
import TotalSummary from '../../../components/widgets/TotalSummary'
import WizardFooter from '../../../components/widgets/WizardFooter'
import SubHeader from "../../../layout/SubHeader"
import Button from '../../../components/ui/Button'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { renderInsuranceStars } from '../../../utils/renderInsuranceStars'
import { getVehicleCharges, postPolicyChoice } from '../../../core/api-resources/insurance'
import { openAcaaVehicle, openVehicleLicenseReport } from '../../../redux/loading/loadingState.combine'
import VehicleInfoRow from '../components/VehicleInfoRow'
import { currencyFormatter } from '../../../utils/currencyFormatter'
import InstructionsModal from './InstructionsModal'

const InsuranceTotal = () => {

    const [data, setData] = useState(null)
    const [selected, setSelected] = useState(null)

    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const selectedInsurance = useSelector(s => s?.insurance?.selectedInsurance)
    const selectedVehicleId = useSelector(s => s?.insurance?.selectedVehicle?.id)

    const selectedVehicle = useSelector(s => s?.insurance?.selectedVehicle)

    const policies = useSelector(s => s?.features?.acaa?.policy)

    const detail = selected?.detail

    const changeInsuranceBtnVisibility = true

    // If no vehicle id || selected insurance redirect home
    useEffect(() => {
        if (!selectedVehicleId || !selectedInsurance) history.push('/landing')
    }, [selectedVehicleId])

    useEffect(() => {
        handleTotalFetch()
    }, [])

    useEffect(() => {
        getSelectedPolicyFromState()
    }, [])

    // Submit policy choice
    useEffect(() => {
        handleSubmitPolicy()
    }, [selected])

    const handleSubmitPolicy = async () => {
        let payload = {
            policyId: selected?.id
        }

        if (selected?.id) {
            await postPolicyChoice(selectedVehicleId, payload)
            await handleTotalFetch()
        }

    }

    const handleTotalFetch = async () => {
        const res = await getVehicleCharges(selectedVehicleId)

        setData(res)
    }

    const handlePrint = async () => {
        dispatch(openAcaaVehicle(selectedVehicleId));
    }

    const getSelectedPolicyFromState = () => {
        // Checks for detail inside selectedInsurance
        // If not found filter firebase policies by insurance id

        if (selectedInsurance?.detail) {
            setSelected(selectedInsurance)
        } else {
            // If no detail on selected insurance from redux filter policies 
            const selected = policies && policies.filter(p => p?.id === selectedInsurance?.policyId)
            setSelected(selected[0])
        }
    }

    return (
        <>

            <div>
                <SubHeader>
                    <div className="container">
                        <div className="row">
                            <div className="col p-0">
                                <PageTitle
                                    style={{ fontWeight: "bold" }}
                                    i18nKey={"insuranceTotal:title"}
                                />
                                <div className="d-none d-lg-flex">
                                    <hr />
                                </div>
                                <Text
                                    i18nKey="insuranceTotal:subTitle"
                                    className="text"
                                    style={{ color: Colors.gray }}
                                />
                            </div>
                        </div>
                    </div>
                </SubHeader>

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <VehicleInfoRow vehicle={selectedVehicle} />
                            <hr />
                        </div>
                    </div>

                    <div className="row d-flex d-lg-none">
                        <InsuranceComponent
                            changeInsuranceBtnVisibility={changeInsuranceBtnVisibility}
                            detail={detail}
                            selectedInsurance={selected}
                        />

                        <TotalComponent data={data} />
                    </div>

                    <div className="row d-none d-lg-flex">
                        <InsuranceComponent
                            changeInsuranceBtnVisibility={changeInsuranceBtnVisibility}
                            detail={detail}
                            selectedInsurance={selected}
                        />

                        <TotalComponent data={data} />
                    </div>
                </div>

                <div className="container">
                    <WizardFooter
                        handleNext={() => handlePrint()}
                        nextText={t('insuranceTotal:print')}
                        disabled={false}
                    />
                </div>
            </div>
        </>
    )
}

const InsuranceComponent = (props) => {

    const {
        changeInsuranceBtnVisibility,
        detail,
        selectedInsurance
    } = props

    const { t } = useTranslation()
    const history = useHistory()

    const lang = useTranslation()?.i18n?.language

    return (
        <div className="col-12 col-lg-7 mb-3">
            <ComponentTitle i18nKey="insuranceTotal:sectionTitles.1" />
            <div
                style={{
                    backgroundColor: '#fff',
                    borderBottom: `6px solid ${Colors.lightGray}`
                }}
            >
                <div className="container">
                    <div className='row'>
                        <div className="col-12 col-lg-4 pl-lg-0">
                            <div className={`text-center p-5`}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: Colors.lightGray,
                                    height: '100%',
                                    flexDirection: 'column'
                                }}>

                                <div className='mb-3'>
                                    <span className="d-flex flex-column">
                                        <div className='d-flex justify-content-center' style={{ color: Colors.primary }}>
                                            <span className='text-lg text-bold mt-1'>$</span>
                                            <span className='text-xxl text-bold'>{selectedInsurance?.yearCost} </span>
                                        </div>
                                        <span className='text-xs text-uppercase' style={{ color: Colors.gray }}>Anual</span>
                                        <span className='text text-uppercase text-bold'>{selectedInsurance?.key}</span>
                                        <div className='py-1'>

                                            {renderInsuranceStars(selectedInsurance?.rating)}
                                        </div>
                                    </span>
                                </div>

                                {
                                    changeInsuranceBtnVisibility &&
                                    <div>
                                        <button
                                            onClick={() => history.push('/insurance/selection')}
                                            className='btn btn-primary btn-sm py-2 px-3'>
                                            {t("insuranceTotal:change")}
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="col-12 col-lg-8 d-none d-lg-flex">
                            <div className='mx-3 mr-lg-3 mt-3 w-100'>
                                {
                                    detail && detail?.length > 0 &&
                                    detail.map((d, i) => (
                                        <div key={i} style={{ fontSize: 16 }}>
                                            {
                                                d?.values && d?.values?.length > 0 ?
                                                    <span style={{ color: Colors.gray }}>{d?.locales?.[lang === "es-US" ? "es" : "en"]}:</span>
                                                    :
                                                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                                        <span style={{ color: Colors.gray }}>{d?.locales?.[lang === "es-US" ? "es" : "en"]}</span>
                                                        <LeadingDots />
                                                        <span className='text-bold'>{currencyFormatter.format(d?.amount)}</span>
                                                    </div>
                                            }
                                            <ul className='p-0'>
                                                {
                                                    d?.values && d?.values?.length > 0 &&
                                                    d?.values?.map((d, i) => (
                                                        <li key={i} className="ml-3">
                                                            <div style={{ display: 'flex', alignItems: 'baseline', lineHeight: 1.5 }}>
                                                                <span style={{ color: Colors.gray }}>{d?.locales?.[lang === "es-US" ? "es" : "en"]}</span>
                                                                <LeadingDots />
                                                                <span className='text-bold'>{currencyFormatter.format(d?.amount)}</span>
                                                            </div>
                                                        </li>

                                                    ))
                                                }
                                            </ul>

                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

const TotalComponent = (props) => {

    const { t } = useTranslation()

    const { data } = props

    return (
        <div className="col-12 col-lg-5 mb-3">
            <ComponentTitle i18nKey="insuranceTotal:sectionTitles.0" />
            <div
                className='container'
                style={{
                    backgroundColor: '#fff',
                    borderBottom: `6px solid ${Colors.lightGray}`
                }}
            >
                <div className="row p-3">
                    {
                        data && data?.charges && data?.charges?.length > 0 &&
                        data.charges.map((c, index) => (
                            <div className='col-12 p-0'>
                                <div className="row">
                                    <div className='col-7'
                                        style={{
                                            color: Colors.gray,
                                            textAlign: 'end'
                                        }}
                                    >
                                        <span className='uppercase' key={index}>{c?.title}</span>
                                        {c?.caption &&
                                            <div>
                                                <span className='text-xs'>{c?.caption}</span>
                                            </div>
                                        }

                                    </div>
                                    <div className='col'
                                        style={{

                                            textAlign: 'end',
                                            color: Colors.gray, fontWeight: 'bold'
                                        }}
                                    >
                                        <span
                                            key={index}
                                        >{currencyFormatter.format(c?.amount)}</span>
                                    </div>

                                </div>
                            </div>
                        ))
                    }

                </div>

                <hr className='m-0' />

                <div className='d-flex py-3'>
                    <div className="col-7">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <span className='text-md text-bold text-uppercase'>{t("insuranceTotal:totalImport")}</span>
                        </div>
                    </div>
                    <div className="col">
                        <div
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <span className='text-md text-bold text-uppercase'>{data?.total ? currencyFormatter.format(data?.total) : '0.00'}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

const chargesStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    textAlign: 'end'
}

export default InsuranceTotal