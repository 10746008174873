import React from "react";
import IdentityBadge from "./IdentityBadge";
import * as Utils from "../../core/Utils";
import _ from "lodash";
import { FontWeight } from "../ui/FontSize";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const IdentityRow = (props) => {
    const {
        under21,
        realId,
        identityType,
        firstName,
        lastName,
        licenseNumber,
        expirationDate,
        category,
    } = props.user;

    const user = useSelector((state) => state?.login?.user);
    const card = useSelector((state) => state?.card);
    const { t } = useTranslation();

    return (
        <div
            role="region"
            style={{
                display: "flex",
                paddingLeft: 20,
                paddingTop: 18,
                alignItems: "center",
                color: "#fff",
                fontSize: "22px",
            }}
            aria-label={`${firstName} ${lastName}`}
        >
            <div style={{ marginRight: 30 }}>
                <IdentityBadge
                    under21={under21}
                    realId={realId}
                    identityType={identityType}
                    veteran={card?.personalInfo?.veteran}
                />
            </div>
            <div
                aria-hidden="true"
                style={{ marginRight: 50, fontWeight: FontWeight.bold }}
            >
                {firstName}
                <br />
                {lastName}
            </div>
            <div
                role="text"
                style={{ marginRight: 100 }}
                aria-label={`${
                    user?.license?.type === "ID"
                        ? t("main:identityHeader.arias.idNo")
                        : t("main:identityHeader.arias.license")
                } ${licenseNumber}`}
            >
                <span>
                    {user?.license?.type === "ID"
                        ? t("main:identityHeader.idNo")
                        : t("main:identityHeader.license")}
                </span>{" "}
                <br />
                <span style={{ fontWeight: "bold" }}>{licenseNumber || "N/A"}</span>
            </div>
            <div
                role="text"
                style={{ marginRight: 100 }}
                className={"d-none d-md-block d-sm-block"}
                aria-label={`${t(
                    "main:identityHeader.arias.expiryDate"
                )} ${Utils.format(expirationDate, "DD.MMMM.YYYY")}`}
            >
                {t("main:identityHeader.expiryDate")} <br />
                <span style={{ fontWeight: "bold" }}>
                    {Utils.format(expirationDate, "DD.MMM.YYYY") || "N/A"}
                </span>
            </div>
            <div
                role="text"
                className={"d-none d-md-block d-sm-block"}
                aria-label={`${t(
                    "main:identityHeader.arias.category"
                )} ${category}`}
            >
                {t("main:identityHeader.category")}{" "}
                <span style={{ fontWeight: "bold" }}>
                    {_.defaultTo(category, "N/A")}
                </span>
                <br />
                {t("main:identityHeader.readId")}&nbsp;
                <span style={{ fontWeight: "bold" }}>
                    {realId
                        ? t("main:identityHeader.yes")
                        : t("main:identityHeader.no")}
                </span>
            </div>
        </div>
    );
};

export default IdentityRow;
