import { getConfig } from "../../core/APICaller";
// import store from "../../store";

export const FETCH_CONFIG = "FETCH_CONFIG";
export const SET_RESOLUTION = "SET_RESOLUTION";
export const FETCH_FEATURES = "FETCH_FEATURES";

export const RESET_STATE = "RESET_STATE";
export const CHANGE_FLOW = "CHANGE_FLOW";
export const SET_RECAPTCHA = "SET_RECAPTCHA";
export const MAINTENANCE_MODAL = "MAINTENANCE_MODAL";

//

export const fetchConfig = () => async (dispatch) => {
    let config = await getConfig(null, () => {
    });
    dispatch({
        type: FETCH_CONFIG,
        payload: config
    })
};

export const setConfig = (data) => async (dispatch) => {
    dispatch({
        type: FETCH_CONFIG,
        payload: data
    })
};

export const setFeatures = (data) => async (dispatch) => {
    dispatch({
        type: FETCH_FEATURES,
        payload: data
    })
};

export const resetState = () => {
    return {
        type: RESET_STATE
    }
};

export const setRecaptcha = (token) => (dispatch) => {
    dispatch({
        type: SET_RECAPTCHA,
        payload: { token: token }
    })
};

export const licenseRenewalFlow = () => {
    return {
        type: CHANGE_FLOW,
        payload: "RENEWAL"
    }
};


export const vehicleRenewalFlow = () => {
    return {
        type: CHANGE_FLOW,
        payload: "RENEWAL_VEHICLE"
    }
};

export const autoexpresoFlow = () => {
    return {
        type: CHANGE_FLOW,
        payload: "AUTOEXPRESO"
    }
};

export const autoexpresoPayAllFlow = () => {
    return {
        type: CHANGE_FLOW,
        payload: "AUTOEXPRESO_PAY_ALL"
    }
};

export const resetFlow = () => {
    return {
        type: CHANGE_FLOW,
        payload: null
    }
};

export const setResolution = (width, height) => {
    return {
        type: SET_RESOLUTION,
        payload: { width, height }
    }
};


export function config(state = null, action) {
    if (action.type === FETCH_CONFIG) {
        return { ...action.payload };
    } else {
        return state;
    }
}

export const setMaintenanceModal = (payload) => (dispatch) => {
    dispatch({
        type: MAINTENANCE_MODAL,
        payload: payload
    })
};

export function features(state = null, action) {
    if (action.type === FETCH_FEATURES) {
        return { ...action.payload };
    } else {
        return state;
    }
}


export function flow(state = null, action) {
    if (action.type === CHANGE_FLOW) {
        return action.payload;
    } else {
        return state;
    }
}

const defaultState = {
    height: null,
    width: null,
    showMaintenanceModal: false 
}

export function general(state = defaultState, action) {
    switch (action.type) {
        case SET_RESOLUTION:
            return { ...state, ...action.payload };
        case MAINTENANCE_MODAL:
            return { ...state, showMaintenanceModal: action.payload };
        default:
            return state;
    }
}

export function recaptcha(state = { token: null }, action) {
    if (action.type === SET_RECAPTCHA) {
        return { ...action.payload };
    } else {
        return state;
    }
}
