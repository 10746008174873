import React from "react";

import PageTitle from "../../components/ui/PageTitle";
import ComponentTitle from "../../components/ui/ComponentTitle";
import { Colors } from "../../Colors";

import Text from "../../components/ui/Text";
import LicenseCertifications from "../../components/widgets/certifications/LicenseCertifications";
import FormCertifications from "../../components/widgets/FormCertifications";
import SelectableVehicleCertifications from "../../components/widgets/SelectableVehicleCertifications";

const Certifications = () => {

    return (
        <div className="container" style={{ padding: 0 }}>
            <div className="col-12">
                <div className="p-mob-only">
                    <PageTitle
                        i18nKey={"certifications:title"}
                        style={{ marginBottom: "40px" }}
                    />
                </div>
                <SelectableVehicleCertifications />

                <ComponentTitle
                    i18nKey={"certifications:licenseTitle"}
                    style={{ marginTop: "20px" }}
                />
                <LicenseCertifications />
                <div
                    style={{
                        display: "flex",
                        direction: "row",
                        justifyContent: "space-between",
                        marginTop: "20px",
                    }}
                >
                    <ComponentTitle i18nKey={"certifications:formsTitle"} />
                    <a
                        href="https://dtop.pr.gov/formularios/"
                        style={{
                            color: Colors.primary,
                            fontSize: "16px",
                            marginRight: "15px",
                        }}
                        target="_blank"
                    >
                        <Text
                            i18nKey={"certifications:otherForms"}
                            className="text"
                        />
                    </a>
                </div>
                <FormCertifications />
            </div>
        </div>
    );
};

export default Certifications;
