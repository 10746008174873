import React, {FC, useEffect, useState} from 'react';
import * as serviceWorker from '../serviceWorker';

//TODO: testing reload
const ServiceWorkerWrapper = (props) => {
    const [showReload, setShowReload] = useState(false);
    const [waitingWorker, setWaitingWorker] = useState(null);

    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    };

    useEffect(() => {
        // serviceWorker.register({onUpdate: onSWUpdate});
        serviceWorker.unregister();
    }, []);

    const reloadPage = () => {
        if(waitingWorker) {
            setShowReload(false);
            waitingWorker.postMessage({type: 'SKIP_WAITING'});
            window.location.reload(true);
        }
    };

    if (!showReload)
        return null;
    else {
        reloadPage();
        return null
    }
}

export default ServiceWorkerWrapper;