import React, {useEffect} from "react";

import Text from "../../../components/ui/Text";
import Button from "../../../components/ui/Button";
import PayFinesWizard from "../components/PayFinesWizard";
import {FontWeight} from "../../../components/ui/FontSize";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import RenewalWizard from "../../Renewal/components/RenewalWizard";
import checked from "./success-lottie";
import Lottie from "react-lottie";
import SubHeader from "../../../layout/SubHeader";

import {useTranslation} from "react-i18next"
import {resetState} from "../../../redux/app/app.combine";
import ClearBackButton from "../../../utils/clearBackButton";

const lottie_checked_opts = {
    loop: false,
    autoplay: true,
    animationData: checked,
};

const PaymentComplete = (props) => {
    const flow = useSelector((state) => state?.flow);
    const history = useHistory();
    const {t} = useTranslation()
    const dispatch = useDispatch();

    useEffect(() => {

        ClearBackButton()
    }, [])

    const onContinue = () => {
        //TODO: check this logic
        if (flow === "RENEWAL") history.push("/renewal/location");
        else if (flow === "RENEWAL_VEHICLE") {
            history.push("/renewal/voucher");
        } else {
            dispatch(resetState())
            history.push("/landing")
        }
    };

    return (
        <>
            {/*<PayFinesWizard step={4} /> */}
            <SubHeader noMargin>
                {flow === "RENEWAL" ? (
                    <RenewalWizard step={2}/>
                ) : (
                    <PayFinesWizard step={4}/>
                )}
            </SubHeader>

            <div className={"container"}>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {/*<img
                        src={iconCompleted}
                        alt={""}
                        style={{ margin: "1rem" }}
                    />*/}
                    <Lottie
                        options={lottie_checked_opts}
                        height={250}
                        width={300}
                    />
                    <Text
                        style={{
                            fontSize: "22px",
                            fontWeight: FontWeight.medium,
                            marginBottom: "25px",
                            marginTop: "10px",
                            letterSpacing: "0.03rem",
                            textAlign: "center",
                            textTransform: "uppercase",
                        }}
                        className="text-md"
                        i18nKey="paymentComplete:bodyText"
                    />
                    {/*<Text style={{ color: Colors.gray }}>
                        Regresar a nuestra pagina
                    </Text>
                    <br />*/}
                    <Button
                        onClick={onContinue}
                        style={{width: "250px", marginBottom: "1rem"}}
                        disabled={false}
                        className="btn-primary"
                    >
                        {flow === "RENEWAL"
                            ? t("paymentComplete:continue")
                            : t("paymentComplete:finish")}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default PaymentComplete;
