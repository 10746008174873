import React from 'react'

const LeadingDots = () => {
    return (
        <div style={{
            borderBottom: '1px dotted #aaa',
            flexGrow: 1,
            margin: '0 5px'
        }} />
    )
}

export default LeadingDots