import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {LOADING_END, LOADING_START} from '../../redux/loading/loadingState.combine'
import { isSafari } from 'react-device-detect'

const CoursesForm = () => {
    const [name, setName] = useState(null)
    const [license, setLicense] = useState(null)
    const [provider, setProvider] = useState(null)

    const user = useSelector(state => state?.login?.user);

    const history = useHistory()
    const dispatch = useDispatch()

    const {i18n} = useTranslation();

    useEffect(() => {

        dispatch({type: LOADING_START});

        if (user) {

            (async () => {
                await fillUserData();
                await handleSubmit()
            })();
        } else {

            dispatch({type: LOADING_END});
            history.push('/landing')
        }
    }, [])


    const fillUserData = async () => {
        await setLicense(user.license.id)
        await setName(user.principal.name)
        await setProvider('portal-cesco-digital')
    }

    const handleSubmit = async () => {

        let form = document.getElementById('courses-form')
        form.submit()
        setTimeout(()=>{
            dispatch({type: LOADING_END});
        },500)
    }

    return (
        <>
            {/* Hidden form */}
            <div className="container">
                <form
                    id="courses-form"
                    name="courses-form"
                    action={process.env.REACT_APP_COURSES_HIDDEN_FORM_LINK}
                    method="post"
                    // target={isSafari ? null : "_blank"}
                >
                    <input
                        type="hidden"
                        name="license"
                        value={license}
                    />
                    <input
                        type="hidden"
                        name="name"
                        value={name}
                    />
                    <input
                        type="hidden"
                        name="provider"
                        value={provider}
                    />
                    <input
                        type="hidden"
                        name="language"
                        value={i18n.language == "en-US" ? 'en' : 'es'}
                    />
                </form>
            </div>
        </>
    )
}

export default CoursesForm;