import React, { useEffect } from "react";

import { FontWeight } from "../components/ui/FontSize";
import Text from "../components/ui/Text";
import ImageBlob from "../components/widgets/ImageBlob";
import * as Utils from "../core/Utils";
import { changeMenuState } from "../redux/menu/menu.combine";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/login/login.combine";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import MenuArt from "../assets/images/menu-art.svg";
import { Colors } from "../Colors";
import './NavPage.css'

const NavPage = (props) => {
    const menu = useSelector((state) => state.menu);
    const user = useSelector((state) => state?.login?.user);
    const card = useSelector((state) => state?.card);
    const dispatch = useDispatch();
    const history = useHistory();
    let { pathname } = history.location;
    let needIdentity = !(
        pathname.endsWith("error") || pathname.endsWith("maintenance")
    );
    const name = !needIdentity ? undefined : Utils.fromEntity(user);

    const selectOption = (isLogout) => {
        if (isLogout) dispatch(logout());
        dispatch(changeMenuState(!menu.open));
    };

    const { t } = useTranslation();

    const menuItem = (key, path, logout = false) => (
        <li className="mb-3">
            <Link
                role={"menuitem"}
                className="text-lg"
                onClick={() => selectOption(logout)}
                to={path}
            >
                {t(key)}
            </Link>
        </li>
    );

    const renderProfileButton = (userType) => {
        let vehicleId = user?.vehicles[0]?.id;

        switch (userType) {
            case "LICENSE":
            case "LEY97":
            case "ID":
                return menuItem(
                    user?.license?.type === "ID"
                        ? "layout:navPage.myId"
                        : "layout:navPage.myLicense",
                    "/profile"
                );
            default:
                return (
                    vehicleId &&
                    menuItem(
                        "layout:navPage.profile",
                        `/vehicle-details/${vehicleId}`
                    )
                );
        }
    };

    const toggleNav = () => {

        dispatch(changeMenuState(!menu.open));
    }

    return (
        <div
        id="mainNav"
            className="pt-5  animate"
            style={{ ...menuStyle(menu), overflowY: 'scroll' }}
        >
            <div className="container">
                <div style={menuHeader} className="align-items-start align-items-lg-center">
                    <div style={userDataStyle}>
                        {
                            <ImageBlob
                                aria-hidden={true}
                                src={user && user.image}
                                sex={card?.personalInfo?.sex}
                                height={100}
                                width={100}
                                style={{
                                    marginRight: "1rem",
                                    boxShadow:
                                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                    borderRadius: "4px",
                                }}
                            />

                        }

                        <Text
                            className="text-white text-md"
                            style={{
                                fontWeight: FontWeight.bold,
                                letterSpacing: "1.2px",
                            }}
                        >
                            <span aria-hidden={true}>
                                {name?.firstName}
                                <br /> {name?.lastName}
                            </span>
                        </Text>
                    </div>


                    <button className="btn p-0 m-0" onClick={() => toggleNav()}>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white" stroke-width="2" height="40">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>

                </div>

                <div style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.2)" }} className="my-4" />


                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <div>
                        <ul style={{ listStyle: 'none', padding: 0 }}>
                            {menuItem("layout:navPage.home", "/landing")}

                            {renderProfileButton(user?.license?.type)}

                            {menuItem(
                                "layout:navPage.certifications",
                                "/certifications"
                            )}
                            {menuItem(
                                "layout:navPage.paymentHistory",
                                "/payment-history"
                            )}
                            {menuItem("layout:navPage.other", "/other-transactions")}
                            {menuItem("layout:navPage.messages", "/messages")}
                            {menuItem("layout:navPage.courses", "/courses")}
                            {menuItem("layout:navPage.locations", "/locations")}
                            {menuItem("layout:navPage.settings", "/settings")}

                            <li
                                style={{ marginTop: "2rem" }}
                            >
                                {menuItem("layout:navPage.logout", "/login", true)}
                            </li>
                        </ul>

                    </div>

                    <div>
                        <img
                            aria-hidden={true}
                            className="d-none d-sm-block"
                            src={MenuArt}
                            style={artStyle}
                            alt={""}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};

const menuHeader = {
    display: 'flex',
    justifyContent: 'space-between',
}

const menuStyle = (menu) => ({
    position: 'fixed',
    zIndex: 10000,
    width: '100%',
    height: '100vh',
    display: menu?.open ? 'flex' : 'none',
    backgroundColor: Colors.primary,
    alignItems: 'flex-start',
    justifyContent: 'center'
})

const artStyle = {
    width: "80%",
    marginTop: "4rem",
};

const userDataStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
};


export default NavPage;
