export const FontSize = {
  sm: '0.7rem',
  md: '1.2rem',
  lg: '1.4rem',
  xl: '2rem'
}

export const FontWeight = {
    extraLight: '200',
    light: '300',
    regular: '400',
    medium: '500',
    bold: '700',
}