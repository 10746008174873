import React from "react";

import Text from "../../components/ui/Text";
import { Colors } from "../../Colors";
import Card from "../../components/ui/Card";
import Loadable from "../../components/ui/Loadable";

import { useTranslation } from "react-i18next";

const VehicleInformation = (props) => {
    let { tag } = props?.vehicle;

    const { t } = useTranslation();

    return (
        <Card>
            <div style={{ display: "flex" }}>
                <div style={{ marginRight: "30px" }}>
                    <div
                        style={vehicleInfoCol}
                        role="text"
                        aria-label={`${t("vehicle:vehicleInfo.vin")}: ${
                            props.vehicle?.vinNumber
                        }`}
                    >
                        <Text
                            style={smallText}
                            i18nKey="vehicle:vehicleInfo.vin"
                        />
                        <Loadable loading={props.loading}>
                            <Text className="text">
                                {props.vehicle?.vinNumber}
                            </Text>
                        </Loadable>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}
                        role="text"
                        aria-label={`${t("vehicle:aria.registrationNo")}: ${
                            props.vehicle?.registrationNumber
                        }`}
                    >
                        <Text
                            style={smallText}
                            i18nKey="vehicle:vehicleInfo.registrationNo"
                        />
                        <Loadable loading={props.loading}>
                            <Text className="text">
                                {props.vehicle?.registrationNumber}
                            </Text>
                        </Loadable>
                    </div>
                </div>
                <div>
                    <div style={vehicleInfoCol}
                        role="text"
                        aria-label={`${t("vehicle:aria.tagNo")}: ${
                            tag?.number
                        }`}
                    >
                        <Text
                            style={smallText}
                            i18nKey="vehicle:vehicleInfo.tagNo"
                        />
                        <Loadable loading={props.loading}>
                            <Text className="text">{tag?.number}</Text>
                        </Loadable>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}
                        role="text"
                        aria-label={`${t("vehicle:aria.licPlate")}: ${
                            props.vehicle?.plate
                        }`}
                    >
                        <Text
                            style={smallText}
                            i18nKey="vehicle:vehicleInfo.licPlate"
                        />
                        <Loadable loading={props.loading}>
                            <Text className="text">{props.vehicle?.plate}</Text>
                        </Loadable>
                    </div>
                </div>
            </div>
        </Card>
    );
};

const vehicleInfoCol = {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
};

const smallText = {
    color: Colors.gray,
    fontSize: "13px",
    textTransform: "uppercase",
};

export default VehicleInformation;
