import {combineReducers} from "redux";
import {login} from "./login/login.combine";
import {menu} from "./menu/menu.combine";
import {config, flow, recaptcha, general, features} from "./app/app.combine";
import {loadingState} from "./loading/loadingState.combine";
import {payment} from "./payment/payment.combine";
import {reducer as toastrReducer} from 'react-redux-toastr'
import {fines} from "./fines/reducer";
import {card} from "./entity/reducer";
import {renewal} from "./renewal/reducer";
import {vehicleRenewal} from "./vehicleRenewal/vehicleRenewal.combine";
import {profile} from "./profile/profile.combine";
import {insurance} from "./insurance/insurance.combine";
import {electronicTag} from "./electronicTag/electronicTag.combine";

export default combineReducers({
    login,
    menu,
    config,
    recaptcha,
    loadingState,
    payment,
    fines,
    card,
    toastr: toastrReducer,
    flow,
    renewal,
    vehicleRenewal,
    general,
    profile,
    features,
    insurance,
    electronicTag
});

