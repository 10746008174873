import React, {useEffect, useTransition} from "react";

import ArtOnlyPage from "../../components/widgets/ArtOnlyPage";

import {useTranslation, withTranslation} from "react-i18next";
import OtherTransactionsBgArt from "../../assets/images/OtrasTransacciones_BG2.jpg"
import OtherTransactionsArt from "../../assets/images/Art_OtrasTransacciones.svg"
import OtherTransactionsArtEN from "../../assets/images/Art_OtrasTransacciones_EN.svg"
import Text from "../../components/ui/Text";
import AppStoresBadges from "../../components/ui/AppStoresBadges";

const OtherTransactions = () => {

    const {i18n, t} = useTranslation();

    console.log(i18n)

    return (
        <div style={{
            backgroundImage:  `url(${OtherTransactionsBgArt})`,
            backgroundRepeat: "none",
            display: "flex",
            justifyContent: "center",
        }}>
            <div
                className={"container"}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "42em"
                }}
            >
                <Text style={{
                    textAlign: "center",
                    fontSize: "1.5em",
                    paddingBottom: "0.7em",
                    paddingTop: "0.7em"}}
                >
                    {t("otherTransactions:header")}
                </Text>
                <ArtOnlyPage
                    showAppStoreInfo={false}
                    artSrc={i18n.language === "es-US" ? OtherTransactionsArt : OtherTransactionsArtEN}
                    alt={t("otherTransactions:imageAlt")}
                    text={t("otherTransactions:caption")}
                />
                <AppStoresBadges />
            </div>
        </div>
    );
};

export default withTranslation()(OtherTransactions) ;
