import {api, handleResponse} from "../APICaller";

export const vehicleRenewalRequirements = (vehicleId) => {
    return api.get(`/vehicleRenewal/requirements/${vehicleId}`)
};

export const vehicleRenewalCharges = (vehicleId) => {
    return api.get(`/vehicleRenewal/charges/${vehicleId}`);
};

export const renewVehicleTag = (form) => {
    return api.post(`/vehicleRenewal/pay/${form.vehicleId}`, form);
};

export function fetchVehicleVoucherData(vehicleId) {
    return api
        .get(`/vehicleRenewal/voucher/data/${vehicleId}`);
}

export function openVehicleVoucher(vehicleId, onSuccess, onError) {
    return api
        .get(`/vehicleRenewal/voucher/pdf/${vehicleId}`, {}, {responseType: 'arraybuffer'})
        .then((response) => handleResponse(response, onSuccess, onError));
}

export const vehicleRenewalNotiReasons = (vehicleId, onSuccess, onError) => {
    return api
        .get(`/vehicle/renewal/${vehicleId}/cannot-print-noti-reasons`)
        .then((response) => handleResponse(response, onSuccess, onError));
};
