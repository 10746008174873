import React from "react";
import WarningIcon from "../../assets/images/warning-orange-icon.svg";
import RefreshIcon from "../../assets/images/refresh-icon.svg";
import { Colors } from "../../Colors";
import { useTranslation } from "react-i18next";
import Text from "./Text";

const ErrorRefresh = props => {
    const { refresh } = props;
    const { t } = useTranslation();
    return (
        <div style={ErrorRefreshStyle}>
            <img
                role={"button"}
                aria-label={t("refreshError:refreshAria")}
                src={RefreshIcon}
                style={{
                    alignSelf: "flex-end",
                    width: "30px",
                    marginBottom: "1rem",
                    cursor: "pointer",
                }}
                alt={""}
                onClick={() => refresh()}
            />
            <img
                src={WarningIcon}
                aria-hidden={true}
                style={{ marginBottom: "0.5rem" }}
                alt={""}
            />
            <Text i18nKey="refreshError:message1" />
            <div>
                <Text i18nKey="refreshError:message2" />
                <a onClick={() => refresh()} style={{ color: Colors.primary }}>
                    &nbsp;{t("refreshError:message3")}
                </a>
            </div>
        </div>
    );
};

const ErrorRefreshStyle = {
    minHeight: "12rem",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem",
    cursor: "pointer",
    textAlign: "center"
};

export default ErrorRefresh;
