import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Text from "../../components/ui/Text";
import { Colors } from "../../Colors";
import Skeleton from "react-skeleton-loader";
import { caller } from "../../redux/loading/loadingState.combine";
import { fetchCescoDetails } from "../../core/api-resources/config";
import {useDispatch} from "react-redux";

/**

 *  payment wizard i18n
 *  other screens like maintenance, not fund
 *  sucess, general error, etcx
*   disable checkboxes when no fines
 */

const CescoServicesModal = props => {
    const [loading, toggleLoading] = useState(false);
    const [error, toggleError] = useState(false);
    const [services, setServices] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchCescoData();
    }, []);

    const fetchCescoData = () => {
        toggleLoading(true);
        toggleError(false);
        dispatch(caller(fetchCescoDetails(props.id, onFetchSuccess, onFetchError)));
    };

    const onFetchSuccess = data => {
        setServices(data.services);
        toggleLoading(false);
    };

    const onFetchError = () => {
        toggleError(true);
    };

    const { toggle } = props;

    return (
        <div>
            <Modal isOpen={true} toggle={toggle}>
                <ModalHeader
                    toggle={toggle}
                    style={{ backgroundColor: Colors.bgGray }}
                >
                    <Text
                        style={{ fontSize: "18px", color: Colors.darkGray }}
                        i18nKey={"cescos:services"}
                    />
                </ModalHeader>

                <ModalBody>
                    {/* <CescoSearchBar /> */}
                    {!loading && services ? (
                        <React.Fragment>
                            {Object.keys(services).map((key, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className="card card-frame mb-3"
                                    >
                                        <div className="card-body">
                                            <Text
                                                style={{
                                                    color: Colors.gray,
                                                    textTransform: "uppercase",
                                                    fontSize: "13px"
                                                }}
                                            >
                                                {key}
                                            </Text>
                                            <br />
                                            <ul>
                                                {services[key].map((i, idx) => (
                                                    <li
                                                        key={`${key}-${idx}`}
                                                        style={{
                                                            fontSize: "16px",
                                                            color:
                                                                Colors.primary
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                color: "#000"
                                                            }}
                                                        >
                                                            {i}
                                                        </Text>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    ) : (
                        <div className="card card-frame mb-3">
                            <div className="card-body">
                                <Skeleton />
                            </div>
                        </div>
                    )}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default CescoServicesModal;
