import React, { useEffect, useState } from "react";
import { Colors } from "../../Colors";
import { FontWeight, FontSize } from "./FontSize";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Checkbox = (props) => {
    const [checked, setChecked] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked]);

    useEffect(() => {
        setDisabled(props.disabled);
    }, [props.disabled]);

    const click = (e) => {
        if (!disabled) {
            setChecked(!checked);
            props.handleCheckbox(!checked, e);
        }
    };

    return (
        <div
            className="custom-control custom-checkbox "
            style={{ ...props.style, color: disabled ? Colors.disabledText : Colors.darkGray }}
            onClick={click}
        >
            <input
                role={"checkbox"}
                aria-checked={checked}
                aria-label={props.ariaLabel}
                aria-disabled={props.disabled}
                type="checkbox"
                className="custom-control-input "
                checked={checked}
                disabled={disabled}
            />

            <label
                aria-hidden="true"
                className="custom-control-label text"
                style={{
                    fontWeight: FontWeight.medium,
                    textTransform: "capitalize",
                    paddingTop: 4
                }}
            >
                {t(props.label)}
            </label>
        </div>
    );
};

Checkbox.propTypes = {
    handleCheckbox: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    ariaLabel: PropTypes.string,
};

Checkbox.defaultProps = {
    disabled: false,
};

export default Checkbox;
