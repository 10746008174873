import React, { useEffect, useState } from "react"
import { Colors } from "../../Colors"
import PageTitle from "../../components/ui/PageTitle"
import Text from "../../components/ui/Text"
import WizardFooter from "../../components/widgets/WizardFooter"
import SubHeader from "../../layout/SubHeader"
import SubHeaderText from "../../components/ui/SubHeaderText"
import VehicleInfoRow from "../Insurance/components/VehicleInfoRow"
import ComponentTitle from "../../components/ui/ComponentTitle"

import iconInfo from "../../assets/images/icon-information-cvc.svg";
import { useHistory } from "react-router-dom"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import prthArt from '../../assets/images/electronic-tag/Sello_PRHT.svg'
import numSelloArt from '../../assets/images/electronic-tag/Autoexpreso_NumSello.svg'
import serialArt from '../../assets/images/electronic-tag/SelloAutoexpreso_NumSerie.svg'

import errorIcon from '../../assets/images/icon_Cancelar_BtMultas.svg'
import warningIcon from '../../assets/images/icon_Warning_White.svg'
import { useTranslation } from "react-i18next"
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux"
import { tagPreboardingRegister } from '../../core/api-resources/tag';
import { setPreboardingStatus } from "../../redux/electronicTag/electronicTag.combine"
import Button from "../../components/ui/Button"
import iconSerial from '../../assets/images/electronic-tag/icon_Sello_Serie.svg'

import _ from 'lodash'

const Preboarding = () => {

    const vehicle = useSelector(s => s?.electronicTag?.preboardingVehicle)
    const preBoardingRegistrationEnabled = useSelector(s => s?.features?.preBoarding?.preBoardingRegistrationEnabled)
    const userEmail = useSelector(s => s?.login?.user?.principal?.email)
    const errorFormUrl = useSelector(s => s.features?.preBoarding?.errorFormUrl)
    const user = useSelector(s => s?.login?.user?.principal)
    const profile = useSelector(s => s?.profile)

    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation()

    const [input, setInput] = useState('')
    const [inputError, setInputError] = useState(false)
    const [inputWarning, setInputWarning] = useState(false)
    const [infoModal, setInfoModal] = useState(false)

    const [submitted, setSubmitted] = useState(false)

    const [formError, setFormError] = useState(false)
    const [errorCode, setErrorCode] = useState(null)

    const [forceNumberModal, setForceNumberModal] = useState(false)
    const [forceNumber, setForceNumber] = useState(false)

    const [emailValidationModal, setEmailValidationModal] = useState(false)

    const allowForceRegister = useSelector(s => s?.features?.preBoarding?.allowForceRegister)

    const [serialNumberInfo, setSerialNumberInfo] = useState(false)

    const [serialNumber, setSerialNumber] = useState('')
    const [serialNumberError, setSerialNumberError] = useState(false)

    const lang = useTranslation()?.i18n?.language
    console.log("LANG: ", lang)

    useEffect(() => {
        if (!vehicle || !preBoardingRegistrationEnabled) history.push('/landing')
    }, [vehicle])

    const validateEmail = () => {
        if (userEmail && profile?.validEmail) {
            return true
        } else {
            setEmailValidationModal(true)
            return false
        }
    }

    const handleValidation = async () => {

        const validEmail = validateEmail()
        if (!validEmail) return

        setSubmitted(true)

        const onSuccess = (res) => {
            dispatch(setPreboardingStatus({ ...res?.payload, tagNumber: input }))
            handleNext()
        }

        const onError = (err) => {
            const status = err.status
            setErrorCode(err?.data?.code)
            setForceNumberModal(false)
            setFormError(true)

            switch (status) {
                case 400:
                    setInputError(t("preboarding:error.400"))
                    break;
                case 404:
                    setInputError(t("preboarding:error.404"))
                    break;
                case 406:
                    if (err?.data?.code === "SERIAL_MISMATCH") {
                        setInputError(t("preboarding:error.serialMismatch"))
                        break;
                    } else {
                        setInputError(t("preboarding:error.406"))
                        break;
                    }
                case 409:
                    if (err?.data?.code === "AlreadyRegistered") {
                        setInputError(t("preboarding:error.409"))
                        break;
                    } else {
                        setInputError(t("preboarding:error.registeredToAnotherTag"))
                        break;
                    }
                case 417:
                    if (allowForceRegister) {
                        setFormError(false)
                        setInputWarning(t('preboarding:force.warning'))
                        setForceNumberModal(true)
                    } else {
                        setInputError(t("preboarding:error.417"))
                    }
                    break;
                default:
                    setInputError(t("preboarding:error.fail"))
                    break;
            }

            setTimeout(() => {
                setErrorCode(false)
                setFormError(false)
                setInputWarning(false)
                setInputError(false)
            }, 5000)
        }

        const vehicleId = vehicle?.id
        const unmaskedValue = input.slice(5)
        await tagPreboardingRegister(
            vehicleId,
            unmaskedValue,
            {
                inputType: "MANUAL",
                email: userEmail,
                force: forceNumber,
                serialFour: _.toUpper(serialNumber)
            },
            onSuccess,
            onError)
        setSubmitted(false)
    }

    const handleNext = () => {
        history.push('/preboarding/result')
    }

    const handleForceNumberAction = () => {
        setForceNumber(true)
    }

    const nextButtonDisabled = () => {

        let regex = /^[a-fA-F0-9]{0,4}$/
        let validSerialNo = regex.test(serialNumber)

        return (input?.length < 13 || submitted || serialNumber?.length !== 4 || !validSerialNo)
    }

    return (
        <>
            {
                emailValidationModal && <InvalidEmailModal toggle={setEmailValidationModal} />
            }

            {
                infoModal ?
                <InfoModal
                    toggle={setInfoModal}
                    title={"preboarding:infoModal.title"}
                    imgSrc={numSelloArt}
                    body={
                        <div className="p-3 border-top lh-md">
                            <span>{t("preboarding:infoModal.0")}</span>
                            <strong>{t("preboarding:infoModal.1")}</strong>
                            <span>{t("preboarding:infoModal.2")}</span>
                            <strong>{t("preboarding:infoModal.3")}</strong>
                            <br /> <br />
                            <span>{t("preboarding:infoModal.4")}</span>
                            <strong style={{ color: Colors.error }}>{t("preboarding:infoModal.5")}</strong>
                        </div>
                    }
                /> : null
            }

            {
                serialNumberInfo ?
                <InfoModal
                    toggle={setSerialNumberInfo}
                    title={"preboarding:serialNumber.title"}
                    imgSrc={serialArt}
                    body={
                        <div className="p-3 border-top lh-md">
                            <span>{t("preboarding:serialNumber.0")}</span>
                            <strong>{t("preboarding:serialNumber.1")}</strong>
                            <span>{t("preboarding:serialNumber.2")}</span>
                            <strong>{t("preboarding:serialNumber.3")}</strong>
                            <br /> <br />
                            <span>{t("preboarding:serialNumber.4")}</span>
                            <strong style={{ color: Colors.error }}>{t("preboarding:serialNumber.5")}</strong>
                        </div>
                    }
                /> : null
            }

            {
                forceNumberModal && <ForceNumberModal
                    toggle={setForceNumberModal}
                    action={handleForceNumberAction}
                    tagNumber={input}
                />
            }

            <div>
                <SubHeader>
                    <div className="container p-0">
                        <PageTitle
                            style={{ fontWeight: "bold" }}
                            i18nKey={"preboarding:title"}
                        />
                        <div className="d-none d-lg-flex">
                            <hr />
                        </div>
                        <SubHeaderText i18nKey="preboarding:subHeader.0" />
                    </div>
                </SubHeader>

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="py-2">
                                <VehicleInfoRow vehicle={vehicle} />
                            </div>

                            <div className="pb-2" >
                                <hr />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className={`mb-2 ${lang === 'es-US' && 'mb-lg-5'}`}>
                                <ComponentTitle i18nKey={"preboarding:enterNumber"} noBorder />
                            </div>

                            <div className="input-group">
                                <div className="input-group-prepend" aria-hidden>
                                    <span
                                        className="input-group-text rounded-0 border-top-0 border-left-0"
                                        style={{ backgroundColor: inputError ? Colors.error : Colors.lightGray }}
                                    >
                                        <img src={prthArt} alt={""} aria-hidden />
                                    </span>
                                </div>
                                <InputMask
                                    className={`form-control border-right-0`}
                                    mask="PRHT-99999999"
                                    maskChar=""
                                    value={input}
                                    placeholder={t("preboarding:input.placeholder")}
                                    onChange={(e) => setInput(e.target.value)}
                                    type="text"
                                    alwaysShowMask={false}
                                    style={{
                                        borderBottomRightRadius: 0,
                                        fontSize: "15px",
                                        fontFamily: "Open Sans",
                                        border: inputError && `1px solid ${Colors.error}`,
                                        backgroundColor: inputError && Colors.errorBg,
                                        color: inputError && Colors.errorDark
                                    }}
                                />
                                <span>
                                    <img
                                        src={iconInfo}
                                        className="creditCardPadding"
                                        alt={""}
                                        style={{
                                            position: "absolute",
                                            right: 0,
                                            top: 17,
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => setInfoModal(true)}
                                    />
                                </span>
                            </div>

                            {
                                inputError &&
                                <div
                                    className="mt-3 p-3 text-white d-flex align-items-center rounded"
                                    style={{ background: Colors.error }}>
                                    <div className="mr-2">
                                        <img src={errorIcon} alt="" aria-hidden />
                                    </div>
                                    <span>{inputError}</span>
                                </div>

                            }

                            {
                                inputWarning &&
                                <div
                                    className="mt-3 p-3 text-white d-flex align-items-center rounded"
                                    style={{ background: Colors.orange }}>
                                    <div className="mr-2">
                                        <img src={warningIcon} alt="" aria-hidden />
                                    </div>
                                    <span>{inputWarning}</span>
                                </div>

                            }

                            {
                                formError &&
                                <div className="mt-5">
                                    <div className="mb-3">
                                        <span>{t("preboarding:error.form.0")}</span>
                                    </div>

                                    <div>
                                        <a
                                            href={`${errorFormUrl}?name=${user?.name}&vehicle=${vehicle?.year + " " + vehicle?.make + " " + vehicle?.model}&plate=${vehicle?.plate}&email=${user?.email}&tagNumber=${input.slice(5)}&reason=${errorCode}`}
                                            className="btn btn-error-outline w-100"
                                        >{t("preboarding:error.form.1")}</a>
                                    </div>
                                </div>
                            }

                        </div>

                        <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                            <div className="mb-2">
                                <ComponentTitle i18nKey={"preboarding:serialNumber.heading"} noBorder />
                            </div>

                            <div className="input-group">
                                <div className="input-group-prepend" aria-hidden>
                                    <span
                                        className="input-group-text rounded-0 border-top-0 border-left-0"
                                        style={{ backgroundColor: inputError ? Colors.error : Colors.lightGray }}
                                    >
                                        <img src={iconSerial} alt={""} aria-hidden />
                                    </span>
                                </div>
                                <InputMask
                                    className={`form-control border-right-0 text-uppercase`}
                                    mask="****"
                                    pattern="^[a-fA-F0-9]{0,4}$"
                                    maskChar=""
                                    value={serialNumber}
                                    placeholder={t("preboarding:serialNumber.placeholder")}
                                    onChange={(e) => setSerialNumber(e.target.value)}
                                    type="text"
                                    alwaysShowMask={false}
                                    style={{
                                        borderBottomRightRadius: 0,
                                        fontSize: "15px",
                                        fontFamily: "Open Sans",
                                        border: serialNumberError && `1px solid ${Colors.error}`,
                                        backgroundColor: serialNumberError && Colors.errorBg,
                                        color: serialNumberError && Colors.errorDark
                                    }}
                                />
                                <span>
                                    <img
                                        src={iconInfo}
                                        className="creditCardPadding"
                                        alt={""}
                                        style={{
                                            position: "absolute",
                                            right: 0,
                                            top: 17,
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => setSerialNumberInfo(true)}
                                    />
                                </span>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container mt-5">
                    <WizardFooter
                        disabled={nextButtonDisabled()}
                        handleNext={() => handleValidation()}
                    />
                </div>
            </div>
        </>
    )
}

const InvalidEmailModal = (props) => {

    const { toggle } = props
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <Modal
            isOpen={true}
            size="md"
            toggle={() => toggle && toggle(false)}
        >
            <ModalHeader
                toggle={() => toggle && toggle(false)}
            >
                <Text i18nKey="preboarding:noEmail.title" className="text" />
            </ModalHeader>

            <ModalBody
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 0
                }}
            >
                <div className="p-3 border-top lh-md">
                    <span>{t("preboarding:noEmail.text")}</span>
                </div>
            </ModalBody>
            <ModalFooter className="d-flex align-items-center justify-content-center">
                <Button
                    onClick={() => history.push('/settings')}
                    disabled={false}
                    className="btn btn-primary">
                    {t("preboarding:noEmail.button")}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const InfoModal = (props) => {

    const { toggle } = props
    const { t } = useTranslation()

    return (
        <Modal
            isOpen={true}
            size="md"
            toggle={() => toggle && toggle(false)}
        >
            <ModalHeader
                toggle={() => toggle && toggle(false)}
            >
                <Text i18nKey={props.title} className="text" />
            </ModalHeader>

            <ModalBody
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 0
                }}
            >
                    <div
                        style={{ backgroundColor: Colors.bgGray }}
                        className="w-100 d-flex justify-content-center py-5"
                    >
                        <img
                            alt=""
                            aria-hidden
                            src={props.imgSrc}
                        />
                    </div>
                {props.body}
            </ModalBody>
        </Modal>
    )
}

const ForceNumberModal = (props) => {

    const { toggle, action, tagNumber } = props
    const { t } = useTranslation()

    const handleAction = () => {
        action && action()
        toggle && toggle(false)
    }

    return (
        <Modal
            isOpen={true}
            size="md"
            toggle={() => toggle && toggle(false)}
        >
            <ModalHeader
                toggle={() => toggle && toggle(false)}
            >
                <Text i18nKey="preboarding:force.alert" className="text" />
            </ModalHeader>

            <ModalBody
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 0
                }}
            >
                <div className="p-3 border-top lh-md">
                    <span>{t("preboarding:force.text.0")}</span>
                    <strong>{tagNumber}{" "}</strong>
                    <span>{t("preboarding:force.text.1")}</span>
                </div>
            </ModalBody>
            <ModalFooter className="d-flex align-items-center justify-content-center">
                <Button
                    onClick={() => handleAction()}
                    disabled={false}
                    className="btn btn-primary">
                    {t("preboarding:continue")}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default Preboarding