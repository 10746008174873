import React from "react";

import { Colors } from "../../Colors";
import Text from "../../components/ui/Text";

import informationIcon from "../../assets/images/icon-information.svg";

const InformationRow = (props) => {
    return (
        <div
            className="information-row"
            style={{
                backgroundColor: Colors.lightGray,
                minHeight: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "50px",
                fontSize: "15px",
            }}
        >
            <img
                src={informationIcon}
                alt={""}
                style={{ marginRight: "15px" }}
            />
            <Text
                style={{ color: Colors.gray }}
                i18nKey={props.textKey}
                className="text-sm"
            />
        </div>
    );
};

export default InformationRow;
