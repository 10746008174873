import React from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "../components/ui/Button";
import Text from "../components/ui/Text";
import DaysLeftSquare from "../components/ui/DaysLeftSquare";

import "../components/styles/alert.css";

import alert from "../assets/images/icon_Alert.svg";
import { format } from "../core/Utils";

const Alert = (props) => {
    const { t } = useTranslation();

    const { error, text, data, action, actionBtnText, closable, days } = props;

    return (
        <div className={"alert-btn-container"} style={alertButtonStyle}>
            <div
                role="text"
                aria-label={`${t(
                    `alerts:${text}.title`,
                    data
                )} ${`alerts:${text}.text`} ${format(data.date, "MM/DDDD/YYYY")} `}
                className={`alert alert-padding alert-${
                    error ? "danger" : "warning"
                } alert-dismissible fade show`}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    {days ? (
                        <DaysLeftSquare days={days} />
                    ) : (
                        <img
                            src={error ? error : alert}
                            alt={""}
                            style={{ height: 35 }}
                        />
                    )}

                    <div style={{ flexGrow: 1, marginLeft: "15px" }}>
                        {text && (
                            <Text
                                className="text"
                                style={{ textTransform: "uppercase" }}
                            >
                                {days ? (
                                    <strong>
                                        {t(`alerts:pickupLicense.title`)}
                                    </strong>
                                ) : (
                                    <strong>
                                        {t(`alerts:${text}.title`, data)}
                                    </strong>
                                )}
                            </Text>
                        )}
                        <div style={{ lineHeight: 1 }}>
                            {days ? (
                                <Text
                                    i18nKeyWithData={{
                                        key: `alerts:pickupLicense.description`,
                                        data: data,
                                    }}
                                    className="text"
                                />
                            ) : (
                                <Text
                                    i18nKeyWithData={{
                                        key: `alerts:${text}.text`,
                                        data: data,
                                    }}
                                    className="text"
                                />
                            )}
                        </div>
                    </div>

                    {action && (
                        <span className={"d-none d-lg-flex"}>
                            <Button
                                onClick={action}
                                disabled={false}
                                className="btn-secondary"
                            >
                                {actionBtnText}
                            </Button>
                        </span>
                    )}
                    {closable && (
                        <div style={{ width: 20 }}>
                            <button
                                type="button"
                                className="close"
                                style={{ top: 6 }}
                                data-dismiss="alert"
                                aria-label={t("layout:body.alert.close")}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {/* <Button
                onClick={action}
                style={{ backgroundColor: "#", alignItems: "center" }}
                className={
                    "rounded-0 btn-block warning d-flex d-lg-none justify-content-center"
                }
                disabled={false}
            >
                {actionBtnText}
            </Button> */}
        </div>
    );
};

const alertButtonStyle = {
    textAlign: "left",
    marginBottom: "25px",
    width: "100%",
    backgroundColor: "transparent",
    border: 0,
    padding: 0,
};

Alert.propTypes = {
    closable: PropTypes.bool,
    text: PropTypes.string.isRequired,
    actionBtnText: PropTypes.string,
    error: PropTypes.bool,
    data: PropTypes.object,
};

Alert.defaultProps = {
    error: false,
    text: "",
};
/**
 RenewLicense: "License about to expire.",
 ExpiredLicense: "Expired licence.",
 Minor: "Under 18.",
 RenewTag: "Tag about to expire.",
 ExpiredTag: "Expired tag.",
 MissingTag: "Vehicle without tag.",
 */

export default Alert;
