import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import PageTitle from "../../../components/ui/PageTitle";
import Text from "../../../components/ui/Text";
import SubHeader from "../../../layout/SubHeader";
import { Colors } from "../../../Colors";
import { FontWeight } from "../../../components/ui/FontSize";
import WizardFooter from "../../../components/widgets/WizardFooter";

import realIdIcon from "../../../assets/images/renovacion-tarjeta-realid.svg";
import ToggleSwitch from "../../../components/ui/ToggleSwitch";
import { useDispatch, useSelector } from "react-redux";
import { setRealIdValue } from "../../../redux/renewal/actions";

import { useTranslation } from "react-i18next";

import ConfirmationModal from "../../../components/ui/ConfirmationModal";

const Type = () => {
    let history = useHistory();
    let dispatch = useDispatch();
    let renewal = useSelector((state) => state.renewal);
    const currentRealId = renewal.realId;
    const [toggleState, setToggleState] = useState(renewal.realIdToggle);

    const [confirmationModal, toggleConfirmation] = useState(false);

    const { t } = useTranslation();

    const onNext = () => {
        if (toggleState) {
            dispatch(setRealIdValue(false));
        } else dispatch(setRealIdValue(true));

        history.push("/renewal/questions");
    };

    return (
        <>
            <SubHeader>
                <div className="container">
                    <PageTitle
                        style={{ fontWeight: "bold" }}
                        i18nKey={"type:pageTitle"}
                    />
                    <div className="d-none d-lg-flex">
                        <hr
                            style={{
                                borderTop: `1px solid ${Colors.borderDarkGray}`,
                            }}
                        />
                    </div>
                    <Text
                        i18nKey={"type:subTitle"}
                        style={{ color: Colors.gray }}
                        className="text"
                    />
                </div>
            </SubHeader>

            <div className="container">
                <div style={realIdContent} className="realIdContent">
                    <img
                        aria-hidden="true"
                        alt=""
                        src={realIdIcon}
                        style={{ marginRight: "15px", marginBottom: "15px" }}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Text
                            style={{
                                fontWeight: FontWeight.medium,
                                marginBottom: "10px",
                            }}
                            className="text-md realIdContentTitle"
                            i18nKey="type:contentTitle"
                        />
                        <p
                            style={{
                                position: "inherit",
                                textTransform: "inherit",
                                fontWeight: FontWeight.regular,
                                color: Colors.primary,
                            }}
                        >
                            <ul
                                className="text"
                                style={{ marginBottom: 0, lineHeight: "1.5" }}
                            >
                                <li>
                                    <Text
                                        className="text"
                                        style={{ color: Colors.gray }}
                                    >
                                        {t("type:uses.flights")}
                                    </Text>
                                </li>
                                <li>
                                    <Text
                                        className="text"
                                        style={{ color: Colors.gray }}
                                    >
                                        {t("type:uses.military")}
                                    </Text>
                                </li>
                            </ul>
                        </p>
                        {/*<Text
                            style={{color: Colors.gray}}
                            i18nKey="type:contentDescription"
                        />*/}
                    </div>
                </div>
                {!currentRealId && (
                    <div style={realIdToggleStyle}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <h5 style={{ fontSize: 14 }}>
                                <Text
                                    i18nKey="type:nonCompliant"
                                    style={{
                                        color: Colors.gray,
                                        fontSize: "14px",
                                    }}
                                />
                                <Text
                                    i18nKey="type:realIdToggle"
                                    style={{
                                        color: Colors.gray,
                                        fontSize: "14px",
                                        fontWeight: FontWeight.regular,
                                    }}
                                />
                            </h5>
                            <div style={{ marginLeft: "15px" }}>
                                <ToggleSwitch
                                    state={toggleState}
                                    setState={setToggleState}
                                    style={{ marginBottom: "10px" }}
                                    // disabled={toggleState} //REAL-ID enabled can not be change
                                />
                            </div>
                        </div>
                        <p
                            style={{
                                position: "inherit",
                                fontSize: "12px",
                                fontWeight: FontWeight.regular,
                                textTransform: "inherit",
                            }}
                        >
                            {t("type:warning.title")}
                            <ul>
                                <li>{t("type:warning.flights")}</li>
                                <li>{t("type:warning.military")}</li>
                            </ul>
                        </p>
                        <p></p>
                    </div>
                )}

                {confirmationModal ? (
                    <ConfirmationModal
                        action={onNext}
                        toggle={toggleConfirmation}
                        text={t("type:confirmationModal")}
                        title={t("type:confirmationModalTitle")}
                    />
                ) : null}

                <WizardFooter
                    // {...props}
                    disabled={false}
                    handleNext={
                        toggleState ? () => toggleConfirmation(true) : onNext
                    }
                />
            </div>
        </>
    );
};

const realIdToggleStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px 0",
    borderTop: `1px solid ${Colors.borderGray}`,
    borderBottom: `1px solid ${Colors.borderGray}`,
};
const realIdContent = {
    display: "flex",
    marginBottom: "30px",
};

export default Type;
