import React from 'react';
import iconWarning from "../../assets/images/icon_Warning_White.svg";
import iconProblem from "./images/icon_Alert_ConnectionError.svg";
import Text from "../../components/ui/Text";
import {FontSize} from "../../components/ui/FontSize";

const TopLoginMessage = (props) => {

    let {error, problem} = props;
    let message, bgColor, icon, alt;
    if (!error && !problem) return null;

    if (problem) {
        message = "login:form.problem";
        icon = iconProblem;
        bgColor = "#F18C36";
        alt = "login:form.accessibility.problem"

    } else {
        message = "login:form.error";
        icon = iconWarning;
        bgColor = "red";
        alt = "login:form.accessibility.error"

    }


    return (
        <div className="card col-12" style={loginErrorStyle}>
            <div
                className="card-body"
                style={{
                    backgroundColor: bgColor,
                    borderRadius: "0.3125rem",
                    display: "flex",
                    alignItems: "center",
                    padding: problem ? "10px": "1rem"
                }}>
                <img src={icon} style={{height: "25px", marginRight: "10px"}} alt={alt}/>
                <Text fontSize={FontSize.sm} i18nKey={message}/>
            </div>
        </div>
    );
};


const loginErrorStyle = {
    backgroundColor: "transparent",
    color: "#fff",
    border: "none",
    position: "absolute",
    top: -20,
    left: 0,
    zIndex: 100,
    padding: 0,
    display: "flex",
};


export default TopLoginMessage;