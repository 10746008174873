import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";

import FineListItem from "../widgets/FineListItem";
import FineListItemMobile from "./FineListItemMobile";
import ErrorRefresh from "../ui/ErrorRefresh";
import NoMultasIcon from "../../assets/images/no-multas-icon.svg";
import { emptyObjArray, isMobile } from "../../core/Utils";
import { useTranslation } from "react-i18next";
import Text from "../ui/Text";
import AutoexpresoFineListItem from "./AutoexpresoFineListItem";

const FineList = (props) => {
    const PAGE_SIZE = 5;

    const { loading, error, fetchFines, fines, noPagination, autoexpreso } = props;

    const [displayFines, setDisplayFines] = useState(emptyObjArray(5));
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = useTranslation();

    useEffect(() => {
        if (noPagination) {
            setDisplayFines(fines);
        } else {
            setDisplayFines(arrayPage(fines, currentPage));
        }
    }, [fines, currentPage]);

    const arrayPage = (arr = [], page = 1) => {
        return arr?.slice(PAGE_SIZE * (page - 1), PAGE_SIZE * page);
    };

    const pageEvent = (p) => {
        setDisplayFines(arrayPage(fines, p));
        setCurrentPage(p);
    };

    return (
        <>
            <div style={{ marginBottom: "35px" }}>
                <div>
                    {error ? (
                        <div style={{ padding: 0 }} role="alert">
                            <ErrorRefresh refresh={fetchFines} />
                        </div>
                    ) : (
                        <>
                            {displayFines && displayFines?.length > 0 ? (
                                displayFines.map((f, i) => (
                                    <React.Fragment key={i}>
                                        <div
                                            className="d-none d-lg-block"
                                            key={`FineListItem_${i}`}
                                            style={fineListItemStyle}
                                        >
                                            {
                                                autoexpreso ?
                                                    <AutoexpresoFineListItem
                                                        key={i}
                                                        fine={f}
                                                        withCheckbox
                                                        noIcon={props.noIcon}
                                                        checked={f.checked}
                                                        loading={loading}
                                                    />
                                                    :
                                                    <FineListItem
                                                        key={i}
                                                        fine={f}
                                                        withCheckbox
                                                        noIcon={props.noIcon}
                                                        checked={f.checked}
                                                        loading={loading}
                                                        style={{ ...props.style }}
                                                    />
                                            }
                                        </div>
                                        <div
                                            className="d-lg-none"
                                            key={`FineListItemMobile_${i}`}
                                            style={fineListItemStyle}
                                        >
                                            {
                                                autoexpreso ?
                                                    <AutoexpresoFineListItem
                                                        key={i}
                                                        fine={f}
                                                        withCheckbox
                                                        checked={f.checked}
                                                        loading={loading}
                                                    />
                                                    :
                                                    <FineListItemMobile
                                                        key={i}
                                                        fine={f}
                                                        withCheckbox
                                                        checked={f.checked}
                                                        loading={loading}
                                                    // style={{ ...props.style }}
                                                    />
                                            }
                                        </div>
                                    </React.Fragment>
                                ))
                            ) : (
                                <div style={{ padding: 0 }} role="figure">
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#fff",
                                            minHeight: "14rem",
                                        }}
                                    >
                                        <img
                                            alt={t("fineList:congratsAlt")}
                                            src={NoMultasIcon}
                                            style={{ margin: "1rem" }}
                                        />
                                        <Text i18nKey="fineList:congrats" />
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            {/*<nav  style={{marginTop: 20 }} className={"d-flex justify-content-between align-items-center"} >*/}
            {!noPagination && fines?.length > PAGE_SIZE && (
                <nav style={{ marginTop: 20, marginBottom: 40 }}>
                    <Pagination
                        innerClass={"pagination justify-content-center"}
                        activePage={currentPage}
                        itemsCountPerPage={PAGE_SIZE}
                        totalItemsCount={fines?.length}
                        pageRangeDisplayed={isMobile() ? 2 : 3}
                        onChange={pageEvent}
                        itemClass={"page-item"}
                        linkClass={"page-link"}
                    />
                    {/*<small className="text-muted">{currentPage} of {pages}</small>*/}
                </nav>
            )}
        </>
    );
};

const fineListItemStyle = {
    width: "100%",
};

FineList.propTypes = {
    onFinesSelectedChange: PropTypes.func,
    autoexpreso: PropTypes.bool,
    fines: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    noIcon: PropTypes.bool,
    noPagination: PropTypes.bool,
    autoexpreso: PropTypes.bool,
    currentPage: PropTypes.number,
    pageEvent: PropTypes.func
};

export default FineList;
