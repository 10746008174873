import "./App.css";
import "./styles/text.css";
import "./styles/buttons.css";
import './assets/scss/redux-toastr-style.scss';

import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation, withRouter } from "react-router-dom";
import moment from "moment";
import es from "./utils/es-do-override";

import Layout from "./layout/Layout";
import Header from "./layout/Header";
import Footer from "./layout/Footer";

import LoginPage from "./pages/Login";
import MainPage from "./pages/Main";
import PaymentHistory from "./pages/PaymentHistory";
import Profile from "./pages/Profile/index";
import Cescos from "./pages/Cescos/index";
import NotFound from "./pages/NotFound/index";
import Maintenance from "./pages/Maintenance/index";
import Messages from "./pages/Messages/index";
import Payment from "./pages/PayFines";
import PaymentComplete from "./pages/PayFines/PaymentComplete";
import PaymentError from "./pages/PayFines/PaymentError";
import PaymentDetail from "./pages/PayFines/PaymentDetail";
import VehicleDetails from "./pages/VehicleDetails/index";
import Certifications from "./pages/Certifications/index";
import RenewalCompleted from "./pages/Renewal/Completed/index";
import RenewalDisclosure from "./pages/Renewal/Disclosure/index";
import Eligible from "./pages/Renewal/Eligible/index";
import NotEligible from "./pages/Renewal/NotEligible/index";
import Questions from "./pages/Renewal/Questions/index";
import Type from "./pages/Renewal/Type/index";
import SelectLocation from "./pages/Renewal/SelectLocation/index";
import MissingDocs from "./pages/Renewal/MissingDocs/index";
import Insurance from "./pages/Renewal/Insurance/index";
import Voucher from "./pages/Renewal/Voucher/index";
import BirthCertificate from "./pages/Renewal/BirthCertificate/index";
import PrivateInsurance from "./pages/Renewal/PrivateInsurance/index";
import Address from "./pages/Renewal/Address/index";
import Validate from "./pages/Validate/index";
import ValidateDetail from "./pages/Validate/ValidateDetail/index";
import EmailValidation from "./pages/EmailValidation/index";

import Loading from "./components/widgets/Loading";
import LoadConfig from "./pages/LoadConfig";
import { useDispatch, useSelector } from "react-redux";
import { resetState, setResolution } from "./redux/app/app.combine";
import GeneralError from "./pages/GeneralError";
import FilterRoute from "./routes/FilterRoute";
import i18n from "./i18n";
import Settings from "./pages/Settings";
import PaymentEmailValidation from "./pages/PayFines/PaymentEmailValidation";
import Help from "./pages/Help";
import Deeplink from "./pages/Deeplink/index";
import Course from "./pages/Courses";
import CoursesForm from "./pages/Courses/form";
import DtopCourse from "./pages/Courses/dtop";
import More from "./pages/More";
import VehicleTransferVoucher from "./pages/VehicleTransfer/Voucher";
import InsuranceSelection from "./pages/Insurance/Selection";
import InsuranceTotal from "./pages/Insurance/Total";
import InsuranceInstructions from "./pages/Insurance/Instructions";
import PinSupport from "./pages/Support/Pin";
import PaymentSupport from "./pages/PaymentSupport";
import VehicleLicense from "./pages/VehicleLicense";
import RegistrationSupport from "./pages/RegistrationSupport";

import Preboarding from "./pages/Preboarding";
import PreboardingInstructions from "./pages/Preboarding/Instructions";
import PreboardingResult from "./pages/Preboarding/Result";
import OtherTransactions from "./pages/OtherTransactions";
import ElectronicTag from "./pages/ElectronicTag";
import ElectronicTagUnlink from "./pages/ElectronicTag/Unlink";
import About from "./pages/About";
import Autoexpreso from "./pages/Autoexpreso/index";
import PayAutoexpresoFines from "./pages/PayFines/PayAutoexpresoFines";

moment.updateLocale("es-do", es);

const App = () => {
    const { pathname } = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const root = document.getElementById('root');
    const [lang, setLang] = useState(localStorage.getItem("i18nextLng"));

    history.listen(({ pathname }) => {
        if (pathname !== '/renewal/insurance')
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
    });

    useEffect(() => {
        resize();
        window.addEventListener("resize", resize);
    }, []);

    const changeLang = (lang) => {
        i18n.changeLanguage(lang);
        setLang(lang);
    };

    const resize = () => {
        dispatch(setResolution(root.offsetWidth, root.offsetHeight))
    };

    return (
        <React.Fragment>
            <Loading />
            <Header showLogoOnly={pathname === "/loading"} lang={lang} changeLang={changeLang} />
            <main id="content" role="main" style={{ minHeight: "100vh" }}>
                <Switch>
                    <Redirect from="/" exact to="/login" />
                    <Route exact path={"/loading"} component={LoadConfig} />
                    <FilterRoute exact path={"/login"} useLayout={false} private={false}>
                        <LoginPage />
                    </FilterRoute>
                    <FilterRoute path="/landing">
                        <MainPage />
                    </FilterRoute>
                    <FilterRoute path={"/locations"} private={false}>
                        <Cescos />
                    </FilterRoute>
                    <FilterRoute path={"/payment-history"}>
                        <PaymentHistory />
                    </FilterRoute>
                    <FilterRoute exact path={"/pay/fines"}>
                        <Payment />
                    </FilterRoute>
                    <FilterRoute exact path={"/pay/fines/autoexpreso/:vehicleId"}>
                        <PayAutoexpresoFines />
                    </FilterRoute>
                    <FilterRoute exact path={"/pay/method"}>
                        <PaymentDetail />
                    </FilterRoute>
                    <FilterRoute exact path={"/pay/complete"}>
                        <PaymentComplete />
                    </FilterRoute>
                    <FilterRoute exact path={"/pay/error"}>
                        <PaymentError />
                    </FilterRoute>
                    <FilterRoute exact path={"/pay/email/validation"}>
                        <PaymentEmailValidation />
                    </FilterRoute>
                    <FilterRoute path={"/profile"}>
                        <Profile />
                    </FilterRoute>
                    <FilterRoute path={"/messages"}>
                        <Messages />
                    </FilterRoute>
                    <FilterRoute path={"/settings"}>
                        <Settings lang={lang} changeLang={changeLang} />
                    </FilterRoute>

                    {/*  <FilterRoute exact path={"/renewal"}>
                        <Renewal />
                    </FilterRoute>*/}
                    {/*<FilterRoute exact path={"/renewal/completed"}>
                        <RenewalCompleted/>
                    </FilterRoute>
                    <FilterRoute exact path={"/renewal/disclosure"}>
                        <RenewalDisclosure/>
                    </FilterRoute>
                    <FilterRoute exact path={"/renewal/eligible"}>
                        <Eligible/>
                    </FilterRoute>
                    <FilterRoute exact path={"/renewal/not-eligible"}>
                        <NotEligible/>
                    </FilterRoute>
                    <FilterRoute exact path={"/renewal/questions"}>
                        <Questions/>
                    </FilterRoute>
                    <FilterRoute exact path={"/renewal/type"}>
                        <Type/>
                    </FilterRoute>
                    <FilterRoute exact path={"/renewal/location"}>
                        <SelectLocation/>
                    </FilterRoute>
                    <FilterRoute exact path={"/renewal/missing-docs"}>
                        <MissingDocs/>
                    </FilterRoute>
                    <FilterRoute exact path={"/renewal/insurance"}>
                        <Insurance/>
                    </FilterRoute>
                    <FilterRoute exact path={"/renewal/voucher"}>
                        <Voucher/>
                    </FilterRoute>
                    <FilterRoute exact path={"/renewal/birth-certificate"}>
                        <BirthCertificate/>
                    </FilterRoute>
                    <FilterRoute exact path={"/renewal/private-insurance"}>
                        <PrivateInsurance/>
                    </FilterRoute>
                    <FilterRoute exact path={"/renewal/address"}>
                        <Address />
                    </FilterRoute>*/}
                    <FilterRoute exact path={"/validate"} private={false} useSubHeader={false}>
                        <Validate />
                    </FilterRoute>
                    <FilterRoute exact path={"/validate/:id"} private={false} useSubHeader={false}>
                        <ValidateDetail />
                    </FilterRoute>
                    <FilterRoute exact path={"/help"} private={false} useSubHeader={true}>
                        <Help />
                    </FilterRoute>
                    <FilterRoute exact path={"/email/validation"} private={false}>
                        <EmailValidation />
                    </FilterRoute>
                    <FilterRoute exact path={"/certifications"}>
                        <Certifications />
                    </FilterRoute>
                    <FilterRoute path={"/vehicle-details/:vehicleId"}>
                        <VehicleDetails />
                    </FilterRoute>
                    <FilterRoute path={"/dl"} private={false}>
                        <Deeplink />
                    </FilterRoute>
                    <FilterRoute exact path={"/courses"} private={false}>
                        <Course />
                    </FilterRoute>
                    <FilterRoute exact path={"/courses/form"}>
                        <CoursesForm />
                    </FilterRoute>
                    <FilterRoute exact path={"/courses/dtop"} private={false}>
                        <DtopCourse />
                    </FilterRoute>
                    <FilterRoute exact path={"/more"} private={false}>
                        <More />
                    </FilterRoute>
                    <FilterRoute exact path={"/vehicle-transfer/voucher/:id"} private={false} useSubHeader={false}>
                        <VehicleTransferVoucher />
                    </FilterRoute>

                    <FilterRoute exact path={"/insurance/instructions"}>
                        <InsuranceInstructions />
                    </FilterRoute>
                    <FilterRoute exact path={"/insurance/selection"}>
                        <InsuranceSelection />
                    </FilterRoute>
                    <FilterRoute exact path={"/insurance/total"}>
                        <InsuranceTotal />
                    </FilterRoute>

                    <FilterRoute exact path={"/vehicle/license"} private={false}>
                        <VehicleLicense />
                    </FilterRoute>

                    <FilterRoute exact path={"/pin-support"} private={false}>
                        <PinSupport />
                    </FilterRoute>

                    <FilterRoute path={"/payment-support"} private={false}>
                        <PaymentSupport />
                    </FilterRoute>

                    <FilterRoute path={"/registration-support"} private={false}>
                        <RegistrationSupport />
                    </FilterRoute>

                    <FilterRoute exact path={"/preboarding"}>
                        <Preboarding />
                    </FilterRoute>

                    <FilterRoute exact path={"/preboarding/instructions"}>
                        <PreboardingInstructions />
                    </FilterRoute>

                    <FilterRoute exact path={"/preboarding/result"}>
                        <PreboardingResult />
                    </FilterRoute>

                    <FilterRoute exact path={"/other-transactions"} private={false}>
                        <OtherTransactions lang={lang} />
                    </FilterRoute>

                    <FilterRoute exact path={"/electronic-tag"}>
                        <ElectronicTag />
                    </FilterRoute>

                    <FilterRoute exact path={"/electronic-tag/unlink"}>
                        <ElectronicTagUnlink />
                    </FilterRoute>

                    <FilterRoute exact path={"/autoexpreso/:vehicleId"}>
                        <Autoexpreso />
                    </FilterRoute>

                    <FilterRoute exact path={"/about"} private={false}>
                        <About />
                    </FilterRoute>

                    <Route path={"/maintenance"}>
                        <Layout>
                            <Maintenance />
                        </Layout>
                    </Route>
                    <Route path={"/error"}>
                        <Layout>
                            <GeneralError />
                        </Layout>
                    </Route>
                    <Route>
                        <Layout>
                            <NotFound />
                        </Layout>
                    </Route>
                </Switch>
            </main>
            {pathname !== "/loading" && <Footer />}
        </React.Fragment>
    );
};

App.propTypes = {};

export default withRouter(App);
