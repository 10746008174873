import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import PaymentHistoryListItem from "./PaymentHistoryListItem";
import Text from "../../components/ui/Text";
import { FontWeight } from "../../components/ui/FontSize";
import { Colors } from "../../Colors";

import noPaymentIcon from "../../assets/images/icon_NoHayPagos.svg";

const PaymentHistoryList = (props) => {
    const { loading, receipts } = props;
    const { t } = useTranslation();

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "20px",
                    fontWeight: FontWeight.bold,
                    borderBottom: `1px solid ${Colors.lightGray}`,
                    borderTop: `1px solid ${Colors.lightGray}`,
                    justifyContent: !props.small ? "inherit" : "space-between",
                }}
            >
                <div className="col-md-1 col-3">
                    <Text style={headerText} className="text">
                        {t("paymentHistory:listHeaders.date")}
                    </Text>
                </div>
                <div className="col-md-3 col-5 ">
                    <Text
                        style={headerText}
                        i18nKey={"paymentHistory:listHeaders.transNum"}
                        className="text"
                    />
                </div>
                {!props.small && (
                    <div className="col-md-4 d-none d-sm-block">
                        <Text style={headerText} className="text">
                            {t("paymentHistory:listHeaders.motive")}
                        </Text>
                    </div>
                )}
                {!props.small && (
                    <div
                    className="col-md-1 d-none d-sm-block pl-3"
                    style={{ padding: 0, textAlign: "center" }}
                >
                    <Text style={headerText} className="text">
                        {t("paymentHistory:listHeaders.status")}
                    </Text>
                </div>
                )}
                <div
                    className="col-md-2 d-none d-sm-block"
                    style={{ padding: 0, textAlign: "center" }}
                >
                    <Text style={headerText} className="text">
                        {t("paymentHistory:listHeaders.method")}
                    </Text>
                </div>
                <div
                    className="col-md-1"
                    style={{ textAlign: "right", padding: 0 }}
                >
                    <Text style={headerText} className="text">
                        {t("paymentHistory:listHeaders.amount")}
                    </Text>
                </div>
            </div>
            <div>
                {receipts && receipts.length > 0 ? (
                    receipts.map((p, i) => (
                        <PaymentHistoryListItem
                            key={i}
                            index={i}
                            payment={p}
                            chooseTransaction={props.chooseTransaction}
                            loading={loading}
                            small={props.small}
                        />
                    ))
                ) : (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: 200,
                            width: "100%",
                            backgroundColor: "#fff",
                        }}
                    >
                        <img
                            src={noPaymentIcon}
                            style={{ opacity: "0.6" }}
                            aria-hidden="true"
                            alt=""
                        />
                        <Text
                            i18nKey="paymentHistory:noPayments"
                            style={{ marginTop: "10px" }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const headerText = {
    color: Colors.darkGray,
    whiteSpace: "nowrap",
};

export default PaymentHistoryList;
