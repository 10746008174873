import React from "react";

import Skeleton from "react-skeleton-loader";

const Loadable = props => {
    return (
        <>
            {props.loading ? (
                <Skeleton
                    width={props.width}
                />
            ) : (
                props.children
            )}
        </>
    );
};

export default Loadable;
