import React, { useEffect, useState } from 'react'
import carroIcon from "../../assets/images/carro-icon.svg";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Text from '../ui/Text';
import VehicleCertifications from './certifications/VehicleCertifications';
import { Colors } from '../../Colors';
import NoVehicles from '../ui/NoVehicles';
import PropTypes from 'prop-types'

const SelectableVehicleCertifications = (props) => {

    const { withEmptyState } = props

    const { t } = useTranslation()

    let user = useSelector((state) => state.login?.user);

    const [dropDown, toggleDropdown] = useState(false);
    const [selectedCar, editSelectedCar] = useState(null);

    useEffect(() => {
        if (user.vehicles && user.vehicles.length > 0)
            editSelectedCar(user.vehicles[0]);
    }, [user.vehicles]);

    const RenderNoVehicles = () => {

        if (!withEmptyState) return null

        return (
            <NoVehicles />
        )
    }

    return (
        <div>
            {user.vehicles && user.vehicles.length > 0 ? (
                <>
                    <div className="col-12" style={{ padding: 0 }}>
                        <div className="p-mob-only">
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 18,
                                    paddingLeft: 10,
                                    flexDirection: "row",
                                }}
                                className="text"
                            >
                                <span
                                    className={"d-none d-sm-block"}
                                    style={{ paddingRight: 15, whiteSpace: 'nowrap' }}
                                >
                                    {t("certifications:select")}:
                                </span>
                                <a
                                    className="btn text-left"
                                    onClick={() =>
                                        toggleDropdown(!dropDown)
                                    }
                                    style={dropDownButtonStyle}
                                >
                                    {selectedCar
                                        ? `${selectedCar.year} ${selectedCar.make} (${selectedCar.plate})`
                                        : "Select"}

                                    <div
                                        aria-hidden={true}
                                        style={{
                                            width: 0,
                                            height: 0,
                                            borderLeft:
                                                "5px solid transparent",
                                            borderRight:
                                                "5px solid transparent",
                                            borderTop: "6px solid green",
                                            marginLeft: "10px",
                                        }}
                                    />
                                </a>
                            </div>

                            {dropDown ? (
                                <div
                                    style={dropDownCard}
                                    className="col-8 col-lg-6"
                                >
                                    {user.vehicles &&
                                        user.vehicles.map((v) => {
                                            return (
                                                <button
                                                    className="hoverable"
                                                    role="text"
                                                    style={dropDownItem}
                                                    onClick={() =>
                                                        editSelectedCar(v) +
                                                        toggleDropdown(
                                                            false
                                                        )
                                                    }
                                                >
                                                    <div>
                                                        <img
                                                            src={carroIcon}
                                                            alt={""}
                                                            style={{
                                                                width:
                                                                    "30px",
                                                                margin:
                                                                    "0 15px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={itemInfo}>
                                                        <div
                                                            style={{
                                                                display:
                                                                    "flex",
                                                                flexDirection:
                                                                    "column",
                                                                justifyContent:
                                                                    "flex-start",
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                        >
                                                            <Text>
                                                                {v.year}
                                                            </Text>
                                                            <Text>
                                                                {v.make}
                                                                &nbsp;(
                                                                {v.plate})
                                                            </Text>
                                                        </div>
                                                    </div>
                                                </button>
                                            );
                                        })}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <VehicleCertifications vehicle={selectedCar} />
                </>
            )
                :
                <RenderNoVehicles />
            }
        </div>
    )
}

SelectableVehicleCertifications.propTypes = {
    withEmptyState: PropTypes.bool
}

const dropDownButtonStyle = {
    backgroundColor: "none",
    border: "none",
    paddingLeft: 0,
    fontSize: "18px",
    color: Colors.gray,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
};

const dropDownCard = {
    maxWidth: '300px',
    position: "absolute",
    zIndex: "999",
    backgroundColor: "white",
    padding: 0,
    marginBottom: "15px",
    borderRadius: 5,
    boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
};

const dropDownItem = {
    width: "100%",
    padding: "15px",
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
    border: "none",
};

const itemInfo = {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 0,
};

export default SelectableVehicleCertifications