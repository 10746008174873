import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import Button from "../ui/Button";
import { Colors } from "../../Colors";
import PropTypes from "prop-types";
import iconBack from "../../assets/images/arrow-back-icon.svg";
import iconCancel from "../../assets/images/icon_Cancelar.svg";
import ConfirmationModal from "../ui/ConfirmationModal";

function backRoute(history) {
    history.goBack();
}

//DEPRECATED
const WizardFooter = (props) => {
    const history = useHistory();
    const [confirmationModal, toggleConfirmation] = useState(false);

    // For last item in wizard use singleButton with finish text
    const { singleButton, hideBack, nextText, customBackAction } = props;

    const { t } = useTranslation();

    const cancelAction = () => {
        if (props.cancelAction) {
            props.cancelAction();
        } else {
            history.push("/landing");
        }
    };

    const handleBack = () => {
        if (customBackAction) {
            customBackAction()
            return
        }

        backRoute(history)
    }

    return (
        <>
            {/* hasFooter(history)  */}
            <div
                className={"d-flex justify-content-between"}
                style={{ width: "100%", padding: "20px 0 20px 0" }}
            >
                {singleButton || (
                    <button
                        onClick={() => toggleConfirmation(true)}
                        className="btn-text btn-text-primary d-flex align-items-center text"
                        style={{
                            border: "none",
                            color: Colors.darkGray,
                            margin: "0 25px",
                            marginLeft: 0,
                            paddingLeft: 0,
                        }}
                        role="button"
                        aria-label={t("wizardFooter:aria.cancel")}
                    >
                        <img
                            aria-hidden="true"
                            src={iconCancel}
                            style={{ marginRight: "10px" }}
                            alt=""
                        />
                        {t("wizardFooter:cancel")}
                    </button>
                )}

                <div
                    className="d-flex justify-content-end"
                    style={{ width: "100%", alignItems: 'center' }}
                >
                    {singleButton || hideBack || (
                        <button
                            onClick={() => handleBack()}
                            className="btn-text btn-text-primary d-flex mr-3"
                            style={{
                                border: "none",
                                color: Colors.primary,
                            }}
                            role="button"
                            aria-label={t("wizardFooter:aria.back")}
                        >
                            <img src={iconBack} alt="" aria-hidden="true" />
                            <span
                                className="d-none d-lg-flex ml-2"
                                style={{ alignItems: "center" }}
                            >
                                {t("wizardFooter:back")}
                            </span>
                        </button>
                    )}

                    <Button
                        disabled={props.disabled}
                        onClick={props.handleNext}
                        className="wizard-footer text btn-primary"
                        label={t("wizardFooter:aria.continue")}
                    >
                        {nextText ? nextText : t("wizardFooter:continue")}
                    </Button>
                </div>
            </div>

            {confirmationModal && (
                <ConfirmationModal
                    action={cancelAction}
                    toggle={toggleConfirmation}
                    text={t("wizardFooter:cancelConfirmation")}
                    title={t("wizardFooter:cancel")}
                />
            )}
        </>
    );
};

WizardFooter.propTypes = {
    disabled: PropTypes.bool,
    nextText: PropTypes.string,
    handleNext: PropTypes.func,
    cancelAction: PropTypes.func,
    singleButton: PropTypes.bool,
    hideBack: PropTypes.bool,
};

export default WizardFooter;
