import React, { useState } from "react";

import Checkbox from "../ui/Checkbox";
import Text from "../ui/Text";
import FineTypeIcon from "../ui/FineTypeIcon";
import * as Utils from "../../core/Utils";
import { formatCurrencyLabel } from "../../core/Utils";
import Skeleton from "react-skeleton-loader";
import { Colors } from "../../Colors";
import { FontWeight } from "../ui/FontSize";
import _ from "lodash";
import { fineRemove, fineRemoveAutoexpreso, fineSelected, fineSelectedAutoexpreso } from "../../redux/fines/actions";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Collapse } from "reactstrap";
import { useTranslation } from "react-i18next";
import DateSquare from "../ui/DateSquare";
import iconAutoexpreso from "../../assets/images/icon-multas-autoexpreso.svg";

const AutoexpresoFineListItem = (props) => {
    const [dropDown, handleDropDown] = useState(false);
    const dispatch = useDispatch();
    const acceptPayment = useSelector(s => s?.features?.payments?.enabled)

    const { t } = useTranslation();

    const { fine } = props

    const {
        id,
        date,
        city,
        checked,
    } = props.fine;

    const manageCheckbox = async (checked, e) => {
        if (e) e.stopPropagation();
        if (checked) dispatch(fineSelectedAutoexpreso(props.fine));
        else dispatch(fineRemoveAutoexpreso(props.fine));
    };

    const { loading } = props;

    const fineListItemStyle = {
        display: "flex",
        borderBottom: `1px solid ${Colors.lightGray}`,
        backgroundColor: dropDown ? "#eee" : null,
        color: dropDown ? "#000" : Colors.gray,
        padding: "17px",
        alignItems: "center",
    };

    let dateStr = Utils.format(date, "DD.MMM.YY");

    let amountStr = formatCurrencyLabel(fine?.amount);
    let tollStr = formatCurrencyLabel(fine?.toll);
    let totalStr = formatCurrencyLabel(fine?.total);

    return (
        <button
            id="fineListItem"
            className="accordion card-collapse hoverable"
            key={id}
            aria-label={t("fineList:fineListItem.arias.fineInWords", {
                typeStr: "autoexpreso",
                ...props.fine,
                dateStr: dateStr,
            })}
            aria-expanded={dropDown}
            aria-controls="collapse"
            style={{
                ...buttonWrapperStyle,
                border: dropDown
                    ? `solid 1px ${Colors.borderDarkGray}`
                    : 'solid 1px white',
            }}
            onClick={() => handleDropDown(!dropDown)}
        >
            <div aria-hidden={true} style={fineListItemStyle} className="justify-content-between">
                <div className="d-flex">
                    <div
                        style={fineRow}
                    >
                        {!loading ? (
                            <Checkbox
                                disabled={!acceptPayment}
                                checked={checked}
                                ariaLabel={t(
                                    "fineList:fineListItem.arias.selectFine"
                                )}
                                handleCheckbox={manageCheckbox}
                            />
                        ) : (
                            <Skeleton width="1rem" />
                        )}
                    </div>

                    <div
                        aria-hidden={true}
                        style={fineRowItemGroup}
                        className="ml-3"
                    >
                        {
                            !loading
                                ? <DateSquare date={date} whiteBackground={true} />
                                : <Skeleton />
                        }
                    </div>

                    <div
                        aria-hidden={true}
                        style={{ display: "flex", alignItems: "center" }}
                        className="ml-4"
                    >
                        {
                            !loading
                                ? <div className="d-flex align-items-center">
                                    <img
                                        aria-hidden={true}
                                        alt={""}
                                        src={iconAutoexpreso}
                                        style={{ height: 16, width: 16 }}
                                        className="mr-2"
                                    />
                                    {_.capitalize(city)}
                                </div>
                                : <Skeleton />
                        }
                    </div>
                </div>

                <div
                    style={{
                        ...fineRowItemGroup,
                        justifyContent: "flex-end",
                    }}
                    aria-hidden={true}
                >
                    <Text
                        style={{
                            color: Colors.primary,
                            fontSize: "20px",
                            fontWeight: FontWeight.bold,
                            textAlign: "right",
                        }}
                    >
                        {!loading ? totalStr : <Skeleton width="2rem" />}
                    </Text>
                    <div
                        aria-hidden={true}
                        onClick={() => handleDropDown(!dropDown)}
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            height: "25px",
                            width: "25px",
                        }}
                    >
                        <div
                            aria-hidden={true}
                            className="caret-right"
                            style={{
                                transform: dropDown && "rotate(90deg)",
                                transition: "all 0.4s ease",
                            }}
                        />
                    </div>
                </div>
            </div>

            <Collapse isOpen={dropDown}>
                <section
                    role="region"
                    aria-labelledby="fineListItem"
                    id="collapse"
                    aria-expanded={dropDown}
                    aria-label={t("fineList:fineListItem.arias.autoexpresoFineDetail", {
                        ...props.fine,
                    })}
                    style={{
                        ...fineListItemStyle,
                        borderTop: dropDown
                            ? `1px solid ${Colors.borderDarkGray}`
                            : null,
                    }}
                >
                    <div
                        className="col-md-3"
                        style={fineCol}
                        aria-hidden={true}
                    >
                        <span style={fineListSmallText}>
                            {t("fineList:fineListItem.fine")}
                        </span>
                        <br />
                        <span className="text-primary text-bold">{amountStr || '-'}</span>
                    </div>

                    <div
                        className="col-md-3"
                        style={fineCol}
                        aria-hidden={true}
                    >
                        <span style={fineListSmallText}>
                            {t("fineList:fineListItem.toll")}
                        </span>
                        <br />
                        <span className="text-primary text-bold">{tollStr || '-'}</span>
                    </div>

                    <div
                        className="col-md-3"
                        style={fineCol}
                        aria-hidden={true}
                    >
                        <span style={fineListSmallText}>
                            {t("fineList:fineListItem.fineNumber")}
                        </span>
                        <br />
                        <span>{fine?.ticket || '-'}</span>
                    </div>

                    <div
                        className="col-md-3"
                        style={fineCol}
                        aria-hidden={true}
                    >
                        <span style={fineListSmallText}>
                            {t("fineList:fineListItem.law")}
                        </span>
                        <br />
                        <span>{fine?.lawNo || '-'}</span>
                    </div>
                </section>

                <div
                    style={{ ...fineListItemStyle, paddingTop: 0 }}
                    aria-hidden={true}
                >
                    <div className="col-md-12" style={fineCol}>
                        <span style={fineListSmallText}>
                            {t("fineList:fineListItem.desc")}
                        </span>
                        <br />
                        <span>
                            {_.upperFirst(_.lowerCase(fine?.law)) || '-'}
                        </span>
                    </div>
                </div>
            </Collapse>
        </button>
    );
};

const buttonWrapperStyle = {
    width: "100%",
    padding: 0,
};

const fineListSmallText = {
    fontSize: "13px",
    textTransform: "uppercase",
    color: Colors.gray,
};

const fineRow = {
    display: "flex",
    alignItems: "center",
    padding: 0,
};

const fineCol = {
    padding: 0,
    textAlign: "left",
    marginRight: "30px",
};

const fineRowItemGroup = {
    display: "flex",
    alignItems: "center",
    padding: 0,
};

export default AutoexpresoFineListItem;
