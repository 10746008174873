import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PageTitle from "../../../components/ui/PageTitle"
import WizardFooter from "../../../components/widgets/WizardFooter"
import { useTranslation } from 'react-i18next'
import { isMobile } from '../../../core/Utils'
import { useSelector } from 'react-redux'
import { tagPreboardingEligibility } from '../../../core/api-resources/tag'
import './style.css'
import HistoryBackButton from '../../../components/ui/buttons/HistoryBackButton'
import { Colors } from '../../../Colors'
import alertIcon from "../../../assets/images/icon_Warning_White.svg";
import { FontWeight } from '../../../components/ui/FontSize'

import {
    Carousel,
    CarouselItem,
    CarouselIndicators,
} from 'reactstrap';

const PreboardingInstructions = () => {

    const [eligible, setEligible] = useState(true)

    const history = useHistory()

    const { i18n, t } = useTranslation();

    const vehicle = useSelector(s => s?.electronicTag?.preboardingVehicle)
    const preBoardingRegistrationEnabled = useSelector(s => s?.features?.preBoarding?.preBoardingRegistrationEnabled)

    const preRegisteredVehicle = vehicle?.tagPreboarding

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        if (!vehicle) return history.push('/landing')
        validateEligibility()
    }, [vehicle])

    const createMarkup = (slide, height, params) => {
        return {
            __html: `<iframe 
                src='https://cesco-digital-next.web.app/vehicle/virtual-tag/${slide}?lang=${i18n.language === "en-US" ? 'en' : 'es'}${params ? params : ''}'
                style=${markupStyle(height)} 
                scrolling="no" 
             />`
        };
    }

    const mobile = isMobile()

    const markupStyle = (height = 500) => `width:100%;border:none;height:${height}px`

    const validateEligibility = async () => {

        let vehicleId = vehicle?.id

        const data = await tagPreboardingEligibility(vehicleId)

        if (data?.eligible === true) {
            setEligible(true)
        } else {
            setEligible(false)
        }
    }

    const enableFooter = !preRegisteredVehicle && preBoardingRegistrationEnabled

    const ArrowButton = (props) => {
        return (
            <button
                onClick={props.onClick}
                className='btn btn-primary d-flex align-items-center justify-content-center p-2'
                style={{ height: 40 }}
            >
                {props.icon}
            </button>
        )
    }

    const items = [
        {
            tag: 'first',
            height: mobile ? 700 : 550
        },
        {
            tag: 'second',
            height: mobile ? 700 : 650
        },
        {
            tag: 'third',
            height: mobile ? 450 : 500
        },
        {
            tag: 'fourth'
        },
        {
            tag: 'fifth',
            height: 500
        },
        {
            tag: 'sixth',
            height: mobile ? 800 : 700
        },
        {
            tag: 'seventh',
            height: mobile ? 850 : 600
        },
    ];


    const next = () => {
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        setActiveIndex(newIndex);
    };

    const slides = items.map((item) => {
        return (
            <CarouselItem
                key={item.src}
                style={{ zIndex: 0 }}
            >
                <div className="col p-0 mb-5" style={{ zIndex: 0 }}>
                    <div dangerouslySetInnerHTML={createMarkup(item.tag, item.height)} style={{ zIndex: 0 }} className='px-3 px-lg-5' />
                </div>
            </CarouselItem>
        );
    });

    const controlStyle = mobile ? { top: '20vh' } : { top: '25vh' }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="mb-3">
                        <PageTitle i18nKey={"preboarding:title"} />
                    </div>

                    {
                        !eligible &&
                        <div
                            className='p-3 rounded text-white d-flex align-items-center'
                            style={{ backgroundColor: Colors.error }}
                        >
                            <div className='mr-3'>

                                <img
                                    src={alertIcon}
                                    alt={""}
                                    aria-hidden={true}
                                    style={{ height: 24 }}
                                />
                            </div>
                            <span style={{ fontWeight: FontWeight.medium }}>{t("preboarding:notEligible")}</span>
                        </div>
                    }

                </div>
            </div>

            <div style={{ position: 'relative' }}>
                <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                    slide={true}
                    interval={0}
                >
                    <div style={{ position: 'absolute', zIndex: 10000, width: '100%', display: 'flex', justifyContent: 'space-between', ...controlStyle }}>
                        <ArrowButton onClick={previous} icon={
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="white" height={20} className='mb-0'>
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                            </svg>
                        } />
                        <ArrowButton onClick={next} icon={
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="white" height={20} className='mb-0'>
                                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                            </svg>
                        } />
                    </div>
                    {slides}
                    <CarouselIndicators
                        items={items}
                        activeIndex={activeIndex}
                        onClickHandler={goToIndex}
                        className='carousel-indicator'
                    />

                </Carousel>
            </div>

            {
                enableFooter ?
                    <div className="row">
                        <div className="col">
                            <WizardFooter
                                disabled={!vehicle || !eligible}
                                handleNext={() => history.push('/preboarding')}
                            />
                        </div>
                    </div>
                    :
                    <HistoryBackButton />
            }
        </div>
    )
}

export default PreboardingInstructions