import {
    ANSWERED_QUESTIONS,
    FETCH_RENEWAL_CHARGES,
    FETCH_RENEWAL_ELIGIBILITY,
    FETCH_VOUCHER_DATA,
    MISSING_DOCS,
    REAL_ID_VALUE
} from "./actions";

const defaultState = {
    realId: false,
    activeRenewalProcess: false,
    eligible: false,
    eligibleRequirements: [],
    missingDocs: [],
    haveMissingDocs: false,
    voucherData: null,
    renewalForm: null,
    realIdToggle: false
};

export function renewal(state = defaultState, action) {

    let {payload} = action;
    switch (action.type) {
        case FETCH_RENEWAL_ELIGIBILITY:
            return {
                ...state,
                activeRenewalProcess: true, ...payload,
                renewalForm: {
                    realId: payload.realId,
                    donor: payload.donor,
                    selectiveServiceCandidate: payload.selectiveServiceCandidate
                }
            };
        case ANSWERED_QUESTIONS:
            return {...state, renewalForm: {...state.renewalForm, ...payload}};
        case FETCH_RENEWAL_CHARGES:
            return {...state, fees: payload};
        case REAL_ID_VALUE:
            return {...state, renewalForm: {...state.renewalForm, realId: payload}, realIdToggle: !payload};
        case MISSING_DOCS:
            return {
                ...state,
                missingDocs: payload,
                haveMissingDocs: payload && payload.length > 0
            };
        case FETCH_VOUCHER_DATA:
            return {...state, voucherData: payload};
        default:
            return state;
    }
}