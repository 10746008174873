import React, {useEffect, useState} from "react";
import identificacion from "../../assets/images/icon-identificacion.svg";
import starRealId from "../../assets/images/icon-star-realid.svg";
import under21 from "../../assets/images/icon-under-21.svg";
import under21Es from "../../assets/images/icon-under-21_ES.svg";
import licenciaExtranjero from "../../assets/images/icon-licencia-extranjero.svg";
import licencia from "../../assets/images/icon-licencia.svg";
import vehiculo from "../../assets/images/icon_Vehiculo.svg";
import veteran from "../../assets/images/icon_Veteran.svg";
import veteranEs from "../../assets/images/icon_Veteran_ES.svg";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const IdentityBadge = (props) => {
    const history = useHistory();
    const {t, i18n} = useTranslation();
    const [lang, setLang] = useState(i18n.language);

    const user = useSelector((state) => state?.login?.user);

    useEffect(() => setLang(i18n.language), [i18n.language]);

    const handleIdentityBadgeClick = (userType) => {
        //TODO: check this logic
        let vehicleId = user?.vehicles[0]?.id;
        switch (userType) {
            case "LICENSE":
            case "LEY97":
            case "ID":
                return () => history.push("/profile");
            default:
                return () => history.push(`/vehicle-details/${vehicleId}`);
        }
    };

    return (
        <div
            style={identityBadgeStyle}
            role={"button"}
            aria-label={t("main:identityHeader.badge")}
            onClick={handleIdentityBadgeClick(user?.license?.type)}
        >
            {props.veteran &&
            (lang === "es" ? (
                <img
                    aria-hidden={true}
                    src={veteranEs}
                    style={under21Style}
                    alt={""}
                />
            ) : (
                <img
                    aria-hidden={true}
                    src={veteran}
                    style={under21Style}
                    alt={""}
                />
            ))}
            {props.realId && (
                <img
                    aria-hidden={true}
                    src={starRealId}
                    style={starRealIdStyle}
                    alt={""}
                />
            )}
            {props.under21 &&
            (lang === "es" ? (
                <img
                    aria-hidden={true}
                    src={under21Es}
                    style={under21Style}
                    alt={""}
                />
            ) : (
                <img
                    aria-hidden={true}
                    src={under21}
                    style={under21Style}
                    alt={""}
                />
            ))}

            {props.identityType === "LEY97" ? (
                <img
                    src={licenciaExtranjero}
                    style={imageStyle}
                    aria-hidden={true}
                    alt={""}
                />
            ) : props.identityType === "ID" ? (
                <img
                    src={identificacion}
                    style={imageStyle}
                    aria-hidden={true}
                    alt={""}
                />
            ) : props.identityType === "LICENSE" ? (
                <img
                    src={licencia}
                    style={imageStyle}
                    aria-hidden={true}
                    alt={""}
                />
            ) : (
                <img
                    src={vehiculo}
                    style={imageStyle}
                    aria-hidden={true}
                    alt={""}
                />
            )}
        </div>
    );
};

const imageStyle = {
    position: "absolute",
    width: "100%",
    zIndex: 0,
    cursor: "pointer",
};

const identityBadgeStyle = {
    width: 60,
    height: 60,
    position: "relative",
    cursor: "pointer",
};

const starRealIdStyle = {
    position: "absolute",
    right: -10,
    top: 4,
    zIndex: 1,
    width: "28px",
    cursor: "pointer",
};

const under21Style = {
    position: "absolute",
    bottom: 0,
    width: "100%",
    zIndex: 1,
    cursor: "pointer",
    height: '18px'
};

export default IdentityBadge;
