import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../../Colors'

// import organDonorImg from "../../../assets/images/organ-donor/icon_DonanteOrgano_Si.svg"
import organDonorImg from "../../../assets/images/organ-donor/icon_DonanteOrgano_Si2.svg"
// import noDonorImg from "../../../assets/images/organ-donor/icon_DonanteOrgano_No.svg"
import noDonorImg from "../../../assets/images/organ-donor/icon_DonanteOrgano_No2.svg"

import { useSelector } from 'react-redux'

const DonorDashboardCard = () => {

    const isDonor = useSelector(s => s?.card?.personalInfo?.donor)

    const { t } = useTranslation()

    return (
        <div
            style={courseChartStyle}
            className="d-flex flex-column-reverse flex-lg-row justify-content-between align-items-center"
        >
            <span
                style={{ color: Colors.gray }}
                className="text">{t("organDonor:dashboardCard.0")}
                <br />
                {t("organDonor:dashboardCard.1")}
            </span>
            <div>
                <img
                    src={isDonor ? organDonorImg : noDonorImg}
                    className="sub-header-icon"
                    alt=""
                    aria-hidden
                />
            </div>
        </div>
    )
}
const courseChartStyle = {
    width: '100%',
    height: '100%',
    lineHeight: 1,
}

export default DonorDashboardCard