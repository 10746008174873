import { api, handleResponse } from "../APICaller";
import store from '../../store.js'

export const postVehicleLicense =async (payload, onSuccess, onError) => {

    let recaptcha = store.getState().recaptcha;
    let config = { headers: { 'recaptcha-token': recaptcha.token } };

    const { total, registrationNumber, controlNumber } = payload
    await api.get('/token');
    return api.post(
        `/notiValidation`,
        {
            total: total,
            registrationNumber: registrationNumber,
            controlNumber: controlNumber
        },
        config
    )
        .then((response) => handleResponse(response, onSuccess, onError));
};
