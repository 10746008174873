import React, {useState} from "react";
import InputMask from 'react-input-mask';
import moment from "moment";
import {validateCardExpiry} from "creditcardutils";
import {Colors} from "../../Colors";


const MonthAndYearInput = (props) => {

    const [value, handleValue] = useState(props?.value || '');
    const [error, setError] = useState(false);

    const innerInputHandler = (valueRaw) => {
        handleValue(valueRaw);
        let formatted = (valueRaw && valueRaw.replace(/\s/g, '')) || 'empty';
        if (formatted && formatted.length === 5) {
            let dateParsed = moment(`01/${formatted}`, "DD/MM/YY");
            let month = dateParsed.format('MM');
            let year = dateParsed.format('YY');
            let valid = moment(`01/${formatted}`, "DD/MM/YY").isValid() && validateCardExpiry(month,year);
            if (valid) {
                props.onValid({month: month, year: year})
                setError(false)
                props.toggleError(props.errorToggler, false)
            }
        }else{
            props.onValid({month: null, year: null})
            setError(true)
            props.toggleError(props.errorToggler, true)
        }
    };

    return (
        <InputMask {...props}
                   mask="99/99"
                   maskChar=" "
                   onChange={(e) => innerInputHandler(e.target.value)}
                   value={value}
                   style={{
                       ...props.style,
                       border: error && `1px solid ${Colors.error}`,
                       backgroundColor: error && Colors.errorBg,
                       color: error && Colors.errorDark
                   }}
        />
    )
};

export default MonthAndYearInput;
