import React from "react";
import Text from "../ui/Text";
import DeleteIcon from "../../assets/images/icon_Delete.svg";
import { formatCurrencyLabel } from "../../core/Utils";
import { Colors } from "../../Colors";
import _ from "lodash";
import DateSquare from "../ui/DateSquare";
import ReactTooltip from "react-tooltip";
import { FontWeight } from "../ui/FontSize";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const PaymentFineListItem = props => {

    const { autoexpreso, handleRemoveFine, renewal, fine } = props;
    const { date, city, amount, discount, surcharge, total } = props.fine;

    const { t } = useTranslation()

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "20px",
                borderBottom: `1px solid ${Colors.lightGray}`
            }}
            className="hoverable"
        >
            <div className="col-md-2 d-flex align-items-center" style={listItem}>
                <DateSquare date={date} whiteBackground={true} />
            </div>
            <div className="col-md-5"
                style={{ listItem, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Text className="text">
                    {_.capitalize(city)}
                </Text>
                {discount &&
                    <span className="badge badge-pill badge-primary mt-1"
                        style={{ backgroundColor: Colors.primary, height: 20, maxWidth: 160 }}>
                        <Text className="text-sm white" i18nKey={"fineList:fineListItem.discount"} />&nbsp;
                        <Text className="text-sm white">{discount || ""}</Text>
                    </span>
                }
            </div>

            <div
                className="col-md-4"
                style={{
                    ...listItem,
                    display: "flex",
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-end'
                }}
                aria-hidden="true"
            >
                {
                    !autoexpreso &&
                    <div className="d-flex" style={{ height: 20, fontWeight: FontWeight.medium }}>
                        <Text className="text-sm">{formatCurrencyLabel(amount)}</Text>&nbsp;
                        <Text className="text-sm">+</Text>&nbsp;
                        <Text className="text-sm" style={{ color: Colors.blue }}>{formatCurrencyLabel(surcharge)}</Text>
                    </div>
                }

                <Text
                    style={{
                        color: "green",
                        fontSize: "20px",
                        fontWeight: "bold"
                    }}
                >
                    {formatCurrencyLabel(total)}
                </Text>
            </div>

            {renewal ? null : (
                <div
                    className="col-md-1"
                    style={{
                        ...listItem,
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                >
                    <img
                        src={DeleteIcon}
                        alt={"boton para eliminar multa a ser pagada"}
                        style={{ width: "24px" }}
                        onClick={() => handleRemoveFine(fine)}
                    />
                </div>
            )}
        </div>
    );
};

PaymentFineListItem.propTypes = {
    autoexpreso: PropTypes.bool,
    handleRemoveFine: PropTypes.func.isRequired,
    fine: PropTypes.object.isRequired,
    renewal: PropTypes.bool
};


const listItem = {
    padding: 0
};

export default PaymentFineListItem;
