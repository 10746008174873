import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { LOADING_START, LOADING_END } from '../../redux/loading/loadingState.combine'

const DtopCourse = () => {
    const [name, setName] = useState(null)
    const [license, setLicense] = useState(null)
    const [provider, setProvider] = useState(null)

    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const queryName = params.get("name")
    const queryLicense = params.get("license")
    const queryProvider = params.get("provider")

    const history = useHistory()
    const dispatch = useDispatch()

    const { i18n } = useTranslation();

    useEffect(() => {

        handleSubmit()
    }, [name, license, provider])

    const handleSubmit = async () => {

        dispatch({ type: LOADING_START });

        queryName && setName(queryName)
        queryLicense && setLicense(queryLicense)
        queryProvider && setProvider(queryProvider)

        if (queryName || queryLicense || queryProvider) {

            // If query params submit form to courses
            submitForm()
        } else {

            // setTimeout(() => {
            //     dispatch({ type: LOADING_END });
            //     history.push('/landing')
            // }, 500)

            // window.open(process.env.REACT_APP_RECIPROCIDAD_LINK)

            window.location = process.env.REACT_APP_RECIPROCIDAD_LINK
        }

        dispatch({ type: LOADING_END });

    }

    const submitForm = () => {
        let form = document.getElementById('courses-form')

        setTimeout(() => {
            history.push('/landing')
        }, 1500)


        form.submit()
    }

    return (
        <>
            {/* Hidden form */}
            <div className="container">
                <form
                    id="courses-form"
                    name="courses-form"
                    action={process.env.REACT_APP_COURSES_HIDDEN_FORM_LINK}
                    method="post"
                // target="_blank"
                >
                    <input
                        type="hidden"
                        name="license"
                        value={license}
                    />
                    <input
                        type="hidden"
                        name="name"
                        value={name}
                    />
                    <input
                        type="hidden"
                        name="provider"
                        value={provider}
                    />
                    <input
                        type="hidden"
                        name="language"
                        value={i18n.language == "en-US" ? 'en' : 'es'}
                    />
                </form>
            </div>
        </>
    )
}

export default DtopCourse;