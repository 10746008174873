import {api, handleResponse} from "../APICaller";

export function fetchPaymentHistory(onSuccess, onError) {
    return api
        .get('/payment/history')
        .then((response) => handleResponse(response, onSuccess, onError));
}

export function fetchPaymentHistoryDetail(transactionId, onSuccess, onError) {
    return api
        .get(`/payment/history/${transactionId}`)
        .then((response) => handleResponse(response, onSuccess, onError));
}

export function fetchPaymentHistoryDetailAutoexpreso(transactionId, onSuccess, onError) {
    return api
        .get(`/cd-fines/autoexpreso/payment/history/${transactionId}`)
        .then((response) => handleResponse(response, onSuccess, onError));
}

export function fetchPaymentFineDetails(fineId) {
    alert('under development ')
}

export function calculateFees(payload, onSuccess, onError) {
    return api.post('/payment/total', payload)
    // .then((response) => handleResponse(response, onSuccess, onError));
}

export function calculateRenewalFees(payload, onSuccess, onError) {
    return api.post('/payment/total', {realId: true})
    // .then((response) => handleResponse(response, onSuccess, onError));
}

export function paymentCompleteV1(payload, onSuccess, onError) {
    return api.post('/payment/complete', payload)
        .then((response) => handleResponse(response, onSuccess, onError));
}

export function paymentComplete(payload, onSuccess, onError) {
    return api.post('/payment/v2/complete', payload)
        .then((response) => handleResponse(response, onSuccess, onError));
}

export function resendReceipt(transactionId, onSuccess, onError) {
    return api
        .get(`/payment/resendReceipt/${transactionId}`)
        .then((response) => handleResponse(response, onSuccess, onError));
}