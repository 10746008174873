import React from 'react';
import {Colors} from "../../Colors";
import {FontWeight} from "./FontSize";
import * as Utils from "../../core/Utils";
import PropTypes from 'prop-types'

const DateSquare = (props) => {

    const {date, expiredStyle} = props

    const day = Utils.format(date, "DD")
    const month = Utils.format(date, "MMM").toUpperCase()
    const year = Utils.format(date, "YYYY")

    const grayed = props.grayed;
    const error = props.error;
    const whiteBackground = props.whiteBackground;

    const expired = (expiredStyle && Utils.isTimestampExpired(date))

    return (
        <div style={dateSquareStyle(expired, error, grayed, whiteBackground)}>
            <span style={{fontSize: 12}}>{month || 'N/A'}</span>
            <span style={{fontSize: 16, fontWeight: FontWeight.bold}}>{day || '00'}</span>
            <span style={{fontSize: 11, fontWeight: FontWeight.medium}}>{year || '0000'}</span>
        </div>
    );
};

const dateSquareStyle = (expired, error, grayed, whiteBackground) => ({
    width: 45,
    height: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${(expired || error) ? Colors.error : grayed ? Colors.gray : Colors.primary}`,
    borderRadius: 4,
    color: (expired || error) ? Colors.error : grayed ? Colors.gray : Colors.primary,
    backgroundColor: (grayed || whiteBackground) && Colors.white
})

DateSquare.propTypes = {
    date: PropTypes.number,
    expiredStyle: PropTypes.bool,
    error: PropTypes.bool,
    grayed: PropTypes.bool,
    whiteBackground: PropTypes.bool,
}

export default DateSquare;