import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Colors } from '../../../Colors'
import PageTitle from '../../../components/ui/PageTitle'
import Text from '../../../components/ui/Text'
import WizardFooter from '../../../components/widgets/WizardFooter'
import SubHeader from '../../../layout/SubHeader'
import InsuranceOptionCard from '../components/InsuranceOptionCard'
import '../index.css'

import basicInsuranceArt from '../../../assets/images/acaa/ACAA_02b.svg'
import basicInsuranceArtEn from '../../../assets/images/acaa/ACAA_02_EN.svg'
import plusInsuranceArt from '../../../assets/images/acaa/ACAA_03b.svg'
import premiumInsuranceArt from '../../../assets/images/acaa/ACAA_04b.svg'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { setSelectedInsurance } from '../../../redux/insurance/insurance.combine'
import { useDispatch, useSelector } from 'react-redux'
import useFirebase from '../../../utils/firebaseDbRef'
import Radio from '../../../components/ui/Radio'
import { renderInsuranceStars } from '../../../utils/renderInsuranceStars'
import Modal from 'reactstrap/lib/Modal'
import VehicleInfoRow from '../components/VehicleInfoRow'
import { RenderPolicyWithArt } from '../../../utils/renderPolicyWithArt'
import InstructionsModal from '../Total/InstructionsModal'

const InsuranceSelection = () => {

    const [selected, setSelected] = useState(null)
    const [modal, toggleModal] = useState(false)

    const [data, setData] = useState(null)

    const lang = useTranslation()?.i18n?.language
    const history = useHistory()
    const dispatch = useDispatch()

    const selectedVehicle = useSelector(s => s?.insurance?.selectedVehicle)

    const policies = useSelector(s => s?.features?.acaa?.policy)

    const [instructionsModal, setInstructionsModal] = useState(true)

    const showAcaaInstructions = useSelector(s => s?.features?.vehicleRenewal?.showAcaaInstructions);

    useEffect(() => {
        if (!selectedVehicle) history.push('/landing')
    }, [selectedVehicle])

    // Fetch firebase insurance data
    useEffect(() => {
        policies && dataWithArt()
    }, [policies])

    const dataWithArt = () => {

        const withArt = RenderPolicyWithArt(policies, lang)
        setData(withArt)
    }

    const handleNext = async () => {

        await dispatch(setSelectedInsurance(selected))
        history.push('/insurance/total')
    }

    return (
        <div>
            <SubHeader>
                <div className="container">
                    <PageTitle
                        style={{ fontWeight: "bold" }}
                        i18nKey={"insuranceSelection:title"}
                    />
                    <div className="d-none d-lg-flex">
                        <hr />
                    </div>
                    <Text
                        i18nKey="insuranceSelection:subTitle"
                        className="text"
                        style={{ color: Colors.gray }}
                    />
                </div>
            </SubHeader>

            <Modal
                isOpen={modal}
                size="md"
                style={{ padding: 0 }}
                toggle={() => toggleModal(!modal)}
            >
                <InsuranceOptionCard
                    selected={selected}
                    setSelected={setSelected}
                    o={selected}
                    modal
                    toggleModal={() => toggleModal(!modal)}
                />
            </Modal>

            <div className='container'>

                <div className="row mb-3">
                    <div className="col">
                        <VehicleInfoRow vehicle={selectedVehicle} />

                        <div>
                            <hr />
                        </div>
                    </div>
                </div>

                <div className='row d-none d-lg-flex'>
                    {
                        data && data?.length > 0 && data.map((o, i) => (
                            <InsuranceOptionCard
                                selected={selected}
                                setSelected={setSelected}
                                index={i}
                                o={o}
                            />
                        ))
                    }
                </div>

            </div>

            <div className="d-flex d-lg-none flex-column mb-3">
                {
                    data && data?.length > 0 && data.map((o, i) => (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderTop: i === 0 ? `1px solid ${Colors.borderGray}` : 'none',
                                borderBottom: `1px solid ${Colors.borderGray}`
                            }}
                            className="px-3 py-4 hoverable"
                            onClick={() => {
                                setSelected(o)
                                toggleModal(true)
                            }}
                        >
                            <div className='d-flex mr-3 w-100'>
                                <div className="mr-3">
                                    <Radio state={o?.id === selected?.id} />
                                </div>
                                <div> {o?.locales?.[lang === "es-US" ? "es" : "en"]} </div>
                            </div>

                            <div style={{ width: '100%', alignItems: 'flex-start' }}>{renderInsuranceStars(o?.rating)}</div>

                            <div className='d-flex'>
                                <div className='mr-2 text-bold' style={{ color: Colors.primary }}>${o?.yearCost}.00</div>
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ height: 20, width: 20 }} fill="none" viewBox="0 0 24 24" stroke={Colors.primary} >
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M9 5l7 7-7 7" />
                                </svg>
                            </div>
                        </div>
                    ))
                }
            </div>

            {
                instructionsModal && !showAcaaInstructions && 
                <InstructionsModal
                    toggle={() => setInstructionsModal(false)}
                />
            }


            <div className="container">
                <WizardFooter
                    disabled={!selected?.id}
                    handleNext={() => handleNext()}
                />
            </div>


        </div >

    )
}


export default InsuranceSelection