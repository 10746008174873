import React from "react";

import SubHeader from "../../../layout/SubHeader";
import Text from "../../../components/ui/Text";
import { Colors } from "../../../Colors";
import PageTitle from "../../../components/ui/PageTitle";
import { FontWeight } from "../../../components/ui/FontSize";
import ToggleThreeWay from "../../../components/ui/ToggleThreeWay";

import iconAddress from "../../../assets/images/icon-address.svg";
import WizardFooter from "../../../components/widgets/WizardFooter";
import { useState } from "react";

const Address = () => {
    const [toggle, setToggle] = useState(1);

    return (
        <>
            <SubHeader>
                <div className="container">
                    <PageTitle
                        style={{ fontWeight: "bold" }}
                        i18nKey={"address:pageTitle"}
                    />
                    <div className="d-none d-lg-flex">
                        <hr />
                    </div>
                    <Text
                        className="text"
                        i18nKey="address:subTitle"
                        style={{ color: Colors.gray }}
                    />
                </div>
            </SubHeader>

            <div className="container renewal-address" style={body}>
                <div style={{ display: "flex" }}>
                    <img
                        src={iconAddress}
                        style={{ width: "120px", marginRight: "10px" }}
                        aria-hidden={true}
                        alt=""
                    />
                    <div style={addressCol}>
                        <Text
                            i18nKey="address:questionTitle"
                            className="text-md"
                            style={{ fontWeight: FontWeight.medium }}
                        />
                        <div style={addressBlock}>
                            <Text
                                className="text"
                                style={{ color: Colors.gray }}
                            >
                                Metro Plaza Tower
                            </Text>
                            <Text
                                className="text"
                                style={{ color: Colors.gray }}
                            >
                                1234 Ave Ponce de Leon Apt 12345
                            </Text>
                        </div>
                    </div>
                </div>

                <div className="renewal-address-toggle">
                    <ToggleThreeWay state={toggle} setState={setToggle} />
                </div>
            </div>

            <div className="container" style={disclaimerBody}>
                <Text
                    i18nKey="address:disclaimerTitle"
                    className="text"
                    style={{ color: Colors.gray, textTransform: "uppercase" }}
                />
                <Text
                    i18nKey="address:disclaimerBody"
                    className="text-sm"
                    style={{ color: Colors.gray }}
                />
            </div>

            <div className="container">
                <WizardFooter hideBack/>
            </div>
        </>
    );
};

const disclaimerBody = {
    display: "flex",
    flexDirection: "column",
    borderBottom: `1px solid ${Colors.borderGray}`,
    paddingBottom: "20px",
    marginBottom: "20px",
};

const body = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    borderBottom: `1px solid ${Colors.borderGray}`,
    paddingBottom: "20px",
};

const addressCol = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
};

const addressBlock = {
    display: "flex",
    flexDirection: "column",
};

export default Address;
