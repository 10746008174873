import React, { useEffect, useState } from 'react'
import Button from "../../../components/ui/Button"
import PageTitle from '../../../components/ui/PageTitle'
import SubHeaderText from '../../../components/ui/SubHeaderText'
import Text from '../../../components/ui/Text'
import SubHeader from '../../../layout/SubHeader'

import { useHistory } from 'react-router-dom'
import PreboardingResultAnimation from '../../../assets/animations/PreboardingResult'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getTagPreboardingData, setPreboardingVehicle, setTagPreboardingRevalidationModal } from '../../../redux/electronicTag/electronicTag.combine'
import { Colors } from '../../../Colors'

const PreboardingResult = () => {

    const history = useHistory()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const vehicle = useSelector(s => s?.electronicTag?.preboardingVehicle)
    const preboarding = vehicle?.preboarding

    useEffect(() => {
        if (!vehicle) history.push('/landing')
    }, [vehicle])

    const [error, setError] = useState(false)
    const [pending, setPending] = useState(false)

    useEffect(() => {
        if (preboarding?.status === "pending") {
            setPending(true)
        }
    }, [preboarding])

    const handleNext = () => {

        if (pending) {
            dispatch(setTagPreboardingRevalidationModal(true))
            history.push(`/vehicle-details/${vehicle?.id}`)
            return
        }

        dispatch(setPreboardingVehicle(null))
        dispatch(getTagPreboardingData())

        history.push('/landing')
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <PageTitle
                            style={{ fontWeight: "bold" }}
                            i18nKey={"preboarding:title"}
                        />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row py-5">
                    <div className="col">
                        <div className="d-flex flex-column align-items-center">
                            <PreboardingResultAnimation
                                pending={preboarding?.status === "pending"}
                            />

                            <div className="my-5">
                                {
                                    pending ?
                                        <Text
                                            className="text-bold text-uppercase text-md"
                                            i18nKey={"preboarding:result.pending"}
                                        />
                                        :
                                        <Text
                                            className="text-bold text-uppercase text-md"
                                            i18nKey={"preboarding:result.success"}
                                        />
                                }
                            </div>

                            {
                                pending &&
                                <div className="mb-5">
                                    <Text
                                        style={{ color: Colors.gray }}
                                        i18nKey={"preboarding:result.body.0"}
                                    />
                                    <strong> <span>{preboarding?.tagNumber?.slice(5) || '-'}</span> </strong>
                                    <Text
                                        style={{ color: Colors.gray }}
                                        i18nKey={"preboarding:result.body.1"}
                                    />
                                </div>
                            }
                            <Button
                                onClick={() => handleNext()}
                                className={"btn btn-primary"} disabled={false}>{t("preboarding:finish")}</Button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default PreboardingResult