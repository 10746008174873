import React from "react";

import iconMultasAutoExpreso from "../../assets/images/icon-multas-autoexpreso.svg";
import iconMultasLicencia from "../../assets/images/icon-multas-licencia.svg";
import iconMultasVehiculo from "../../assets/images/icon-multas-vehiculos.svg";

const FineTypeIcon = props => {
    const type = props.type;
    const iconObj = icon(type);
    return (
        <img
            aria-hidden={true}
            alt={""}
            src={iconObj.icon}
            style={{...props.style}}
        />
    );
};

const icon = type =>{
    switch (type) {
        case "a":
        case "AUTOEXPRESSO": return {icon:iconMultasAutoExpreso};
        case "l":
        case "LICENSE": return {icon:iconMultasLicencia};
        default: return {icon:iconMultasVehiculo};
    }
}

export default FineTypeIcon;
