import React, { useEffect, useState } from "react";
import BlockButton from "../../components/ui/BlockButton";
import Icon from "../../components/ui/Icon";

import iconLicenciaTablilla from "../../assets/images/icon_LicenciaTablilla.svg";
import iconSSN from "../../assets/images/icon_SSN.svg";
import iconCake from "../../assets/images/icon_Cake.svg";
import iconWarning from "../../assets/images/icon_Warning_White.svg";

import iconEye from "../../assets/images/icon-eye.svg";
import iconEyeHidden from "../../assets/images/icon-eye-hidden.svg";
import Text from "../../components/ui/Text";
import { FontSize, FontWeight } from "../../components/ui/FontSize";
import { Colors } from "../../Colors";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import TextInput from "../../components/ui/TextInput";
import * as Utils from "../../core/Utils";
import TopLoginMessage from "./TopLoginMessage";

//REGex
const LAST_SSN = /^\d{4}$/;
const LICENSE_OR_PLATE = /^\S{6,12}$/;

const LoginInputs = (props) => {
    const [license, handleLicense] = useState("");
    const [ssn, handleSsn] = useState("");
    const [ssnVisibility, toggleSsnVisibility] = useState(false);

    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [day, setDay] = useState("");
    const [dropDown, toggleDropdown] = useState(false);
    const [disabledLoginBtn, setDisabledLoginBtn] = useState(true);

    useEffect(() => {
        let paddedDay = _.padStart(day, 2, '0');
        let paddedMonth = _.padStart(month, 2, '0');
        let valid = LAST_SSN.test(ssn)
            && LICENSE_OR_PLATE.test(license)
            && Utils.validDateForDDMMYYYY_Format(`${paddedDay}${paddedMonth}${year}`)
        setDisabledLoginBtn(!valid);
    }, [license, ssn, day, month, year]);

    const handleSubmit = (e) => {
        e.preventDefault();
        props.onLogin({
            license,
            ssn,
            dateSegmented: { dayOfMonth: day, month: month, year: year },
        });
    };
    const { t } = useTranslation();
    const { loading } = props;
    return (
        <div style={{ ...props.style }}>
            <form
                className="login-input col-12 login-inputs-margin"
                style={{
                    zIndex: 1,
                    padding: 0,
                }}
                onSubmit={handleSubmit}
            >
                <div
                    style={{ marginBottom: "10px" }}
                    className="login-inputs-header"
                >
                    <Text
                        i18nKey={"login:form.title"}
                        className="text-xl"
                        style={{ color: "#fff", fontWeight: FontWeight.light }}
                    />
                </div>

                <TopLoginMessage error={props.error} problem={props.problem} />

                <div style={activeBorder}>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span
                                className="input-group-text"
                                style={{
                                    borderBottomLeftRadius: 0,
                                    backgroundColor: Colors.lightGray,
                                }}
                            >
                                <Icon src={iconLicenciaTablilla} alt={""} />
                            </span>
                        </div>
                        <input
                            type="text"
                            tabIndex={100}
                            className="form-control"
                            aria-label={t("login:form.accessibility.license")}
                            placeholder={t("login:form.license")}
                            value={license}
                            onChange={(e) => handleLicense(e.target.value)}
                            style={{
                                borderBottomRightRadius: 0,
                                height: "auto",
                                textTransform: "uppercase",
                            }}
                        />
                    </div>

                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span
                                className="input-group-text rounded-0 border-top-0 border-bottom-0"
                                style={{ backgroundColor: Colors.lightGray }}
                            >
                                <Icon src={iconSSN} alt={""} />
                            </span>
                        </div>
                        <TextInput
                            tabIndex={200}
                            type={ssnVisibility ? "text" : "password"}
                            code="lastSSN"
                            aria-required={true}
                            inputMode="numeric"
                            className="form-control border-top-0 border-bottom-0 border-right-0 rounded-0"
                            style={{
                                height: "auto",
                                fontSize: "15px",
                                fontFamily: "Open Sans",
                            }}
                            aria-label={t("login:form.accessibility.ssn")}
                            placeholder={t("login:form.ssn")}
                            maxLength={4}
                            value={ssn}
                            onChange={(e) => handleSsn(e.target.value)}
                        />
                        <div
                            className="input-group-prepend"
                            style={{
                                backgroundColor: "white",
                                marginRight: 0,
                                paddingRight: "15px",
                                borderRight: `1px solid ${Colors.borderDarkGray}`,
                                borderLeft: '1px solid white'
                            }}
                        >
                            <img
                                className="pointer"
                                src={ssnVisibility ? iconEyeHidden : iconEye}
                                alt={""}
                                onClick={() =>
                                    toggleSsnVisibility(!ssnVisibility)
                                }
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>

                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span
                                className="input-group-text rounded-0"
                                style={{ backgroundColor: Colors.lightGray }}
                            >
                                <Icon src={iconCake} alt={""} />
                            </span>
                        </div>
                        <select
                            type="text"
                            tabIndex={300}
                            className="custom-select border-bottom-0 col-7 pointer"
                            aria-label={t("login:form.accessibility.month")}
                            placeholder={t("login:form.month")}
                            value={month}
                            onClick={() => toggleDropdown(!dropDown)}
                            onChange={(e) => setMonth(e.target.value)}
                            style={{
                                height: "auto",
                            }}
                        >
                            <option value="">{t("login:form.month")}</option>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                                (m, index) => (
                                    <option value={m} key={index}>
                                        {_.padStart(m, 2, "0")}&nbsp;
                                        {t(`login:form.months.${m}`)}
                                    </option>
                                )
                            )}
                        </select>

                        <TextInput
                            tabIndex={400}
                            code="day"
                            inputMode="numeric"
                            className="form-control border-bottom-0 rounded-0 col-2"
                            placeholder={t("login:form.day")}
                            value={day}
                            onChange={(e) => setDay(e.target.value)}
                            style={{
                                borderBottomRightRadius: 0,
                                height: "auto",
                                borderRight: 0,
                                paddingRight: 0,
                            }}
                        />
                        <TextInput
                            tabIndex={500}
                            code="year"
                            inputMode="numeric"
                            className="form-control border-bottom-0 rounded-0 col-3"
                            placeholder={t("login:form.year")}
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            style={{
                                borderBottomRightRadius: 0,
                                height: "auto",
                            }}
                        />
                    </div>
                    <BlockButton type="submit" loading={loading} disabled={disabledLoginBtn}>
                        <Text i18nKey={"login:form.submit"} />
                    </BlockButton>
                </div>
            </form>
        </div>
    );
};
const activeBorder = {
    boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "4px",
};

export default LoginInputs;
