import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PreboardingResultAnimation from '../../assets/animations/PreboardingResult'
import PillHeaderModal from '../ui/PillHeaderModal'
import Text from '../ui/Text'

const RevalidationModal = (props) => {

    const { toggle, vehicleId, pending, error } = props
    const { t } = useTranslation()

    const user = useSelector((state) => state?.login?.user);

    const [vehicle, setVehicle] = useState(null)

    useEffect(() => {
        const preboardedVehicle = user?.vehicles.filter(v => v.id === vehicleId)
        if (preboardedVehicle?.length > 0) setVehicle(preboardedVehicle[0])
    }, [vehicleId, user])

    const RenderText = () => {

        if (error) return ("preboarding:revalidation.error")

        if (pending) return ("preboarding:revalidation.pending.title")

        return ("preboarding:revalidation.success")
    }

    const RenderAnimation = () => {

        if (error) return <PreboardingResultAnimation error={true} />

        if (pending) return <PreboardingResultAnimation pending={true} />

        return <PreboardingResultAnimation />
    }

    return (
        <PillHeaderModal
            toggle={toggle}
            title={t("vehicleDetails:electronicTag.modal.title")}
            pillContent={vehicle?.tagPreboarding?.tagNumber}
            action={() => toggle(false)}
            actionText={t("vehicleDetails:finish")}
        >
            <div className="d-flex flex-column">
                <div className="mb-3 d-flex justify-content-center">
                    {RenderAnimation()}
                </div>

                <div className='d-flex justify-content-center'>
                    <Text
                        className="text-bold text-uppercase text-md text-center"
                        i18nKey={RenderText()}
                    />
                </div>

                {
                    pending &&
                    <div className='mt-2 text-center'>
                        <Text
                            i18nKey={"preboarding:revalidation.pending.text.0"}
                        />
                        <span><strong>{" "}{vehicle?.tagPreboarding?.tagNumber}{" "}</strong></span>
                        <Text
                            i18nKey={"preboarding:revalidation.pending.text.1"}
                        />
                    </div>
                }

            </div>
        </PillHeaderModal>
    )
}

RevalidationModal.propTypes = {
    toggle: PropTypes.func,
    vehicleId: PropTypes.string
}

export default RevalidationModal