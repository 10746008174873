import React from "react";

import PropTypes from "prop-types";

import Text from "../../../components/ui/Text";
import { Colors } from "../../../Colors";
import cescoLogo from "../../../assets/images/LogoCescoGreen.svg";
import govLogo from "../../../assets/images/Footer_LogoSelloGob.svg";
import { useTranslation } from "react-i18next";
import {format} from "../../../core/Utils";

const VoucherBody = (props) => {

    const {
        owner,
        qrImage,
        renewalDate,
        renewalPeriod,
        vehicle,
        tagType,
        insurance,
        idcNumber
    } = props

    const { t } = useTranslation();

    let qrCode = `data:image/png;base64,${qrImage}`

    return (
        <div>
            <div className="container" style={resultStyle}>
                {/*<div style={resultHeading}>
                    <div className="d-flex mt-3 ml-1">
                        <img src={cescoLogo} alt="logo" style={{ height: 50 }} />
                        <img src={govLogo} alt="logo"
                            style={{ height: 50, marginLeft: 15, borderLeft: '1px solid lightgray', paddingLeft: 15 }} />
                    </div>

                    <div className="d-none d-lg-flex">
                        <img src={qrCode} alt="QR" style={{ height: 100 }} />
                    </div>
                    <div className="d-lg-none d-flex">
                        <img src={qrCode} alt="QR" style={{ height: 60 }} />
                    </div>

                </div>*/}
        {/*        <div className="my-4 mx-3 text-center">
                    <Text className="text-bold">
                        {t("tagRenewalVoucher:pageTitle")}
                    </Text>
                </div>*/}

{/*                <div style={resultIdc}>
                    <Text className="text-md text-bold">
                        IDC#&nbsp;
                    </Text>
                    <Text className="text-md text-bold">
                        {idcNumber || 'N/A'}
                    </Text>
                </div>*/}

                {/* DESKTOP TABLE */}
                <table className="table table-bordered mb-0 d-none d-md-table">
                    <tbody className="text">
                        <tr>
                            <td scope="row">
                                <span className="text-sm" style={labelStyle}>
                                    {t("tagRenewalVoucher:name")}:
                                </span>
                                <br />
                                <span className="text-bold">
                                    {owner?.firstName || 'N/A'} {owner?.fatherLastName} {owner?.motherLastName}
                                </span>
                            </td>

                            <td align="center">
                                <span className="text-sm" style={labelStyle}>
                                    {t("tagRenewalVoucher:transactionDate")}:
                                </span>
                                <br />
                                <span className="text-bold">
                                    {format(renewalDate) || 'N/A'}
                                </span>
                            </td>

                            <td align="center">
                                <span className="text-sm" style={labelStyle}>
                                    {t("tagRenewalVoucher:validity")}:
                                </span>
                                <br />
                                <span className="text-bold">
                                    {format(renewalPeriod?.startDate) || 'N/A'}
                                    {" - "}
                                    {format(renewalPeriod?.endDate) || 'N/A'}
                                </span>
                            </td>
                        </tr>

                        <tr>
                            <td scope="row">
                                <div className="mb-1">
                                    <span className="text-sm" style={labelStyle}>
                                        {t("tagRenewalVoucher:vehicle")}:
                                    </span>
                                    <br />
                                    <span className="text-bold">
                                        {vehicle?.year || 'N/A'} {vehicle?.make} {vehicle?.color}
                                    </span>
                                    <br />
                                </div>
                                <div className="mb-1">
                                    <span className="text-sm" style={labelStyle}>
                                        {t("tagRenewalVoucher:model")}:
                                    </span>
                                    <br />
                                    <span className="text-bold">
                                        {vehicle?.model || 'N/A'}

                                    </span>
                                    <br />
                                </div>
                                <div className="d-flex">
                                    {/*<div className="mr-3">*/}
                                    {/*    <span className="text-sm" style={labelStyle}>*/}
                                    {/*        {t("tagRenewalVoucher:register")}:*/}
                                    {/*    </span>*/}
                                    {/*    <br />*/}
                                    {/*    <span className="text-bold">*/}
                                    {/*        {vehicle?.registration || 'N/A'}*/}
                                    {/*    </span>*/}
                                    {/*</div>*/}
                                    <div>
                                        <span className="text-sm" style={labelStyle}>
                                            {t("tagRenewalVoucher:plate")}:
                                        </span>
                                        <br />
                                        <span className="text-bold">
                                            {vehicle?.plate || 'N/A'}
                                        </span>
                                    </div>
                                </div>
                            </td>

                            <td style={{ verticalAlign: 'middle' }}>
                                <div className="d-flex justify-content-around">
                                    {/*<div>*/}
                                    {/*    <span className="text-sm" style={labelStyle}>*/}
                                    {/*        {t("tagRenewalVoucher:tagType")}:*/}
                                    {/*    </span>*/}
                                    {/*    <br />*/}
                                    {/*    <span className="text-bold">*/}
                                    {/*        {tagType === "STICKER"*/}
                                    {/*            ? "Pegatina"*/}
                                    {/*            : tagType || "N/A"}*/}
                                    {/*    </span>*/}
                                    {/*</div>*/}

                                    <div>
                                        <span className="text-sm" style={labelStyle}>
                                            {t("tagRenewalVoucher:expiration")}:
                                        </span>
                                        <br />
                                        <span className="text-bold">
                                            {format(renewalPeriod?.endDate) || 'N/A'}
                                        </span>
                                    </div>
                                </div>
                            </td>

                            <td style={{ verticalAlign: 'middle' }} align="center" >
                                <span className="text-sm" style={labelStyle}>
                                    {t("tagRenewalVoucher:insurance")}:
                                </span>
                                <br />
                                <span className="text-bold">
                                    {insurance?.company || 'N/A'}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/* MOBILE TABLE */}
                <table className="table table-bordered text-uppercase mb-0 d-md-none d-table">
                    <tbody className="text">
                        <tr>
                            <td scope="row">
                                <span className="text-sm" style={labelStyle}>
                                    {t("tagRenewalVoucher:name")}:
                                </span>
                                <br />
                                <span className="text-bold">
                                    {owner?.firstName || 'N/A'} {owner?.fatherLastName} {owner?.motherLastName}
                                </span>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <span className="text-sm" style={labelStyle}>
                                    {t("tagRenewalVoucher:transactionDate")}:
                                </span>
                                <br />
                                <span className="text-bold">
                                    {renewalDate || 'N/A'}
                                </span>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <span className="text-sm" style={labelStyle}>
                                    {t("tagRenewalVoucher:validity")}:
                                </span>
                                <br />
                                <span className="text-bold">
                                    {renewalPeriod?.startDate || 'N/A'}
                                    {" - "}
                                    {renewalPeriod?.endDate || 'N/A'}
                                </span>
                            </td>
                        </tr>

                        <tr>
                            <td scope="row">
                                <div className="mb-1">
                                    <span className="text-sm" style={labelStyle}>
                                        {t("tagRenewalVoucher:vehicle")}:
                                    </span>
                                    <br />
                                    <span className="text-bold">
                                        {vehicle?.year || 'N/A'} {vehicle?.make} {vehicle?.color}
                                    </span>
                                    <br />
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td scope="row">
                                <div className="mb-1">
                                    <span className="text-sm" style={labelStyle}>
                                        {t("tagRenewalVoucher:model")}:
                                    </span>
                                    <br />
                                    <span className="text-bold">
                                        {vehicle?.model || 'N/A'}
                                    </span>
                                    <br />
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div className="row">
                                    {/*<div className="col">*/}
                                    {/*    <span className="text-sm" style={labelStyle}>*/}
                                    {/*        {t("tagRenewalVoucher:register")}:*/}
                                    {/*    </span>*/}
                                    {/*    <br />*/}
                                    {/*    <span className="text-bold">*/}
                                    {/*        {vehicle?.registration || 'N/A'}*/}
                                    {/*    </span>*/}
                                    {/*</div>*/}
                                    <div className="col">
                                        <span className="text-sm" style={labelStyle}>
                                            {t("tagRenewalVoucher:plate")}:
                                        </span>
                                        <br />
                                        <span className="text-bold">
                                            {vehicle?.plate || 'N/A'}
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div className="row">
                                    {/*<div className="col">*/}
                                    {/*    <span className="text-sm" style={labelStyle}>*/}
                                    {/*        {t("tagRenewalVoucher:tagType")}:*/}
                                    {/*    </span>*/}
                                    {/*    <br />*/}
                                    {/*    <span className="text-bold">*/}
                                    {/*        {tagType === "STICKER"*/}
                                    {/*            ? "Pegatina"*/}
                                    {/*            : tagType || "N/A"}*/}
                                    {/*    </span>*/}
                                    {/*</div>*/}
                                    <div className="col">
                                        <span className="text-sm" style={labelStyle}>
                                            {t("tagRenewalVoucher:expiration")}:
                                        </span>
                                        <br />
                                        <span className="text-bold">
                                            {renewalPeriod?.endDate || 'N/A'}
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <span className="text-sm" style={labelStyle}>
                                    {t("tagRenewalVoucher:insurance")}:
                                </span>
                                <br />
                                <span className="text-bold">
                                    {insurance?.company || 'N/A'}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

VoucherBody.propTypes = {
    owner: PropTypes.object,
    qrImage: PropTypes.string,
    renewalDate: PropTypes.string,
    renewalPeriod: PropTypes.object,
    vehicle: PropTypes.object,
    tagType: PropTypes.string,
    insurance: PropTypes.object,
    idcNumber: PropTypes.string
}

const labelStyle = {
    color: Colors.gray
}

const resultIdc = {
    backgroundColor: Colors.lightGray,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
};

const resultHeading = {
    display: "flex",
    padding: "15px",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
};

const resultStyle = {
    backgroundColor: "#fff",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "1000px",
};

export default VoucherBody;
