import React, { useEffect, useState } from "react";
import PageTitle from "../../components/ui/PageTitle";
import SubHeader from "../../layout/SubHeader";
import DateSquare from "../../components/ui/DateSquare";
import Card from "../../components/ui/Card";
import ComponentTitle from "../../components/ui/ComponentTitle";

import electronicTagIcon from "../../assets/images/electronic-tag/MarbeteElectronico.svg";
import stickerTagIcon from "../../assets/images/electronic-tag/icon_MarbeteElectronico_MarbeteRegular.svg";
import Pagination from "react-js-pagination";
import VehicleListItem from "../../components/widgets/VehicleListItem";

import arrowBack from "../../assets/images/arrow-back-icon.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { vehicleRenewalTagHistory } from "../../core/api-resources/electronicTag";
import { isMobile } from "../../core/Utils";

import _ from "lodash";
import { Colors } from "../../Colors";

import numSerieIcon from '../../assets/images/electronic-tag/icon_MarbeteDigital_Numero_Serie.svg'

const ElectronicTag = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const vehicle = useSelector((s) => s.electronicTag.vehicle);

    const [data, setData] = useState(null)

    const [currentPage, setCurrentPage] = useState(1);
    const [displayList, setDisplayList] = useState(1);

    const PAGE_SIZE = 5;

    useEffect(() => {
        if (!vehicle) history.push('/landing')
    }, [vehicle])

    useEffect(() => {
        fetchHistory()
    }, [vehicle?.id])

    useEffect(() => {
        setDisplayList(arrayPage(data, currentPage));
    }, [data, currentPage]);

    const fetchHistory = async () => {
        let history = await vehicleRenewalTagHistory(vehicle?.id)
        setData(history?.vehicleTags)
    }

    const arrayPage = (arr = [], page = 1) => {
        return arr?.slice(PAGE_SIZE * (page - 1), PAGE_SIZE * page);
    };

    const pageEvent = (p) => {
        setDisplayList(arrayPage(data, p));
        setCurrentPage(p);
    };

    return (
        <div>
            <SubHeader>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <PageTitle i18nKey={"electronicTag:title"} />
                        </div>
                    </div>
                </div>
            </SubHeader>

            <div style={{ marginTop: -100 }} className="mb-5">
                <SubHeadingCard
                    vehicleTag={vehicle?.tag}
                    serialNumber={vehicle?.serialNumber}
                />
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-5">
                        <div className="row no-gutters mb-3">
                            <div className="col">
                                <ComponentTitle
                                    i18nKey={"electronicTag:electronicTagMenu"}
                                />
                                <Card
                                    hoverable
                                    onClick={() => history.goBack()}
                                >
                                    <div className="d-flex justify-content-between">
                                        <span className="text-medium">
                                            {t("electronicTag:back")}
                                        </span>

                                        <img
                                            aria-hidden
                                            alt=""
                                            src={arrowBack}
                                            style={{ width: 20 }}
                                        />
                                    </div>
                                </Card>
                            </div>
                        </div>
                        <div className="row no-gutters mb-3">
                            <div className="col">
                                <ComponentTitle
                                    i18nKey={"electronicTag:vehicle"}
                                />
                                <VehicleListItem
                                    id={vehicle?.id}
                                    year={vehicle?.year}
                                    brand={vehicle?.brand}
                                    model={vehicle?.model}
                                    plate={vehicle?.plate}
                                    type={vehicle?.type}
                                    gravamen={vehicle?.gravamen}
                                    isClean={vehicle?.isClean}
                                    fines={vehicle?.fines}
                                    canRenewTag={vehicle?.canRenewTag}
                                    vehicle={vehicle}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-7">
                        <ComponentTitle i18nKey={"electronicTag:history"} />

                        <div className="row border-bottom text-uppercase no-gutters p-3">
                            <div className="col">
                                <strong>
                                    {t("electronicTag:tagNumber.0")}
                                    {t("electronicTag:tagNumber.1")}
                                </strong>
                            </div>
                            <div className="col-3 col-lg-2">
                                <div className="d-flex justify-content-center">
                                    <strong>{t("electronicTag:date")}</strong>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            {displayList && displayList?.length > 0 && displayList?.map((item, i) => (
                                <div
                                    key={i}
                                    className="row p-3 border-bottom d-flex align-items-center hoverable no-gutters"
                                >
                                    <div className="col">
                                        <div className="d-flex align-items-center">
                                            {item?.type === "DIGITAL" ?
                                                <img
                                                    aria-hidden
                                                    alt=""
                                                    src={electronicTagIcon}
                                                    style={{ width: 40 }}
                                                />
                                                :
                                                <img
                                                    aria-hidden
                                                    alt=""
                                                    src={stickerTagIcon}
                                                    style={{ width: 40 }}
                                                />
                                            }
                                            <div className="col">{_.padStart(item.number, 9, "0")}</div>
                                        </div>
                                    </div>
                                    <div className="col-3 col-lg-2">
                                        <div className="d-flex justify-content-center">
                                            <DateSquare date={item.endDate} expiredStyle />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div>
                            <Pagination
                                innerClass={"pagination justify-content-center"}
                                activePage={currentPage}
                                itemsCountPerPage={PAGE_SIZE}
                                totalItemsCount={data?.length}
                                pageRangeDisplayed={isMobile() ? 2 : 3}
                                onChange={pageEvent}
                                itemClass={"page-item"}
                                linkClass={"page-link"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SubHeadingCard = (props) => {

    const { vehicleTag, serialNumber } = props;

    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div className="container">
            <Card>
                <div className="row">
                    <div
                        className={`col-12 col-lg-6 mb-5 mb-lg-0`} >
                        <div className="row h-100">
                            <div className="col-6 border-right">
                                <div className="d-flex align-items-center h-100 justify-content-center">
                                    <div>
                                        {vehicleTag?.type === "DIGITAL" ?
                                            <img
                                                aria-hidden
                                                alt=""
                                                src={electronicTagIcon}
                                                style={{ width: 40 }}
                                                className="mr-3"
                                            />
                                            :
                                            <img
                                                aria-hidden
                                                alt=""
                                                src={stickerTagIcon}
                                                style={{ width: 40 }}
                                                className="mr-3"
                                            />
                                        }
                                    </div>

                                    <div className="d-flex flex-column align-items-start">
                                        <div
                                            style={{ color: Colors.gray }}
                                            className="d-flex flex-column flex-lg-row">
                                            <span className="text-sm text-uppercase mr-1">
                                                {t("electronicTag:tagNumber.0")}
                                            </span>
                                            <span className="text-sm text-uppercase">
                                                {t("electronicTag:tagNumber.1")}
                                            </span>
                                        </div>
                                        <div>
                                            <strong className="text-md">
                                                {vehicleTag?.number || "N/A"}
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={`col-6 border-right`}
                            >
                                <div className="d-flex align-items-center h-100 justify-content-center">
                                    <div className="mr-3">
                                        <DateSquare
                                            date={vehicleTag?.expirationDate}
                                            expiredStyle
                                        />
                                    </div>
                                    <div
                                        style={{ color: Colors.gray }}
                                        className="text-sm text-uppercase">
                                        <span>
                                            {t("electronicTag:expiration.0")}
                                        </span>
                                        <br />
                                        <span>
                                            {t("electronicTag:expiration.1")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 col-lg-6">
                        <div className="d-flex align-items-center justify-content-center px-3">
                            <div className="mr-3 flex align-items-center justify-content-center">
                                <img
                                    style={{ width: 40 }}
                                    src={numSerieIcon}
                                    aria-hidden={true}
                                    alt=""
                                />
                            </div>
                            <div>
                                <span
                                    className="text-sm text-uppercase"
                                    style={{ color: Colors.gray }}
                                >{t("electronicTag:serialNo")}</span>
                                <br />
                                <strong className="text-md">{serialNumber || "-"}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ElectronicTag;
