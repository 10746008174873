import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import certificacionMultaIcon from "../../../assets/images/certificacion-multas-icon.svg";
import certificacionRenovacionIcon from "../../../assets/images/icon-certificacion-licencia-vehiculo.svg";
import certificacionVehiculoMotor from "../../../assets/images/icon-certificacion-vehiculo-motor.svg";

import { useHistory, useParams } from "react-router-dom";
import { getCurrentPolicy } from "../../../core/api-resources/insurance";
import { setSelectedInsurance, setSelectedVehicle } from "../../../redux/insurance/insurance.combine";
import {
    openVehicleCertificationReport,
    openVehicleFinesReport,
    openVehicleLicenseReport
} from "../../../redux/loading/loadingState.combine";
import CertificationsComponent from "../Certifications";
import { vehicleRenewalNotiReasons } from "../../../core/APICaller";
import { setVehicleNotiReasons } from "../../../redux/vehicleRenewal/vehicleRenewal.combine";
import VehicleNotiEligibilityModal from "../VehicleNotiEligibilityModal";

const VehicleCertifications = props => {

    const acaaFeature = useSelector(s => s?.features?.acaa?.enabled)

    const history = useHistory();
    const dispatch = useDispatch();

    let printVehicleLicense = useSelector(
        state => state.features?.titleTransfer?.printVehicleLicense
    );

    const showAcaaInstructions = useSelector(s => s?.features?.vehicleRenewal?.showAcaaInstructions);

    const vehicleNotiEligibilityModal = useSelector(s => s?.vehicleRenewal?.showModal)

    const openRenewNotification = () => {
        dispatch(openVehicleLicenseReport(props.vehicle.id));
    };

    const openVehicleCertification = () => {
        dispatch(openVehicleCertificationReport(props.vehicle.id));
    };

    const openFineReport = () => {
        dispatch(openVehicleFinesReport(props.vehicle.id));
    };

    const acaaAction = async () => {
        const res = await getCurrentPolicy(props?.vehicle?.id)

        await dispatch(setSelectedVehicle(props?.vehicle))

        // If policy found go to total else go through wizard
        if (res?.policyId) {

            await dispatch(setSelectedInsurance(res))
            history.push('/insurance/total')
        } else {
            if (showAcaaInstructions) {
                history.push('/insurance/instructions')
            } else {
                history.push('/insurance/selection')
            }
        }
    }

    const handleVehicleLicenseClick = () => {
        vehicleRenewalNotiReasons(props.vehicle.id, (response) => {
            if (response?.valid) {
                // Old action
                acaaFeature ? acaaAction() : openRenewNotification()
            } else {
                // Show reasons modal
                dispatch(setVehicleNotiReasons(true, response?.reasons))
            }
        });
    }

    const certifications = [
        {
            name: "vehicle:certifications.fines",
            icon: certificacionMultaIcon,
            action: openFineReport,
            enable: true,
        },
        {
            name: "vehicle:certifications.renewal",
            icon: certificacionRenovacionIcon,
            // action: acaaFeature ? acaaAction : openRenewNotification,
            action: handleVehicleLicenseClick,
            // enable: printVehicleLicense && props.vehicle?.canRenewTag,
            enable: true,
        },
        {
            name: "vehicle:certifications.vehicleCertification",
            icon: certificacionVehiculoMotor,
            action: openVehicleCertification,
            enable: true,
        }
    ];

    return (
        <>
            {
                vehicleNotiEligibilityModal &&
                <VehicleNotiEligibilityModal
                    toggle={() => dispatch(setVehicleNotiReasons(false, []))}
                />
            }

            <CertificationsComponent
                certifications={certifications}
                source={"vehicle"}
            />
        </>
    );
};

VehicleCertifications.propTypes = {
    vehicle: PropTypes.object
};

export default VehicleCertifications;
