import React from 'react'
import { useState } from 'react';
import { Colors } from '../../Colors';
import InputMask from "react-input-mask";
import './style.css'
import { useForm, Controller } from 'react-hook-form';
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import ConfirmationModal from '../../components/ui/ConfirmationModal';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const PaymentSupport = () => {
    const [succes, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const [loading, setLoading] = useState(false)

    const { search } = useLocation()
    const qs = queryString.parse(search)


    const { register, watch, formState: { errors }, handleSubmit, control, reset } = useForm({
        defaultValues: {
            firstName: qs.firstName || '',
            lastName: qs.lastName || '',
            telephone: qs.telephone || null,
            email: qs.email || '',
            typeOfId: qs.typeOfId || '',
            numberOfId: qs.numberOfId || null,
            transactionNumber: qs.transactionNumber || null,
            subcategoryName: qs.subcategoryName || '',
            description: qs.description || ''
        }
    })

    const requiredMsg = 'Este campo es requerido'
    const invalidFormatMsg = 'Formato inválido'

    const onSubmit = data => submitFunction(data)

    async function submitFunction(data) {

        setLoading(true)

        // Remove any empty key/value pairs from the Object
        Object.keys(data).forEach((key) => {
            if (data[key] === "" || data[key] === null || data[key] === undefined) {
                delete data[key];
            }
        });

        await postData(data)
            .then((response) => {
                if (response.status !== 201) {
                    console.error(`There was an error. Status Code: ${response.status}`);
                }


                setLoading(false)
                return response.json();
            })
            .then((data) => {
                console.log(data?.errors);
                if (data?.errors?.response_status?.status_code === 4000) {
                    setSuccess(false)
                    setError(true)
                    console.log("ERROR: ", data?.error)
                } else {
                    setError(false)
                    setSuccess(data?.data?.ticketId)
                    reset()
                }
            })
            .catch(e => {
                setSuccess(false)
                setError(true)
                console.log("ERROR: ", e)
            })

        setLoading(false)
    }

    async function postData(data = {}) {
        const response = await fetch(
            "https://prits-itsm-api.azurewebsites.net/api/v1/CescoDigitalCreatePaymentTicket?code=WjPtfcVoOP8nNt101hZCrZgoZyHmOwtOcLikfAf1lgtFAzFusqHddA==",
            {
                method: "POST",
                mode: "cors",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    // "x-functions-key":
                    //     "LmOwqdAS2VhRS6MpaN-BlqAF40sArJZnhDP2y1LTQiU5AzFu7yZqnw==",
                },
                body: JSON.stringify(data),
            });

        return response;
    }

    const renderInputTitle = () => {
        switch (watch("typeOfId")) {
            case "Tablilla":
                return "Tablilla"
            case "Licencia":
                return "Licencia"
            default:
                return "Identificación"
        }

    }

    return (
        <div id="top">
            <div class="support-form__center">
                <div class="support-form__container">


                    {
                        succes &&
                        <SuccessModal
                            ticketNumber={succes}
                            setModal={setSuccess}
                        />
                    }

                    {
                        error &&
                        <ErrorDialog />
                    }

                    <div class="support-form__title">Formulario de ayuda para problemas con <u>pagos</u> en Cesco Digital</div>
                    <form action="" id="supportForm" method="post" onSubmit={handleSubmit(onSubmit)}>
                        <small>
                            <p>NOTA: Por el momento, no se están aceptando tarjetas que tengan una dirección de facturación fuera de Puerto Rico.</p>
                            <p>NOTICE: At this time, the payment gateway isn’t accepting credit cards that have a billing address outside of Puerto Rico.</p>
                        </small>

                        <div class="support-form__subtitle">Información de contacto</div>
                        <div class="support-form__user-details">
                            <div class="support-form__input-box">
                                <label class="support-form__details" for="firstName"
                                >Nombre <span class="support-form__required" aria-label="required">*</span>
                                </label>

                                <input {...register("firstName", {
                                    required: requiredMsg,
                                    pattern: { value: /^[A-Za-z]{2,24}$/, message: invalidFormatMsg }
                                })} />

                                <InputError message={errors?.firstName?.message} />

                            </div>
                            <div class="support-form__input-box">
                                <label class="support-form__details" for="lastName"
                                >Apellido <span class="support-form__required" aria-label="required">*</span>
                                </label>

                                <input {...register("lastName", {
                                    required: requiredMsg,
                                    pattern: { value: /^[A-Za-z]{2,24}$/, message: invalidFormatMsg }
                                })} />

                                <InputError message={errors?.lastName?.message} />

                            </div>
                            <div class="support-form__input-box">
                                <label class="support-form__details" for="telephone"
                                >Teléfono <span class="support-form__required" aria-label="required">*</span>
                                </label>

                                <Controller
                                    render={({ field }) => (
                                        <InputMask mask="(999)999-9999"
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                    control={control}
                                    name="telephone"
                                    rules={{
                                        required: { value: true, message: requiredMsg },
                                        pattern: {
                                            value: /^\(\d{3}\)\d{3}-\d{4}/,
                                            message: invalidFormatMsg
                                        }
                                    }}
                                />


                                <InputError message={errors?.telephone?.message} />

                            </div>
                            <div class="support-form__input-box">
                                <label class="support-form__details" for="email"
                                >Correo electrónico</label>

                                <input {...register("email", { required: requiredMsg, maxLength: 50 })}
                                    type="email"
                                />

                                <InputError message={errors?.email?.message} />

                            </div>
                            <div class="support-form__select">
                                <label class="support-form__details" for="typeOfId"
                                >Seleccione el tipo de identificación
                                    <span class="support-form__required" aria-label="required">*</span>
                                </label>

                                <select {...register("typeOfId", { required: requiredMsg })} className="custom-select">
                                    <option disabled selected value="">Seleccione una opción</option>
                                    <option value="Licencia">Licencia</option>
                                    <option value="ID">ID</option>
                                    <option value="Tablilla">Tablilla</option>
                                </select>

                                <InputError message={errors?.typeOfId?.message} />
                            </div>
                            <div class="support-form__input-box">
                                <label class="support-form__details" for="numberOfId"
                                >Número de  {renderInputTitle()}
                                    <span class="support-form__required" aria-label="required">*</span></label>

                                <input {...register("numberOfId", {
                                    required: requiredMsg,
                                    minLength: {
                                        value: 6,
                                        message: 'Cantidad minima de digitos debe ser 6'
                                    },
                                    maxLength: {
                                        value: 12,
                                        message: 'Cantidad maxima de digitos debe ser 12'
                                    }
                                })} />

                                <InputError message={errors?.numberOfId?.message} />
                            </div>
                        </div>

                        <div class="support-form__subtitle">Detalles del error</div>

                        <div class="support-form__user-details">
                            <div class="support-form__input-box" style={{ width: '100%' }}>
                                <label class="support-form__details" for="idcNumber"
                                >Número de transacción (IDC# o Número de transacción para reclamación)</label>

                                <Controller
                                    render={({ field }) => (
                                        <InputMask mask="99999999-*******"
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                    control={control}
                                    name="transactionNumber"
                                    rules={{
                                        required: { value: true, message: requiredMsg },
                                        pattern: {
                                            value: /^[0-9]{8}-[a-zA-Z0-9]{7}$/,
                                            message: invalidFormatMsg
                                        }
                                    }}
                                />

                                <InputError message={errors?.transactionNumber?.message} />
                            </div>

                            <div class="support-form__select" style={{ width: '100%' }} >
                                <label class="support-form__details" for="subcategoryName"
                                >Tipo de transacción
                                    <span class="support-form__required" aria-label="required">*</span>
                                </label>

                                <select {...register("subcategoryName", { required: requiredMsg })} className="custom-select">
                                    <option disabled selected value="">Seleccione una opción</option>
                                    <option value="Multas">Multas</option>
                                    <option value="Traspaso de vehículo">Traspaso de vehículo</option>
                                    <option value="Duplicado de licencia por cambio de dirección">
                                        Duplicado de licencia por cambio de dirección
                                    </option>
                                    <option value="Renovación de licencia">
                                        Renovación de licencia
                                    </option>
                                </select>

                                <InputError message={errors?.subcategoryName?.message} />
                            </div>

                            <div class="support-form__text-box">
                                <label class="support-form__details" for="description"
                                >Descripción del problema
                                    <span class="support-form__required" aria-label="required">*</span>
                                </label>

                                <textarea {...register("description", { required: requiredMsg, maxLength: 500 })}
                                    id="description"
                                    cols="30"
                                    rows="10"
                                ></textarea>

                                <InputError message={errors?.description?.message} />
                            </div>
                        </div>

                        <input
                            type="submit"
                            style={{ backgroundColor: Colors.primary, color: 'white', opacity: loading ? 0.7 : 1 }} className='w-100 rounded p-3'
                            disabled={loading}
                            value={loading ? 'Enviando' : 'Someter'}
                        />

                    </form>
                </div>
            </div>
        </div>
    )
}

const InputError = ({ message }) => {

    if (!message) return null

    return (
        <p className='m-0' style={{ color: Colors.error }}><small>{message}</small></p>
    )
}

const SuccessDialog = (props) => {

    return (
        <div style={{ backgroundColor: Colors.primary, color: 'white' }} className="p-3 rounded mb-3">
            <span>La forma fué sometida exitosamente. Su número de ticket es: {props?.ticketNumber}</span>
        </div>
    )
}


const SuccessModal = (props) => {
    return (
        <Modal
            isOpen={true}
            size="md"
            style={{ padding: "15px" }}
            toggle={() => props?.setModal(false)}
            role="dialog"
            aria-modal={true}
            autoFocus={true}
        >

            <ModalHeader
                style={{ backgroundColor: Colors.lightGray }}
                toggle={() => props?.setModal(false)}
            >
                <span className="text">Formulario sometido</span>
            </ModalHeader>

            <ModalBody
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    margin: "10px 0",
                }}
            >
                <span>El formulario fué sometido exitosamente. Su número de ticket es:
                    <span style={{ fontWeight: 'bold', color: Colors.primary, marginLeft: 5 }}>{props?.ticketNumber}</span>
                </span>

            </ModalBody>
        </Modal>
    )
}

const ErrorDialog = () => {
    return (
        <div style={{ backgroundColor: Colors.error, color: 'white' }} className="p-3 rounded mb-3">
            <span>Lo sentimos. Ocurrió un error sometiendo la forma.</span>
        </div>
    )

}

export default PaymentSupport