import React, { useEffect, useState } from "react";

import FineList from "../../components/widgets/FineList";

import Text from "../../components/ui/Text";
import { Colors } from "../../Colors";
import Button from "../../components/ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import SubHeader from "../../layout/SubHeader";
import SubHeaderIconRow from "../../layout/SubHeader/SubHeaderIconRow";
import Checkbox from "../../components/ui/Checkbox";
import { selectFines } from "../../redux/payment/payment.combine";
import ComponentTitle from "../../components/ui/ComponentTitle";
import Card from "../../components/ui/Card";
import { FontWeight } from "../../components/ui/FontSize";
import { fineSelectAll, resetCart, } from "../../redux/fines/actions";
import { fetchCard } from "../../redux/entity/actions";
import * as Utils from "../../core/Utils";
import { formatCurrencyLabel } from "../../core/Utils";
import LicenseCertifications from "../../components/widgets/certifications/LicenseCertifications";
import AlertGroup from "../../components/widgets/alerts/AlertGroup";
import FinesControlRowMobile from "../../components/widgets/FinesControlRowMobile";
import OrganDonor from "../../components/widgets/OrganDonor";
import {useTranslation} from "react-i18next";
import Icon from "../../components/ui/Icon";
import IconTraspaso from "../../assets/images/icon_Traspaso.svg";
import ServicesRow from "../VehicleDetails/ServicesRow";
import ClickableRow from "../../components/ui/ClickableRow";

const Profile = (props) => {
    const [loading, setLoading] = useState(true);
    // const [fines, setFines] = useState(Utils.emptyObjArray(5));
    let userFines = useSelector((state) => state.fines);
    let card = useSelector((state) => state.card);
    let config = useSelector((state) => state.config);

    const donorFeature = useSelector(s => s?.features?.organDonors?.enabled);

    const [personalInfo, setPersonalInfo] = useState({});
    const [license, setLicense] = useState({});
    const [all, setAll] = useState(false);

    let history = useHistory();
    const { t } = useTranslation()
    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetCart());
        dispatch(fetchCard(onCardFetchSuccess));
    }, []);

    const onCardFetchSuccess = (info) => {
        let { personalInfo, license } = info;
        setLicense(license);
        setPersonalInfo(personalInfo);
        setLoading(false);
    };

    const handlePayFines = () => {
        if (!loading) {
            let selectedFines = userFines.cart.fines;
            dispatch(
                selectFines(selectedFines, () => {
                    history.push("/pay/fines");
                })
            );
        }
    };

    const selectAllClick = (checked) => {
        dispatch(fineSelectAll(checked));
    };

    const deselectAll = () => {
        dispatch(fineSelectAll());
    };

    const finesControlRow = () => (
        <>
            <ComponentTitle
                style={{ fontWeight: "bold" }}
                i18nKey={"profile:fines"}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "15px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Checkbox
                        checked={all}
                        ariaLabel={"profile:selectAllArial"}
                        disabled={userFines.cart.fines.length === 0}
                        handleCheckbox={selectAllClick}
                        label={"profile:selectAll"}
                    />
                </div>
                <Button
                    onClick={handlePayFines}
                    disabled={userFines.cart.fines.length === 0}
                    style={{ width: 160 }}
                    className="btn-primary"
                >
                    {userFines.cart.sumAmount > 0 && (
                        <>
                            <span style={{ fontSize: 18 }}>
                                {formatCurrencyLabel(userFines.cart.sumAmount)}
                            </span>
                            <br />
                        </>
                    )}
                    <span
                        style={{
                            fontSize: userFines.cart.sumAmount > 0 ? 12 : 15,
                        }}
                    >
                        <Text i18nKey={"profile:payFines"} />
                    </span>
                </Button>
            </div>
        </>
    );


    let { address, restrictions } = license;
    let line1, line2;

    if (license && address) {
        line1 = address.line1;
        line2 = address.line2;
    }
    return (
        <>
            <SubHeader>
                <SubHeaderIconRow {...{ ...personalInfo, ...license }} />
            </SubHeader>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <AlertGroup alerts={license.alerts} colorFill />
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">

                    <div style={profileLeft} className="col-lg-5 col-12">
                        <ServicesRow>
                            <ClickableRow
                                text={t('layout:navPage.other')}
                                icon={IconTraspaso}
                                action={() => history.push(`/other-transactions`)}
                            />
                        </ServicesRow>
                        <div style={contentBlock}>
                            <ComponentTitle
                                style={{ fontWeight: "bold" }}
                                i18nKey={"profile:certifications.title"}
                            >
                                <Link
                                    to="/certifications"
                                    className="link"
                                    style={{
                                        color: Colors.primary,
                                        fontSize: "16px",
                                        marginRight: "15px",
                                    }}
                                >
                                    <Text
                                        i18nKey={"profile:certifications.more"}
                                    />
                                </Link>
                            </ComponentTitle>
                            <LicenseCertifications />
                        </div>

                        {
                            donorFeature &&
                            <div style={contentBlock}>
                                <OrganDonor />
                            </div>
                        }


                        <div className="d-lg-none">
                            <FinesControlRowMobile
                                i18nKey={"profile:fines"}
                                config={config}
                                selectAllClick={selectAllClick}
                                unpaidTolls={card?.license?.unpaidTolls > 0}
                                all={all}
                                deselectAll={deselectAll}
                                handlePayFines={handlePayFines}
                                disabled={userFines.cart.fines.length === 0}
                            />
                            <div style={{ marginBottom: "35px" }}>
                                <FineList
                                    fines={userFines.workingFines}
                                    loading={loading}
                                    noIcon
                                />
                            </div>
                        </div>

                        <div style={contentBlock}>
                            <ComponentTitle
                                style={{ fontWeight: "bold" }}
                                i18nKey={"profile:addresses"}
                            />
                            <Card>
                                <span
                                    role="text"
                                    aria-label={`${address && address.line1} 
                                    ${address && address.line2}
                                    ${address && address.city}
                                    ${address && address.state}
                                    ${address && address.zipCode}
                                    `}
                                >
                                    <Text style={{ color: Colors.gray }} className="text">
                                        {line1}
                                        {line1 && <br />}
                                        {line2}
                                        {line2 && <br />}
                                        {address && Utils.cityZipFormat(address)}
                                    </Text>
                                </span>
                            </Card>
                        </div>

                        {restrictions && restrictions.length > 0 && (
                            <div style={contentBlock}>
                                <ComponentTitle i18nKey={"profile:restrictions"} />
                                <Card>
                                    {restrictions.map((r) => (
                                        <div style={restrictionItem} role="text"
                                            aria-label={`${r.code}: ${r.description}`}>
                                            <span
                                                style={restrictionRow}
                                                className="badge badge-sm rounded-circle"
                                            >
                                                {r.code}
                                            </span>
                                            <Text
                                                style={{ color: Colors.gray }}
                                                className="text"
                                            >
                                                {r.description}
                                            </Text>
                                        </div>
                                    ))}
                                </Card>
                            </div>
                        )}
                    </div>

                    <div className="col-lg-7 d-none d-lg-block">
                        {finesControlRow()}
                        <div>
                            <FineList
                                fines={userFines.workingFines}
                                loading={loading}
                                noIcon
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const profileLeft = {
    display: "flex",
    flexDirection: "column",
};

const restrictionItem = {
    display: "flex",
    alignItems: "center",
    marginTop: 5,
    marginBottom: 5,
};

const contentBlock = {
    marginBottom: "25px",
};

const restrictionRow = {
    marginRight: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.primary,
    color: "white",
    fontSize: "12px",
    fontWeight: FontWeight.bold,
};


export default Profile;
