import React, {useEffect, useState} from "react";

import SubHeader from "../../layout/SubHeader";
import PageTitle from "../../components/ui/PageTitle";
import Text from "../../components/ui/Text";
import {Colors} from "../../Colors";
import EmailValidationComponent from "../../components/widgets/EmailValidationComponent";

const EmailValidation = (props) => {
    return (
        <>
            <SubHeader>
                <div className="container">
                    <PageTitle
                        style={{fontWeight: "bold"}}
                        i18nKey={"emailValidation:title"}
                    />
                    <div className="d-none d-lg-flex">
                        <hr/>
                    </div>
                    <Text
                        i18nKey="emailValidation:subTitle"
                        className="text"
                        style={{color: Colors.gray}}
                    />
                </div>
            </SubHeader>

            <EmailValidationComponent />
        </>
    );
};

export default EmailValidation;
