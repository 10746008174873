import React from 'react'
import Text from "./Text";
import PropTypes from 'prop-types'
import { Colors } from '../../Colors';

const SubHeaderText = (props) => {

    const { i18nKey } = props

    return (
        <Text
            i18nKey={i18nKey || "No i18nKey provided"}
            className="text"
            style={{ color: Colors.gray }}
        />
    )
}

SubHeaderText.propTypes = {
    i18nKey: PropTypes.string
}

export default SubHeaderText