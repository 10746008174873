import React from "react";

const Help = () => {

    return (
        <div className="container">
            <h3> Tome en consideración los siguientes pasos:</h3>
            <ul className="list-group">
                <li className="list-group-item">Llegue al CESCO indicado para el recogido al menos 15 minutos antes de
                    la <strong>hora y
                        fecha</strong>indicada. Si desea consultar la hora y fecha de su cita<sup>1</sup>, puede acceder
                    la
                    opción de citas en el menú de CESCO Digital.
                </li>
                <li className="list-group-item">Una vez llegue al CESCO, un ujier le solicitará su <strong>número de
                    cita</strong> y le brindará
                    los
                    próximos pasos a seguir. Podrá encontrar su número de cita para <strong>Recogido de
                        Licencia</strong> al acceder la opción de citas en el menú de CESCO Digital.
                </li>
                <li className="list-group-item">Mantenga presente este comprobante, ya sea desde la aplicación o una
                    versión impresa, en caso de
                    que
                    el operador del CESCO requiera revisarlo.
                </li>
            </ul>
        </div>
    );
};

export default Help;
