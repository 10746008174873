import React from 'react'
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string'
import SuccessDialog from './components/Success';
import ErrorDialog from './components/ErrorDialog';
import { Colors } from '../../../Colors';
import moment from 'moment'
import { useTranslation } from 'react-i18next';

const PinSupport = () => {

    const [succes, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const [loading, setLoading] = useState(false)

    const { search } = useLocation()
    const qs = queryString.parse(search)

    const { t } = useTranslation()

    const { register, watch, formState: { errors }, handleSubmit, control, reset } = useForm({
        defaultValues: {
            firstName: qs.firstName || '',
            lastName: qs.lastName || '',
            telephone: qs.telephone || null,
            email: qs.email || '',
            typeOfId: qs.typeOfId || '',
            numberOfId: qs.numberOfId || null,
            description: qs.description || '',
            dateOfIncident: qs.dateOfIncident || ''
        }
    })

    const requiredMsg = t("support:pin.requiredMessage")

    const onSubmit = data => submitFunction(data)

    async function submitFunction(data) {

        setLoading(true)

        data.dateOfIncident = moment(data.dateOfIncident).toISOString()

        // Remove any empty key/value pairs from the Object
        Object.keys(data).forEach((key) => {
            if (data[key] === "" || data[key] === null || data[key] === undefined) {
                delete data[key];
            }
        });

        await postData(data)
            .then((response) => {
                if (response.status !== 201) {
                    console.error(`There was an error. Status Code: ${response.status}`);
                }


                setLoading(false)
                return response.json();
            })
            .then((data) => {
                console.log(data?.errors);
                if (data?.errors?.response_status?.status_code === 4000) {
                    setSuccess(false)
                    setError(true)
                    console.log("ERROR: ", data?.error)
                } else {
                    setError(false)
                    setSuccess(data?.data?.ticketId)
                    reset()
                }
            })
            .catch(e => {
                setSuccess(false)
                setError(true)
                console.log("ERROR: ", e)
            })

        setLoading(false)
    }

    async function postData(data = {}) {
        const response = await fetch(
            "https://prits-itsm-api.azurewebsites.net/api/v1/CescoDigitalCreatePrePrivacyTicket",
            {
                method: "POST",
                mode: "cors",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-functions-key": "Y5a-uP1Y65PzmD7UxhtJ0fRx3iODZ0oDh_9Sq9MzHVt3AzFuCynePw=="
                },
                body: JSON.stringify(data),
            });

        return response;
    }

    return (
        <div className="container card p-3 p-lg-5 shadow d-flex align-items-center justify-content-center">

            <div className="row w-100">
                <div className="col p-0 p-lg-3">
                    <span className='text-xl'>{t("support:pin.title")}</span>
                    <hr />
                </div>
            </div>

            {
                succes &&
                <SuccessDialog
                    ticketNumber={succes}
                    setModal={setSuccess}
                />
            }

            {
                error &&
                <ErrorDialog />
            }

            <form className="w-100 row" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group col-lg-6 col-12">
                    <label for="firstName">{t("support:pin.fields.0")}</label>
                    <input
                        {...register("firstName", { required: requiredMsg })}
                        type="string" className="form-control" id="firstName" name="firstName" aria-describedby="firstName" placeholder={t("support:pin.fields.0")}
                        maxLength={24}
                    />
                    {
                        errors?.firstName &&
                        <FieldError message={errors?.firstName?.message} />
                    }
                </div>

                <div className="form-group col-lg-6 col-12">
                    <label for="lastName">{t("support:pin.fields.1")}</label>
                    <input
                        {...register("lastName", { required: requiredMsg })}
                        type="string" className="form-control" id="lastName" name="lastName" aria-describedby="lastName" placeholder={t("support:pin.fields.1")}
                        maxLength={24}
                    />
                    {
                        errors?.lastName &&
                        <FieldError message={errors?.lastName?.message} />
                    }
                </div>

                <div className="form-group col-lg-6 col-12">

                    <label for="telephone">{t("support:pin.fields.2")}</label>
                    <Controller
                        render={({ field }) => (
                            <ReactInputMask
                                mask="(999)999-9999"
                                placeholder="(787)123-45678"
                                value={field.value}
                                onChange={field.onChange}
                                className="form-control"
                            />
                        )}
                        control={control}
                        name="telephone"
                        rules={{
                            required: { value: true, message: requiredMsg },
                            pattern: {
                                value: /^\(\d{3}\)\d{3}-\d{4}/,
                                message: t("support:pin.invalidFormat")
                            }
                        }}
                    />

                    {
                        errors?.telephone &&
                        <FieldError message={errors?.telephone?.message} />
                    }
                </div>

                <div className="form-group col-lg-6 col-12">
                    <label for="email">{t("support:pin.fields.3")}</label>
                    <input
                        {...register("email", { required: requiredMsg })}
                        type="email" className="form-control" id="email" name="email" aria-describedby="email" placeholder={t("support:pin.fields.3")}
                        maxLength={64}
                    />
                    {
                        errors?.email &&
                        <FieldError message={errors?.email?.message} />
                    }
                </div>

                <div className="form-group col-lg-6 col-12">
                    <label for="typeOfId">{t("support:pin.fields.4.label")}</label>

                    <select {...register("typeOfId", { required: requiredMsg })} className="custom-select">
                        <option disabled selected value="">{t("support:pin.fields.4.options.select")}</option>
                        <option value="Licencia">{t("support:pin.fields.4.options.0")}</option>
                        <option value="ID">{t("support:pin.fields.4.options.1")}</option>
                        <option value="Tablilla">{t("support:pin.fields.4.options.2")}</option>
                    </select>
                    {
                        errors?.typeOfId &&
                        <FieldError message={errors?.typeOfId?.message} />
                    }
                </div>

                <div className="form-group col-lg-6 col-12">
                    <label for="numberOfId">{t("support:pin.fields.5.label")}</label>
                    <input
                        {...register("numberOfId", {
                            required: requiredMsg,
                            minLength: {
                                value: 6,
                                message: `${t("support:pin.minLength")} 6`
                            },
                            maxLength: {
                                value: 12,
                                message:  `${t("support:pin.maxLength")} 12`
                            }
                        })}
                        type="number" 
                        className="form-control" 
                        id="numberOfId" 
                        name="numberOfId" 
                        aria-describedby="numberOfId" 
                        placeholder={t("support:pin.fields.5.placeholder")}
                        maxLength={12}
                    />
                    {
                        errors?.numberOfId &&
                        <FieldError message={errors?.numberOfId?.message} />
                    }
                </div>

                <div className="form-group col-lg-6 col-12">
                    <label for="description">{t("support:pin.fields.6.label")}</label>
                    <textarea
                        {...register("description", { required: requiredMsg })}
                        type="string" className="form-control" id="description" name="description" aria-describedby="description" placeholder={t("support:pin.fields.6.placeholder")}
                        maxLength={500}
                    />
                    {
                        errors?.description &&
                        <FieldError message={errors?.description?.message} />
                    }
                </div>

                <div className="form-group col-lg-6 col-12">
                    <label for="dateOfIncident">{t("support:pin.fields.7")}</label>
                    <input
                        {...register("dateOfIncident", { required: requiredMsg })}
                        type="date"
                        max={new Date().toISOString().slice(0, 10)}
                        className="form-control" id="dateOfIncident" name="dateOfIncident" aria-describedby="dateOfIncident" placeholder={t("support:pin.fields.7")}
                    />

                    {
                        errors?.dateOfIncident &&
                        <FieldError message={errors?.dateOfIncident?.message} />
                    }
                </div>

                <div className="form-group col-12 d-flex justify-content-center align-items-center mt-lg-3">
                    {/* <button type="submit" className="btn btn-primary w-40">Submit</button> */}
                    <input
                        type="submit"
                        style={{ backgroundColor: Colors.primary, color: 'white', opacity: loading ? 0.7 : 1 }} className='w-40 rounded p-3 border-0'
                        disabled={loading}
                        value={loading ? t("support:pin.button.sending") : t("support:pin.button.submit")}
                    />

                </div>
            </form>
        </div>

    )
}

const FieldError = (props) => {
    return (
        <small className="form-text text-danger">{props?.message}</small>
    )
}

export default PinSupport