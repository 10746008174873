import { api, handleResponse } from "../APICaller";


export const licenseRenewalRequirements = () => {
    return api.get('/renewal/requirements')
};

export const licenseRenewalCharges = (realId) => {
    return api.get(`/renewal/charges?realId=${realId}`);
};

export const renewLicense = (form) => {
    return api.post('/renewal/pay', form);
};

export function getLicenseRenewalVoucherData() {
    return api.get(`/renewal/voucher/data`);
}

export function openLicenseRenewalVoucher(onSuccess, onError) {
    return api
        .get(`/renewal/voucher/pdf`, {}, { responseType: 'arraybuffer' })
        .then((response) => handleResponse(response, onSuccess, onError));
}

export const fetchOnlineRenewalCescos = (onSuccess, onError) => {
    return api
        .get('/renewal/locations')
        .then((response) => handleResponse(response, onSuccess, onError));
};

export const savePickup = (form) => {
    return api.post('/renewal/pickup', form);
};
