import React, {useEffect, useState} from "react";

import {FontWeight} from "../../components/ui/FontSize";
import {Colors} from "../../Colors";
import Text from "./Text";
import {useTranslation} from "react-i18next";
import moment from "moment";

const DaysLeftSquare = (props) => {
    const [timeNumber, setTimeNumber] = useState(0);
    const [timeString, setTimeString] = useState("day");
    const [backgroundColor, setBackgroundColor] = useState('')
    const {days, dueDate, error, color} = props;

    const {t} = useTranslation();

    useEffect(() => {
        if (!dueDate) {
            setTimeNumber(days)
            setTimeString(days > 1 ? "days" : "day")
            return;
        }
        let timeValue;
        let daysUsed = days;
        if (!daysUsed)
            daysUsed = Math.abs(moment().diff(new Date(dueDate), 'days'));
        if (daysUsed <= 0)
            daysUsed = Math.abs(moment().diff(new Date(dueDate), 'days'))
        if (daysUsed > 90 && daysUsed < 365) {
            setTimeNumber(timeValue = Math.abs(moment().diff(new Date(dueDate), 'months')));
            setTimeString(timeValue > 1 ? "months" : "month")
        } else if (daysUsed >= 365) {
            setTimeNumber(timeValue = Math.abs(moment().diff(new Date(dueDate), 'years')));
            setTimeString(timeValue > 1 ? "years" : "year")

        } else {
            setTimeNumber(timeValue = Math.abs(moment().diff(new Date(dueDate), 'days')))
            setTimeString(timeValue > 1 ? "days" : "day")
        }
    }, [dueDate, days]);

    useEffect(() => { calcBgColor() }, [color, error])

    const calcBgColor = () => {
        if (color) {
            setBackgroundColor(color)
        } else {
            error ? setBackgroundColor(Colors.error) : setBackgroundColor(Colors.orange);
        }
    }


    return (
        <div
            role="text"
            aria-label={`${days} ${t('alerts:daysLeft')}`}
            className="d-flex"
            style={{
                ...daysBlock,
                backgroundColor: backgroundColor
            }}
        >
            <Text
                i18nKey={`${timeNumber}`}
                style={{
                    fontWeight: FontWeight.bold,
                    marginBottom: "-3px",
                    fontSize: "20px",
                }}
            />
            <Text i18nKey={`alerts:${timeString}`} style={{fontSize: '12px', fontWeight: FontWeight.medium}}/>
        </div>
    );
};

const daysBlock = {
    textAlign: "center",
    height: "45px",
    width: "45px",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3px",
    padding: "15px",
};

export default DaysLeftSquare;
