import React from "react";
import { Colors } from "../../Colors";

const BlockButton = props => {
    let disabled = props.loading || props.disabled;
    return (
        <button
            type={props.type || "button"}
            style={buttonStyle}
            disabled={disabled}
            className={`login-btn btn btn-primary ${disabled && "default-cursor" }`}
            onClick={() => {
                if (props.onClick) props.onClick();
            }}
        >
            {props.loading ? (
                <div className="d-flex justify-content-center">
                    <div
                        className="spinner-border-login"
                        role="status"
                        style={{ height: 20, width: 20 }}
                    >
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <React.Fragment>{props.children}</React.Fragment>
            )}
        </button>
    );
};

const buttonStyle = {
    width: '100%',
    maxHeight: "60px",
    borderRadius: "4px",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    lineHeight: 1.3,
    borderColor: "transparent",
    backgroundColor: Colors.primary,
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

export default BlockButton;
