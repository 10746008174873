import React from "react";
import PropTypes from 'prop-types'

const Icon = props => {
    return (
        <span style={props.style}>
            <img
                src={props.src}
                style={{ width: '36px', height: '24px' }}
                alt={props.alt}
                onClick={props.onClick}
            />
        </span>
    );
};

Icon.propTypes = {
    src: PropTypes.string,
    style: PropTypes.object,
    alt: PropTypes.string,
    onClick: PropTypes.func
}

export default Icon;
