import React from 'react';
import PropTypes from 'prop-types'
import Text from "../ui/Text";
import { Colors } from "../../Colors";
import _ from 'lodash';

const TabNavigation = (props) => {

    const {
        activeTab,
        changeTab,
        actionButton,
        tabNavigationItems,
        finesDisabled
    } = props

    return (
        <div>
            <ul className="nav nav-classic d-flex flex-row align-items-center">
                {
                    tabNavigationItems && tabNavigationItems.length > 0 &&
                    tabNavigationItems.map((item, index) => (
                        <li className="nav-item">
                            <a
                                role={"tab"}
                                aria-selected={activeTab === index}
                                className={`nav-link ${activeTab === index ? "active" : null}`}
                                style={{
                                    paddingRight: 10,
                                    paddingLeft: 10,
                                    opacity: finesDisabled && index === 0 ? 0.5 : 1
                                }}
                                onClick={() => {
                                    finesDisabled && index === 0 ?
                                        _.noop()
                                        :
                                        changeTab(index)
                                }}
                            >
                                <Text
                                    i18nKey={item.i18nKey}
                                    style={{
                                        color:
                                            activeTab === index
                                                ? Colors.primary
                                                : Colors.gray,
                                    }}
                                />
                            </a>
                        </li>

                    ))
                }
                {actionButton && actionButton()}
            </ul>
        </div>
    );
};

const navTabStyle = {
    paddingRight: 10,
    paddingLeft: 10
}

TabNavigation.propTypes = {
    finesDisabled: PropTypes.bool
}

export default TabNavigation;