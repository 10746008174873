import React, { useState } from "react";

import SubHeader from "../../../layout/SubHeader";
import PageTitle from "../../../components/ui/PageTitle";
import Alert from "../../../layout/Alert";
import Text from "../../../components/ui/Text";
import { FontWeight } from "../../../components/ui/FontSize";
import { Colors } from "../../../Colors";
import NotEligibleModal from "../components/NotEligibleModal";
import NotEligibleIcon from "../components/NotEligibleIcon";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const NotEligible = () => {
    const [modal, setModal] = useState(false);
    const [reason, setReason] = useState({});
    let renewal = useSelector((state) => state.renewal);
    const history = useHistory();

    const toggleModal = (reason) => {
        setModal(!modal);
        setReason(reason);
    };
    let reasons = renewal?.eligibleRequirements.filter(
        (e) => e.eligible === false
    );
    return (
        <>
            <SubHeader>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    <div className="container">
                        <PageTitle
                            style={{ fontWeight: "bold" }}
                            i18nKey={"notEligible:pageTitle"}
                        />
                    </div>

                    <div className="container" style={{ padding: 0 }}>
                        <Alert
                            actionBtnText={"CESCOS"}
                            error={false}
                            action={() => history.replace("/locations")}
                        />
                    </div>
                </div>
            </SubHeader>

            {modal ? (
                <NotEligibleModal toggleModal={toggleModal} reason={reason} />
            ) : null}

            <div className="container" style={{ padding: 0 }}>
                <div className="p-mob-only">
                    <div style={{ marginBottom: "15px" }}>
                        <Text i18nKey="notEligible:subTitle" style={subTitle} />
                    </div>
                </div>
                {reasons.map((r) => (
                    <div
                        className="hoverable col-12"
                        style={reasonStyle}
                        onClick={() => toggleModal(r)}
                    >
                        <div style={{ display: "flex" }}>
                            <NotEligibleIcon type={r.type} />
                            <div style={reasonContent}>
                                <Text
                                    style={{
                                        fontWeight: FontWeight.medium,
                                    }}
                                    // i18nKey={`notEligible:eligibility.${r.type}.title`}
                                >
                                    {r.title}
                                </Text>
                                <Text
                                    style={{ color: Colors.gray }}
                                    // i18nKey={`notEligible:eligibility.${r.type}.description`}
                                >
                                    {r.description}
                                </Text>
                            </div>
                        </div>
                        <div
                            style={{ marginLeft: "10px" }}
                            aria-hidden={true}
                            className="caret-right"
                        />
                    </div>
                ))}
            </div>
        </>
    );
};

const subTitle = {
    fontWeight: FontWeight.medium,
    fontSize: "20px",
};

const reasonStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px",
    borderBottom: `1px solid ${Colors.borderGray}`,
};


const reasonContent = {
    display: "flex",
    flexDirection: "column",
};

export default NotEligible;
