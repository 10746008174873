import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import * as API from "../../../core/APICaller";
import {useTranslation} from 'react-i18next'
import TransferVoucherBody from "./TransferVoucherBody";

const mockPayload = {
    cesco: "TBD",
    total: 92.0,
    charges: [{
        type: "vehicleTransferFee",
        title: "Ley 22",
        amount: 15.0,
        caption: "Ley 22 Cargo Traspaso",
        conceptPaid: 2014,
        conceptType: 60
   }, {
        type: "law24Fee",
        title: "Ley Núm. 24-2017",
        amount: 2.0,
        caption: "Cargo Especial Centro de Trauma",
        conceptPaid: 842,
        conceptType: 62
    }],
    qrImage: "iVBORw0KGgoAAAANSUhEUgAAASwAAAEsAQAAAABRBrPYAAACCklEQVR4Xu2ZO47DMAxEaaRwmSPoKDqadTQfJUdImSIwlzOUN4iywG65wIiNPnxuCHJI2+Z/sbuNNz/axAab2GATG2xig/1/bDdYcV+esVY/Lg8svLVNEeO27NviD3iWvOyLJGa23kvsnna9VQ/6CiyiWJSx3ZbnerfaYsG1POYNh5ttxyWWKo1hG3E7LAOGE5fTp4ZBXxAiJNJr4a0pYqc1u1CCoxlBcV6mhiFuoTFmKKmgqTg4nbQalkUUGgNxMY4r4W8osPdEksHaYbCN40r1hc2ouQ9x08KuEbdzXMku1CVYEYvUYTNK0cVAiwzCs+mWwxpryfIQ2htNGuFzlpsglvMbIxV+0gif5RiniJ2DG7qzpQQXR9zeKksHw5wSomuMlGd3LtAf5pMgtnMyod8gut2Pd+O3ytLBYvsIv7E7fyeSoUNVAHJYZtDO7oyLfIgdikc9DJEKQ0lhycvPypLCXt359H9Ulg62IXUMX5/DH5Xl7M7EKgA9LLtQn2spNca4QXEksQNfGikuETdUFue3iCJjKod1Y4FFpJrRQonxrCK285o3LDD4jU167M4q2IZtcYcDF110s8AkMbwDFiTSit83qTg2VpYehi6UJ2RQ61/blDFEKvxMJDQjZpckhm1hM4pEQoFBgnORxJgxiBvffPACRO0d5zcZ7Feb2GATG2xig01sMCHsC6h8MNrLiI1qAAAAAElFTkSuQmCC",
    language: "es",
    saleDate: 1624049209261,
    idcNumber: "20210618-f8b7smx",
    finesCount: 1,
    finesTotal: 50.0,
    finesCredit: 0.0,
    renewalDate: 1624049209261,
    chargesTotal: 17.0,
    buyerLastName: "POLANCO",
    chargesActive: true,
    paymentMethod: "VISA",
    buyerFirstName: "DIANA",
    finesPenalties: 25.0,
    sellerLastName: "ORTIZ TORRES",
    finesFinalTotal: 75.0,
    sellerFirstName: "EDRICK",
    requiredDocuments: null,
    transactionReceipt: "06182117307V",
    vehicleDescription: "2005 INFINITI G35",
    digitalExpirationDate: null
}

const VehicleTransferVoucher = () => {
    const [data, setData] = useState({});
    const [loading, toggleLoading] = useState(false);

    const {id} = useParams();
    const history = useHistory()
    const {t} = useTranslation()

    // Set mock payload to data
    useEffect(() => {
        setData(mockPayload)
    }, [])


    // useEffect(() => {
    //     if (id) searchIdc()
    //     else history.push('/validate')
    // }, [])

    const searchIdc = async () => {
        toggleLoading(true);
        await API.validateIdc(id, onSuccess, onError);
    };

    const onSuccess = (payload) => {
        setData(payload);
        toggleLoading(false)
    };

    const onError = () => {
        // history.push('/validate')
        toggleLoading(false)
    };

    return (
        <>
            <TransferVoucherBody data={data} loading={loading}/>

            <div
                className="container"
                style={{display: "flex", justifyContent: "center"}}
            >
                <button
                    className="btn btn-primary"
                    style={{margin: "20px 0", width: "200px"}}
                    onClick={() => history.push('/validate')}
                >
                    {t("validate:closeButton")}
                </button>
            </div>
        </>
    );
};

export default VehicleTransferVoucher;