import React from "react";
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from "react-redux";
import certificacionMultaIcon from "../../../assets/images/certificacion-multas-icon.svg";
import registroChoferil from "../../../assets/images/icon-certificacion-registro-choferil.svg";
import * as Api from "../../../core/APICaller";
import * as Utils from "../../../core/Utils";
import { caller, showError } from "../../../redux/loading/loadingState.combine";
import CertificationsComponent from "../Certifications";

const LicenseCertifications = props => {

    const { vehicleSelection } = props

    let dispatch = useDispatch();
    let canPrintDriverRecord = useSelector(
        state => state.features?.reports?.printDriverCertification
    );

    const openFineReport = () => {
        dispatch(
            caller(
                Api.fetchLicenseFinesReport(data => {
                    Utils.openDialog(data);
                }, onReportError)
            )
        );
    };

    const openRenewNotification = () => {
        dispatch(
            caller(
                Api.fetchClientIdentity(data => {
                    Utils.openDialog(data);
                }, onReportError)
            )
        );
    };

    const onReportError = () => {
        dispatch(showError("Error al generar reporte"));
    };

    const certifications = [
        {
            name: "profile:certifications.fines",
            icon: certificacionMultaIcon,
            action: openFineReport,
            enable: true
        },
        {
            name: "profile:certifications.renewal",
            icon: registroChoferil,
            action: openRenewNotification,
            enable: canPrintDriverRecord
        }
    ];

    return (
        <CertificationsComponent
            certifications={certifications}
            source={"profile"}
            vehicleSelection={vehicleSelection}
        />
    );
};

LicenseCertifications.propTypes = {
    vehicleSelection: PropTypes.bool
}

export default LicenseCertifications;
