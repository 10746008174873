import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Colors } from "../../../Colors";
import { FontWeight } from "../../../components/ui/FontSize";
import MissingDocsIcon from "../components/MissingDocsIcon";
import FineTotalBadge from "../../../components/ui/FineTotalBadge";
import Text from "../../../components/ui/Text";
import Radio from "../../../components/ui/Radio";
import Dropzone from "react-dropzone";

import dangerIcon from "../../../assets/images/icon_Warning_Red.svg";
import iconInformationCvc from "../../../assets/images/icon-information-cvc.svg";

import SignatureCanvas from "react-signature-canvas";

const MissingDocListItem = (props) => {
    const [dropDown, toggleDropDown] = useState(false);
    const [number, setNumber] = useState("");
    const [modal, toggleModal] = useState(false);

    const { doc, idx } = props;
    const { t } = useTranslation();

    return (
        <>
            <div
                style={listItemWrapper}
                label={doc.title}
                key={idx}
                className="hoverable"
                onClick={() => toggleDropDown(!dropDown)}
            >
                <div style={innerListItem}>
                    <div style={itemTitle}>
                        {doc.error ? (
                            <img
                                src={dangerIcon}
                                aria-hidden={true}
                                style={{ width: "24px", marginRight: "10px" }}
                                alt=""
                            />
                        ) : (
                            <FineTotalBadge amount={idx + 1} />
                        )}
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <Text
                                i18nKey={doc.title}
                                className="text"
                                style={{
                                    fontWeight: FontWeight.bold,
                                    lineHeight: 1.6,
                                }}
                            />
                            <Text
                                i18nKey={doc.description}
                                className="text-sm"
                                style={{
                                    color: Colors.gray,
                                }}
                            />
                        </div>
                    </div>

                    <div style={itemTitle}>
                        {doc.confirmed && <Radio state={true} />}

                        <div
                            aria-hidden={true}
                            className="caret-right"
                            style={{
                                transform: dropDown && "rotate(90deg)",
                                transition: "all 0.4s ease",
                                marginLeft: "25px",
                            }}
                        />
                    </div>
                </div>
            </div>

            {dropDown && (
                <div style={listItemWrapper}>
                    <div style={dropzoneWrapper}>
                        <Dropzone
                            onDrop={(acceptedFiles) =>
                                console.log(acceptedFiles)
                            }
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Text>
                                            Drag and drop some files here, or
                                            click to select files
                                        </Text>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>

                    <div style={documentUploadStyle}>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span
                                    className="input-group-text"
                                    style={{
                                        backgroundColor: Colors.lightGray,
                                        borderRadius: 0,
                                    }}
                                >
                                    <Text
                                        style={{
                                            width: "24px",
                                            fontWeight: FontWeight.bold,
                                        }}
                                    >
                                        #
                                    </Text>
                                </span>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                aria-label={t("missingDocs:listItemInput")}
                                placeholder={t("missingDocs:listItemInput")}
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                                style={{
                                    height: "auto",
                                    borderRadius: 0,
                                }}
                            />
                            <span>
                                <img
                                    src={iconInformationCvc}
                                    className="creditCardPadding"
                                    alt={""}
                                    style={{
                                        position: "absolute",
                                        right: 0,
                                        top: 17,
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        props.toggleModal(!props.modal)
                                    }
                                />
                            </span>
                        </div>
                    </div>

                    <div>
                        <SignatureCanvas
                            penColor="green"
                            canvasProps={{
                                width: 500,
                                height: 200,
                                className: "sigCanvas",
                            }}
                        />
                    </div>

                    <Text
                        i18nKey={doc.description}
                        className="text-sm"
                        style={{
                            color: Colors.gray,
                        }}
                    />
                    <Text style={{ color: Colors.gray }} i18nKey={doc.body} />

                    <div
                        id="htmlRenderBody"
                        dangerouslySetInnerHTML={{ __html: doc.body }}
                    />
                </div>
            )}
        </>
    );
};

const dropzoneWrapper = {
    width: "100%",
    padding: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px dashed ${Colors.borderGray}`,
    borderRadius: "4px",
    marginBottom: "20px",
};

const documentUploadStyle = {};

const listItemWrapper = {
    backgroundColor: "#fff",
    padding: "15px",
    borderTop: `solid 1px ${Colors.borderGray}`,
};

const innerListItem = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

const itemTitle = {
    display: "flex",
    alignItems: "center",
};

export default MissingDocListItem;
