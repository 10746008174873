import React from 'react'
import { Colors } from '../../../Colors'
import PageTitle from '../../../components/ui/PageTitle'
import WizardFooter from '../../../components/widgets/WizardFooter'
import SubHeader from '../../../layout/SubHeader'
import '../index.css'
import { FontWeight } from '../../../components/ui/FontSize'
import Text from '../../../components/ui/Text'
import { useTranslation } from 'react-i18next'
import { isMobile } from '../../../core/Utils'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import VehicleInfoRow from '../components/VehicleInfoRow'
import { useEffect } from 'react'

const InsuranceInstructions = () => {


    const { i18n } = useTranslation();
    const mobile = isMobile()
    const history = useHistory()

    const selectedVehicle = useSelector(s => s?.insurance?.selectedVehicle)

    useEffect(() => {
        // If no selected vehicle redirect home
        if (!selectedVehicle) history.push('/landing')
    }, [selectedVehicle])

    const createMarkup = () => {
        return {
            __html: `<iframe src='https://cesco-digital.web.app/acaa/slides/first?lang=${i18n.language === "en-US" ? 'en' : 'es'}' style=${markupStyle} scrolling="no" />`
        };
    }

    const markupStyle = `width:100%;${mobile ? 'min-height:130vh;' : 'min-height:400px;'}border:none;`

    const handleNext = () => {
        history.push('/insurance/selection')
    }

    return (
        <div>
            <SubHeader>

                <div className="p-mob-only">
                    <PageTitle
                        i18nKey={"insuranceInstructions:title"}
                    />

                    <div className="d-none d-lg-flex">
                        <hr />
                    </div>

                    <Text
                        i18nKey="insuranceInstructions:subTitle"
                        className="text"
                        style={{ color: Colors.gray }}
                    />

                </div>
            </SubHeader>

            <div className="container">
                <div className="row">
                    <div className="col">

                        <VehicleInfoRow vehicle={selectedVehicle} />

                        <div>
                            <hr />
                        </div>

                        <div className='mb-3'>
                            <Text
                                i18nKey="insuranceInstructions:instructions"
                                style={{ fontWeight: FontWeight.medium }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className='row'>

                    <div className="col p-0 px-lg-3">
                        <div dangerouslySetInnerHTML={createMarkup()} />
                    </div>
                </div>

                <WizardFooter
                    handleNext={() => handleNext()}
                    disabled={false}
                    hideBack
                />

            </div>
        </div>

    )
}

export default InsuranceInstructions