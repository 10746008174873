import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Colors } from '../../Colors';
import Icon from '../ui/Icon';
import Text from '../ui/Text';
import PropTypes from 'prop-types'
import ClickableRow from '../ui/ClickableRow';
import carIcon from '../../assets/images/carro-icon.svg'
import pendingCarIcon from '../../assets/images/electronic-tag/pre-registered-pending.svg'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPreboardingVehicle, setTagPreboardingRevalidationModal } from '../../redux/electronicTag/electronicTag.combine';
import NoVehicleIcon from "../../assets/images/no-vehiculo-icon.svg";
import { useTranslation } from 'react-i18next';

const VehicleSelectionModal = (props) => {

    const { t } = useTranslation();

    let user = useSelector((state) => state.login?.user);

    const userVehicles = user?.vehicles || []

    const { toggle } = props

    const notPreboardedVehicles = userVehicles.filter(v => v.tagPreboarding === null)

    const RenderNotPreboarded = () => {

        const vehicles = notPreboardedVehicles

        return (
            <div>
                {
                    vehicles && vehicles?.length > 0 && vehicles.map((v, i) => (
                        (v.tag.type !== "DIGITAL") ? (
                            <VehicleRowItem v={v} i={i} />
                        ) : null
                    ))
                }
            </div>
        )
    }

    const RenderEmptyList = () => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    minHeight: "12rem",
                }}
            >
                <img
                    src={NoVehicleIcon}
                    style={{ margin: "1rem" }}
                    alt={""}
                />
                <p>{t("alerts:noVehicles")}</p>
            </div>
        )
    }

    const noVehicles = (!notPreboardedVehicles || notPreboardedVehicles?.length === 0)

    return (
        <Modal
            isOpen={true}
            size="md"
            toggle={() => toggle && toggle(false)}
            role="dialog"
            aria-modal={true}
            autoFocus={true}
        >
            <ModalHeader
                style={{ backgroundColor: Colors.lightGray }}
                toggle={() => toggle && toggle(false)}
            >
                <Text className="text">{t("vehicleList:selectVehicle")}</Text>
            </ModalHeader>
            <ModalBody
                style={{
                    display: "flex",
                    flexDirection: 'column',
                    padding: 0,
                    borderBottom: 0
                }}
            >
                {
                    noVehicles ?
                        RenderEmptyList()
                        :
                        <RenderNotPreboarded />
                }
            </ModalBody>

            <ModalFooter style={{ border: 'none' }} />
        </Modal>
    )
}

const VehicleRowItem = (props) => {

    const { v, i } = props

    const history = useHistory()
    const dispatch = useDispatch()

    const handleAction = () => {

        dispatch(setPreboardingVehicle(v))
        history.push('/preboarding/instructions')
    }

    return (
        <ClickableRow key={i} action={() => handleAction()}>
            <div className='d-flex align-items-center w-100'>
                <img src={
                    v?.tagPreboarding?.status === "pending"
                        ? pendingCarIcon
                        : carIcon
                } alt="" aria-hidden style={{ width: 35 }} />
                <div className='d-flex flex-column text-left ml-3 w-100 text'>
                    <strong>{v?.year} {v?.make}</strong>
                    <div style={{ color: Colors.gray }} className="d-flex flex-column">
                        <span>{v?.model}</span>
                        <span>{v?.plate}</span>
                    </div>
                </div>
            </div>
        </ClickableRow>
    )
}

VehicleRowItem.propTypes = {
    v: PropTypes.object,
    i: PropTypes.number,
}

VehicleSelectionModal.propTypes = {
    toggle: PropTypes.func
}

export default VehicleSelectionModal