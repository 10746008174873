import React, { useEffect, useState } from 'react'
import { Colors } from '../../../Colors'
import Card from '../../ui/Card'
import ComponentTitle from '../../ui/ComponentTitle'
import Text from '../../ui/Text'
import OrganDonorModal from './OrganDonorModal'
import { FontWeight } from '../../ui/FontSize'

import organDonorArt from '../../../assets/images/organ-donor/icon_DonanteOrgano_Si.svg'
import noDonorArt from '../../../assets/images/organ-donor/icon_DonanteOrgano_No.svg'
import { useSelector } from 'react-redux'

const OrganDonor = () => {

    const [modal, setModal] = useState(false)

    const isDonor = useSelector(s => s?.card?.personalInfo?.donor)

    return (
        <div>
            {
                modal &&
                <OrganDonorModal
                    toggle={setModal}
                />
            }
            <ComponentTitle
                style={{ fontWeight: "bold" }}
                i18nKey={"organDonor:title"}
            />
            <Card
                hoverable
                onClick={() => setModal(true)}
            >
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        <img
                            src={isDonor ? organDonorArt : noDonorArt}
                            alt=""
                            aria-hidden="false"
                            className='mr-3'
                            style={{ width: 32}}
                        />
                        <Text
                            className="text"
                            i18nKey='organDonor:donor'
                            style={{ fontWeight: FontWeight.medium}}
                        />
                    </div>

                    <span
                        aria-hidden={true}
                        className="caret-right"
                    />
                </div>
            </Card>
        </div>
    )
}

export default OrganDonor