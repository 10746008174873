import React, {useEffect, useRef, useState} from "react";

import PageTitle from "../../components/ui/PageTitle";
import Text from "../../components/ui/Text";
import {Colors} from "../../Colors";

import iconSearch from "../../assets/images/search-icon.svg";
import errorIcon from "../../assets/images/icon_Warning_White.svg";

import {useTranslation} from "react-i18next";
import Card from "../../components/ui/Card";
import * as API from "../../core/APICaller";
import {useHistory} from "react-router-dom";

import {loadReCaptcha} from "react-recaptcha-v3";
import Recaptcha from "../Login/Recaptcha";


const Validate = () => {
    const [idcInput, handleIdcInput] = useState("");
    const [error, toggleError] = useState(false);
    const [loading, toggleLoading] = useState(false);
    const {t} = useTranslation();
    const history = useHistory();

    let url = window.location.href;
    const formatRegEx = /^\d{8}-[\da-z]{7}/i;

    const recaptchaRef = useRef();

    useEffect( () => {
        loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY);
    }, []);

    useEffect(() => {
        const { current } = recaptchaRef;
        current.execute();
    },[]);

    const searchIdc = async () => {
        toggleLoading(true);
        history.push(`/validate/${idcInput}`)
        // await API.validateIdc(idcInput, onSuccess, onError);
    };

    /*const onSuccess = () => {
        toggleLoading(false);
        history.push(`/validate/${idcInput}`)
    };

    const onError = () => {
        toggleLoading(false);
        setError()
    };*/

    /*const setError = () => {
        toggleError(true);
        setTimeout(() => {
            toggleError(false);
        }, 4000);
    }*/

    useEffect( () => {
        let urlChange = null;
        if(url.includes("error")){
            urlChange = setTimeout(() => {
                history.replace("/validate");
            }, 4000)
        }
        return () => { clearTimeout(urlChange) }
    }, []);

    return (
        <>
            <div style={{backgroundColor: "white"}}>
                <div className="container">
                    <PageTitle
                        style={{fontWeight: "bold"}}
                        i18nKey={"validate:pageTitle"}
                    />
                    <div className="d-none d-lg-flex">
                        <hr/>
                    </div>
                    <Text i18nKey="validate:subTitle"/>
                </div>
                <Card
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        className="input-group input-group-lg shadow-lg rounded d-none d-lg-flex"
                        style={{
                            width: "100%",
                            maxWidth: "700px",
                            margin: "40px 0",
                            backgroundColor: Colors.disabledGray,
                        }}
                    >
                        {(error || url.includes("error")) && (
                            <div style={errorStyle}>
                                <img
                                    src={errorIcon}
                                    alt=""
                                    aria-hidden={true}
                                    style={{
                                        width: "24px",
                                        marginRight: "10px",
                                    }}
                                />
                                <Text
                                    i18nKey="validate:errorMessage"
                                    className="text"
                                />
                            </div>
                        )}
                        <div className="input-group-prepend" aria-hidden={true}>
                            <span
                                className="input-group-text"
                                style={{
                                    backgroundColor: Colors.lightGray,
                                    borderTopLeftRadius: "4px",
                                    borderBottomLeftRadius: "4px",
                                }}
                            >
                                <img
                                    src={iconSearch}
                                    alt={""}
                                    aria-hidden={true}
                                />
                            </span>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            aria-label={t("validate:form")}
                            placeholder={t("validate:form")}
                            value={idcInput}
                            onChange={(e) => handleIdcInput(e.target.value)}
                            style={{
                                height: "auto",
                                textTransform: "uppercase",
                                fontSize: 24,
                            }}
                        />

                        <div
                            className="input-group-prepend rounded-right"
                            style={{
                                border: "1px solid #d5dae2",
                                borderLeft: 0,
                            }}
                        >
                            <button
                                className="btn btn-primary rounded-right"
                                onClick={() => searchIdc()}
                                disabled={idcInput === "" || !formatRegEx.test(idcInput)}
                                style={{
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    width: 140
                                }}
                            >
                                {
                                    loading ?
                                        <div className='spinner-border-loading' role="status"
                                             style={{height: 30, width: 30}}
                                             aria-hidden="true">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        : t("validate:button")
                                }
                            </button>
                        </div>
                    </div>

                    {/* Mobile */}
                    <div
                        className="input-group input-group shadow-lg rounded d-flex d-lg-none"
                        style={{
                            width: "100%",
                            maxWidth: "700px",
                            margin: "40px 0",
                            backgroundColor: Colors.disabledGray,
                        }}
                    >
                        {(error || url.includes("error")) && (
                            <div style={errorStyle}>
                                <img
                                    src={errorIcon}
                                    alt=""
                                    aria-hidden={false}
                                    style={{
                                        width: "24px",
                                        marginRight: "10px",
                                    }}
                                />
                                <Text
                                    i18nKey="validate:errorMessage"
                                    className="text"
                                />
                            </div>
                        )}

                        <div className="flex-column w-100">
                            <input
                                type="text"
                                className="form-control text"
                                aria-label={t("validate:form")}
                                placeholder={t("validate:form")}
                                value={idcInput}
                                onChange={(e) => handleIdcInput(e.target.value)}
                                style={{
                                    height: "auto",
                                    textTransform: "uppercase",
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0
                                }}
                            />

                            <div className="d-flex w-100">
                                <button
                                      className="btn btn-primary btn-block text"
                                    onClick={() => searchIdc()}
                                    disabled={idcInput === "" || !formatRegEx.test(idcInput)}
                                    style={{
                                        borderTopRightRadius: 0,
                                        borderTopLeftRadius: 0
                                    }}
                                >
                                    {
                                        loading ?
                                            <div className='spinner-border-loading' role="status"
                                                 aria-hidden="true">
                                            </div>
                                            : t("validate:button")
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>

            <Recaptcha forwardRef={recaptchaRef} action=""/>
        </>
    );
};

const errorStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.error,
    width: "100%",
    height: "60px",
    marginBottom: "20px",
    color: "#fff",
    borderRadius: "4px",
};


export default Validate;
