import React from 'react'
import { useHistory } from "react-router-dom"
import iconBack from "../../../assets/images/arrow-back-icon.svg";
import { Colors } from "../../../Colors";
import { useTranslation } from 'react-i18next';

const HistoryBackButton = () => {
    const { t} = useTranslation()
    const history = useHistory()

    return (
        <button
            onClick={() => history.goBack()}
            className="btn-text btn-text-primary d-flex mr-3"
            style={{
                border: "none",
                color: Colors.primary,
            }}
            role="button"
            aria-label={t("wizardFooter:aria.back")}
        >
            <img src={iconBack} alt="" aria-hidden="true" />
            <span
                className="d-none d-lg-flex ml-2"
                style={{ alignItems: "center" }}
            >
                {t("wizardFooter:back")}
            </span>
        </button>
    )
}

export default HistoryBackButton