import React from "react";

import IconMasterCard from "../../assets/images/icon-master-card.svg";
import IconDiscover from "../../assets/images/icon-discover-card.svg";
import IconAmex from "../../assets/images/icon-amex-card.svg";
import IconVisa from "../../assets/images/icon-visa-card.svg";
import { Colors } from "../../Colors";
import { useTranslation } from "react-i18next";

const CreditCardIcon = (props) => {
    const { noPadding } = props;
    const paymentMethod = props.paymentMethod;
    const { t } = useTranslation();

    const style = {
        right: 0,
        zIndex: 1000,
        backgroundColor: "transparent",
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderBottomColor: "#e7eaf3",
        borderTopColor: "#e7eaf3",
        paddingRight: 5,
        ...props.style,
    };

    return (
        <span style={{ ...style, paddingTop: noPadding ? 0 : 13 }} className={props.className}>
            {paymentMethod === "MASTERCARD" ? (
                <img
                    src={IconMasterCard}
                    alt={t("paymentHistory:creditCard.mastercard")}
                />
            ) : paymentMethod === "VISA" ? (
                <img src={IconVisa} alt={t("paymentHistory:creditCard.visa")} />
            ) : null}
        </span>
    );
};

export default CreditCardIcon;
