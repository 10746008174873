import React, {useEffect, useState} from "react";
import {Colors} from "../../../Colors";
import CircleChart from "../../../components/ui/CircleChart";
import {FontSize} from "../../../components/ui/FontSize";
import Text from "../../../components/ui/Text";
import * as Utils from "../../../core/Utils";
import {formatCurrencyLabel} from "../../../core/Utils";
import PropsTypes from "prop-types";
import congrats from "../images/icon_Badge_MultasLic_Excelente.svg";
import congratsEn from "../../../assets/images/icon_Badge_MultasLic_Excelente_EN.svg";

import warningIcon from "../../../assets/images/icon_Warning_Red.svg";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const circleChartStyle = {
    color: Colors.gray,
    marginTop: "8px",
    display: "flex",
    flexDirection: "column-reverse",
    fontSize: FontSize.sm,
};

const mobCircleChartStyle = {
    color: Colors.gray,
    marginTop: "8px",
    display: "flex",
    flexDirection: "column",
    fontSize: FontSize.sm,
};


const TOTAL_LIMIT = 1000;

const FineScore = (props) => {
    const [progress, handleProgress] = useState(0);
    const [progressColor, handleProgressColor] = useState();
    const [fineTotal, handleFineTotal] = useState(0);
    const {i18n} = useTranslation();
    const [lang, setLang] = useState(i18n.language);
    let user = useSelector((state) => state.login?.user);

    useEffect(() => {
        setLang(i18n.language);

        let {fines} = props;
        if (fines && fines.filter((f) => f.id && f.type === "l").length > 0) {
            let total = fines
                .filter((f) => f.type === "l")
                .map((f) => f.amount)
                .reduce((a, b) => a + b);
            let progress =
                total > TOTAL_LIMIT ? 100 : (total / TOTAL_LIMIT) * 100;
            let color = Utils.calculateTicketsColor(progress);
            handleProgress(progress);
            handleProgressColor(color);
            handleFineTotal(total);
        }
    });

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: Colors.gray,
                height: 'inherit',
                width: '100%'
            }}
            className="sub-header-container"
        >
            {/*<Text
                style={{color: progressColor, lineHeight: "1"}}
                className={"d-none d-md-block d-sm-block"}
            >
                Multas de Licencia
            </Text>*/}
            {fineTotal >= TOTAL_LIMIT ? (
                <img
                    src={warningIcon}
                    alt="Warning Icon"
                    className="d-none d-lg-block"
                    style={{marginRight: '8px'}}
                />
            ) : null}
            <Text
                style={{
                    color:
                        fineTotal >= TOTAL_LIMIT ? Colors.error : Colors.gray,
                    lineHeight: "1",
                }}
                i18nKey={user?.license?.type === "ID" ? "main:subHeader.fineScoreId" : "main:subHeader.fineScore"}
                className="sub-header-chart-text"
            />
            {fineTotal === 0 && (
                <div>
                    <img
                        src={lang === "en-US" ? congratsEn : congrats}
                        alt={"main:noNegativePoints"}
                        className="sub-header-icon"
                    />
                </div>
            )}
            {fineTotal > 0 && (
                <>
                    <div className="d-none d-lg-flex">
                        <CircleChart
                            progress={progress}
                            progressColor={progressColor}
                        >
                            <div
                                style={{
                                    ...circleChartStyle,
                                    color:
                                        fineTotal >= TOTAL_LIMIT
                                            ? Colors.error
                                            : Colors.gray,
                                }}
                            >
                                <Text style={{fontSize: "11px"}}>
                                    {formatCurrencyLabel("1000")}
                                </Text>
                                <hr
                                    style={{
                                        margin: "2px 0",
                                        borderColor:
                                            fineTotal >= TOTAL_LIMIT
                                                ? Colors.error
                                                : Colors.gray,
                                    }}
                                />
                                <Text
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                    }}
                                >
                                    {`${formatCurrencyLabel(fineTotal, 0)}`}
                                </Text>
                            </div>
                        </CircleChart>
                    </div>
                    <div className="d-lg-none">
                        <CircleChart
                            progress={progress}
                            progressColor={progressColor}
                            mobile
                        >
                            <div
                                style={{
                                    ...mobCircleChartStyle,
                                    color:
                                        fineTotal >= TOTAL_LIMIT
                                            ? Colors.error
                                            : Colors.gray,
                                }}
                            >
                                <Text
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                    }}
                                >
                                    {`${formatCurrencyLabel(fineTotal, 0)}`}
                                </Text>
                                <hr
                                    style={{
                                        margin: "2px 0",
                                        borderColor:
                                            fineTotal >= TOTAL_LIMIT
                                                ? Colors.error
                                                : Colors.gray,
                                    }}
                                />
                                <Text style={{fontSize: "8px"}}>
                                    {formatCurrencyLabel("1000")}
                                </Text>
                            </div>
                        </CircleChart>
                    </div>
                </>
            )}
        </div>
    );
};

FineScore.propTypes = {
    fines: PropsTypes.array,
};

export default FineScore;
