import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { format, formatCurrencyLabel } from "../../core/Utils";

import Text from "../../components/ui/Text";
import { FontWeight } from "../../components/ui/FontSize";
import ComponentTitle from "../../components/ui/ComponentTitle";
import { Colors } from "../../Colors";
import CreditCardIcon from "../../components/ui/CreditCardIcon";
import TotalSummary from "../../components/widgets/TotalSummary";

import ModalFineListItem from "./ModalFineListItem";
import ErrorRefresh from "../../components/ui/ErrorRefresh";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import resendIcon from '../../assets/images/icon_SendEmail_Color.svg'
import { resendReceipt } from "../../core/APICaller";
import DateSquare from "../../components/ui/DateSquare";
import disputedIcon from "../../assets/images/icon_HistorialPago_Reclamado.svg";
import errorIcon from "../../assets/images/icon_Check_Cancel.svg";
import AutoexpresoTotalSummary from "../../components/widgets/AutoexpresoTotalSummary";

const PaymentHistoryModal = (props) => {
    const [summary, setSummary] = useState({});
    const [loading, toggleLoading] = useState(true);
    const [error, toggleError] = useState(false);

    useEffect(() => {
        toggleLoading(true);

        if (props?.data) {
            setSummary(props.data);
            toggleLoading(false);
            toggleError(false);
        } else {
            toggleLoading(false);
            toggleError(true);
        }
    }, []);

    const { toggle } = props;

    const {
        processedDate,
        paymentType,
        receiptNo,
        paymentMethod,
        fines,
        message,
        transactionId,
        finesCount,
        processed,
        status
    } = props?.data;

    const { t } = useTranslation();

    const handleEmailResend = async (id) => {
        id && await resendReceipt(id, onSuccess, onError)
    }

    const onSuccess = () => {
        alert(t("paymentHistory:modal.resendAlert.success"))
    }

    const onError = () => {
        alert(t("paymentHistory:modal.resendAlert.fail"))
    }

    return (
        <div>
            <Modal isOpen={true} toggle={toggle} size="md"
                className="payment-history-modal"
                role="dialog"
                centered={true}>

                <div style={modalHeaderStyle} className="p-3">
                    <div>
                        <Text style={{ fontSize: "18px" }} i18nKey={"paymentHistory:modal.title"} />
                    </div>

                    <div className="d-flex align-items-center justify-content-end">
                        {status === 'COMPLETE' && (
                            <span
                                onClick={() => handleEmailResend(transactionId)}
                                aria-label={t("paymentHistory:modal.resendEmailAlt")}
                            >
                                <img src={resendIcon} alt="" aria-hidden="true"
                                    style={{ width: 22, height: 'auto' }}
                                />
                            </span>
                        )}
                        <button
                            className="ml-3"
                            onClick={() => toggle()}
                            style={{ border: 'none', backgroundColor: 'transparent' }}
                            aria-label={t("paymentHistory:modal.close")}
                        >
                            <span aria-hidden="true" style={{ color: Colors.gray, fontSize: '1.5rem', fontWeight: '700' }}>×</span>
                        </button>
                    </div>
                </div>

                <ModalBody style={{ padding: 0 }}>
                    <div style={userInfoRow} className="alert-padding d-flex align-items-center">
                        <div style={rowItemStyle}
                            role="text"
                            className="pr-3"
                            aria-label={`${t("paymentHistory:modal.header.date")} ${format(processedDate, "MM/DDDD/YYYY")}`}>
                            <DateSquare date={processedDate} error={status === "ERROR"} grayed={status === "DISPUTED"} />
                        </div>
                        <div style={rowItemStyle} role="text"
                            aria-label={`${t("paymentHistory:modal.aria.header.transNum")} ${transactionId}`}>
                            <Text style={smallText}>
                                {t("paymentHistory:modal.header.transNum")}
                            </Text>
                            <br />
                            <Text className="text">
                                {_.defaultTo(transactionId, "-")}
                            </Text>
                        </div>
                        {paymentType && (<div style={{ ...userInfoRow, paddingTop: 0 }} className="alert-padding"
                            role="text"
                            aria-label={`${t("paymentHistory:modal.motive")}: ${"placeholder"}`}>
                            <div style={rowItemStyle}>
                                <Text style={smallText} i18nKey="paymentHistory:modal.motive" />
                                <br />
                                <Text className="text" i18nKey={`paymentHistory:modal.motives.${paymentType}`} />
                            </div>
                        </div>)}
                        <span role="text"
                            aria-label={`${t("paymentHistory:modal.aria.header.paymentMethod")}: ${paymentMethod}`}>
                            <CreditCardIcon paymentMethod={paymentMethod} />
                        </span>
                    </div>

                    {!processed && (
                        <div style={{ ...userInfoRow, paddingTop: 15, justifyContent: "start", backgroundColor: Colors.lightGray }}
                            className="alert-padding"
                            role="text">
                            {status === "DISPUTED" && (
                                <img src={disputedIcon} alt="Disputed Status" />
                            )}
                            {status === "ERROR" && (
                                <img src={errorIcon} alt="Error Status" />
                            )}
                            <div className="d-flex align-items-center pl-3">
                                <div>
                                    {status === "ERROR" ?
                                        <span style={{ color: Colors.error, fontWeight: 'bold' }}>
                                            {t("paymentHistory:error")}
                                        </span>
                                        :
                                        <span style={{ fontWeight: 'bold' }}>
                                            {t("paymentHistory:disputed")}
                                        </span>
                                    }
                                    <br />
                                    <span>
                                        {t("paymentHistory:paymentError")}
                                    </span>
                                </div>
                            </div>
                        </div>)}

                    {/* Green row */}
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: status === "COMPLETE" ? Colors.primary : status === "DISPUTED" ? Colors.gray : Colors.error,
                        color: "#fff",
                    }}
                        className="alert-padding"
                        role="text"
                        aria-label={`${t("paymentHistory:modal.finesTotal")}: ${fines}. TOTAL: ${formatCurrencyLabel(summary?.total)}`}>
                        <div>
                            <Text
                                style={{
                                    border: "none",
                                    fontWeight: FontWeight.medium,
                                }}
                                i18nKey={"paymentHistory:modal.finesTotal"}
                                className="text" />
                            <Text style={{ marginLeft: "5px", fontWeight: "bold", textDecoration: status === "DISPUTED" ? "line-through" : "none" }}
                                className="text">
                                {finesCount}
                            </Text>
                        </div>

                        <div>
                            <Text style={{ border: "none", fontWeight: FontWeight.medium, }}
                                className="text">
                                TOTAL:
                            </Text>
                            <Text style={{ marginLeft: "5px", fontWeight: "bold", textDecoration: status === "DISPUTED" ? "line-through" : "none" }} className="text">
                                {formatCurrencyLabel(summary?.total)}
                            </Text>
                        </div>
                    </div>

                    <div className="pl-3" style={{ borderBottom: `1px solid ${Colors.borderGray}` }}>
                        <ComponentTitle
                            i18nKey={"paymentHistory:modal.subTitle1"}
                            style={{ marginTop: "20px" }}
                            sm
                            noBorder
                        />
                    </div>

                    <div>
                        <div style={{
                            maxHeight: "280px", overflowY: "auto ", overflowX: "hidden",
                            borderBottom: `5px solid ${Colors.lightGray}`
                        }}>
                            {!error
                                ? (<>
                                    {fines?.map((f) => (
                                        <ModalFineListItem
                                            data={f}
                                            key={f.id}
                                            loading={loading}
                                        />
                                    ))}
                                </>) : (<ErrorRefresh refresh={toggle()} />)}
                        </div>
                    </div>
                </ModalBody>

                <div className="pl-3">
                    <ComponentTitle
                        i18nKey={"paymentHistory:modal.subTitle2"}
                        style={{ marginTop: "20px" }}
                        sm
                        noBorder
                    />
                </div>


                <ModalFooter style={{ backgroundColor: Colors.lightGray, padding: 0 }}>
                    {
                        paymentType === "AUTOEXPRESO_FINES" ?
                            <AutoexpresoTotalSummary
                                finesTotal={summary?.finesTotal}
                                tollAmount={summary?.finesPenalties}
                                total={summary?.total}
                                noLines
                            />
                            :
                            <TotalSummary
                                finesTotal={summary?.finesTotal}
                                finesPenalties={summary?.finesPenalties}
                                finesCredits={summary?.finesCredits}
                                total={summary?.total}
                                noLines
                            />
                    }
                </ModalFooter>
            </Modal>
        </div>
    );
};

const modalHeaderStyle = {
    backgroundColor: Colors.bgGray,
    borderTopRightRadius: '25%',
    borderTopLeftRadius: '25%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: Colors.gray,
    fontWeight: FontWeight.medium
}

const userInfoRow = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
};

const rowItemStyle = {
    flexDirection: "column",
};

const smallText = {
    fontSize: "13px",
    color: Colors.gray,
};

export default PaymentHistoryModal;
