import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../../components/ui/Button';
import art from '../../../assets/images/maintenance/Art_Mantenimiento.svg'
import { Colors } from '../../../Colors';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { setMaintenanceModal } from '../../../redux/app/app.combine';

const MaintenanceModal = (props) => {

    const { toggle } = props

    const [moreInfo, setMoreInfo] = useState()

    const maintenance = useSelector(s => s?.features?.maintenance)

    const { t, i18n } = useTranslation();
    const lang = i18n.language === 'en-US' ? 'en' : 'es'

    const startDate = moment.unix(maintenance?.date?.start).locale(lang).format("DD MMMM YYYY");
    const startTime = moment.unix(maintenance?.date?.start).locale(lang).format("hh:mm A");

    const dispatch = useDispatch()

    const handleButton = () => {
        dispatch(setMaintenanceModal(false))
        toggle()
    }

    return (
        <Modal
            isOpen={true}
            size="md"
            toggle={() => toggle(false)}
            role="dialog"
            aria-modal={true}
            autoFocus={true}
        >
            <ModalBody style={{ padding: 0 }}>
                <div className='w-full text-center pt-4 pb-3 border-bottom'>
                    <span className='text-bold text-md'>
                        {t("main:maintenance.title")}
                    </span>
                </div>

                <div
                    style={{ background: Colors.bgGray, marginBottom: 50 }}
                    className='border-bottom d-flex justify-content-center pt-5'
                >
                    <img
                        src={art}
                        aria-hidden="true"
                        alt=""
                        style={{ marginBottom: -40 }}
                    />
                </div>

                <div className='p-3'>
                    <span>
                        {t("main:maintenance.text.0")}
                        <strong> {startDate} </strong>
                        {t("main:maintenance.text.1")}
                        <strong> {startTime} </strong>
                        {t("main:maintenance.text.2")}
                        <strong> {maintenance?.date?.duration} {t("main:maintenance.text.3")}</strong>{t("main:maintenance.text.4")}.
                    </span>
                </div>

                {
                    moreInfo &&
                    <div className='m-3'>
                        <span> {maintenance?.[lang]?.more}</span>
                    </div>
                }

                <div className='text-center mb-3'>
                    <span
                        className='text-bold'
                        style={{ color: Colors.primary }}
                        onClick={() => setMoreInfo(!moreInfo)}
                    >{moreInfo ? t("main:maintenance.less") : t("main:maintenance.more")}</span>
                </div>

            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
                <Button
                    className="btn-primary w-40"
                    onClick={() => handleButton()}
                    disabled={false}
                >
                    {t("main:continue")}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

MaintenanceModal.propTypes = {
    toggle: PropTypes.func.isRequired
}

export default MaintenanceModal;
