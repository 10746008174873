import React, { useEffect, useState } from "react";

import CescoListItem from "../../components/widgets/CescoListItem";
import ErrorRefresh from "../../components/ui/ErrorRefresh";
import ComponentTitle from "../../components/ui/ComponentTitle";
import CescoDetails from "./CescoDetails";
import CescoSearchBar from "../../components/widgets/CescoSearchBar";
import CescoServicesModal from "./CescoServicesModal";
import PageTitle from "../../components/ui/PageTitle";
import * as Api from "../../core/APICaller";
import { caller } from "../../redux/loading/loadingState.combine";
import { useDispatch } from "react-redux";
import { Colors } from "../../Colors";
import { emptyObjArray } from "../../core/Utils";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { FontWeight } from "../../components/ui/FontSize";
import CescoList from "../../components/widgets/CescoList";
import ScrollToTopButton from "../../components/ui/ScrollToTopButton";

const Cescos = props => {
    const [loading, setLoading] = useState(false);
    const [cescos, setCescos] = useState(emptyObjArray(14));
    const [cescosFilter, setCescosFilter] = useState([]);
    const [error, setError] = useState(false);
    const [selectedCesco, setSelectedCesco] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        fetchCescos();
    }, []);

    const fetchCescos = () => {
        setLoading(true);
        setError(false);
        dispatch(
            caller(Api.fetchCescos(onFetchCescosSuccess, onFetchCescosError))
        );
    };

    const onFetchCescosSuccess = cescos => {
        setLoading(false);
        setCescos(cescos);
        setCescosFilter(cescos);
        setSelectedCesco(cescos[0]);
    };

    const onFetchCescosError = () => {
        setLoading(false);
        setError(true);
    };

    const selectCesco = cesco => {
        setSelectedCesco(cesco);
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const filterCescos = text => {
        if (_.isEmpty(text)) {
            setCescosFilter(cescos);
            return;
        }
        setCescosFilter(
            cescos.filter(c => c.name.includes(text.toUpperCase()))
        );
    };

    return (
        <div className="container">
            <PageTitle i18nKey={"cescos:title"} />
            <div style={{ marginTop: "40px" }} className="row">
                <div className="col-lg-4 col-12">
                    <CescoList
                        error={error}
                        loading={loading}
                        cescosFilter={cescosFilter}
                        fetchCescos={fetchCescos}
                        selectCesco={selectCesco}
                        filterCescos={filterCescos}
                        selectedCesco={selectedCesco}
                    />
                </div>
                <div className="col-lg-8 col-12">
                    <div className="d-none d-lg-block">
                        <CescoSearchBar onChange={filterCescos} />
                    </div>
                    {selectedCesco && (
                        <div id="cescos">
                            <ComponentTitle i18nKey={"cescos:address"} />
                            <CescoDetails
                                selectedCesco={selectedCesco}
                                toggle={toggleModal}
                            />
                        </div>
                    )}
                    {showModal ? (
                        <CescoServicesModal
                            toggle={toggleModal}
                            id={selectedCesco.id}
                        />
                    ) : null}
                </div>
            </div>


                <div
                    className="container d-flex d-md-none"
                    style={scrollToTopStyle}
                >
                    <ScrollToTopButton />
                </div>
        </div>
    );
};

const tableHead = {
    border: "none"
};

    const scrollToTopStyle = {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "10px",
    };

export default Cescos;
