import React, { useEffect, useState } from "react"
import { Colors } from "../../Colors"
import ComponentTitle from "../../components/ui/ComponentTitle"
import PageTitle from "../../components/ui/PageTitle"
import SubHeaderText from "../../components/ui/SubHeaderText"
import WizardFooter from "../../components/widgets/WizardFooter"
import SubHeader from "../../layout/SubHeader"
import VehicleInfoRow from "../../pages/Insurance/components/VehicleInfoRow"

import { useHistory } from "react-router-dom"
import iconInfo from "../../assets/images/icon-information-cvc.svg"

import PropTypes from 'prop-types'
import { useTranslation } from "react-i18next"
import InputMask from "react-input-mask"
import { useDispatch, useSelector } from "react-redux"
import prthArt from '../../assets/images/electronic-tag/Sello_PRHT.svg'
import errorIcon from '../../assets/images/icon_Cancelar_BtMultas.svg'
import { tagPreboardingRegister } from '../../core/api-resources/tag'
import { setPreboardingStatus } from "../../redux/electronicTag/electronicTag.combine"
import ValidationInputInfoModal from "./ValidationInputInfoModal"

const ElectronicTagValidationLayout = (props) => {

    const { vehicle: vehicleFromProps, subHeaderText } = props

    const preboardingVehicle = useSelector(s => s?.electronicTag?.preboardingVehicle)

    const [vehicle, setVehicle] = useState(null)

    useEffect(() => {

        if (vehicleFromProps) {
            setVehicle(vehicleFromProps)
        } else {
            setVehicle(preboardingVehicle)
        }
    }, [vehicleFromProps])


    const preBoardingRegistrationEnabled = useSelector(s => s?.features?.preBoarding?.preBoardingRegistrationEnabled)
    const userEmail = useSelector(s => s?.login?.user?.principal?.email)
    const errorFormUrl = useSelector(s => s.features?.preBoarding?.errorFormUrl)
    const user = useSelector(s => s?.login?.user?.principal)

    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation()

    const [input, setInput] = useState('')
    const [inputError, setInputError] = useState(false)
    const [infoModal, setInfoModal] = useState(false)

    const [submitted, setSubmitted] = useState(false)

    const [formError, setFormError] = useState(false)
    const [errorCode, setErrorCode] = useState(null)

    // useEffect(() => {
    //     if (!vehicle || !preBoardingRegistrationEnabled) history.push('/landing')
    // }, [vehicle])

    const handleValidation = async () => {
        setSubmitted(true)

        const onSuccess = (res) => {
            dispatch(setPreboardingStatus({ ...res?.payload, tagNumber: input }))
            handleNext()
        }

        const onError = (err) => {
            const status = err.status
            setErrorCode(err?.data?.code)
            setFormError(true)

            switch (status) {
                case 400:
                    setInputError(t("preboarding:error.400"))
                    break;
                case 406:
                    setInputError(t("preboarding:error.406"))
                    break;
                case 409:
                    if (err?.data?.code === "AlreadyRegistered") {
                        setInputError(t("preboarding:error.409"))
                        break;
                    } else {
                        setInputError(t("preboarding:error.registeredToAnotherTag"))
                        break;
                    }
                case 417:
                    setInputError(t("preboarding:error.417"))
                    break;
                default:
                    setInputError(t("preboarding:error.fail"))
                    break;
            }
        }

        const vehicleId = vehicle?.id
        const unmaskedValue = input.slice(5)
        await tagPreboardingRegister(vehicleId, unmaskedValue, { inputType: "MANUAL", email: userEmail }, onSuccess, onError)
        setSubmitted(false)
    }

    const handleNext = () => {
        history.push('/preboarding/result')
    }

    return (
        <>
            {
                infoModal && <ValidationInputInfoModal toggle={setInfoModal} />
            }

            <div>
                <SubHeader>
                    <div className="container p-0">
                        <PageTitle
                            style={{ fontWeight: "bold" }}
                            i18nKey={"preboarding:title"}
                        />
                        <div className="d-none d-lg-flex">
                            <hr />
                        </div>
                        {
                            subHeaderText ||
                            <SubHeaderText i18nKey={"preboarding:subHeader.0"} />
                        }
                    </div>
                </SubHeader>

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="py-2">
                                <VehicleInfoRow vehicle={vehicle} />
                            </div>

                            <div className="pb-2" >
                                <hr />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="mb-2">
                                <ComponentTitle i18nKey={"preboarding:enterNumber"} noBorder />
                            </div>

                            <div className="input-group">
                                <div className="input-group-prepend" aria-hidden>
                                    <span
                                        className="input-group-text rounded-0 border-top-0 border-left-0"
                                        style={{ backgroundColor: inputError ? Colors.error : Colors.lightGray }}
                                    >
                                        <img src={prthArt} alt={""} aria-hidden />
                                    </span>
                                </div>
                                <InputMask
                                    className={`form-control border-right-0`}
                                    mask="PRHT-99999999"
                                    maskChar=""
                                    value={input}
                                    placeholder={t("preboarding:input.placeholder")}
                                    onChange={(e) => setInput(e.target.value)}
                                    type="text"
                                    alwaysShowMask={false}
                                    style={{
                                        borderBottomRightRadius: 0,
                                        fontSize: "15px",
                                        fontFamily: "Open Sans",
                                        border: inputError && `1px solid ${Colors.error}`,
                                        backgroundColor: inputError && Colors.errorBg,
                                        color: inputError && Colors.errorDark
                                    }}
                                />
                                <span
                                // data-tip={dataTip}
                                // data-for={placeholder}
                                >
                                    <img
                                        src={iconInfo}
                                        className="creditCardPadding"
                                        alt={""}
                                        style={{
                                            position: "absolute",
                                            right: 0,
                                            top: 17,
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => setInfoModal(true)}
                                    />
                                </span>
                            </div>

                            {
                                inputError &&
                                <div
                                    className="mt-3 p-3 text-white d-flex align-items-center rounded"
                                    style={{ background: Colors.error }}>
                                    <div className="mr-2">
                                        <img src={errorIcon} alt="" aria-hidden />
                                    </div>
                                    <span>{inputError}</span>
                                </div>

                            }

                            {
                                formError &&
                                <div className="mt-5">
                                    <div className="mb-3">
                                        <span>{t("preboarding:error.form.0")}</span>
                                    </div>

                                    <div>
                                        <a
                                            href={`${errorFormUrl}?name=${user?.name}&vehicleYear=${vehicle?.year}$vehicleMake=${vehicle?.make}&vehicleModel=${vehicle?.model}&plate=${vehicle?.plate}&email=${user?.email}&tagNumber=${vehicle?.tag?.number}&responseCode=${errorCode}`}
                                            className="btn btn-error-outline w-100"
                                        >{t("preboarding:error.form.1")}</a>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>

                <div className="container mt-5">
                    <WizardFooter
                        disabled={input?.length < 13 || submitted}
                        handleNext={() => handleValidation()}
                    />
                </div>
            </div>
        </>
    )
}

ElectronicTagValidationLayout.propTypes = {
    vehicle: PropTypes.object,
    subHeaderText: PropTypes.element
}

export default ElectronicTagValidationLayout