import React, { useState, useEffect } from "react";

import PageTitle from "../ui/PageTitle";
import { FontWeight } from "../ui/FontSize";
import { Colors } from "../../Colors";
import Text from "../ui/Text";

import { useTranslation } from "react-i18next";

import checkIcon from "../../assets/images/ic_check_48px.svg";
import errorIcon from "../../assets/images/icon_Wizard_Unsucessful.svg";

const WizardPayment = (props) => {
    const { step, labels, error } = props;
    const [wizardStep, toggleWizardStep] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        if (step) {
            toggleWizardStep(step);
        }
    }, []);

    return (
        <div style={cell}>
            {labels && labels.length > 0
                ? labels.map((l, i) => (
                      <div
                          aria-hidden={step === i + 1 ? "false" : "true"}
                          role="text"
                          aria-label={`${t(
                              "payFines:wizard.aria.stepAria"
                          )}: ${l}`}
                          key={i}
                          style={{
                              ...cellStyle,
                              borderBottom: error
                                  ? `5px solid ${Colors.error}`
                                  : `5px solid ${
                                        step === i + 1
                                            ? Colors.gray
                                            : i + 1 < step
                                            ? Colors.primary
                                            : Colors.borderDarkGray
                                    }`,
                          }}
                      >
                          {i + 1 < step && (
                              <div
                                  style={{
                                      ...circle,
                                      backgroundColor: error
                                          ? Colors.error
                                          : Colors.darkGreen,
                                  }}
                              >
                                  <img
                                      src={error ? errorIcon : checkIcon}
                                      style={{ width: error ? "12px" : "18px" }}
                                      aria-hidden={true}
                                      alt=""
                                  />
                              </div>
                          )}
                          <Text
                              i18nKey={l}
                              className="text-sm"
                              style={{
                                  fontWeight: FontWeight.medium,
                                  color: error
                                      ? Colors.error
                                      : step === i + 1
                                      ? Colors.gray
                                      : i + 1 < step
                                      ? Colors.darkGreen
                                      : Colors.borderDarkGray,
                              }}
                          />
                      </div>
                  ))
                : null}
        </div>
    );
};

const circle = {
    height: "24px",
    width: "24px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "8px",
};

const cell = {
    width: "100%",
    display: "flex",
    padding: "10px 0",
    justifyContent: "space-around",
    color: Colors.primary,
    marginBottom: "-20px",
};

const cellStyle = {
    minHeight: "60px",
    padding: "0 8px 8px 8px",
    margin: "0 4px 4px 4px",
    width: "40%",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    textTransform: "uppercase",
    textAlign: "center",
};

export default WizardPayment;
