import React from 'react'

import smartphones from '../../assets/images/about/Smartphones.png'
import cescoLogo from '../../assets/images/about/Logo_CescoDigital.svg'
import arrow from '../../assets/images/about/icon_Arrows.svg'
import driversLicense from '../../assets/images/about/icon_LicenseRenewal.svg'
import virtualLicense from '../../assets/images/about/icon_VirtualLicense.svg'
import iconTag from '../../assets/images/about/icon_TagRenewal.svg'
import titleTransfer from '../../assets/images/about/icon_VehicleTransfer.svg'
import finesIcon from '../../assets/images/about/icon_FinesNotifications.svg'
import timelineIcon from '../../assets/images/about/icon_Timeline.svg'

import logoPayment from '../../assets/images/about/Logo_PaymentAgnostic.svg'
import logoAws from '../../assets/images/about/Logo_AWSCloud.png'
import logoAwards from '../../assets/images/about/Logo_Awards.svg'

import cescoMdl from '../../assets/images/about/Logo_CescoMDL.svg'
import appleMdl from '../../assets/images/about/Logo_AppleMDL.svg'
import googleMdl from '../../assets/images/about/Logo_GoogleMDL.svg'

import logoApple from '../../assets/images/about/Logo_Apple.svg'
import logoAndroid from '../../assets/images/about/Logo_Android.svg'
import logoWeb from '../../assets/images/about/Logo_Web.svg'

import prIcon from '../../assets/images/about/icon_Map.svg'

import { Colors } from "../../Colors";
import { useTranslation } from 'react-i18next'

import bgTop from '../../assets/images/about/BG_Top.svg'
import bgMiddle from '../../assets/images/about/BG_Middle.svg'

import patternDot from '../../assets/images/about/Pattern_Dot.svg'

import biometrics from '../../assets/images/about/icon_Biometrics.svg'
import donation from '../../assets/images/about/icon_Donation.svg'
import gis from '../../assets/images/about/Logo_GIS.svg'
import vacuid from '../../assets/images/about/Logo_VacuID.svg'
import whiteLabel from '../../assets/images/about/Logo_WhiteLabel.svg'
import iconChart from '../../assets/images/about/icon_Chart.svg'

const About = () => {

    const { t } = useTranslation()

    const functionalities = [
        {
            icon: driversLicense,
            title: t("about:functionalities.0.title"),
            text: t("about:functionalities.0.text")
        },
        {
            icon: titleTransfer,
            title: t("about:functionalities.1.title"),
            text: t("about:functionalities.1.text")
        },
        {
            icon: donation,
            title: t("about:functionalities.2.title"),
            text: <div>
                <span>{t("about:functionalities.2.text.0")}</span>
                <strong>{t("about:functionalities.2.text.1")}</strong>
                <span>{t("about:functionalities.2.text.2")}</span>
            </div>
        },
        {
            icon: iconTag,
            title: t("about:functionalities.3.title"),
            text: t("about:functionalities.3.text")
        },
        {
            icon: finesIcon,
            title: t("about:functionalities.4.title"),
            text: <div>
                <span>{t("about:functionalities.4.text.0")}</span>
                <strong>{t("about:functionalities.4.text.1")}</strong>
                <span>{t("about:functionalities.4.text.2")}</span>
            </div>
        },
        {
            icon: biometrics,
            title: t("about:functionalities.5.title"),
            text: t("about:functionalities.5.text")
        },
        {
            icon: virtualLicense,
            title: t("about:functionalities.6.title"),
            text: t("about:functionalities.6.text")
        }
    ]

    const highlights = [
        {
            icon: logoAws
        },
        {
            icon: logoPayment
        },
        {
            icon: vacuid
        },
        {
            icon: gis
        },
        {
            icon: logoAwards
        }
    ]

    return (
        <div className='container'>
            <div className='row mb-5'>
                <div className='col-12 col-lg-7  p-mob-only'>
                    <div className='mb-3'>
                        <img
                            alt=""
                            aria-hidden="true"
                            src={cescoLogo}
                            width={300}
                        />
                    </div>
                    <br />
                    <div className='mb-3 text' style={{ lineHeight: '32px' }}>
                        <strong>{t("about:intro.0")}</strong>
                        <span>{t("about:intro.1")}</span>
                    </div>
                </div>

                <div className='col'>
                    <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                        <img
                            alt=""
                            aria-hidden="true"
                            src={smartphones}
                            style={{ zIndex: 100, width: '70%', position: 'relative' }}
                        />
                        <img
                            alt=""
                            aria-hidden="true"
                            src={bgTop}
                            style={{ position: 'absolute', zIndex: 1, width: '100%', left: 0 }}
                        />
                    </div>
                </div>
            </div>

            <div className='row mb-5'>
                <div className='col'>
                    <div style={{ position: 'relative' }}>
                        <div className='mb-5'>
                            <SectionTitle
                                title={t("about:subTitles.0")}
                            />
                        </div>
                        <div className='row p-mob-only'>
                            {
                                functionalities.map((item, i) => (
                                    <div className='col-12 col-lg-4 mb-6' key={i}>
                                        <div className='d-flex'>
                                            <div className='mr-3'>
                                                <img
                                                    alt=""
                                                    aria-hidden="true"
                                                    src={item.icon}
                                                    width={50}
                                                />
                                            </div>
                                            <div className='text' style={{ lineHeight: '26px' }}>
                                                <div className='mb-1'>
                                                    <strong>{item.title}</strong>
                                                </div>
                                                <span className='text-sm'>{item.text}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <img
                            alt=""
                            aria-hidden="true"
                            src={bgMiddle}
                            style={{ position: 'absolute', zIndex: 1, width: '100%', top: 0 }}
                        />
                    </div>
                </div>
            </div>

            <div className='row mb-5 mb-lg-10'>
                <div className='col'>
                    <div style={{ position: 'relative' }}>
                        <div className='mb-5'>
                            <SectionTitle
                                title={t("about:subTitles.1")}
                            />
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <div className='d-flex flex-column flex-lg-row justify-content-between align-items-center'>
                                    {
                                        highlights.map((item, i) => (
                                            <div key={i} className='mb-5'>
                                                <img
                                                    alt=""
                                                    aria-hidden="true"
                                                    src={item.icon}
                                                    className='mr-2'
                                                    height={50}
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col'>
                    <div className='d-flex'>
                        <div className='mr-3 mr-lg-5'>
                            <SectionTitle
                                title={t("about:subTitles.2")}
                            />
                        </div>
                        <div className='d-flex w-100 justify-content-between text-md align-items-start'>
                            <div className='d-flex align-items-center mr-2'>
                                <img
                                    alt=""
                                    aria-hidden="true"
                                    src={timelineIcon}
                                    width={24}
                                    className='mr-2'
                                />
                                <strong>2018</strong>
                            </div>
                            <div style={{ width: '100%' }}>
                                <div
                                    style={{
                                        background: `url(${patternDot}) repeat`,
                                        width: '100%',
                                        height: 20,
                                        marginTop: 2
                                    }}
                                />
                            </div>
                            <div className='d-flex align-items-center ml-1'>
                                <strong className='mr-2'>2023</strong>
                                <img
                                    alt=""
                                    aria-hidden="true"
                                    src={arrow}
                                    width={22}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mb-5'>
                <div className='col'>
                    <div className='mb-5'>
                        <div className="table-responsive">
                            <table className='w-100 table'>
                                <tr style={{ color: Colors.gray }}>
                                    <th style={{ ...th, borderTopLeftRadius: 30, borderLeft: 'none' }}></th>
                                    <th style={th}>{t("about:table.head.0")}</th>
                                    <th style={th}>{t("about:table.head.1")}</th>
                                    <th style={th}>{t("about:table.head.2")}</th>
                                    <th style={th}>{t("about:table.head.3")}</th>
                                    <th style={{ ...th, borderTopRightRadius: 30, borderRight: 'none' }}>{t("about:table.head.4")}</th>
                                </tr>
                                <tr>
                                    <td style={{ ...td, borderLeft: 'none' }} className='text-bold'>Total</td>
                                    <td style={td} className='text-md text-bold'>152k</td>
                                    <td style={td} className='text-md text-bold'>16k</td>
                                    <td style={td} className='text-md text-bold'>79k</td>
                                    <td style={td} className='text-md text-bold'>1.42M</td>
                                    <td style={{ ...td, borderRight: 'none' }} rowSpan={2}>
                                        <div
                                            className='text-sm'
                                            style={{ background: 'none', display: 'flex', justifyContent: 'space-between', color: Colors.gray }}>
                                            <div className='mr-1'>
                                                <img
                                                    alt=""
                                                    aria-hidden="true"
                                                    src={logoApple}
                                                    height={40}
                                                    className='mb-2'
                                                />
                                                <br />
                                                <span>Apple</span>
                                            </div>
                                            <div className='mr-1'>
                                                <img
                                                    alt=""
                                                    aria-hidden="true"
                                                    src={logoAndroid}
                                                    height={40}
                                                    className='mb-2'
                                                />
                                                <br />
                                                <span>Android</span>
                                            </div>
                                            <div>
                                                <img
                                                    alt=""
                                                    aria-hidden="true"
                                                    src={logoWeb}
                                                    height={40}
                                                    className='mb-2'
                                                />
                                                <br />
                                                <span>Web</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ ...td, borderBottomLeftRadius: 30, borderLeft: 'none' }} className='text-bold'>{t("about:table.body.0")}</td>
                                    <td style={td} className='text-md text-bold'>$12.67M</td>
                                    <td style={td} className='text-md text-bold'>$3.5M</td>
                                    <td style={td} className='text-md text-bold'>$1.45M</td>
                                    <td style={{ ...td, borderBottomRightRadius: 30, borderRight: 'none' }} className='text-md text-bold'>$177.86M</td>
                                </tr>
                                <tr>
                                    <td colSpan={8} style={{ ...td, borderBottomLeftRadius: 30, borderLeft: 'none' }}>
                                        <div style={{ background: 'transparent', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div className='mr-5'>
                                                <img
                                                    alt=""
                                                    aria-hidden="true"
                                                    src={prIcon}
                                                    height={80}
                                                    className='mb-2'
                                                />
                                            </div>

                                            <div style={{ textAlign: 'start', width: '50%' }} className='mr-5'>
                                                <div className='mb-2'>
                                                    <strong className='text-md mb-3'>{t("about:adoption.0.title")}</strong>
                                                </div>
                                                <div className='text'>
                                                    <span>{t("about:adoption.0.text.0")}</span>
                                                    <strong>{t("about:adoption.0.text.1")}</strong>
                                                    <span>{t("about:adoption.0.text.2")}</span>
                                                    <strong>{t("about:adoption.0.text.3")}</strong>
                                                    <span>{t("about:adoption.0.text.4")}</span>
                                                    <strong style={{ color: Colors.primary }}>75% </strong>
                                                    <span>{t("about:adoption.0.text.5")}</span>
                                                </div>
                                            </div>

                                            <div style={{ textAlign: 'start' }} className='mr-5'>
                                                <div className='mb-1'>
                                                    <strong style={{ color: Colors.primary }} className='text-lg'>2.53 {t("about:adoption.1.0")}</strong>
                                                </div>
                                                <div>
                                                    <strong className='text'>{t("about:adoption.1.1")}</strong>
                                                </div>
                                            </div>

                                            <div className='d-flex flex-column flex-lg-row align-items-center'>
                                                <img
                                                    alt=""
                                                    aria-hidden="true"
                                                    src={iconChart}
                                                    height={80}
                                                    className='mr-2 mb-2 mb-lg-0'
                                                />
                                                <strong className='text'>{t("about:adoption.2")}</strong>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div className='mb-5'>
                        <div className='mb-5'>
                            <SectionTitle
                                title={t("about:subTitles.3")}
                            />
                        </div>
                        <div className='d-flex flex-column flex-lg-row justify-content-between align-items-start px-5'>
                            <img
                                alt=""
                                aria-hidden="true"
                                src={cescoMdl}
                                height={30}
                                className='mb-5'
                            />
                            <img
                                alt=""
                                aria-hidden="true"
                                src={appleMdl}
                                height={30}
                                className='mb-5'
                            />
                            <img
                                alt=""
                                aria-hidden="true"
                                src={googleMdl}
                                height={30}
                                className='mb-5'
                            />
                            <img
                                alt=""
                                aria-hidden="true"
                                src={whiteLabel}
                                height={30}
                                className='mb-5'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

const th = {
    padding: 20,
    backgroundColor: '#ebebed',
    textAlign: 'center',
    border: '1px solid white',
    borderTop: 'none'
}

const td = {
    padding: 20,
    backgroundColor: '#f6f6f6',
    textAlign: 'center',
    border: '1px solid white',
    borderBottom: 'none'
}

const SectionTitle = (props) => {

    const { icon, title } = props

    return (
        <div className='mb-4 d-flex align-items-start'>
            <img
                alt=""
                aria-hidden="true"
                src={arrow}
                width={22}
                className='mr-2'
                style={{ marginTop: 2 }}
            />
            <span
                className='text-md'
                style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
            >{title || 'No title'}</span>
        </div>
    )
}

export default About