import React from "react";

import Skeleton from "react-skeleton-loader";

import Text from "../ui/Text";

import starIcon from "../../assets/images/star-icon.svg";

import _ from "lodash";
import { FontWeight } from "../ui/FontSize";
import { useTranslation } from "react-i18next";
import { Colors } from "../../Colors";
import Radio from "../ui/Radio";
import * as Utils from "../../core/Utils";

const CescoListItem = (props) => {
    const { loading, selectCesco, checkList, selectedCesco } = props;
    const { id, openNow, realId, name } = props.cesco;
    const { t } = useTranslation();

    return (
        <button
            className="hoverable"
            onClick={() => {
                Utils.scrollToDiv("cescos");
                selectCesco(props.cesco)
            }}
            style={{
                display: "flex",
                width: "100%",
                height: "60px",
                alignItems: "center",
                border: 'none',
                borderBottom: `1px solid ${Colors.borderGray}`,
                cursor: "pointer",
                color: '#000'
            }}
        >
            {checkList || (
                <div className="col-3">
                    {!loading ? (
                        <Text
                            className={`badge  ${
                                openNow ? "badge-success" : "badge-danger"
                            } badge-pill`}
                            style={{ width: "65px", fontSize: '12px' }}
                            i18nKey={`cescos:${openNow ? "open" : "close"}`}
                        />
                    ) : (
                        <Skeleton width="3rem" />
                    )}
                </div>
            )}

            <div
                className="col-3"
                style={{ display: "flex", justifyContent: "center" }}
            >
                {!loading ? (
                    <React.Fragment>
                        {realId ? (
                            <img
                                src={starIcon}
                                alt={t("cescos:realIdEnabledAria")}
                                style={{ width: '20px'}}
                            />
                        ) : null}
                    </React.Fragment>
                ) : (
                    <Skeleton width="2rem" />
                )}
            </div>

            <div className="col-2" style={{ fontWeight: FontWeight.bold }}>
                {!loading ? <Text className="text">{_.capitalize(name)}</Text> : <Skeleton />}
            </div>

            {checkList && (
                <div
                    className="col-7"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                >
                    {!loading ? (
                        <>
                            <Radio
                                state={selectedCesco && selectedCesco.id === id}
                            />
                        </>
                    ) : (
                        <Skeleton width="1rem" />
                    )}
                </div>
            )}
        </button>
    );
};

export default CescoListItem;
