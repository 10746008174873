import React, { useCallback, useEffect } from "react";
import AppStoresBadges from "../../components/ui/AppStoresBadges";
import { isAndroid, isIOS } from "react-device-detect";
import Button from "../../components/ui/Button";
import { Colors } from "../../Colors";
import Text from "../../components/ui/Text";

const Deeplink = () => {
    const params = window.location.search;
    const iosUrl = `cescodigital://dl${params}`;
    const iosAppStoreUrl = `itms-apps://itunes.apple.com/us/app/apple-store/id1389951990?mt=8`;
    const androidUrl = `intent://dl${params}/#Intent;scheme=cescodigital;package=com.dtop_mobile;end`;

    const checkUrl = useCallback(() => {
        if (!isIOS && !isAndroid) return null;
        if (isIOS) {
            return iosUrl;
        }
        if (isAndroid) {
            return androidUrl;
        }
    }, [androidUrl, iosUrl]);

    /**
     * On IOS it will redirect to app, if app is not installed, then it will fallback to
     * apple store. On android, the `href` in the button should be intelligent enough.
     * @type {function(): void}
     */
    const redirect = useCallback(() => {
        /*setTimeout(function () {
            window.location.href = isIOS ? iosAppStoreUrl : "/";
        }, 1500);*/

        // window.location.href = isIOS ? iosUrl : androidUrl;
        window.location.replace(isIOS ? iosUrl : androidUrl);
        setTimeout(function () {
            window.location.replace(/*isIOS ? iosAppStoreUrl : */"/");
            // window.location.reload();
            // alert("Sdsd")
            }, 2000);
        // window.location.href = isIOS ? iosUrl : androidUrl;


    }, [androidUrl, iosAppStoreUrl, iosUrl]);

    useEffect(() => {
        redirect();
    }, [redirect]);



    return (
        <div className="container">
            <AppStoresBadges />
            {checkUrl() && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <a href={isAndroid ? androidUrl : "#"} onClick={redirect}>
                        <Button
                            style={{
                                backgroundColor: Colors.darkGreen,
                                minWidth: 200,
                            }}
                            disabled={false}
                        >
                            <Text
                                style={{ color: "white" }}
                                i18nKey={"wizardFooter:continue"}
                            />
                        </Button>
                    </a>
                </div>
            )}
        </div>
    );
};

export default Deeplink;
