import React from 'react'
import { Colors } from '../../../Colors'
import carIcon from '../../../assets/images/icon_Vehiculo.svg'

const VehicleInfoRow = (props) => {

    const { vehicle } = props

    if (!vehicle) {
        return (
            <div className='d-flex justify-content-center text-center'>
                <div className='mr-2'>
                    <img src={carIcon} style={{ width: 36 }} alt="" aria-hidden="true" />
                </div>

                <h5>Not found
                    <span style={{ color: Colors.gray }}> (000)</span></h5>
            </div>
        )
    }

    return (
        <div className='d-flex justify-content-center text-center align-items-center'>
            <div className='mr-2'>
                <img src={carIcon} style={{ width: 36 }} alt="" aria-hidden="true" />
            </div>

            <h5 className='m-0'>{vehicle?.year} {vehicle?.make} {vehicle?.model}
                <span style={{ color: Colors.gray }}> ({vehicle?.plate})</span></h5>
        </div>
    )
}
export default VehicleInfoRow