const ClearBackButton = () => {

    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
    };

}

export default ClearBackButton
