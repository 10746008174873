import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import Text from "../../ui/Text"
import donorImage from '../../../assets/images/organ-donor/Art_Donante.svg'
import ToggleSwitch from '../../ui/ToggleSwitch'
import Button from '../../ui/Button'
import { Colors } from '../../../Colors'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { changeDonorStatus } from '../../../core/api-resources/organDonor'
import { fetchCard } from '../../../redux/entity/actions'
import { FontWeight } from '../../ui/FontSize'
import ConfirmationModal from '../../ui/ConfirmationModal'

const OrganDonorModal = (props) => {

    const { toggle } = props

    const [toggleState, setToggleState] = useState(false);
    const [toggleInitialState, setInitialState] = useState(false);

    const [confirmation, setConfirmation] = useState(false);

    const isDonor = useSelector(s => s?.card?.personalInfo?.donor)

    const { t } = useTranslation()
    const dispatch = useDispatch()

    // Set initial toggle state
    useEffect(() => {
        // Save initial state
        setInitialState(isDonor)

        if (isDonor) setToggleState(true)
    }, [])

    const handleDonorChange = async () => {
        const payload = {
            donor: toggleState
        }

        if (toggleState === false) {
            return setConfirmation(true)
        }

        postDonorChange(payload)
        
        // await changeDonorStatus(payload)
        // await dispatch(fetchCard())
        // toggle()
    }

    const postDonorChange = async (payload) => {
        await changeDonorStatus(payload)
        await dispatch(fetchCard())
        toggle()
    }


    if (confirmation) return (
        <ConfirmationModal
            title={t("organDonor:confirmation.title")}
            toggle={setConfirmation}
            action={() => postDonorChange({donor: false})}
        >
            <div className='flex flex-col'>
                <div className='mb-3'>
                    <span className='text'>{t("organDonor:confirmation.text.0")}</span>
                </div>
                <div>
                    <strong>{t("organDonor:confirmation.text.1")}</strong>
                </div>
            </div>
        </ConfirmationModal>

    )

    return (
        <Modal
            isOpen={true}
            size="md"
            style={{ padding: "15px" }}
            toggle={() => toggle && toggle(false)}
            role="dialog"
            aria-modal={true}
            autoFocus={true}
        >
            <ModalHeader
                style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: Colors.lightGray,
                }}
                toggle={() => toggle && toggle(false)}
            >
                <span
                    style={{ color: Colors.gray }}
                    className='text'>{t("organDonor:title")}</span>
            </ModalHeader>

            <ModalBody>
                <div style={modalBodyStyle}>

                    <div className='mb-3 d-flex justify-content-center'
                        style={{ width: '100%' }}
                    >

                        <img
                            src={donorImage}
                            style={{ width: '70%' }}
                            alt=""
                            aria-hidden="true"
                        />

                    </div>
                    <div className='my-4'>
                        <span style={{ color: Colors.gray }}>{t("organDonor:text")}</span>
                        <a
                            href="http://www.donevidapuertorico.org"
                            style={{ color: Colors.primary }}>www.donevidapuertorico.org.</a>
                    </div>
                    <div className='my-4'>
                    </div>

                    <div className='mb-2'>
                        <span className='text-md text-bold'>{t("organDonor:question")}</span>
                    </div>

                    <div className='mb-4'>
                        <span className='text-xs'
                            style={{ color: Colors.gray }}
                        >{t("organDonor:disclaimer")}</span>
                    </div>

                    <div className='mb-3'>

                        <ToggleSwitch
                            state={toggleState}
                            setState={setToggleState}
                        />
                    </div>

                </div>
            </ModalBody>

            <ModalFooter
                style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: Colors.lightGray,
                }}
            >
                <Button
                    className="btn-primary"
                    onClick={() => handleDonorChange()}
                    disabled={toggleInitialState === toggleState}
                >
                    {t("organDonor:save")}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const modalBodyStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}

export default OrganDonorModal