export const Colors = {
    primary: '#149137',
    primaryDark: '#18622d',
    // secondary: 'green',
    lightGray: '#eee',
    bgGray: '#f8f9fa',
    darkGray: '#333',
    gray: '#748187',
    white: '#fff',
    blue: '#0094fc',
    error: "#e30303", // red
    errorDark: "#be0000", // dark red
    errorBg: 'rgb(246, 217, 220)', // dark red
    borderGray: '#e7eaf3',
    borderDarkGray: '#d6d6d6',
    disabledGray: '#f2f2f2',
    orange: '#ff8706',
    orangeDark: '#d67003',
    orangeLight: '#F5E5D8',
    orangeBorder: "#E9934A",
    yellow: '#ffc108',
    darkGreen: '#18622d',
    rgbGray: 'rgb(116, 129, 135)',
    rgbLightGray: 'rgb(231, 234, 243)',
    disabledText: '#979797'
}
