import React from "react";

import { useTranslation } from "react-i18next";

import { Colors } from "../../Colors";
import Text from "./Text";

import alertIcon from "../../assets/images/icon_Warning_White.svg";

const DisabledPayments = () => {
    const { t } = useTranslation();

    return (
        <div
            role={"alert"}
            style={{
                height: 60,
                backgroundColor: Colors.error,
                display: "flex",
                alignItems: "center",
                border: "1px solid #880000",
                textAlign: "center",
                padding: '15px'
            }}
            className="disabled-payments"
        >
            <img
                src={alertIcon}
                alt={""}
                aria-hidden={true}
                style={{ height: 16 }}
            />
            <Text style={{ color: "#fff", margin: '0px 20px' }} className={"text-uppercase"}>
                {t("layout:header.paymentDisabled")}
            </Text>
            <img
                src={alertIcon}
                aria-hidden={true}
                alt={""}
                style={{ height: 16 }}
            />
        </div>
    );
};

export default DisabledPayments;
