import React, { useEffect, useState } from "react";

import PropTypes, { bool } from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "../../ui/Button";
import Text from "../../ui/Text";
import DaysLeftSquare from "../../ui/DaysLeftSquare";

import "../../styles/alert.css";

import { Colors } from "../../../Colors";
import { FontWeight } from "../../ui/FontSize";
import { useHistory } from "react-router-dom";
import _ from "lodash";

// import {fetchRenewalEligibility} from "../../../redux/renewal/actions";
import alertRedIcon from "../../../assets/images/icon_Warning_Red.svg";
import alertWhiteIcon from "../../../assets/images/icon_Warning_White.svg";
import starIcon from "../../../assets/images/icon-alert-star.svg";
import marbeteIcon from "../../../assets/images/icon-alerta-marbete.svg"
import appointmentIcon from "../../../assets/images/icon_Alert_Citas.svg"
import personalAlertIcon from "../../../assets/images/icon_Alert_Personal.svg"
import licenseAlertIcon from "../../../assets/images/icon_Alert_Licencia.svg"
import alertVehicleIcon from "../../../assets/images/icon_Alert_Vehiculo.svg"
import alertWarningIcon from "../../../assets/images/warning-orange-icon.svg"
import alertFineIcon from "../../../assets/images/icon_Alert_Multa.svg"
import alertNotification from "../../../assets/images/icon_Alert_Notificacion.svg"
import comprobanteIcon from "../../../assets/images/icon_Alert_Comprobante.svg"
import preboardingIcon from "../../../assets/images/electronic-tag/MarbeteElectronico.svg"

import moment from "moment";
import { useSelector } from "react-redux";
import VehicleSelectionModal from "../VehicleSelectionModal";
import TagRenewalModal from "../TagRenewalModal";

const AlertGroup = (props) => {
    const { alerts, colorFill, vehicle } = props

    if (_.isEmpty(alerts))
        return null;

    const renewalCandidate = () => {
        // return (
        //     dispatch(
        //         fetchRenewalEligibility((eligibility) => {
        //             if (eligibility.eligible) {
        //                 let agreement = eligibility.userAgreements[0];
        //                 if (agreement?.accepted)
        //                     history.push("/renewal/missing-docs");
        //                 else history.push("/renewal/disclosure");
        //             } else history.push("/renewal/not-eligible");
        //         })
        //     )
        // )
    };

    return (
        <div
            className={`alert alert-margin alert-warning alert-dismissible fade show alert-group alert-radius`}
            style={{ ...alertGroupStyle, border: colorFill && 'none' }}
            role="alert"
        >
            {alerts && alerts?.map((a, index) => (
                <Alert
                    a={a}
                    index={index}
                    colorFill={colorFill}
                    vehicle={vehicle}
                />
            ))}
        </div>
    );
};

const Alert = (props) => {
    const [alertColor, setAlertColor] = useState('')
    const [dayBoxColor, setDayBoxColor] = useState('')

    let [vehicleSelectionModal, setVehicleSelectionModal] = useState(false);
    const [inspectionRequired, setInspectionRequired] = useState(false)

    let [tagRenewalModal, setTagRenewalModal] = useState(false);

    const { a, index, colorFill, vehicle } = props

    const vehicleInspectionFeature = useSelector(s => s?.features?.vehicleInspection)

    const {
        severity,
        title,
        caption,
        reference,
        type,
        daysLeft,
        dueDate,
        action,
        path,
        criteriaId
    } = a

    const { t } = useTranslation();
    let history = useHistory();

    const vehicleYear = vehicle?.year

    // Los vehículos solo requieren inspección cuando año del marbete proximo a renovar es mayor a dos años del año del modelo del vehiculo
    const getInspectionRequired = () => {
        if (!vehicleInspectionFeature?.enabled || reference !== "vehicleRenewal") return false

        const yearsLimit = vehicleInspectionFeature?.yearsLimit
        const currentYear = moment().year();

        // Si el vehiculo fue manufacturado hace menos de dos años no debe mostrar alerta
        if ((currentYear - vehicleYear) > yearsLimit) return true
        else return false
    }

    useEffect(() => {
        const required = getInspectionRequired()
        setInspectionRequired(required)
    }, [vehicle, vehicleInspectionFeature])

    const availableWebActions = ['vehicle', 'license', 'inbox', 'renewal', 'url', 'location', 'electronicTagPreboarding']

    useEffect(() => {
        defineAlert()
    }, [a])

    const defineAlert = () => {
        switch (severity) {
            case "error":
                setAlertColor(Colors.error)
                setDayBoxColor(Colors.errorDark)
                break;
            case "warning":
                setAlertColor(Colors.orange)
                setDayBoxColor(Colors.orangeDark)
                break;
            case "info":
                setAlertColor(Colors.primary)
                setDayBoxColor(Colors.primaryDark)
                break;
            case "alert":
                setAlertColor(Colors.yellow)
                setDayBoxColor(Colors.yellow)
                break;
        }
    }

    const alertAction = (action, path, criteriaId) => {
        if (action === 'nav') {
            // Create path to navigate based on db data
            let pathToNav;
            const navigate = (path) => history.push(path)

            if (criteriaId) {
                switch (path) {
                    case 'vehicle':
                        pathToNav = `/vehicle-details/${criteriaId}`
                        navigate(pathToNav)
                        break;
                }
            } else {
                switch (path) {
                    case 'license':
                        pathToNav = '/profile'
                        navigate(pathToNav)
                        break;
                    case 'inbox':
                        pathToNav = '/messages'
                        navigate(pathToNav)
                        break;
                    case 'renewal':
                        pathToNav = '/renewal/candidate-eligibility'
                        navigate(pathToNav)
                        break;
                    case 'renewalVoucher':
                        pathToNav = '/voucher'
                        navigate(pathToNav)
                        break;
                    case 'location':
                        pathToNav = '/locations'
                        navigate(pathToNav)
                        break;
                    case 'electronicTagPreboarding':
                        setVehicleSelectionModal(true)
                        break;
                }
            }
        } else if (action === 'url') {
            window.open(path, "", "width=600,height=600");
        }

        if (inspectionRequired) {
            setTagRenewalModal(true)
            return
        }
    };

    const calcIcon = (colorFill) => {
        if (severity === "error") {
            return colorFill ? alertWhiteIcon : alertRedIcon
        }

        if (reference === "licenseRenewal" && type === "alert") {
            return starIcon
        } else if (reference === "vehicleRenewal" && type === "alert") {
            return marbeteIcon
        } else if (reference === "appointment" && type === "alert") {
            return appointmentIcon
        } else if (reference === "person" && type === "alert") {
            return personalAlertIcon
        } else if (reference === "license" && type === "alert") {
            return licenseAlertIcon
        } else if (reference === "vehicle" && type === "alert") {
            return alertVehicleIcon
        } else if (reference === "fine" && type === "alert") {
            return alertFineIcon
        } else if (reference === "licenseRenew" && type === "alert") {
            return comprobanteIcon
        } else if (reference === "preboarding" && type === "alert") {
            return preboardingIcon
        } else if (severity === "warning") {
            return alertWarningIcon
        } else {
            /*if(type === 'notification')*/
            return alertNotification
        }
    };

    const validateAction = (action, path) => {
        if (inspectionRequired) return true
        if (action === 'none') return false
        if (path === 'renewal') return false

        if (action === 'nav') {
            return availableWebActions.includes(path) ?
                true : false
        } else {
            return true
        }
    }

    const renderButtonText = (path) => {
        switch (path) {
            case "renewal":
                return t("alerts:button.renewal")
            case "error":
                return t("alerts:button.error")
            case "notification":
                return t("alerts:button.notification")
            case "location":
                return t("alerts:button.location")
            case "renewalVoucher":
                return t("alerts:button.renewalVoucher")
            case "license":
                return t("alerts:button.license")
            case "electronicTagPreboarding":
                return t("alerts:button.electronicTagPreboarding")
        }

        if (inspectionRequired) {
            return t("alerts:button.expiredTag")
        }
    }

    const renderButtonClass = () => {
        switch (severity) {
            case "error":
                return `${colorFill ? 'btn-error-dark' : 'btn-error'}`
            case "warning":
                return `${colorFill ? 'btn-secondary-dark' : 'btn-secondary'}`
            case "info":
                return null
            case "alert":
                return `${colorFill ? 'btn-yellow-dark' : 'btn-yellow'}`
            default:
                return `${colorFill ? 'btn-primary-dark' : 'btn-primary'}`
        }
    }

    const renderTextColor = () => {

        if (path === "electronicTagPreboarding") {
            return Colors.darkGray
        }

        return colorFill ? 'white' : Colors.darkGray
    }

    return (
        <div>
            {
                vehicleSelectionModal &&
                <VehicleSelectionModal
                    toggle={setVehicleSelectionModal}
                />
            }

            {
                tagRenewalModal &&
                <TagRenewalModal
                    toggle={setTagRenewalModal}
                />
            }

            <div
                key={index}
                style={{
                    ...alertRow,
                    borderTop:
                        index === 0 || `1px solid ${Colors.borderGray}`,
                    // borderRadius: colorFill ? 4 : 0,
                    backgroundColor: colorFill ? alertColor : 'transparent',
                }}
                // COVID alert hidden
                className={`hoverable  alert-padding ${reference === 'covid' && 'd-none'} alert-radius`}
                // className={`hoverable  alert-padding ${reference === 'covid' && 'd-none'} ${inspectionRequired ? 'alert-radius-t' : 'alert-radius'}`}
                onClick={() => validateAction(action, path) && alertAction(action, path, criteriaId)}
                role="text"
            >
                <div style={{ width: '45px !important' }}>
                    {daysLeft && dueDate ? (
                        <DaysLeftSquare
                            days={daysLeft}
                            dueDate={dueDate}
                            color={dayBoxColor}
                        />
                    ) : (
                        <div className="d-flex justify-content-center align-items-center" style={{ width: 45 }}>
                            <img src={calcIcon(colorFill)} alt={""} style={{ width: 35 }} />
                        </div>
                    )}
                </div>

                <div style={{ flexGrow: 1, lineHeight: 1.2 }} className="ml-3 mr-3">
                    <Text className="text"
                        style={{ color: renderTextColor(), fontWeight: FontWeight.bold }}>
                        {title}
                    </Text>

                    <div style={{ lineHeight: 1.2 }}>
                        <Text className="text" style={{ color: renderTextColor() }}>
                            {caption}
                        </Text>
                    </div>
                </div>

                {reference === "renewal" && (
                    <span
                        aria-label={t(
                            "alerts:alertGroup.aria.buttonRenewal"
                        )}
                        className={"d-flex d-lg-none caret-right"}
                    />
                )}

                <span className={"d-none d-lg-flex pl-3"}>
                    <Button
                        onClick={() => alertAction(action, path, criteriaId)}
                        disabled={false}
                        color={dayBoxColor}
                        style={{ display: validateAction(action, path) ? 'block' : 'none', minWidth: 155 }}
                        className={renderButtonClass()}

                    >
                        {renderButtonText(path)}
                    </Button>
                </span>

                <span className="d-lg-none" style={{ display: validateAction(action, path) ? 'block' : 'none' }}>
                    <div className="caret-right" />
                </span>
            </div>

            {/*{*/}
            {/*    inspectionRequired &&*/}
            {/*    <div className="container">*/}
            {/*        <div className="alert-radius-b row" style={{ backgroundColor: Colors.errorDark }}>*/}
            {/*            <div className="p-3 ml-lg-8 text-white w-100 text-center text-lg-left">*/}
            {/*                <span className="text text-bold">{t('alerts:inspection.text')}</span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}

        </div>
    )
}

const alertGroupStyle = {
    padding: 0
};

const alertRow = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

AlertGroup.propTypes = {
    alerts: PropTypes.array,
    renewalAction: PropTypes.func,
    colorFill: PropTypes.bool,
    vehicle: PropTypes.object
};

AlertGroup.defaultProps = {
    alerts: [],
    colorFill: false,
    inspectionRequired: bool
};

export default AlertGroup;
