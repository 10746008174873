import React, { useState } from "react";

import Checkbox from "../ui/Checkbox";
import Text from "../ui/Text";
import FineTypeIcon from "../ui/FineTypeIcon";
import * as Utils from "../../core/Utils";
import { formatCurrencyLabel } from "../../core/Utils";
import Skeleton from "react-skeleton-loader";
import { Colors } from "../../Colors";
import { FontWeight } from "../ui/FontSize";
import _ from "lodash";
import { fineRemove, fineSelected } from "../../redux/fines/actions";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Collapse } from "reactstrap";
import { useTranslation } from "react-i18next";

const FineListItem = (props) => {
    const [dropDown, handleDropDown] = useState(false);
    const dispatch = useDispatch();
    const acceptPayment = useSelector(s => s?.features?.payments?.enabled)

    const { t } = useTranslation();

    const {
        id,
        date,
        city,
        amount,
        ticket,
        law,
        points,
        type,
        discount,
        checked,
        plate,
        lawNo,
        noPoints,
    } = props.fine;

    const manageCheckbox = async (checked, e) => {
        if (e) e.stopPropagation();
        if (checked) dispatch(fineSelected(props.fine));
        else dispatch(fineRemove(props.fine));
    };

    const { loading } = props;

    const fineListItemStyle = {
        display: "flex",
        borderBottom: `1px solid ${Colors.lightGray}`,
        backgroundColor: dropDown ? "#eee" : null,
        color: dropDown ? "#000" : Colors.gray,
        padding: "17px",
        alignItems: "center",
        ...props.style,
    };
    let dateStr = Utils.format(date, "DD.MMM.YY");
    let amountStr = formatCurrencyLabel(amount);

    return (
        <button
            id="fineListItem"
            className="accordion card-collapse hoverable"
            key={id}
            aria-label={t("fineList:fineListItem.arias.fineInWords", {
                typeStr: t(type === "l" ? "license" : "vehicle"),
                ...props.fine,
                dateStr: dateStr,
            })}
            aria-expanded={dropDown}
            aria-controls="collapse"
            style={{
                ...buttonWrapperStyle,
                border: dropDown
                    ? `solid 1px ${Colors.borderDarkGray}`
                    : 'solid 1px white',
            }}
            onClick={() => handleDropDown(!dropDown)}
        >
            <div>
                <div style={fineListItemStyle}>
                    <div
                        style={fineRow}
                        className={`col-md-${!props.noIcon ? 2 : 1}`}
                    >
                        {!loading ? (
                            <Checkbox
                                disabled={!acceptPayment}
                                checked={checked}
                                ariaLabel={t(
                                    "fineList:fineListItem.arias.selectFine"
                                )}
                                handleCheckbox={manageCheckbox}
                            />
                        ) : (
                            <Skeleton width="1rem" />
                        )}

                        {!props.noIcon && (
                            <div aria-hidden={true}>
                                {!loading ? (
                                    <div data-tip={t(`fineList:icon.${type}`)} data-for="fine-tooltip">
                                        <FineTypeIcon
                                            type={type}
                                            style={{ marginLeft: "10px" }}
                                        />
                                    </div>
                                ) : (
                                    <Skeleton width="2rem" />
                                )}
                            </div>
                        )}
                    </div>

                    <div
                        aria-hidden={true}
                        style={fineRowItemGroup}
                        className="col-md-2"
                    >
                        {!loading ? dateStr : <Skeleton />}
                        {!loading && props.fine.points > 0 && (
                            <>
                                <ReactTooltip
                                    id="fine-tooltip"
                                    place="right"
                                    type="dark"
                                    effect="float"
                                />
                                <span
                                    aria-hidden={true}
                                    data-tip={t(
                                        "fineList:fineListItem.pointTooltip",
                                        { points: props.fine.points }
                                    )}
                                    data-for="fine-tooltip"
                                    className="badge badge-sm badge-warning rounded-circle"
                                    style={{
                                        width: "12px",
                                        height: "12px",
                                        fontSize: "8px",
                                        fontWeight: FontWeight.bold,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginLeft: "4px",
                                        marginBottom: "1px",
                                    }}
                                >
                                    P
                                </span>
                            </>
                        )}
                    </div>

                    {/* <div
                            aria-hidden={true}
                            style={fineRowItemGroup}
                            className={`col-md-${!props.noIcon ? 7 : 8}`}
                            onClick={() => handleDropDown(!dropDown)}
                        > */}

                    <div
                        aria-hidden={true}
                        className="col-3"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        {!loading ? _.capitalize(city) : <Skeleton />}
                    </div>

                    <div aria-hidden={true} className="col-2">
                        {!loading ? <Text>{plate}</Text> : <Skeleton />}
                    </div>

                    <a
                        aria-hidden={true}
                        className={`col-md-${props.noIcon ? 2 : 1}`}
                        style={{ paddingLeft: 0 }}
                    >
                        <Text style={{ fontSize: "13px" }}>
                            {discount || " "}
                        </Text>
                    </a>

                    <div
                        style={{
                            ...fineRowItemGroup,
                            justifyContent: "flex-end",
                        }}
                        aria-hidden={true}
                        className="col-2"
                    >
                        <Text
                            style={{
                                color: Colors.primary,
                                fontSize: "20px",
                                fontWeight: FontWeight.bold,
                                textAlign: "right",
                            }}
                        >
                            {!loading ? amountStr : <Skeleton width="2rem" />}
                        </Text>
                        <div
                            aria-hidden={true}
                            onClick={() => handleDropDown(!dropDown)}
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                height: "25px",
                                width: "25px",
                            }}
                        >
                            <div
                                aria-hidden={true}
                                className="caret-right"
                                style={{
                                    transform: dropDown && "rotate(90deg)",
                                    transition: "all 0.4s ease",
                                }}
                            />
                        </div>
                    </div>

                    {/* </div> */}
                </div>
            </div>

            <Collapse isOpen={dropDown}>
                <section
                    role="region"
                    aria-labelledby="fineListItem"
                    id="collapse"
                    aria-expanded={dropDown}
                    aria-label="test"
                    style={{
                        ...fineListItemStyle,
                        borderTop: dropDown
                            ? `1px solid ${Colors.borderDarkGray}`
                            : null,
                    }}
                >
                    {props.withCheckbox && <div />}
                    <div
                        className="col-md-3"
                        style={fineCol}
                        aria-hidden={true}
                    >
                        <span style={fineListSmallText}>
                            {t("fineList:fineListItem.ticketNo")}
                        </span>
                        <br />
                        <span>{ticket}</span>
                    </div>
                    <div
                        className="col-md-3"
                        style={fineCol}
                        aria-hidden={true}
                    >
                        <span style={fineListSmallText}>
                            {t("fineList:fineListItem.lawNo")}
                        </span>
                        <br />
                        <span>{lawNo}</span>
                    </div>
                    {points > 0 && (
                        <div
                            aria-hidden={true}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: 0,
                            }}
                            className="col-md-3"
                        >
                            <div aria-hidden={true} style={fineCol}>
                                <span style={fineListSmallText}>
                                    {t("fineList:fineListItem.points")}
                                </span>
                                <br />
                                <span>-{points}</span>
                            </div>
                        </div>
                    )}
                </section>
                <div
                    style={{ ...fineListItemStyle, paddingTop: 0 }}
                    aria-hidden={true}
                >
                    <div className="col-md-12" style={fineCol}>
                        <span style={fineListSmallText}>
                            {t("fineList:fineListItem.desc")}
                        </span>
                        <br />
                        <span style={{ color: Colors.gray, fontSize: "16px" }}>
                            {law}
                        </span>
                    </div>
                </div>
            </Collapse>
        </button>
    );
};

const buttonWrapperStyle = {
    width: "100%",
    padding: 0,
};

const fineListSmallText = {
    fontSize: "13px",
    textTransform: "uppercase",
    color: Colors.gray,
};

const fineRow = {
    display: "flex",
    alignItems: "center",
    padding: 0,
};

const fineCol = {
    padding: 0,
    textAlign: "left",
    marginRight: "30px",
};

const fineRowItemGroup = {
    display: "flex",
    alignItems: "center",
    padding: 0,
};

const buttonStyle = {
    padding: 0,
    margin: 0,
    border: "1px solid red",
    borderRadius: 0,
};

export default FineListItem;
