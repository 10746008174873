import React, {useEffect} from "react";

import ArtOnlyPage from "../../components/widgets/ArtOnlyPage";

import MaintenanceArt from "../../assets/images/page-maintenance-art.svg";
import {withTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import _ from "lodash";

const Maintenance = (props) => {

    const config = useSelector(state => state.config);
    const maintenance = useSelector((state) => state.features?.config?.web?.maintenance);
    const history = useHistory();

    useEffect(() => {

        if (_.isEmpty(config))
            return;

        if (!maintenance)
            history.push('/login')

    }, [config])
    ;
    const {t} = props;
    return (
        <ArtOnlyPage
            showAppStoreInfo={false}
            artSrc={MaintenanceArt}
            alt={t("maintenance:imageAlt")}
            text={t("maintenance:caption")}
        />
    );
};

export default withTranslation()(Maintenance) ;
