import React from "react";
import { BaseCircleChart } from "./BaseCircleChart";

const style = {
    progress: 13,
    progressColor: "#ebba42",
    bgColor: "Moccasin",
    textColor: "Maroon",
    lineWidth: `20`,
    animated: true,
    roundedStroke: true,
    responsive: true,
    defaultMode: false
};

const CircleChart = props => {
    return (
        <>
            {props.mobile ? (
                <BaseCircleChart
                    animate={style.animated}
                    roundedStroke={style.roundedStroke}
                    size='70'
                    progress={props.progress}
                    progressColor={props.progressColor || style.progressColor}
                    bgColor={"#dfdfdf"}
                    textColor={"#000"}
                    lineWidth={style.lineWidth}
                    textStyle={{
                        font: "bold 5rem Helvetica, Arial, sans-serif"
                    }}
                >
                    {props.children}
                </BaseCircleChart>
            ) : (
                <BaseCircleChart
                    animate={style.animated}
                    roundedStroke={style.roundedStroke}
                    size='90'
                    progress={props.progress}
                    progressColor={props.progressColor || style.progressColor}
                    bgColor={"#dfdfdf"}
                    textColor={"#000"}
                    lineWidth={style.lineWidth}
                    textStyle={{
                        font: "bold 5rem Helvetica, Arial, sans-serif"
                    }}
                >
                    {props.children}
                </BaseCircleChart>
            )}
        </>
    );
};

export default CircleChart;
