import {fetchProfile, savePhone, submitValidationCode, updateProfile} from "../../core/api-resources/profile";
import {LOGOUT_START} from "../login/login.combine";

export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const DUPLICATE_EMAIL = "DUPLICATE_EMAIL";
export const SET_PROFILE = "SET_PROFILE";

export const getProfile = () => async (dispatch) => {
    const res = await fetchProfile()
    localStorage.setItem("session_open",res?.id);
    dispatch({
        type: SET_PROFILE,
        payload: res
    })
};

export const updateEmail = (email, onSuccess, onError) => async (dispatch) => {
    await updateProfile({email}, (res) => {
        dispatch({
            type: SET_PROFILE,
            payload: res
        })
        onSuccess(res)
    }, async (res) => {
        // if (!res?.ok && res?.status === 409) {
        //     await dispatch({type: DUPLICATE_EMAIL})
        // }
        onError(res);
    })
};

const defaultState = {
    isDuplicateEmail: false
};

export function profile(state = defaultState, action) {
    switch (action.type) {
        case UPDATE_EMAIL:
            return {};
        case SET_PROFILE:
            return {...state, ...action.payload};
        case DUPLICATE_EMAIL:
            return {...state, isDuplicateEmail: true};
        case LOGOUT_START:
            return defaultState;
        default:
            return state
    }
}

