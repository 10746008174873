import { tagPreboardingSummary } from "../../core/api-resources/tag";
import { setFeatures } from "../app/app.combine";
import { SET_PREBOARDED_VEHICLES } from "../login/login.combine";

export const SET_PREBOARDING_VEHICLE = "SET_PREBOARDING_VEHICLE";
export const SET_PREBOARDING_STATUS = "SET_PREBOARDING_STATUS";
export const SET_REVALIDATION_MODAL = "SET_REVALIDATION_MODAL";

export const SET_ELECTRONIC_TAG_VEHICLE = "SET_ELECTRONIC_TAG_VEHICLE";

const initialState = {
    preboardingVehicle: null,
    showRevalidationModal: false,
    vehicle: null
}

export const setPreboardingVehicle = state => {
    return {
        type: SET_PREBOARDING_VEHICLE,
        payload: state
    }
};

export const setElectronicTagVehicle = state => {
    return {
        type: SET_ELECTRONIC_TAG_VEHICLE,
        payload: state
    }
};


export const getTagPreboardingData = () => async (dispatch, getState) => {

    const handleError = () => {
        const features = getState().features
        const featuresWithoutPreboarding = {
            ...features,
            preBoarding: {
                enabled: false,
                highlightsEnabled: false,
                preBoardingRegistrationEnabled: false
            }
        }
        dispatch(setFeatures(featuresWithoutPreboarding))
    }

    const preboardingData = await tagPreboardingSummary(null, handleError)

    // const res = await fetchProfile()
    // localStorage.setItem("session_open",res?.id);
    dispatch({
        type: SET_PREBOARDED_VEHICLES,
        payload: preboardingData
    })
};

export const setPreboardingStatus = state => {
    return {
        type: SET_PREBOARDING_STATUS,
        payload: state
    }
};

export const setTagPreboardingRevalidationModal = state => {
    return {
        type: SET_REVALIDATION_MODAL,
        payload: state
    }
};

export function electronicTag(state = initialState, action) {

    switch (action.type) {
        case SET_PREBOARDING_VEHICLE:
            return {
                ...state, preboardingVehicle: action.payload
            };
        case SET_PREBOARDING_STATUS:
            return {
                ...state, preboardingVehicle: { ...state.preboardingVehicle, preboarding: action.payload }
            };
        case SET_REVALIDATION_MODAL:
            return {
                ...state, showRevalidationModal: action.payload
            }
        case SET_ELECTRONIC_TAG_VEHICLE:
            return {
                ...state, vehicle: action.payload
            };
        default:
            return state
    }
}
