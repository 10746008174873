import React from "react";

import { Colors } from "../../Colors";
import * as Utils from "../../core/Utils";
import icon from '../../assets/images/icon-arrow-go-top.svg'

const scrollToTop = () => {
    Utils.scrollToDiv("header");
};

const ScrollToTopButton = () => (
    <a style={scrollToTopButton} onClick={() => scrollToTop()}>
        <img src={icon} alt="" aria-hidden="true"/>
    </a>
);

const scrollToTopButton = {
    backgroundColor: Colors.primary,
    borderRadius: "50%",
    height: "40px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#000",
    margin: '10px 0 10px 0'
};

export default ScrollToTopButton;
