import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setPreboardingVehicle } from '../../redux/electronicTag/electronicTag.combine'
import { FontWeight } from '../../components/ui/FontSize'

import electronicTagRegistroIcon from '../../assets/images/electronic-tag/MarbeteElectronico_White.svg';
import electronicTagIcon from '../../assets/images/electronic-tag/MarbeteElectronico.svg';

const PreboardingAlert = (props) => {

    const { pending } = props

    const enabled = useSelector(s => s?.features?.preBoarding?.enabled)

    const { vehicle } = props

    const history = useHistory()
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const handleAction = () => {

        dispatch(setPreboardingVehicle(vehicle))
        history.push('/preboarding/instructions')
    }

    if (!enabled) return null;

    return (
        <button
            onClick={() => handleAction()}
            className={`w-100 d-flex align-items-center justify-content-between px-3 py-4 rounded mb-3 box-shadow border-0 ${pending ? 'bg-orange' : 'bg-yellow'}`}>
            <div className="d-flex align-items-center">
                <img src={pending ? electronicTagRegistroIcon : electronicTagIcon} alt="" aria-hidden />
                <span
                    className="ml-3"
                    style={{ color: pending ? 'white' : 'black', fontWeight: FontWeight.medium }}
                >{t("vehicleDetails:electronicTag.alert")}</span>
            </div>
            {
                pending ?
                    <div className="caret-right-white" />
                    :
                    <div className="caret-right" />
            }
        </button>
    )
}

PreboardingAlert.propTypes = {
    pending: PropTypes.bool
}

export default PreboardingAlert