import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PillHeaderModal from '../ui/PillHeaderModal'
import PreboardingResultAnimation from '../../assets/animations/PreboardingResult'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getTagPreboardingData } from '../../redux/electronicTag/electronicTag.combine'

const UnbindSuccessModal = (props) => {

    const { toggle, vehicleId, success } = props

    const { t } = useTranslation()
    const history = useHistory()

    const user = useSelector((state) => state?.login?.user);

    const [vehicle, setVehicle] = useState(null)

    const dispatch = useDispatch()

    useEffect(() => {
        const preboardedVehicle = user?.vehicles.filter(v => v.id === vehicleId)
        if (preboardedVehicle?.length > 0) setVehicle(preboardedVehicle[0])
    }, [vehicleId, user])

    const action = () => {
        toggle(false)
        dispatch(getTagPreboardingData())
        history.push('/landing')
    }

    return (
        <PillHeaderModal
            toggle={action}
            title={t("vehicleDetails:electronicTag.modal.title")}
            pillContent={vehicle?.tagPreboarding?.tagNumber}
            action={() => action()}
            actionText={t("vehicleDetails:finish")}
        >

            <div className="d-flex flex-column">
                <div className="mb-3 d-flex justify-content-center">
                    <PreboardingResultAnimation error noAutoexpresoIcon={true} />
                </div>

                <div className="d-flex justify-content-center">
                    {
                        success ?
                            <div className='text-center'>
                                <div className='mb-2'>
                                    <strong className="text-uppercase text-center">{t("vehicleDetails:electronicTag.unbindSuccess.0")}</strong>
                                </div>
                                <span>{t("vehicleDetails:electronicTag.unbindSuccess.1")}</span>
                            </div>
                            :
                            <strong className="text-uppercase text-center">{t("vehicleDetails:electronicTag.unbindError")}</strong>
                    }
                </div>
            </div>
        </PillHeaderModal>
    )
}

UnbindSuccessModal.propTypes = {
    toggle: PropTypes.func,
    vehicleId: PropTypes.string
}

export default UnbindSuccessModal