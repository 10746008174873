import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {Colors} from '../../Colors'
import ComponentTitle from '../../components/ui/ComponentTitle'
import PageTitle from '../../components/ui/PageTitle'
import Text from '../../components/ui/Text'
import FormCertifications from '../../components/widgets/FormCertifications'
import CoursesList from '../Courses/components/CoursesList'
import TabNavigation from "../../components/widgets/TabNavigation";
import {isMobile} from "../../core/Utils";

const More = () => {

    let [activeTab, setActiveTab] = useState(0);

    const user = useSelector(state => state?.login?.user);

    const history = useHistory()

    const {i18n} = useTranslation();

    const mobile = isMobile()

    const roadService = window.location.hash

    useEffect(() => {
        roadService === "#roadService" &&
        setActiveTab(1)
    }, [])

    const handleSubmit = async () => {

        if (user) {
            history.push('/courses/form')

            setTimeout(() => {
                history.push('/landing')
            }, 500)
        } else {

            let form = document.getElementById('courses-form')
            form.submit()
        }
    }

    const tabNavigationItems = [
        {
            i18nKey: 'more:navigation.0'
        },
        {
            i18nKey: 'more:navigation.1'
        },
    ]

    const renderActiveTab = (step) => {
        switch (step) {
            case 0:
                return (
                    <>
                        <div className="container p-0 px-lg-3">
                            <ComponentTitle
                                i18nKey={"courses:list"}
                            />
                            <CoursesList action={handleSubmit} isAuth={user !== null}/>
                        </div>
                        <div>
                            {
                                !user &&
                                <div className="container p-0 p-lg-3">
                                    <div
                                        style={{
                                            display: "flex",
                                            direction: "row",
                                            justifyContent: "space-between",
                                            marginTop: "20px",
                                        }}
                                    >
                                        <ComponentTitle i18nKey={"certifications:formsTitle"}/>
                                        <a
                                            href={process.env.REACT_APP_FORMULARIOS_LINK}
                                            style={{
                                                color: Colors.primary,
                                                fontSize: "16px",
                                                marginRight: "15px",
                                            }}
                                            target="_blank"
                                        >
                                            <Text
                                                i18nKey={"certifications:otherForms"}
                                                className="text"
                                            />
                                        </a>
                                    </div>

                                    <FormCertifications/>
                                </div>
                            }
                        </div>

                        <form
                            id="courses-form"
                            name="courses-form"
                            action={process.env.REACT_APP_RECIPROCIDAD_LINK}
                            method="post"
                            target="_blank"
                        >
                            <input
                                type="hidden"
                                name="language"
                                value={i18n.language === "en-US" ? 'en' : 'es'}
                            />
                        </form>
                    </>
                )
            case 1:
                return (
                    <div className="container p-0 px-lg-3 w-100">
                        <div className="row w-100 m-0">
                            <ComponentTitle
                                i18nKey={"more:roadAssistance"}
                            />
                        </div>
                        <div className="row w-100 m-0">
                            <div className="col p-0">
                                <div dangerouslySetInnerHTML={createMarkup()}/>
                            </div>
                        </div>
                    </div>
                )
        }
    }

    const createMarkup = () => {
        return {
            __html: `<iframe src='https://cesco-digital.web.app/road-assist?lang=${i18n.language === "en-US" ? 'en' : 'es'}' style=${markupStyle} />`
        };
    }

    const markupStyle = `width:100%;${mobile ? 'min-height:110vh;' : 'min-height:350px;'}border:none;`

    const changeTab = (index) => {
        setActiveTab(index)

        if (activeTab === 0) {
            history.push('/more#roadService')
        } else {
            history.replace('/more')
        }
    }

    return (
        <>
            <div className="container p-0 px-lg-3">
                <div className="p-mob-only">
                    <PageTitle
                        i18nKey={"more:title"}
                        style={{marginBottom: "40px"}}
                    />
                </div>
            </div>

            <div className="container p-0 px-lg-3 pb-3">
                <TabNavigation
                    activeTab={activeTab}
                    changeTab={changeTab}
                    tabNavigationItems={tabNavigationItems}
                    href={activeTab === 1 ? '/more#roadService' : '/more'}
                />

            </div>

            {
                renderActiveTab(activeTab)
            }

        </>

    )
}

export default More