import _ from "lodash";
import {
    ADD_FINE_TO_PAY,
    CALC_CART_TOTAL,
    FETCH_ALL_FINES,
    FETCH_CARD_FINES,
    FETCH_VEHICLE_FINES,
    FILTER_LICENSE,
    FILTER_VEHICLE,
    REMOVE_FINE_TO_PAY, RESET_CART,
    TOGGLE_ALL_FINE_TO_PAY,
    FETCH_AUTOEXPRESO_FINES,
    DESELECT_ALL_AUTOEXPRESO_FINES,
    ADD_AUTOEXPRESO_FINE_TO_PAY,
    REMOVE_AUTOEXPRESO_FINE_TO_PAY
} from "./actions";
import { RESET_STATE } from "../app/app.combine";
import { LOGOUT_END } from "../login/login.combine";


const stateDefault = {
    workingFines: [],
    allFines: [],
    cart: {
        all: false,
        fines: [],
        sumAmount: 0,
        paymentTotal: {
            finesTotal: 0,
            finesPenalties: 0,
            finesCredits: 0,
            total: 0
        },
        creditCard: {},
        valid: false,
        vehicleId: null
    },
    filter: {
        vehicle: false,
        license: false
    },
    autoexpresoFines: {
        content: [],
        pageable: {
            number: 0,
            size: 0,
            totalPages: 0,
            totalElements: 0
        }
    },
};

const applyCheckedFines = (fineList, checkedFines) => {
    if (fineList)
        return fineList.map(f => {
            if (!checkedFines || checkedFines.length === 0) return _.clone(f);
            checkedFines.map(cf => {
                if (f.id === cf.id)
                    f.checked = true;
            });
            return _.clone(f);
        });
    return []
};

export function fines(state = stateDefault, action) {
    let stateClone = _.cloneDeep(state);
    switch (action.type) {
        case FETCH_ALL_FINES:


        // return {...state,...stateClone /*workingFines: action.payload*/,allFines: action.payload};
        case FETCH_CARD_FINES:
        case FETCH_AUTOEXPRESO_FINES:
        // stateClone.workingFines = applyCheckedFines(action.payload,state.cart.fines);
        // return {...state,...stateClone, allFines: action.payload};
        // return {...state, allFines: action.payload, workingFines: action.payload};
        case FETCH_VEHICLE_FINES:
            // stateClone.workingFines = applyCheckedFines(action.payload,state.cart.fines);
            stateClone.workingFines = applyCheckedFines(action.payload, state.cart.fines);
            return { ...state, ...stateClone, allFines: action.payload };
        // return {...state, allFines: action.payload, workingFines: action.payload};
        case DESELECT_ALL_AUTOEXPRESO_FINES:
            stateClone.workingFines = state.workingFines.map(f => {
                f.checked = false;
                return _.clone(f);
            });
            return { ...state, ...stateClone, cart: stateDefault.cart };
        case ADD_FINE_TO_PAY:

            let sumAmountNew = stateClone.cart.sumAmount + action.payload.amount;
            stateClone.cart.fines.push(action.payload);
            stateClone.cart.sumAmount = sumAmountNew;

            return { ...state, ...stateClone };
        case ADD_AUTOEXPRESO_FINE_TO_PAY:

            let cartPlusAddedFine = stateClone.cart.sumAmount + action.payload.total;
            stateClone.cart.fines.push(action.payload);
            stateClone.cart.sumAmount = cartPlusAddedFine;

            return { ...state, ...stateClone };
        case REMOVE_FINE_TO_PAY:
            let subAmountNew = stateClone.cart.sumAmount - action.payload.amount;
            let finesSubNew = stateClone.cart.fines.filter(f => (action.payload.id !== f.id));
            stateClone.cart.fines = finesSubNew;
            stateClone.cart.sumAmount = subAmountNew;
            return { ...state, ...stateClone };

        case REMOVE_AUTOEXPRESO_FINE_TO_PAY:
            let subAmountNewAE = stateClone.cart.sumAmount - action.payload.total;
            let finesSubNewAE = stateClone.cart.fines.filter(f => (action.payload.id !== f.id));
            stateClone.cart.fines = finesSubNewAE;
            stateClone.cart.sumAmount = subAmountNewAE;
            return { ...state, ...stateClone };
        case TOGGLE_ALL_FINE_TO_PAY:
            let amount = 0;
            stateClone.workingFines = state.workingFines.map(f => {
                amount += f.amount;
                f.checked = action.payload.on;
                return _.clone(f);
            });
            if (action.payload.on)
                stateClone.cart.fines = state.workingFines;
            else {
                stateClone.cart.fines = [];
                amount = 0;
            }
            stateClone.cart.all = action.payload.on;
            stateClone.cart.sumAmount = amount;
            return { ...state, ...stateClone };

        case FILTER_LICENSE:
            if (action.payload.on) {
                stateClone.workingFines = state.allFines.filter(f => f.type === 'l');
                //---------reset checks
                stateClone.cart.all = false;
                stateClone.cart.sumAmount = 0;
                stateClone.workingFines = stateClone.workingFines.map(f => {
                    f.checked = false;
                    return _.clone(f);
                });
                //------------------------
            } else {
                stateClone.cart.all = action.payload.on;
                stateClone.cart.sumAmount = 0;
                stateClone.workingFines = state.allFines;
            }

            stateClone.filter.license = action.payload.on;
            stateClone.filter.vehicle = false;
            return { ...state, ...stateClone };
        case FILTER_VEHICLE:
            if (action.payload.on) {
                stateClone.workingFines = state.allFines.filter(f => f.type === 'p' || f.type === 'a');
                //---------reset checks
                stateClone.cart.all = false;
                stateClone.cart.sumAmount = 0;
                stateClone.workingFines = stateClone.workingFines.map(f => {
                    f.checked = false;
                    return _.clone(f);
                });
                //------------------------
            } else {
                stateClone.cart.all = action.payload.on;
                stateClone.cart.sumAmount = 0;
                stateClone.workingFines = state.allFines;
            }

            stateClone.filter.vehicle = action.payload.on;
            stateClone.filter.license = false;
            return { ...state, ...stateClone };
        case CALC_CART_TOTAL:
            stateClone.cart.paymentTotal = action.payload.paymentTotal;
            stateClone.cart.fines = action.payload.paymentTotal?.fines;
            stateClone.cart.vehicleId = action.payload?.vehicleId || null;
            return { ...state, ...stateClone };
        case RESET_CART:
        case RESET_STATE:
        case LOGOUT_END:
            return stateDefault;
        default:
            return state
    }


}