import React from 'react'
import { number } from 'prop-types'
import { bool } from 'prop-types'
import { Colors } from '../../../Colors'
import { any } from 'prop-types'
import LeadingDots from '../../../components/ui/LeadingDots'
import { useTranslation } from 'react-i18next'
import Text from '../../../components/ui/Text'
import { currencyFormatter } from '../../../utils/currencyFormatter'


const InsuranceOptionCard = (props) => {

    const {
        selected,
        setSelected,
        index,
        o,
        smLayout,
        modal,
        toggleModal
    } = props

    return (
        <div className="col-12 col-lg-4">
            <div className={!modal && 'mx-3 my-1 m-lg-0'}>
                <div className="js-slide">
                    <div className={`card card-hover ${selected?.id === o?.id && "box-shadow"
                        }`}>
                        <header
                            className={`card-header text-center p-0 rounded`}
                            style={{
                                backgroundColor: selected?.id === o?.id ? Colors.primary : Colors.lightGray,
                            }}
                        >
                            <div style={{
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <div style={{ position: 'relative' }}>
                                    <div
                                    onClick={() => toggleModal()}
                                        className='d-flex d-lg-none p-2'
                                        style={{ position: 'absolute', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', width: '100%' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" stroke-width="2" style={{ width: 34 }}>
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </div>
                                    <img
                                        src={o?.artSrc}
                                        style={{ width: '100%' }}
                                        className='rounded-top'
                                        alt=""
                                        aria-hidden
                                    />
                                </div>
                                <div style={{ position: 'absolute', color: 'white', display: 'flex', flexDirection: 'column', marginTop: 80, alignItems: 'center' }}>
                                    <div style={{ display: 'flex', marginRight: 10 }}>
                                        <span className='mt-2 mr-1 text-bold text'>$</span>
                                        <span className='text-xxl text-bold'>{o?.yearCost}</span>
                                    </div>
                                    <Text i18nKey='insuranceSelection:annual' className="text-uppercase text-sm" />
                                </div>
                            </div>
                        </header>

                        {
                            !smLayout &&
                            <OptionDetail
                                o={o}
                                setSelected={setSelected}
                                index={index}
                                smLayout={smLayout}
                                selected={selected}
                                modal={modal}
                            />
                        }

                    </div>
                </div>
            </div>

        </div>
    )
}

export const OptionDetail = (props) => {

    const { o, setSelected, selected, modal } = props

    const lang = useTranslation()?.i18n?.language

    return (
        <div>
            <div className="card-body p-4">
                <div style={{ display: 'flex', flexDirection: 'column', fontSize: 16 }}>
                    {
                        o && o?.detail && o?.detail?.length > 0 &&
                        o.detail.map((detail, i) => (
                            <div key={i}>
                                {
                                    detail?.values && detail?.values?.length > 0 ?
                                        <span
                                            style={{ color: Colors.gray }}
                                        >{detail?.locales?.[lang === "es-US" ? "es" : "en"]}:</span>
                                        :
                                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                            <span style={{ color: Colors.gray }}>{detail?.locales?.[lang === "es-US" ? "es" : "en"]}</span>
                                            <LeadingDots />
                                            <span className='text-bold'>{currencyFormatter.format(detail?.amount)}</span>
                                        </div>
                                }
                                <ul className='pl-3'>
                                    {
                                        detail?.values && detail?.values?.length > 0 &&
                                        detail?.values?.map((d, i) => (
                                            <li key={i}>
                                                <div style={{ display: 'flex', alignItems: 'baseline', lineHeight: 1.8 }}>
                                                    <span style={{ color: Colors.gray }}>{d?.locales?.[lang === "es-US" ? "es" : "en"]}</span>
                                                    <LeadingDots />
                                                    <span className='text-bold'>{currencyFormatter.format(d?.amount)}</span>
                                                </div>
                                            </li>

                                        ))
                                    }
                                </ul>

                            </div>
                        ))
                    }
                </div>


                {
                    !modal &&
                    <button
                        onClick={() => setSelected(o)}
                        type="button"
                        className="btn btn-sm btn-block transition-3d-hover p-3"
                        style={{
                            backgroundColor: selected?.id === o?.id ? Colors.primaryDark : 'transparent',
                            border: selected?.id === o?.id ? 'none' : `2px solid ${Colors.primary}`
                        }}
                    >
                        <Text
                            i18nKey="insuranceSelection:select"
                            className="text"
                            style={{
                                color: selected?.id === o?.id ? 'white' : Colors.primary
                            }}
                        />
                    </button>
                }

            </div>
        </div>
    )
}


InsuranceOptionCard.propTypes = {
    selected: bool,
    setSelected: any,
    index: number,
    o: any,
    smLayout: bool
};

export default InsuranceOptionCard