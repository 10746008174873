import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import * as API from "../../../core/APICaller";
import VoucherBody from '../../../components/widgets/VoucherBody/index'
import {useTranslation} from 'react-i18next'
import {loadReCaptcha} from "react-recaptcha-v3";
import Recaptcha from "../../Login/Recaptcha";

const ValidateDetail = () => {
    const [data, setData] = useState({});
    const [loading, toggleLoading] = useState(false);

    const {id} = useParams();
    const history = useHistory();
    const {t} = useTranslation();

    const recaptchaRef = useRef();

    useEffect(  () => {
        loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY);
    }, []);

    useEffect(() => {
        const { current } = recaptchaRef;
        current.execute();
    },[]);

    useEffect( () => {
        let timer = null;
        if(id) {
            timer = setTimeout(() => {
                (async () => await searchIdc())();
            }, 1500)
            return () => { clearTimeout(timer) }
        } else {
            history.push("/validate")
        }
    }, []);

    const searchIdc = async () => {
        toggleLoading(true);
        await API.validateIdc(id, onSuccess, onError);
    };

    const onSuccess = (payload) => {
        setData(payload);
        toggleLoading(false)
    };

    const onError = () => {
        history.push('/validate?error')
        toggleLoading(false)
    };

    return (
        <>
            <VoucherBody data={data} loading={loading}/>
            <div
                className="container"
                style={{display: "flex", justifyContent: "center"}}
            >
                <button
                    className="btn btn-primary"
                    style={{margin: "20px 0", width: "200px"}}
                    onClick={() => history.push('/validate')}
                >
                    {t("validate:closeButton")}
                </button>
            </div>
            <Recaptcha forwardRef={recaptchaRef} action=""/>
        </>
    );
};

export default ValidateDetail;