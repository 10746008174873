import * as Api from "../../core/APICaller";
import { fetchEntity } from "../../core/APICaller";
import { unloadRecaptcha } from "../../core/Utils";
import { fetchImage } from "../../core/api-resources/entity";
import { setMaintenanceModal, setRecaptcha } from "../app/app.combine";
import { getProfile } from "../profile/profile.combine";
import _ from "lodash";
import { getTagPreboardingData } from "../electronicTag/electronicTag.combine";

const CLEAN_TIMEOUT = 3000;
export const AUTHENTICATING = "AUTHENTICATING";
export const AUTHENTICATED = "AUTHENTICATED";
export const ERROR_LOGIN = "ERROR_LOGIN";
export const LOGIN_PROBLEM = "LOGIN_PROBLEM";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const CLEAN_AUTHENTICATION = "CLEAN_AUTHENTICATION";
export const SESSION_TIMEOUT_ID = "SESSION_TIMEOUT_ID";


export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_END = "LOGOUT_END";

export const SET_PREBOARDED_VEHICLES = "SET_PREBOARDED_VEHICLES";

export const setUser = (user) => ({ type: AUTHENTICATED, payload: user });

export const setAndClearSessionTimeoutId = (id) => ({ type: SESSION_TIMEOUT_ID, payload: id });

export const getAuthEntity = (fromPath) => async (dispatch) => {
    let user = await fetchEntity(null, () => {
        dispatch({ type: LOGIN_FAIL });
    });
    if (user) {
        if (fromPath === "/login" || fromPath === "/") {
            dispatch(logout());
        } else {
            dispatch(setUser(user));
            dispatch(getProfile())
            dispatch(getTagPreboardingData())
            user.image = await fetchImage();
        }
    }
};

export const authenticateStart = () => (dispatch) => {
    dispatch({ type: AUTHENTICATING });
}

export const authenticate = (credentials, onSuccess) => (dispatch) => {
    Api.authenticate(credentials
        , (user) => _onSuccessLogin(user, dispatch, onSuccess)
        , (problem) => _onErrorLogin(dispatch, problem));
};

function _onSuccessLogin(user, dispatch, onSuccess) {
    unloadRecaptcha(process.env.REACT_APP_RECAPTCHA_KEY);
    dispatch(setUser(user));
    dispatch(getProfile())
    dispatch(getTagPreboardingData())
    dispatch(setMaintenanceModal(true))
    if (onSuccess) onSuccess();
}

function _onErrorLogin(dispatch, problem) {
    dispatch(setRecaptcha(undefined));
    if (problem !== "CLIENT_ERROR") {
        dispatch({ type: LOGIN_PROBLEM });
    } else {
        dispatch({ type: ERROR_LOGIN });

    }
    setTimeout(() => {
        dispatch({ type: CLEAN_AUTHENTICATION })
    }, CLEAN_TIMEOUT)
}


export const logout = () => async (dispatch) => {
    dispatch({ type: LOGOUT_START });
    await Api.logout();
    dispatch({ type: LOGOUT_END });
};

const defaultState = {
    loading: false,
    authenticated: undefined,
    user: null,
    unreadNotifications: 0
};

export function login(state = defaultState, action) {

    switch (action.type) {
        case AUTHENTICATING:
            return { ...state, ...defaultState, loading: true };
        case AUTHENTICATED:
            return { ...state, loading: false, authenticated: true, user: _.cloneDeep(action.payload) };
        case ERROR_LOGIN:
            return { ...state, loading: false, user: null, error: true, problem: false };
        case LOGIN_PROBLEM:
            return { ...state, loading: false, user: null, error: false, problem: true };
        case CLEAN_AUTHENTICATION:
            return { ...state, ...defaultState, error: false, problem: false };
        case LOGIN_FAIL:
            return { ...state, ...defaultState, authenticated: false };
        case SESSION_TIMEOUT_ID:
            let currSessionTimeoutId = state.sessionTimeoutId;
            if (currSessionTimeoutId) {
                console.log(`clear session timeout {}`, currSessionTimeoutId)
                clearTimeout(currSessionTimeoutId)
            }
            return { ...state, sessionTimeoutId: action.payload };
        case LOGOUT_START:
            return { ...state, logoutInProgress: true };
        case LOGOUT_END:
            return defaultState;
        case SET_PREBOARDED_VEHICLES:

            let vehicles = _.cloneDeep(state?.user?.vehicles)
            let preboardingData = action.payload?.summary

            let vehiclesWithPreboarding = []

            vehicles && vehicles?.length > 0 && vehicles.map(v => {
                let preboarded = preboardingData?.length > 0 && preboardingData.filter(i => i.vehicleId === v.id)[0]
                vehiclesWithPreboarding.push({ ...  v, tagPreboarding: preboarded || null }) 
            })

            return { ...state, user: { ...state.user, vehicles: vehiclesWithPreboarding } };
        default:
            return state
    }
}