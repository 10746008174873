import React from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'
import Text from './Text'
import { FontWeight } from './FontSize'
import { useTranslation } from 'react-i18next'

const ClickableRow = (props) => {

    const { t } = useTranslation()

    const {
        text,
        icon,
        action,
        className,
        children,
        key,
        caretWhite,
        fines
    } = props

    return (
        <button
            key={key}
            className={`list-group-item d-flex justify-content-between align-items-center w-100 hoverable ${className} p-3`}
            style={{
                // opacity: enable
                //     ? 1
                //     : 0.5,
                borderRadius: 0,
                padding: 0,
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
            }}
            onClick={() => action && action()}
        >

            {
                children && children
            }

            <div
                style={{
                    // color: enable
                    //     ? "#000"
                    //     : Colors.gray,
                    display: "flex",
                    width: "80%",
                    alignItems: 'center'
                }}
            >


                {
                    icon &&
                    <Icon
                        alt={""}
                        src={icon}
                        style={{
                            marginRight: "1rem",
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    />
                }

                {
                    text &&
                    <Text
                        className="text"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            textAlign: 'left',
                            fontWeight: FontWeight.medium,
                        }}
                    >
                        {/* {t(`${cert.name}.text`)} */}
                        {text || "No text provided"}
                    </Text>
                }

            </div>

            {fines > 0 && (
                    <span
                        aria-label={t("vehicleList:hasFines", {
                            fines: props.fines,
                        })}
                        style={spanFines}
                        className="badge badge-sm badge-danger rounded-circle"
                        data-tip={t("vehicleList:fines")}
                        data-for="vehicle-tooltip"
                    >
                        <span aria-hidden={true}>
                            <Text
                                style={{ marginTop: "2px", fontSize: "13px" }}
                            >
                                {fines}
                            </Text>
                        </span>
                    </span>
                )}
            <div className={caretWhite ? "caret-right-white" : "caret-right"}/>
        </button>
    )
}

const spanFines = {
    marginLeft: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "24px",
    height: "24px",
};

ClickableRow.propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string,
    action: PropTypes.func,
    className: PropTypes.string,
    key: PropTypes.number,
    caretWhite: PropTypes.bool
}


export default ClickableRow