import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Col } from "reactstrap";

import Text from "./Text";
import Button from "./Button";

import { Colors } from "../../Colors";
import iconCancel from "../../assets/images/icon-cancel-green.svg";
import PropTypes from 'prop-types'
import { useTranslation } from "react-i18next";

const AppModal = props => {
    const { toggle, body, title, action, actionDisabled, singleAction, size, actionText } = props;

    const { t } = useTranslation();

    return (
        <Modal isOpen={true} size={size || 'sm'} toggle={toggle}>
            <ModalHeader
                toggle={toggle}
                style={{ color: Colors.gray, backgroundColor: Colors.bgGray, display: 'flex', alignItems: 'center' }}
            >
                <Text i18nKey={title} className="text" />
            </ModalHeader>

            <ModalBody
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                {body}
            </ModalBody>

            <ModalFooter
                style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: Colors.lightGray,
                }}
            >
                {
                    !singleAction &&
                    <button
                        onClick={() => toggle(false)}
                        disabled={false}
                        style={noStyle}
                    >
                        <img
                            src={iconCancel}
                            aria-hidden={true}
                            alt=""
                            style={{ marginRight: "4px" }}
                        />
                        No
                    </button>
                }

                <Button
                    className={`btn btn-cesco ${singleAction && 'btn-block'}`}
                    onClick={() => action()}
                    disabled={actionDisabled ? actionDisabled : false}
                >
                    {actionText || t("main:yes")}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const noStyle = {
    backgroundColor: "transparent",
    border: "none",
    color: Colors.primary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
    width: "90px",
};

AppModal.propTypes = {
    toggle: PropTypes.func,
    action: PropTypes.func,
    actionDisabled: PropTypes.bool,
    singleAction: PropTypes.bool,
    text: PropTypes.string,
    title: PropTypes.string
}

export default AppModal;
