import { bool, func } from "prop-types";
import React from "react";

import { Colors } from "../../Colors";

const Card = props => {

    const { 
        hoverable,
        onClick
     } = props

    return (
        <div
            className={`card card-frame ${hoverable && 'hoverable'}`}
            onClick={() => onClick && onClick()}
            style={{
                width: '100%',
                borderTop: 0,
                borderRight: 0,
                borderLeft: 0,
                borderRadius: 0,
            }}
        >
            <div
                className="card-body"
                style={{
                    borderBottom: `6px solid ${Colors.lightGray}`,
                    padding: props.noPadding ? 0 : "20px",
                    ...props.style
                }}
            >
                {props.children}
            </div>
        </div>
    );
};

Card.propTypes = {
    hoverable: bool,
    onClick: func,
    noPadding: bool
};

Card.defaultProps = {
    hoverable: false,
    onClick: null
};


export default Card;
