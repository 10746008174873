import React, {useEffect} from "react";

import Text from "../../../components/ui/Text";
import Button from "../../../components/ui/Button";
import PayFinesWizard from "../components/PayFinesWizard";

import {FontWeight} from "../../../components/ui/FontSize";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import RenewalWizard from "../../Renewal/components/RenewalWizard";
import Lottie from "react-lottie";
import error from "./error-lottie.json";
import SubHeader from "../../../layout/SubHeader";
import {useTranslation} from "react-i18next";
import {resetState} from "../../../redux/app/app.combine";
import ClearBackButton from "../../../utils/clearBackButton";

const lottie_error_opts = {
    loop: false,
    autoplay: true,
    animationData: error,
};

const PaymentError = (props) => {
    const history = useHistory();
    const flow = useSelector((state) => state?.flow);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const onContinue = () => {
        dispatch(resetState())
        history.push("/landing")
    }

    useEffect(() => {

        ClearBackButton()
    }, [])

    return (
        <>
            <SubHeader>
                {flow === "RENEWAL" ? (
                    <RenewalWizard step={2}/>
                ) : (
                    <PayFinesWizard step={4} error={true}/>
                )}
            </SubHeader>

            <div className="container">
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    {/*   <img
                        src={iconFailed}
                        alt={"Imagen de Error al momento de pago"}
                        style={{ margin: "1rem" }}
                    />*/}
                    <Lottie
                        options={lottie_error_opts}
                        height={250}
                        width={300}
                    />
                    <Text
                        style={{
                            fontSize: "22px",
                            fontWeight: FontWeight.medium,
                            marginBottom: "25px",
                            letterSpacing: "0.03rem",
                            textAlign: "center",
                            textTransform: 'uppercase'
                        }}
                        className="text-md"
                        i18nKey="paymentError:title"
                    />
                    {/*<Text style={{ color: Colors.gray }}>
                        Regresar a nuestra pagina
                    </Text>
                    <br />*/}
                    <Button
                        onClick={onContinue}
                        style={{marginBottom: "1rem", width: '250px'}}
                        disabled={false}
                        className="btn-primary"
                    >
                        {t("paymentError:finish")}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default PaymentError;
