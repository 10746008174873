import React from "react";

import Text from "../../../components/ui/Text";
import { Colors } from "../../../Colors";
import cescoLogo from "../../../assets/images/LogoCescoGreen.svg";
import govLogo from "../../../assets/images/Footer_LogoSelloGob.svg";
import * as Utils from "../../../core/Utils";
import { useTranslation } from "react-i18next";
import Skeleton from 'react-skeleton-loader'
import downloadIcon from "../../../assets/images/icon_download.svg";
import { openVoucherPdf } from "../../../core/api-resources/voucher";
import VoucherBody from "../../../pages/TagRenewal/Voucher/VoucherBody";

// TYPES of voucher
// VEHICLE_TRANSFER, CARD_PRINT, LICENSE_RENEWAL

const Index = (props) => {
    let { data, loading, download } = props;

    const { t } = useTranslation();

    let licenseRenewalDate = Utils.format(data?.licenseRenewalDate, "DD-MMM-YYYY h:mm a");
    let digitalExpirationDate = Utils.format(data?.digitalExpirationDate, "DD.MMM.YY");
    let renewalDate = Utils.format(data?.renewalDate, "DD-MMM-YYYY h:mm a");
    let paymentDate = Utils.format(data?.paymentDate, "DD-MMM-YYYY");

    const type = data?.type

    const openPdf = async () => {

        const pdf = await openVoucherPdf()
        if (pdf) Utils.openDialog(pdf)
    }

    const renderLoading = () => (
        <>
            <table className="table table-bordered text-uppercase mb-0 d-none d-md-table">
                <tbody className="text">
                    <tr>
                        <td><Skeleton /><br /><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                    </tr>
                    <tr>
                        <td scope="row"><Skeleton /></td>
                        <td><Skeleton /></td>
                        <td><Skeleton /></td>
                    </tr>
                </tbody>
            </table>

            <table className="table table-bordered text-uppercase mb-0 d-md-none d-table">
                <tbody className="text">
                    <tr>
                        <td><Skeleton /></td>
                    </tr>
                    <tr>
                        <td><Skeleton /></td>
                    </tr>
                    <tr>
                        <td><Skeleton /></td>
                    </tr>
                    <tr>
                        <td scope="row"><Skeleton /></td>
                    </tr>
                    <tr>
                        <td><Skeleton /></td>
                    </tr>
                    <tr>
                        <td><Skeleton /></td>
                    </tr>
                </tbody>
            </table>
        </>
    )

    const renderTable = () => {
        if (!type) return

        switch (type) {
            case 'VEHICLE_TRANSFER':
                return vehicleTransferTable();
            case 'CARD_PRINT':
                return cardPrintTable();
            case 'LICENSE_RENEWAL':
                return licenseRenewalTable();
            case 'VEHICLE_RENEWAL':
                return RenderTagRenewalVoucher();
        }
    }

    const RenderTagRenewalVoucher = () => {
        return (
            <VoucherBody
                owner={data?.owner}
                qrImage={data?.qrImage}
                renewalDate={data?.renewalDate}
                renewalPeriod={data?.renewalPeriod}
                vehicle={data?.vehicle}
                tagType={data?.tagType}
                insurance={data?.insurance}
                idcNumber={data?.idcNumber}
            />
        )
    }

    // Table title

    const renderTableTitle = () => {
        if (!type) return

        switch (type) {
            case 'VEHICLE_TRANSFER':
                return t("voucherBody:tables.vehicleTransfer.title");
            case 'CARD_PRINT':
                return t("voucherBody:tables.cardPrint.title");
            case 'LICENSE_RENEWAL':
                return t("voucherBody:tables.licenseRenewal.title");
            case 'VEHICLE_RENEWAL':
                return t("voucherBody:tables.licenseRenewal.title")
        }
    }

    // TABLES
    const vehicleTransferTable = () => {
        return (
            <>
                {/* Desktop */}
                <table className="table table-bordered text-uppercase mb-0 d-none d-md-table">
                    <tbody className="text">
                        <tr>
                            <td scope="row">
                                {t("voucherBody:tables.vehicleTransfer.buyer")}: <br />
                                <span className="text-bold">
                                    {`${data?.buyerFirstName}`} <br /> {`${data?.buyerLastName}`}
                                </span>
                            </td>

                            <td colSpan={2} style={{ verticalAlign: 'middle' }}>
                                {t("voucherBody:tables.vehicleTransfer.vehicle")}: <br />
                                <span className="text-bold">{data?.vehicleDescription || "N/A"}</span>
                            </td>
                        </tr>

                        <tr>
                            <td scope="row">
                                {t("voucherBody:tables.vehicleTransfer.seller")}: <br />
                                <span className="text-bold">
                                    {`${data?.sellerFirstName}`} <br /> {`${data?.sellerLastName}`}
                                </span>
                            </td>

                            <td align="center" style={{ verticalAlign: 'middle' }}>
                                {t("voucherBody:renewalDate")}: <br />
                                <span className="text-bold">{renewalDate || "N/A"}</span>
                            </td>
                            <td align="center" style={{ verticalAlign: 'middle' }}>
                                {t("voucherBody:cescoPickup")}: <br />
                                <span className="text-bold">{data?.cesco || "N/A"}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/* Mobile */}
                <table className="table table-bordered text-uppercase mb-0 d-md-none d-table">
                    <tbody className="text">
                        <tr>
                            <td>
                                {t("voucherBody:tables.vehicleTransfer.buyer")}: <br />
                                <span className="text-bold">
                                    {`${data?.buyerFirstName}`} <br /> {`${data?.buyerLastName}`}
                                </span>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                {t("voucherBody:tables.vehicleTransfer.vehicle")}: <br />
                                <span className="text-bold">{data?.vehicleDescription || "N/A"}</span>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                {t("voucherBody:tables.vehicleTransfer.seller")}: <br />
                                <span className="text-bold">
                                    {`${data?.sellerFirstName}`} <br /> {`${data?.sellerLastName}`}
                                </span>
                            </td>
                        </tr>

                        <tr>
                            <td scope="row">
                                {t("voucherBody:renewalDate")}: <br />
                                <span className="text-bold">{renewalDate || "N/A"}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t("voucherBody:cescoPickup")}: <br />
                                <span className="text-bold">{data?.cesco || "N/A"}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
        )
    }

    const cardPrintTable = () => {
        return (
            <>
                {/* Desktop */}
                <table className="table table-bordered text-uppercase mb-0 d-none d-md-table">
                    <tbody className="text">
                        <tr>
                            <td scope="row" className="text-bold">
                                {`${data?.firstName}`} <br /> {`${data?.lastName}`}
                            </td>
                            <td align="center" style={{ verticalAlign: 'middle' }}>
                                {t("voucherBody:tables.cardPrint.postalDelivery")}<span>: </span>
                                <span className="text-bold">
                                    {data?.deliveryType === "PostalDelivery" ?
                                        t("voucherBody:tables.cardPrint.yes") :
                                        "No"}
                                </span>
                            </td>
                            <td align={"center"} >
                                {t("voucherBody:tables.cardPrint.changeDate")}: <br />
                                <span className="text-bold">{paymentDate || "N/A"}</span>
                            </td>
                        </tr>

                        <tr>
                            <td scope="row">
                                {t("voucherBody:tables.cardPrint.cardNumber")}: <br />
                                <span className="text-bold">{data?.cardNumber || "N/A"}</span>
                            </td>
                            <td align={"center"}>
                                {t("voucherBody:tables.cardPrint.transactionDate")}: <br />
                                <span className="text-bold">{data?.transactionDate || "N/A"}</span>
                            </td>
                            <td align={"center"}>
                                {t("voucherBody:tables.cardPrint.cesco")}: <br />
                                <span className="text-bold">{data?.cesco || "N/A"}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/* Mobile */}
                <table className="table table-bordered text-uppercase mb-0 d-md-none d-table">
                    <tbody className="text">
                        <tr>
                            <td scope="row" className="text-bold">
                                {`${data?.firstName}`} <br /> {`${data?.lastName}`}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                {t("voucherBody:tables.cardPrint.postalDelivery")}<span>: </span>
                                <span className="text-bold">
                                    {data?.deliveryType === "PostalDelivery" ?
                                        t("voucherBody:tables.cardPrint.yes") :
                                        "No"}
                                </span>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                {t("voucherBody:tables.cardPrint.changeDate")}: <br />
                                <span className="text-bold">{paymentDate || "N/A"}</span>
                            </td>
                        </tr>

                        <tr>
                            <td scope="row">
                                {t("voucherBody:tables.cardPrint.cardNumber")}: <br />
                                <span className="text-bold">{data?.cardNumber || "N/A"}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t("voucherBody:tables.cardPrint.transactionDate")}: <br />
                                <span className="text-bold">{data?.transactionDate || "N/A"}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t("voucherBody:tables.cardPrint.cesco")}: <br />
                                <span className="text-bold">{data?.cesco || "N/A"}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
        )
    }

    const licenseRenewalTable = () => {
        return (
            <>
                {/* Desktop */}
                <table className="table table-bordered text-uppercase mb-0 d-none d-md-table">
                    <tbody className="text">
                        <tr>
                            <td scope="row" className="text-bold">{`${data?.firstName}`} <br /> {`${data?.lastName}`}</td>
                            <td>{t("voucherBody:category")}:
                                <>
                                    <span className="text-bold">{data?.category || "N/A"}</span> <br />
                                    REAL ID: <span
                                        className="text-bold">{data?.realId ? t("voucherBody:true") : "No"}</span>
                                </>
                            </td>
                            <td>{t("voucherBody:renewalDate")}: <br />
                                <span className="text-bold">{licenseRenewalDate || "N/A"}</span>
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">{t("voucherBody:licenseNo")}: <br />
                                <span className="text-bold">{data?.license || "N/A"}</span>
                            </td>
                            <td>
                                {t("voucherBody:expirationDate")}: <br />
                                <span className="text-bold">{digitalExpirationDate || "N/A"}</span>
                            </td>
                            <td>{t("voucherBody:cescoPickup")}: <br />
                                <span className="text-bold">{data?.cesco || "N/A"}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/* Mobile */}
                <table className="table table-bordered text-uppercase mb-0 d-md-none d-table">
                    <tbody className="text">
                        <tr>
                            <td scope="row" className="text-bold">{`${data?.firstName}`} <br /> {`${data?.lastName}`}</td>
                        </tr>

                        <tr>
                            <td>{t("voucherBody:category")}:
                                <span className="text-bold">{data?.category || "N/A"}</span> <br />
                                REAL ID: <span
                                    className="text-bold">{data?.realId ? t("voucherBody:true") : "No"}</span>
                            </td>
                        </tr>

                        <tr>
                            <td> {t("voucherBody:renewalDate")}: <br />
                                <span className="text-bold">{licenseRenewalDate || "N/A"}</span>
                            </td>
                        </tr>

                        <tr>
                            <td scope="row">{t("voucherBody:licenseNo")}: <br />
                                <span className="text-bold">{data?.licenseNumber || "N/A"}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>{t("voucherBody:expirationDate")}: <br />
                                <span className="text-bold">{digitalExpirationDate || "N/A"}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>{t("voucherBody:cescoPickup")}: <br />
                                <span className="text-bold">{data?.cesco || "N/A"}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
        )
    }

    return (
        <div>
            <div className="container" style={resultStyle}>
                <div style={resultHeading}>
                    <div className="d-flex mt-3 ml-3">
                        <img src={cescoLogo} alt="logo" style={{ height: 50 }} />
                        <img src={govLogo} alt="logo"
                            style={{ height: 50, marginLeft: 15, borderLeft: '1px solid lightgray', paddingLeft: 15 }} />
                    </div>
                    {/*<img*/}
                    {/*    src={`data:image/png;base64, ${data?.qrImage}`}*/}
                    {/*    alt="QR"*/}
                    {/*    style={{*/}
                    {/*        height: "120px",*/}
                    {/*        width: "120px",*/}
                    {/*    }}*/}
                    {/*/>*/}

                    {
                        download &&
                        <div className="mr-3">
                            <a
                                href={"#"}
                                style={{ color: "black" }}
                                onClick={() => openPdf()}
                            >
                                <img
                                    src={downloadIcon}
                                    alt={t("disclosure:download")}
                                />
                            </a>
                        </div>
                    }

                </div>
                <div className="my-4 mx-3 text-center">
                    <Text className="text-bold">
                        {renderTableTitle()}
                    </Text>
                </div>

                <div style={resultIdc}>
                    {
                        loading ? <Skeleton /> :
                            <>
                                <Text className="text-md text-bold">
                                    IDC#&nbsp;
                                </Text>
                                <Text className="text-md text-bold">
                                    {data?.idcNumber}
                                </Text>
                            </>
                    }
                </div>

                {loading ? renderLoading() : renderTable()}

            </div>

        </div>
    );
};

const resultIdc = {
    backgroundColor: Colors.lightGray,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
};

const resultHeading = {
    display: "flex",
    padding: "15px",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
};

const resultStyle = {
    backgroundColor: "#fff",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "1000px",
};

export default Index;
