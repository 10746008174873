import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Text from "../../../components/ui/Text";
import SubHeader from "../../../layout/SubHeader";
import { Colors } from "../../../Colors";
import WizardFooter from "../../../components/widgets/WizardFooter";
import RenewalWizard from "../components/RenewalWizard";
import ToggleThreeWay from "../../../components/ui/ToggleThreeWay";
import { useDispatch, useSelector } from "react-redux";
import { setAnswersToQuestions } from "../../../redux/renewal/actions";

const RenewalQuestions = () => {
    let dispatch = useDispatch();
    let renewalForm = useSelector((state) => state.renewal?.renewalForm);
    const convertThreeWayInput = (value) => {
        if (typeof value === "boolean") return value ? 2 : 0;
        else return 1;
    };

    const convertThreeWayToBoolean = (value) => {
        if (value === 0) return false;
        else if (value === 2) return true;
        else return null;
    };

    const bloodOptions = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];
    const [bloodType, setBloodType] = useState(renewalForm?.bloodType);
    const [donor, setDonor] = useState(convertThreeWayInput(renewalForm?.donor));
    const [serviceState, setServiceState] = useState(
        convertThreeWayInput(renewalForm?.selectiveServiceCandidate)
    );

    let history = useHistory();
    const changeBloodType = (type) => {
        if (bloodType == type) {
            setBloodType("");
        } else {
            setBloodType(type);
        }
    };

    const nextAction = () => {
        let form = {
            donor: convertThreeWayToBoolean(donor),
            bloodType: bloodType,
            selectiveServiceCandidate: convertThreeWayToBoolean(serviceState),
        };
        //TODO: add validation here
        dispatch(
            setAnswersToQuestions(form, () => {
                history.push("/pay/fines");
            })
        );
    };

    return (
        <>
            <SubHeader>
                <div className="container">
                    <RenewalWizard step={1} />
                </div>
            </SubHeader>

            <div className="d-flex flex-column align-items-center">
                <div style={subTitleStyle} className="container">
                    <Text
                        style={{ color: Colors.gray }}
                        i18nKey="questions:subTitle"
                        className="text"
                    />
                </div>

                <div className="container" style={{ padding: 0 }}>
                    <div style={questionStyle}>
                        <div style={questionContent}>
                            <Text style={questionNo} className="text-xl">
                                1.
                            </Text>
                            <Text
                                i18nKey="questions:questionOne"
                                className="text"
                            />
                        </div>
                        <div>
                            <ToggleThreeWay
                                state={donor}
                                setState={setDonor}
                                i={0}
                            />
                        </div>
                    </div>

                    <div style={questionStyle} className="bloodOptions">
                        <div style={questionContent}>
                            <Text style={questionNo} className="text-xl">
                                2.
                            </Text>
                            <Text
                                i18nKey="questions:questionTwo"
                                className="text"
                            />
                        </div>
                        <div style={bloodOptionsGroup} className="bloodType">
                            {bloodOptions &&
                                bloodOptions.map((o) => (
                                    <button
                                    className="hoverable"
                                        style={{
                                            ...bloodOption,
                                            backgroundColor:
                                                bloodType == o
                                                    ? Colors.primary
                                                    : "#fff",
                                            color:
                                                bloodType == o
                                                    ? "#fff"
                                                    : "#000",
                                        }}
                                        value={o}
                                        onClick={() => changeBloodType(o)}
                                    >
                                        {o}
                                    </button>
                                ))}
                        </div>
                    </div>
                </div>

                <div className="container">
                    <WizardFooter
                        // {...props}
                        // disabled={!bloodType || !isValidDonor()}
                        disabled={false}
                        handleNext={nextAction}
                    />
                </div>
            </div>
        </>
    );
};

const questionStyle = {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px",
    width: "100%",
    marginBottom: "20px",
    border: `1px solid ${Colors.lightGray}`,
    borderRadius: "5px",
};

const questionNo = {
    color: Colors.primary,
    marginRight: "10px",
};

const questionContent = {
    display: "flex",
    alignItems: "center",
    width: "100%",
};

const bloodOptionsGroup = {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    alignContent: "stretch",
    alignItems: "stretch",
};

const bloodOption = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${Colors.borderGray}`,
    width: "40px",
    height: "40px",
    borderRadius: "4px",
    fontSize: "16px",
    marginLeft: "15px",
    marginTop: "4px",
    marginBottom: "4px",
};

const subTitleStyle = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
};

export default RenewalQuestions;
