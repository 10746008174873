import {LOADING_END, LOADING_START} from "../loading/loadingState.combine";
import * as Api from "../../core/api-resources/entity";
import {handleResponse} from "../../core/APICaller";
import {FETCH_CARD_FINES, FETCH_VEHICLE_FINES} from "../fines/actions";


export const FETCH_CARD_INFO = "FETCH_CARD_INFO";
export const FETCH_VEHICLE_INFO = "FETCH_VEHICLE_INFO";

export const fetchCard = (onCardFetchSuccess,includeFines=true) => async (dispatch) => {
    dispatch({type: LOADING_START});
    let card = handleResponse(await Api.fetchCardInfo(),onCardFetchSuccess);
    dispatch({type: LOADING_END});

    dispatch({
        type: FETCH_CARD_INFO,
        payload: card || {}
    });
    if(includeFines)
    dispatch({
        type: FETCH_CARD_FINES,
        payload: card?.fines
    })

};

export const fetchVehicle = (vehicleId,onFetchSuccess,onFetchError,includeFines=true) => async (dispatch) => {
    dispatch({type: LOADING_START});
    let vehicle = handleResponse(await Api.fetchVehicleInfo(vehicleId),onFetchSuccess,onFetchError);
    dispatch({type: LOADING_END});

    dispatch({
        type: FETCH_VEHICLE_INFO,
        payload: vehicle
    });
    if(includeFines)
    dispatch({
        type: FETCH_VEHICLE_FINES,
        payload: vehicle.fines
    })

};