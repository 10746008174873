import React, { useEffect, useState } from "react";

import Text from "../ui/Text";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Colors } from "../../Colors";
import PropTypes from "prop-types";

import Art from "../../assets/images/Art_Suscribete.svg";
import iconMail from "../../assets/images/icon_Mail.svg";
import { FontWeight } from "../ui/FontSize";
import { saveEmail } from "../../core/api-resources/entity";
import {
    LOADING_END,
    LOADING_START,
} from "../../redux/loading/loadingState.combine";
import { useDispatch } from "react-redux";

const ContactInformationModal = (props) => {
    const { t } = useTranslation();
    let dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [modal, setModal] = useState(false);
    // const toggle = () => setModal(!modal);

    useEffect(() => {
        setModal(props.showModal);
    }, [props.showModal]);

    const submit = async () => {
        // eslint-disable-next-line no-undef
        dispatch({ type: LOADING_START });
        await saveEmail(email);
        dispatch({ type: LOADING_END });
        setModal(false);
    };

    return (
        <Modal
            isOpen={modal}
            size="md"
            style={{ padding: "15px" }}
            toggle={() => setModal(false)}
        >
            <ModalHeader toggle={() => setModal(false)} style={{ borderBottom: 0}}></ModalHeader>

            <ModalBody style={{ paddingTop: 0 }}>
                <div style={bodyStyle}>
                    <Text style={heading} i18nKey="contactInfo:title" />
                    <Text
                        i18nKey="contactInfo:paragraph"
                        style={{ marginBottom: "10px" }}
                    />
                    <div style={{ margin: "10px 0" }}>
                        <img src={Art} style={{ width: "100%" }} alt={""} />
                    </div>

                    {/*<div className="input-group mb-2">
                        <div className="input-group-prepend">
                            <span
                                className="input-group-text"
                                style={{
                                    backgroundColor: Colors.lightGray
                                }}
                            >
                                <img
                                    src={iconPhone}
                                    style={{height: "20px", width: "20px"}}
                                    alt=""
                                />
                            </span>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            aria-label={t("contactInfo:phone")}
                            placeholder={t("contactInfo:phone")}
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            style={{
                                height: "auto",
                            }}
                        />
                    </div>*/}

                    <div className="input-group mb-2">
                        <div className="input-group-prepend">
                            <span
                                className="input-group-text"
                                style={{
                                    backgroundColor: Colors.lightGray,
                                }}
                            >
                                <img
                                    src={iconMail}
                                    style={{ height: "20px", width: "20px" }}
                                    alt=""
                                />
                            </span>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            aria-label={t("contactInfo:email")}
                            placeholder={t("contactInfo:email")}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{
                                height: "auto",
                            }}
                        />
                    </div>

                    <button
                        className="btn-text btn-text-primary"
                        onClick={submit}
                        style={{
                            backgroundColor: Colors.primary,
                            width: "100%",
                            color: "#fff",
                            padding: "15px",
                            border: "none",
                            borderRadius: "5px",
                        }}
                    >
                        {t("contactInfo:subscribe")}
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
};

const heading = {
    fontSize: "22px",
    textTransform: "uppercase",
    color: "#000",
    marginBottom: "10px",
    fontWeight: FontWeight.medium,
};

const bodyStyle = {
    display: "flex",
    padding: "15px",
    paddingTop: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    fontSize: "15px",
    color: Colors.gray,
    textAlign: "center",
};

ContactInformationModal.propsTypes = {
    showModal: PropTypes.bool,
};

export default ContactInformationModal;
