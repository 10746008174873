import React from "react";

import iconAlert from "../../assets/images/icon-alert.svg";
import iconAlertGray from "../../assets/images/icon-alert-gray.svg";
import iconNotification from "../../assets/images/icon-notification.svg";
import iconNotificationGray from "../../assets/images/icon-notification-gray.svg";
import iconNotificationWhite from "../../assets/images/icon-notification-white.svg";
import iconAlertWhite from "../../assets/images/icon-alert-white.svg"

const AlertIcon = props => {
    const type = props.type;
    return (
        <img
            alt={""}
            src={
                type === "ALERT"
                    ? iconAlert
                    : type === "ALERT_GRAY"
                    ? iconAlertGray
                    : type === "ALERT_WHITE"
                    ? iconAlertWhite
                    : type === "NOTIFICATION"
                    ? iconNotification
                    : type === "NOTIFICATION_GRAY"
                    ? iconNotificationGray
                    : type === "NOTIFICATION_WHITE"
                    ? iconNotificationWhite
                    : null
            }
            style={{
                height: "24px",
                width: "24px",
                ...props.style
            }}
        />
    );
};

export default AlertIcon;
