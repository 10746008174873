import React from 'react';
import HeIcon from "../../assets/images/icon_He.svg";
import SheIcon from "../../assets/images/icon_She.svg";

const ImageBlob = (props) => {
    let {src, sex} = props;
    let srcDetermined;

    if (src)
        if (!(src instanceof ArrayBuffer) && src.startsWith("data:image"))
            srcDetermined = src;
        else if (src instanceof ArrayBuffer && src.byteLength > 0) {
            const blob = new Blob([src], {type: 'image/jpeg'});
            srcDetermined = URL.createObjectURL(blob);
        }

    if (!srcDetermined) {
        return (<div style={sexIconStyle}>
            {(sex === "M" || !sex) && (<img src={HeIcon} aria-hidden="true" alt="" style={{ height: '35px', width: '35px'}}/>)}
            {sex === "F" && (<img src={SheIcon} aria-hidden="true" alt="" style={{ height: '35px', width: '40px'}}/>)}
        </div>)
    }

    return (<img src={srcDetermined} height={props.height} style={props.style} aria-hidden={true} alt=""/>);
};

const sexIconStyle = {
    height: "75px",
    width: "75px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
    marginRight: "15px",
};

export default ImageBlob;
