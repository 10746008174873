import * as Api from "../../core/api-resources/payment";
import { caller, LOADING_END, LOADING_START, showError } from "../loading/loadingState.combine";
import { autoexpresoPay, autoexpresoPayAll, handleResponse } from "../../core/APICaller";
import { RESET_STATE } from "../app/app.combine";

export const SELECT_FINES = "SELECT_FINES";
export const ADD_PAYMENT_INFO = "ADD_PAYMENT_INFO";
export const SUCCESS_COMPLETE_PAYMENT = "SUCCESS_COMPLETE_PAYMENT";
export const ERROR_COMPLETE_PAYMENT = "ERROR_COMPLETE_PAYMENT";

export const selectFines = (fines, onSuccess) => async (dispatch) => {
    let tickets = fines.map(f => f.id);
    if (tickets && tickets.length > 0) {
        dispatch({ type: LOADING_START });
        let data = handleResponse(await Api.calculateFees({ tickets: tickets }), null, showError());
        dispatch({ type: LOADING_END });
        let {
            finesCredits,
            finesPenalties,
            finesTotal,
            total
        } = data;
        let payload = {
            fines: fines,
            paymentTotal: {
                finesTotal: finesTotal,
                finesPenalties: finesPenalties,
                finesCredits: finesCredits,
                total: total
            },
            creditCard: {},
            valid: false
        };
        dispatch({ type: SELECT_FINES, payload });
    }

    if (onSuccess) onSuccess();

};

export const addPaymentMethod = (payload) => async (dispatch) => {
    dispatch({ type: ADD_PAYMENT_INFO, payload });
};

export const completePayment = (onSuccess, onError, notProcessedError) => async (dispatch, getState) => {
    let { creditCard } = getState().payment;
    let { fines } = getState().fines.cart;
    let tickets = fines.map(f => f.id);
    let email = creditCard.email;

    // Remove email from creditCard object
    delete creditCard.email;
    // Add century digits to expiryYear
    creditCard.expiryYear = `20${creditCard.expiryYear}`

    let payload = {
        creditCard: creditCard,
        tickets: tickets,
        email: email
    };

    const paymentEndpoint = Api.paymentComplete

    dispatch(caller(paymentEndpoint(payload,
        (receipt) => {
            if (receipt && receipt.processed) {
                dispatch({ type: SUCCESS_COMPLETE_PAYMENT });
                onSuccess();
            } else {
                onPaymentError(dispatch, notProcessedError)
            }
        },
        () => {
            onPaymentError(dispatch, onError)
        },
    )));
};

export const submitPayAllAutoexpreso = (onSuccess, onError, notProcessedError) => async (dispatch, getState) => {

    dispatch({ "type": LOADING_START });

    let { payment } = getState();
    let cart = getState().fines.cart;

    let { creditCard } = payment;
    let paymentTotal = cart?.paymentTotal;
    let vehicleId = cart?.vehicleId;

    let payload = {
        paymentInfo: {
            creditCard: creditCard,
        },
        postalAddress: payment.creditCard.billingAddress,
        finesIds: paymentTotal?.fines,
        ...paymentTotal,
        // finesTotal: paymentTotal?.finesTotal,
        // finesCount: paymentTotal?.finesCount,
        // total: paymentTotal?.total,
        // tollAmount: paymentTotal?.tollAmount,
        // finesIds: paymentTotal?.fines
    }

    delete payload.fines

    dispatch(caller(autoexpresoPayAll(vehicleId, payload,
        (receipt) => {
            if (receipt && receipt.processed) {
                dispatch({ type: SUCCESS_COMPLETE_PAYMENT });
                onSuccess();
            } else {
                onPaymentError(dispatch, notProcessedError)
            }
        },
        () => {
            onPaymentError(dispatch, onError)
        },
    )));

    // dispatch({ "type": LOADING_END });
}

export const submitPayAutoexpreso = (onSuccess, onError, notProcessedError) => async (dispatch, getState) => {

    dispatch({ "type": LOADING_START });

    let { payment, fines } = getState();
    let { cart } = fines
    let { creditCard } = payment;
    const vehicleId = cart.vehicleId;
    const paymentTotal = cart?.paymentTotal;

    let payload = {
        paymentInfo: {
            creditCard: creditCard
        },
        finesTotal: paymentTotal?.finesTotal,
        finesCount: paymentTotal?.finesCount,
        tollAmount: paymentTotal?.tollAmount,
        total: paymentTotal?.total,
        selectedFines: cart.fines,
    }

    dispatch(caller(autoexpresoPay(vehicleId, payload,
        (receipt) => {
            if (receipt && receipt.processed) {
                dispatch({ type: SUCCESS_COMPLETE_PAYMENT });
                onSuccess();
            } else {
                onPaymentError(dispatch, notProcessedError)
            }
        },
        () => {
            onPaymentError(dispatch, onError)
        },
    )));

    // dispatch({ "type": LOADING_END });
}

const onPaymentError = (dispatch, onError) => {
    dispatch({ type: ERROR_COMPLETE_PAYMENT });
    onError();
}

const defaultState = {
    completed: false,
    error: false,
    creditCard: {},
    valid: false
};

export function payment(state = defaultState, action) {

    let { payload } = action;
    switch (action.type) {
        case ADD_PAYMENT_INFO:
            return { ...state, creditCard: payload };
        case SUCCESS_COMPLETE_PAYMENT:
            return { completed: true };
        case ERROR_COMPLETE_PAYMENT:
            return { ...state, completed: true, error: true };
        case RESET_STATE:
            return defaultState;
        default:
            return state
    }
}