export const SET_SELECTED_INSURANCE = "SET_SELECTED_INSURANCE";
export const SET_SELECTED_VEHICLE = "SET_SELECTED_VEHICLE"

export const setSelectedInsurance = state => {
    return {
        type: SET_SELECTED_INSURANCE,
        payload: state
    }
};

export const setSelectedVehicle = state => {
    return {
        type: SET_SELECTED_VEHICLE,
        payload: state
    }
};


const initialState = {
    selectedInsurance: null,
    selectedVehicle: null
}

export function insurance(state = initialState, action) {

    switch (action.type) {
        case SET_SELECTED_INSURANCE:
            return {
                 ...state, selectedInsurance: action.payload
            };

        case SET_SELECTED_VEHICLE:
            return {
                 ...state, selectedVehicle: action.payload
            };

        default:
            return state
    }
}
