import {createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import reducer from "./redux/reducers";
const middleware = process.env.NODE_ENV !== 'production' ?
    [thunk, logger] :
    [thunk];
let store = createStore(reducer, applyMiddleware(...middleware));


export default store;
