import React, {useEffect, useState} from "react";

import iconCardEmail from "../../assets/images/icon-card-email.svg";
import iconPhone from "../../assets/images/icon-phone.svg";
import iconEmailInvalid from "../../assets/images/icon-email-invalid.svg";
import iconCheckBlue from "../../assets/images/icon-check-blue.svg";
import iconEnter from "../../assets/images/icon-enter.svg";
import iconCode from "../../assets/images/icon_IngresarCodigo.png";
import iconEdit from '../../assets/images/icon_Edit.svg'
import iconEnterDisabled from "../../assets/images/icon_Enter_Disable.svg";

import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getProfile, updateEmail} from "../../redux/profile/profile.combine";
import {savePhone, sendValidationCode} from "../../core/api-resources/profile";
import ComponentTitle from "../ui/ComponentTitle";
import InputNotificationPopup from "../../pages/Login/InputNotificationPopup";
import TextInput from "../ui/TextInput";
import Button from "../ui/Button";
import EmailValidationModal from "../../pages/EmailValidation/EmailValidationModal";
import {Colors} from "../../Colors";

const EmailValidationComponent = (props) => {
    const [email, setEmail] = useState("")
    const [emailSent, toggleEmailSent] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [error, toggleError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, toggleLoading] = useState(false)
    //const [isDuplicateEmail, setIsDuplicateEmail] = useState(false)

    // MODAL
    const [emailValidationModal, setEmailValidationModal] = useState(false);
    const [emailError, toggleEmailError] = useState(false)

    const profile = useSelector((state) => state?.profile);

    const dispatch = useDispatch()

    const {t} = useTranslation();

    useEffect(() => {
        if (profile?.email) {
            setEmail(profile?.email)
        }

        if (profile?.validPhone) {
            setPhoneNumber(profile?.phone)
        }

    }, [profile])

    const closeModal = () => {
        setEmailValidationModal(false);
    };

    const submitEmail = async () => {
        toggleLoading(true)
        await dispatch(updateEmail(email, updateEmailSuccess, updateEmailError))
    }

    const updateEmailSuccess = async () => {
        await sendCode()
    }

    const updateEmailError = (res) => {
        toggleEmailSent(false)
        setEmailValidationModal(false)
        toggleLoading(false)

        if (res?.status === 409)
            onError('emailValidation:error.duplicateEmail');
        else
            onError('emailValidation:error.default');
    }

    const sendCode = async () => {
        await sendValidationCode(sendValidationCodeSuccess, sendValidationCodeError)
    }

    const sendValidationCodeSuccess = () => {
        toggleEmailSent(true)
        setEmailValidationModal(true)
        toggleLoading(false)
    }


    const sendValidationCodeError = () => {
        toggleEmailSent(false)
        setEmailValidationModal(false)
        toggleLoading(false)
        // send duplicate email message or validation code error
        onError('emailValidation:error.default')
    }

    const savePhoneNumber = async (phone) => {
        await savePhone(phone, onPhoneSaveSuccess, onPhoneSaveError)
    }

    const onPhoneSaveSuccess = () => {
        dispatch(getProfile())
    }

    const onPhoneSaveError = () => {
        onError('emailValidation:error.phone')
    }

    const onError = (errorMessage) => {
        toggleError(true)

        if (errorMessage) {
            setErrorMessage(errorMessage)
        } else {
            setErrorMessage('emailValidation:error.default')
        }

        setTimeout(() => {
            toggleError(false)

            // Reset phone at input if any
            if (profile?.phone) setPhoneNumber(profile?.phone)
        }, 5000)
    }

    const editPhone = () => {
        let element = document.getElementById("phoneInput")
        if (element) element.focus()
    }

    const toggleInputError = (errorToggler, bool) => errorToggler(bool)

    const inputWrapperStyle = (error) => ({
        borderTop: error && `1px solid ${Colors.error}`,
        borderBottom: error && `1px solid ${Colors.error}`,
        backgroundColor: error ? Colors.error : Colors.lightGray
    })

    return (
        <>
            <div className="container d-lg-block mb-5">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <ComponentTitle i18nKey="emailValidation:contactInfo"/>
                    </div>
                </div>

                {error &&
                <div className="row">
                    <div className="col-12">
                        <InputNotificationPopup message={errorMessage}/>
                    </div>
                </div>
                }

                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="input-group">
                            <div className="input-group-prepend">
                            <span
                                className="input-group-text rounded-0 border-top-0 border-left-0"
                                style={inputWrapperStyle(emailError)}
                            >
                                <img
                                    src={iconCardEmail}
                                    alt={""}
                                    style={iconStyle}
                                />
                            </span>
                            </div>

                            <TextInput
                                placeholder={t(
                                    "emailValidation:input.contactInfoEmail"
                                )}
                                code="email"
                                type="email"
                                className="form-control border-top-0 rounded-0 border-right-0"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                toggleError={toggleInputError}
                                errorToggler={toggleEmailError}
                            />

                            <span>
                            {profile?.validEmail && profile?.email === email ? (
                                <img
                                    src={iconCheckBlue}
                                    className="creditCardPadding"
                                    style={{top: 14, ...inputIconStyle}}
                                    aria-hidden="true"
                                    alt=""
                                />
                            ) : (
                                <>{
                                    !profile.validEmail && email === "" ?
                                        <img
                                            src={iconEmailInvalid}
                                            className="creditCardPadding"
                                            aria-hidden="true"
                                            alt=""
                                            style={{top: 17, ...inputIconStyle}}
                                        />
                                        :

                                        loading ?
                                            <div className='spinner-border-loading' role="status"
                                                 style={{top: 14, ...loadingStyle}} aria-hidden="true">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            :
                                            <img
                                                src={iconEnter}
                                                className="creditCardPadding"
                                                aria-hidden="true"
                                                alt=""
                                                style={{top: 14, ...inputIconStyle, opacity: emailError ? 0.3 : 1}}
                                                onClick={() => !emailError && submitEmail()}
                                            />

                                }
                                </>
                            )}
                        </span>
                        </div>
                    </div>

                    {profile?.validEmail && profile?.email === email &&
                    <div className="col-12 col-md-6">
                        <div className="input-group /email-validation-phone-input">
                            <div className="input-group-prepend">
                            <span
                                className="input-group-text rounded-0 border-top-0 border-left-0"
                                style={grayBackground}
                            >
                                <img
                                    src={iconPhone}
                                    alt={""}
                                    style={iconStyle}
                                />
                            </span>
                            </div>
                            <TextInput
                                // id="phoneInput"
                                placeholder={t(
                                    "emailValidation:input.phoneNumber"
                                )}
                                code="phone"
                                type="number"
                                className="form-control border-top-0 rounded-0 border-right-0"
                                value={phoneNumber}
                                onChange={(e) =>
                                    setPhoneNumber(e.target.value)
                                }
                                style={{textTransform: "capitalize"}}
                            />
                            <span>
                            {profile?.validPhone && profile?.phone === phoneNumber ? (
                                <img
                                    src={iconEdit}
                                    className="creditCardPadding"
                                    style={{top: 14, ...inputIconStyle}}
                                    alt={t("emailValidation:accessibility.icon.edit")}
                                    onClick={() => editPhone()}
                                />
                            ) : phoneNumber.length === 10 ? (
                                    <img
                                        src={iconEnter}
                                        className="creditCardPadding"
                                        alt={t("emailValidation:accessibility.icon.save")}
                                        style={{top: 14, ...inputIconStyle}}
                                        onClick={() => savePhoneNumber(phoneNumber)}
                                    />
                                ) :
                                <img
                                    src={iconEnterDisabled}
                                    className="creditCardPadding"
                                    alt={t("emailValidation:accessibility.icon.disabled")}
                                    style={{top: 14, ...inputIconStyle}}
                                />
                            }
                        </span>
                        </div>
                    </div>
                    }

                    {
                        !profile?.validEmail && emailSent &&
                        <div className="col-12 col-md-6 d-flex justify-content-center align-items-end">
                            <Button className="btn btn-primary" disabled={false}
                                    onClick={() => setEmailValidationModal(true)}>
                                <img src={iconCode} alt="" aria-hidden="true" style={{width: 20, marginRight: 8}}/>
                                {t("emailValidation:modalButton")}
                            </Button>
                        </div>
                    }

                </div>

            </div>

            {emailValidationModal && (
                <EmailValidationModal toggle={closeModal} email={email} resendEmail={submitEmail}/>
            )}
        </>
    );
};

const grayBackground = {
    backgroundColor: Colors.lightGray,
};

const iconStyle = {
    width: '24px'
};

const inputIconStyle = {
    zIndex: 1000,
    position: "absolute",
    right: 0,
    cursor: "pointer",
}

const loadingStyle = {
    position: "absolute",
    right: 17,
    width: 24,
    height: 24,
    color: Colors.blue
}

export default EmailValidationComponent;
