import React from "react";

import Text from "../ui/Text";
import DeleteIcon from "../../assets/images/icon_Delete.svg";
import * as Utils from "../../core/Utils";
import { formatCurrencyLabel } from "../../core/Utils";
import { Colors } from "../../Colors";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import DateSquare from "../ui/DateSquare";
import { FontWeight } from "../ui/FontSize";
import PropTypes from "prop-types";

const PaymentFineListItemMobile = (props) => {

    const { autoexpreso, handleRemoveFine, renewal, fine } = props;
    const { id, date, city, amount, discount, type, surcharge } = props.fine;

    const { t } = useTranslation();
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "15px",
                borderBottom: `1px solid ${Colors.lightGray}`,
                fontSize: "16px",
            }}
            className="hoverable"
        >
            <div className="d-flex">
                <div
                    style={listItem}
                    role="text"
                    aria-label={`${t("fineList:fineListItem.arias.fineInWords", {
                        typeStr: t(type === "l" ? "license" : "vehicle"),
                        ...props.fine,
                        dateStr: Utils.format(date, "DD/MMMM/YYYY"),
                        amountStr: amount,
                    })}`}
                >
                    <div className="mr-3">
                        <DateSquare date={date} />
                    </div>
                </div>
                <div
                    style={{ listItem, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                    aria-hidden="true">
                    <Text className="text">
                        {_.capitalize(city)}
                    </Text>
                    {discount &&
                        <span className="badge badge-pill badge-primary mt-1"
                            style={{ backgroundColor: Colors.primary, height: 20 }}>
                            <Text className="text-sm white" i18nKey={"fineList:fineListItem.discount"} />&nbsp;
                            <Text className="text-sm white">{discount || ""}</Text>
                        </span>
                    }
                </div>
            </div>

            <div className="d-flex justify-content-end">
                <div
                    style={{
                        ...listItem,
                        display: "flex",
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-end'
                    }}
                    aria-hidden="true"
                >
                    {
                        !autoexpreso &&
                        <div className="d-flex" style={{ height: 20, fontWeight: FontWeight.medium }}>
                            <Text className="text-sm">{formatCurrencyLabel(amount)}</Text>&nbsp;
                            <Text className="text-sm">+</Text>&nbsp;
                            <Text className="text-sm" style={{ color: Colors.blue }}>{formatCurrencyLabel(surcharge)}</Text>
                        </div>
                    }

                    <Text
                        style={{
                            color: "green",
                            fontWeight: "bold",
                        }}
                        className="text"
                    >
                        {formatCurrencyLabel(amount)}
                    </Text>
                </div>

                {renewal ? null : (
                    <div
                        className="ml-3 d-flex justify-content-center"
                        role="button"
                        aria-label={`${t(
                            "fineList:fineListItem.arias.removeFine"
                        )}`}
                        style={{
                            justifyContent: "flex-end",
                        }}
                        onClick={() => handleRemoveFine(fine)}
                    >
                        <img
                            aria-hidden="true"
                            alt=""
                            src={DeleteIcon}
                            style={{ width: "18px" }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

PaymentFineListItemMobile.propTypes = {
    autoexpreso: PropTypes.bool,
    handleRemoveFine: PropTypes.func.isRequired,
    fine: PropTypes.object.isRequired,
    renewal: PropTypes.bool
};


const listItem = {
    padding: 0,
};

export default PaymentFineListItemMobile;
