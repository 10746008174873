import React from 'react'
import ComponentTitle from "../../components/ui/ComponentTitle"

const ServicesRow = ({ children }) => {

    if (!children) return null

    return (
        <div style={contentBlock}>
            <ComponentTitle
                i18nKey={"vehicleDetails:services"}
            />
            {children}
        </div>
    )
}

const contentBlock = {
    marginBottom: "25px"
};

export default ServicesRow 
