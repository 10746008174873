import React from "react";

import ArtOnlyPage from "../../components/widgets/ArtOnlyPage";

import NotFoundArt from "../../assets/images/page-not-found-art.svg";
import {useTranslation} from "react-i18next";

const NotFound = () => {
    const { t } = useTranslation();
    return (
        <ArtOnlyPage
            showAppStoreInfo={true}
            artSrc={NotFoundArt}
            alt={t("notFound:imgAlt")}
            text={t("notFound:text")}
        />
    );
};

export default NotFound;
