import React from "react";

import { useTranslation } from "react-i18next";

import WizardPayment from "../../../components/widgets/WizardPayment";
import MobileWizardPayment from "../../../components/widgets/MobileWizardPayment";

const RenewalWizard = ({ step }) => {
    const { t } = useTranslation();

    return (
        <>
            <div style={{ width: '100%'}}className="d-none d-lg-flex">
                <WizardPayment
                    step={step}
                    labels={[
                        t("renewal:wizard.stepOne"),
                        t("renewal:wizard.stepTwo"),
                        // t("renewal:wizard.stepThree"),
                        t("renewal:wizard.stepFour"),
                    ]}
                />
            </div>
            <div style={{ width: '100%'}} className="d-flex d-lg-none">
                <MobileWizardPayment
                    step={step}
                    labels={[
                        t("renewal:wizard.stepOne"),
                        t("renewal:wizard.stepTwo"),
                        // t("renewal:wizard.stepThree"),
                        t("renewal:wizard.stepFour"),
                    ]}
                />
            </div>
        </>
    );
};

export default RenewalWizard;
