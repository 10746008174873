import React from 'react'
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { Colors } from '../../../../Colors'
import { useTranslation } from 'react-i18next'

const SuccessDialog = (props) => {

    const { t } = useTranslation()

    return (
        <Modal
            isOpen={true}
            size="md"
            style={{ padding: "15px" }}
            toggle={() => props?.setModal(false)}
            role="dialog"
            aria-modal={true}
            autoFocus={true}
        >

            <ModalHeader
                style={{ backgroundColor: Colors.lightGray }}
                toggle={() => props?.setModal(false)}
            >
                <span className="text">{t("support:pin.success.title")}</span>
            </ModalHeader>

            <ModalBody
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    margin: "10px 0",
                }}
            >
                <span>{t("support:pin.success.text")}
                    <span style={{ fontWeight: 'bold', color: Colors.primary, marginLeft: 5 }}>{props?.ticketNumber}</span>
                </span>

            </ModalBody>
        </Modal>
    )
}

export default SuccessDialog