import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import Button from './Button'
import { Colors } from '../../Colors'

import xIcon from '../../assets/images/x-mark.svg'

const ErrorModal = (props) => {

    const { title, toggle, action, children, actionText, noPadding} = props

    return (
        <Modal
            isOpen={true}
            size="md"
            toggle={() => toggle && toggle(false)}
            role="dialog"
            aria-modal={true}
            autoFocus={true}
        >
            <div
                style={{ backgroundColor: Colors.bgGray, borderTopRightRadius: 'calc(.625rem - 1px)', borderTopLeftRadius: 'calc(.625rem - 1px)' }}
                className="w-100 d-flex justify-content-between p-3 border-bottom">
                <div className="d-flex justify-content-between align-items-center w-100">
                    <strong>{title || "Error"}</strong>
                </div>

                <button
                    onClick={() => toggle(false)}
                    className="d-flex align-items-center hoverable"
                    style={{ border: 0, backgroundColor: 'transparent' }}
                >
                    <img src={xIcon} alt="" aria-hidden style={{ height: 20, width: 20 }} />
                </button>
            </div>
            <ModalBody style={{ padding: noPadding && 0 }}>
                {children}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
                <Button
                    className="btn btn-cesco"
                    onClick={() => action()}
                    disabled={false}
                >
                    {actionText || "No Text"}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

ErrorModal.propTypes = {
    title: PropTypes.string,
    toggle: PropTypes.func,
    action: PropTypes.func,
    actionText: PropTypes.string,
    pillContent: PropTypes.string,
    children: PropTypes.element,
    noPadding: PropTypes.bool
}

export default ErrorModal