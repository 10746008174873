import React from "react";
import { changeMenuState } from "../redux/menu/menu.combine";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const BurgerMenu = () => {
    const menu = useSelector((state) => state.menu);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    return (
        <button
            id="headerToggler"
            aria-label={t("layout:header.arias.menu")}
            type="button"
            className="navbar-toggler d-block btn u-hamburger u-hamburger--white u-fullscreen--toggler hamburguer-icon"
            onClick={() => {
                dispatch(changeMenuState(!menu.open));
            }}
        >
            <span
                id="hamburgerTrigger"
                aria-hidden={true}
                className={`u-hamburger__box ${menu.open ? "is-active" : ""}`}
            >
                <span className="u-hamburger__inner" />
            </span>
        </button>
    );
};

export default BurgerMenu;
