import { api, handleResponse } from "../APICaller";

export function tagPreboardingSummary(onSuccess, onError) {

    // return {
    //     "summary": [
    //         {
    //             "vehicleId": "1234",
    //             "status": "registered",
    //             "tagNumber": "100012345678",
    //             "registrationDate": "2023-03-09"
    //         },
    //         {
    //             "vehicleId":"d162ebfb-1fe1-44ad-b779-fc7734c2d328",
    //             "status": "pending",
    //             "tagNumber": "100012345678",
    //             "registrationDate": Date.now()
    //         }
    //     ]
    // }

    return api
        .get(`/pre-boarding/summary`)
        .then((response) => handleResponse(response, onSuccess, onError));
}

export function tagPreboardingEligibility(vehicleId, onSuccess, onError) {

    if (!vehicleId) throw new Error('No vehicleId provided to tagPreboardingEligibility.')

    return api
        .get(`/pre-boarding/vehicle/${vehicleId}/eligibility`)
        .then((response) => handleResponse(response, onSuccess, onError));
}

export function tagPreboardingRegister(vehicleId, tagNumber, body, onSuccess, onError) {

    if (!vehicleId) throw new Error('No vehicleId provided to tagPreboardingRegister.')
    if (!tagNumber) throw new Error('No tagNumber provided to tagPreboardingRegister.')

    return api
        .post(`/pre-boarding/vehicle/${vehicleId}/register?validateSerial=true`, { tagNumber: tagNumber, ...body })
        .then((response) => handleResponse(response, onSuccess, onError));
}

export function tagPreboardingUnregister(vehicleId, onSuccess, onError) {

    if (!vehicleId) throw new Error('No vehicleId provided to tagPreboardingRegister.')

    return api
        .delete(`/pre-boarding/vehicle/${vehicleId}/unregister`)
        .then((response) => handleResponse(response, onSuccess, onError));
}
