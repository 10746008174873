import React from 'react';
import PropTypes from 'prop-types';
import {formatCurrencyLabel} from "../../core/Utils";
import {useTranslation} from "react-i18next";
import {Colors} from "../../Colors";
import cancelIcon from '../../assets/images/icon_Cancelar_BtMultas_Strokes.svg'
import cancelIconGray from '../../assets/images/icon_Cancelar_BtMultas_Gray.svg'

const PayWithAmountButton = props => {
    let {userFines, disabled, deselectAll, label, className, handlePayFines, payDisabled} = props;
    let {t} = useTranslation();

    if (!userFines) return null

    return (
        <div className="btn-group" role="group">
            <button
                type='button'
                role="button"
                onClick={() => !disabled ? deselectAll() : () => {
                }}
                disabled={
                    userFines?.cart?.fines?.length === 0
                }
                aria-disabled={disabled}
                style={{
                    height: "45px",
                    borderRadius: "4px",
                    minHeight: '2rem',
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0,
                    backgroundColor: Colors.darkGreen,
                }}
                className={`btn btn-sm btn-primary-dark ${className}`}
                aria-label={label}
            >
                <img src={userFines?.cart?.fines?.length === 0 ? cancelIconGray : cancelIcon} aria-hidden="true" alt=""/>
            </button>
            <button
                type='button'
                role="button"
                onClick={() => !disabled ? handlePayFines() : () => {
                }}
                disabled={ userFines?.cart?.fines?.length === 0 || disabled || payDisabled}
                aria-disabled={disabled}
                style={{
                    height: "45px",
                    borderRadius: "4px",
                    minWidth: '3rem',
                    minHeight: '2rem',
                    width: 170,
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0,
                }}
                className={`btn btn-sm btn-primary ${className}`}
                aria-label={label}
            >
                {userFines.cart.sumAmount > 0 && (
                    <>
                    <span style={{fontSize: 18}}>
                        {formatCurrencyLabel(
                            userFines.cart.sumAmount
                        )}
                    </span>
                        <br/>
                    </>
                )}
                <span
                    style={{
                        fontSize:
                            userFines.cart.sumAmount > 0
                                ? 12
                                : 15
                    }}
                >
                {t("main:finesSection.payButton")}
            </span>
            </button>
        </div>
    );
};

PayWithAmountButton.propTypes = {
    handlePayFines: PropTypes.func,
    userFines: PropTypes.object

};

export default PayWithAmountButton;