import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Col } from "reactstrap";

import Text from "../../components/ui/Text";
import Button from "./Button";

import icon from "../../assets/images/icon_TarjetaBack.svg";
import { Colors } from "../../Colors";

const InfoModal = props => {
    const { toggle } = props;

    return (
        <Modal isOpen={true} size="sm" toggle={toggle}>
            <ModalHeader
                toggle={toggle}
                style={{ color: Colors.gray, backgroundColor: Colors.bgGray }}
            >
                <Text i18nKey="infoModal:header" className="text" />
            </ModalHeader>

            <ModalBody
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <img
                    src={icon}
                    alt=""
                    aria-hidden="true"
                    style={{ width: "100%", marginBottom: "15px" }}
                />
                <Text i18nKey="infoModal:body" />
            </ModalBody>
        </Modal>
    );
};

export default InfoModal;
