import React from 'react'
import Lottie from "react-lottie"
import successAnimation from '../checkmark-blue-animation.json'
import errorAnimation from '../error-animation.json'
import pendingAnimation from './Pending.json'
import PropTypes from 'prop-types'

import artCompleted from '../../images/electronic-tag/Completed.svg'
import artNoAutoexpreso from '../../images/electronic-tag/Art_MarbeteElectronico_Completed_NoAutoexpreso.svg'

const lottieSuccess = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
}

const lottieError = {
    loop: false,
    autoplay: true,
    animationData: errorAnimation,
}

const lottiePending = {
    loop: false,
    autoplay: true,
    animationData: pendingAnimation,
}


const PreboardingResultAnimation = (props) => {

    const { error, pending, noAutoexpresoIcon } = props

    const renderOption = () => {
        if (error) return lottieError
        if (pending) return lottiePending

        return lottieSuccess
    }

    if (noAutoexpresoIcon) {
        return (
            <div style={{ width: 250 }}>
                <div style={{ position: 'relative' }}>
                    <img src={artNoAutoexpreso} alt="" />
                    <div style={{ position: 'absolute', right: 60, bottom: -4 }}>
                        <Lottie
                            options={renderOption()}
                            height={60}
                            width={60}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{ width: 250 }}>
            <div style={{ position: 'relative' }}>
                <img src={artCompleted} alt="" />
                <div style={{ position: 'absolute', right: 18, bottom: 0 }}>
                    <Lottie
                        options={renderOption()}
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        </div>
    )
}

PreboardingResultAnimation.propTypes = {
    error: PropTypes.bool
}

export default PreboardingResultAnimation