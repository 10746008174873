import {api, handleResponse} from "../APICaller";

export async function fetchEntity(onSuccess, onError) {
    return handleResponse(await api
        .get('/entity'), onSuccess, onError)
    // .then((response) => handleResponse(response, onSuccess, onError));

}

export function fetchFines() {
    return api
        .get('/entity/fines')
    /*.then((response) => handleResponse(response));*/
}

export function fetchVehicleInfo(vehicleId, onSuccess, onError) {
    return api
        .get(`/entity/vehicle/${vehicleId}`)
        .then((response) => handleResponse(response, onSuccess, onError));
}

export async function fetchImage() {
    return fetchImageAsBytes();
}

async function fetchImageAsBytes() {
    let response = await api.get(`/entity/image`, {}, {responseType: 'arraybuffer'});
    return response.ok ? response.data : undefined;

}

async function fetchImageAsBase64() {
    let payload = handleResponse(await api.get(`/profile`));
    return payload.entityImage;
}

export function fetchCardInfo(onSuccess, onError) {
    return api
        .get(`/entity/card`)
    // .then((response) => handleResponse(response, onSuccess, onError));
}

export const saveEmail = async email => {
    return handleResponse(await api.post("/profile/update", {email: email}), null, () => {
    });
};

/*export const savePhone = async (phone, success, fail) => {
    return handleResponse(await api.post("/profile/edit/phone", {phone: code}));
};*/
