import React from 'react'
import { Colors } from '../../../../Colors'
import { useTranslation } from 'react-i18next'

const ErrorDialog = () => {

    const { t } = useTranslation()
    
    return (
        <div className="container">
            <div style={{ backgroundColor: Colors.error, color: 'white' }} className="w-100 p-3 rounded mb-3 col-12">
                <span>{t("support:pin.error")}</span>
            </div>
        </div>

    )

}

export default ErrorDialog