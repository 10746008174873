import {useDispatch, useSelector} from "react-redux";
import {Redirect, Route} from "react-router-dom";
import {resetState} from "../redux/app/app.combine";
import Layout from "../layout/Layout";
import React, {useEffect} from "react";
import _ from "lodash";
import {getAuthEntity} from "../redux/login/login.combine";

const FilterRoute = props => {
    let {children, ...rest} = props;
    let dispatch = useDispatch();
    const config = useSelector(state => state.config);
    const maintenance = useSelector((state) => state.features?.config?.web?.maintenance);
    const flow = useSelector(state => state.flow);
    const {authenticated} = useSelector(state => state.login);
    const fines = useSelector(state => state.fines);
    const user = useSelector(state => state?.login?.user);
    const acceptPayment = useSelector(s => s?.features?.payments?.enabled)

    const handlePrivateRouteRefresh = (isPrivate) => {

        if ((!user || _.isEmpty(user)) && isPrivate) {
            dispatch(getAuthEntity(props.pathname))
        }
    }

    return (
        <Route
            {...rest}
            render={({location}) => {
                let redirectPath = null;
                let {pathname} = location;
                if (rest.private && (!config || authenticated === undefined)) {
                    redirectPath = "loading";
                } else if (maintenance) {
                    redirectPath = "maintenance";
                } else if (!authenticated && rest.private) {
                    redirectPath = "login";
                }

                handlePrivateRouteRefresh(rest.private)

                let hasEmptyFines = (!fines || fines.workingFines?.length <= 0);
                let isPaymentDisabled = !acceptPayment;
                let isPaymentRouteInvalid = (hasEmptyFines || isPaymentDisabled) && pathname.includes("/pay/") && !flow;
                let isRenewalRouteInvalid = (!flow && location && pathname.startsWith('/renewal'));
                if (rest.private && (isPaymentRouteInvalid || isRenewalRouteInvalid)) {
                    redirectPath = "landing";
                }
                if (pathname === "login") {
                    dispatch(resetState());
                }

                if (redirectPath) {
                    return (
                        <Redirect
                            to={{
                                pathname: `/${redirectPath}`,
                                state: {from: location}
                            }}
                        />
                    );
                } else
                    return rest.useLayout ? (
                        <Layout subHeader={rest.useSubHeader}>
                            {children}
                        </Layout>
                    ) : (
                        children
                    );
            }}
        />
    );
};
FilterRoute.defaultProps = {
    useLayout: true,
    useSubHeader: true,
    private: true
};

export default FilterRoute;
