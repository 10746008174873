import React, { useEffect, useMemo, useState } from "react";
import * as Api from "../../core/APICaller";

import VehicleList from "../../components/widgets/VehicleList";
import FineList from "../../components/widgets/FineList";

import Text from "../../components/ui/Text";
import PaymentHistoryList from "../PaymentHistory/PaymentHistoryList";
import { useDispatch, useSelector } from "react-redux";
import SubHeader from "../../layout/SubHeader";
import SubHeaderContainer from "../../layout/SubHeader/SubHeaderContainer";
import { Colors } from "../../Colors";
import { emptyObjArray } from "../../core/Utils";
import ComponentTitle from "../../components/ui/ComponentTitle";
import PointScore from "./components/PointScore";
import { Link, useHistory } from "react-router-dom";
import Checkbox from "../../components/ui/Checkbox";
import FineScore from "./components/FineScore";
import { fetchAllFines, filterLicense, filterVehicle, fineSelectAll, resetCart, } from "../../redux/fines/actions";
import { fetchCard } from "../../redux/entity/actions";
import FineBigNumber from "./components/FineBigNumber";
import { useTranslation } from "react-i18next";
import MobileLicenseView from "./components/MobileLicenseView";
import _ from "lodash";
import PayWithAmountButton from "../../components/ui/PayWithAmountButton";
import AlertGroup from "../../components/widgets/alerts/AlertGroup";
import { fetchRenewalEligibility } from "../../redux/renewal/actions";
import FineTotalBadge from "../../components/ui/FineTotalBadge";
import LicenseCertifications from "../../components/widgets/certifications/LicenseCertifications";
import CourseChart from "./components/CourseChart";
import TabNavigation from "../../components/widgets/TabNavigation";
import AutoExpresoFines from "../../components/ui/AutoExpresoFines";
import DonorDashboardCard from "../../components/widgets/OrganDonor/DonorDashboardCard";
import OrganDonorModal from "../../components/widgets/OrganDonor/OrganDonorModal";
import VehicleSelectionModal from "../../components/widgets/VehicleSelectionModal";
import { preBoardingMock } from '../../core/api-resources/tag';
import { getTagPreboardingData } from "../../redux/electronicTag/electronicTag.combine";
import MaintenanceModal from "./components/MaintenanceModal";
import moment from "moment";

const MainPage = (props) => {
    let dispatch = useDispatch();
    let user = useSelector((state) => state.login?.user);
    let userFines = useSelector((state) => state.fines);
    let card = useSelector((state) => state.card);
    let history = useHistory();
    let [loading, setLoading] = useState(true);
    let [activeTab, setActiveTab] = useState(0);
    let [payments, setPayments] = useState(emptyObjArray(5));
    let [error, setError] = useState(emptyObjArray(5));
    let [unpaidTolls, setUnpaidTolls] = useState(false);

    const [maintenanceModal, setMaintenanceModal] = useState(false)

    const licenseCategory = user?.license?.category

    const disableStatistics = useSelector(s => s?.features?.preBoarding?.disableStatistics);

    const donorFeature = useSelector(s => s?.features?.organDonors?.enabled);

    const preboardingEnabled = useSelector(s => s?.features?.preBoarding?.enabled);
    const highlightsEnabled = useSelector(s => s?.features?.preBoarding?.highlightsEnabled);
    const preBoardingRegistrationEnabled = useSelector(s => s?.features?.preBoarding?.highlightsEnabled);

    const maintenance = useSelector(s => s?.features?.maintenance)
    const showMaintenanceModal = useSelector(s => s?.general?.showMaintenanceModal)

    const { t } = useTranslation();

    useEffect(() => {
        // Clear cart on landing load
        dispatch(resetCart());
    }, [])

    useEffect(() => {
        if (disableStatistics === true || disableStatistics === undefined)
            return

        fetchFines();
        if (preboardingEnabled) {
            dispatch(getTagPreboardingData())
        }
    }, [disableStatistics]);

    useEffect(() => {

        if (disableStatistics) {
            setActiveTab(2)
        }
    }, [disableStatistics])

    const fetchFines = () => {
        setLoading(true);
        setError(false);
        dispatch(fetchCard(null, false));
        dispatch(
            fetchAllFines(
                () => setLoading(false),
                () => setError(true)
            )
        );
    };

    const changeTab = (tab) => {
        setActiveTab(tab);
        tab === 0 ? console.log("CASE 0 ") : fetchPaymentHistory();
    };

    const handlePayFines = () => {
        if (!loading) {
            history.push("/pay/fines");
        }
    };

    const fetchPaymentHistory = async () => {
        setLoading(true);
        Api.fetchPaymentHistory(onPaymentHistorySuccess, onPaymentHistoryError);
    };

    const onPaymentHistorySuccess = (data) => {
        const payments = data.receipts;
        setPayments(payments);
        setLoading(false);
    };

    const onPaymentHistoryError = () => {
        setError(true);
    };

    const selectAllClick = (checked) => {
        dispatch(fineSelectAll(checked));
    };

    const deselectAll = () => {
        dispatch(fineSelectAll());
    };

    const renewalCandidate = () => {
        dispatch(
            fetchRenewalEligibility((eligibility) => {
                if (eligibility.eligible) {
                    let agreement = eligibility.userAgreements[0];
                    if (agreement.accepted)
                        history.push("/renewal/missing-docs");
                    else history.push("/renewal/disclosure");
                } else history.push("/renewal/not-eligible");
            })
        );
    };

    const renderPointScore = (userType) => {
        switch (userType) {
            case "LICENSE":
            case "LEY97":
                return (
                    <SubHeaderContainer>
                        <PointScore />
                    </SubHeaderContainer>
                );
            default:
                break;
        }
    };

    const chooseTransaction = (transactionId) => {
        if (transactionId) history.push(`/payment-history?id=${transactionId}`)
    }

    const renderFineScore = (userType, allFines) => {
        switch (userType) {
            case "LICENSE":
            case "LEY97":
            case "ID":
                return (
                    <SubHeaderContainer>
                        <FineScore fines={allFines} />
                    </SubHeaderContainer>
                );
            default:
                break;
        }
    };

    const autoexpresoBanner = useMemo(() => {

        if (!unpaidTolls || unpaidTolls === 0) return;
        return <AutoExpresoFines />

    }, [unpaidTolls])

    const renderActiveTab = (step) => {
        switch (step) {
            case 0:
                return (
                    <>
                        <div
                            style={{
                                height: "60px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "0 17px",
                                fontSize: "16px",
                                color: Colors.darkGray,
                            }}
                        >
                            <Checkbox
                                ariaLabel={t(
                                    "main:finesSection.arias.selectionAll"
                                )}
                                label="main:finesSection.selectionAll"
                                checked={userFines?.cart.all}
                                disabled={userFines?.workingFines.length === 0}
                                handleCheckbox={selectAllClick}
                            />
                            <div style={{ display: "flex" }}>
                                <Checkbox
                                    checked={
                                        userFines?.filter.license
                                    }
                                    disabled={userFines?.workingFines.length === 0}
                                    ariaLabel={t(
                                        "main:finesSection.arias.selectionLicense"
                                    )}
                                    label="main:finesSection.selectionLicense"
                                    handleCheckbox={(checked) => dispatch(filterLicense(checked))}
                                    style={{ marginRight: "25px" }}
                                />
                                <Checkbox
                                    ariaLabel={t(
                                        "main:finesSection.arias.selectionVehicle"
                                    )}
                                    label="main:finesSection.selectionVehicle"
                                    checked={
                                        userFines?.filter.vehicle
                                    }
                                    disabled={userFines?.workingFines.length === 0}
                                    handleCheckbox={(checked) => dispatch(filterVehicle(checked))}
                                />
                            </div>
                        </div>
                        {autoexpresoBanner}
                        <FineList
                            fines={userFines?.workingFines}
                            loading={loading}
                            error={error}
                            fetchFines={fetchFines}
                            style={{ padding: "17px" }}
                        />
                    </>

                )
            case 1:
                return (
                    <PaymentHistoryList
                        condense={true}
                        receipts={payments}
                        loading={loading}
                        small={true}
                        chooseTransaction={chooseTransaction}
                    />
                )
            case 2:
                return (
                    <>
                        {
                            !disableStatistics &&
                            <ComponentTitle
                                i18nKey={"certifications:licenseTitle"}
                                style={{ marginTop: "20px" }}
                                sm
                            />
                        }
                        <LicenseCertifications
                            vehicleSelection={disableStatistics}
                        />
                    </>
                )
        }
    }

    const renderCourseChart = () => (
        <SubHeaderContainer clickable>
            <CourseChart />
        </SubHeaderContainer>
    )

    const ActionButton = () => (
        <div
            className={`ml-auto p-2`}
            style={{ display: activeTab === 0 ? 'inherit' : 'none' }}
        >
            <PayWithAmountButton
                handlePayFines={handlePayFines}
                userFines={userFines}
                deselectAll={deselectAll}
            />
        </div>
    )

    const tabNavigationItems = [
        {
            i18nKey: 'main:fines'
        },
        {
            i18nKey: 'main:lastPayments'
        },
        {
            i18nKey: 'main:certifications'
        },
    ]

    // TODO should come from backend / Mock alert
    const preboardingAlert = [{
        action: "nav",
        caption: t("preboarding:alert.text"),
        criteriaId: null,
        done: null,
        dueDate: 1618459200000,
        format: "TEXT",
        notificationDate: 1678372884062,
        notificationGuid: null,
        path: "electronicTagPreboarding",
        reference: "preboarding",
        reqAuth: true,
        seen: true,
        seenDate: 1678372884062,
        severity: "alert",
        title: t("preboarding:alert.title"),
        type: "alert"
    }]

    const RenderSubHeader = () => {
        if (disableStatistics) {
            return null
        }

        return (
            <SubHeader
                className="p-0 pl-0 pb-3"
                withControls={licenseCategory == 1}
                landing={true}
                overflow
            >
                <DonorCard donorFeature={(donorFeature)} />
                {licenseCategory == 1 && renderCourseChart()}
                {renderPointScore(user?.license?.type)}
                {renderFineScore(user?.license?.type, userFines?.allFines)}
                <SubHeaderContainer>
                    <FineBigNumber fines={userFines?.allFines} />
                </SubHeaderContainer>
            </SubHeader>
        )
    }

    useEffect(() => {
        const startDate = maintenance?.date?.start
        const duration = maintenance?.date?.duration
        const endDate = moment.unix(startDate).add(duration, 'hours').unix()
        const currentTime = moment().unix();

        const startDateLessThanDuration = currentTime < endDate;

        if (maintenance?.maintenance_passcode_drawer_show && startDateLessThanDuration && showMaintenanceModal) {
            setMaintenanceModal(true)
        }
    }, [maintenance, showMaintenanceModal])

    const RenderMaintenanceModal = () => {

        if (!maintenanceModal) return

        return (
            <MaintenanceModal
                toggle={setMaintenanceModal}
            />
        )
    }

    return (
        <>
            {RenderSubHeader()}

            {RenderMaintenanceModal()}

            {!_.isEmpty(user) && !_.isEmpty(user.alerts) && (
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Link
                                to={"/messages"}
                                role="button"
                                aria-label={t("main:alertTitle")}
                            >
                                <ComponentTitle i18nKey="main:alertTitle" noBorder>
                                    <div
                                        className="d-md-none"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginRight: "15px",
                                        }}
                                    >
                                        <FineTotalBadge amount={card?.license?.unpaidFines} red />
                                        <div className={"icon-arrow"} />
                                    </div>
                                </ComponentTitle>
                            </Link>

                            {
                                preboardingEnabled && highlightsEnabled && preBoardingRegistrationEnabled &&
                                <AlertGroup alerts={preboardingAlert} colorFill />
                            }
                            <AlertGroup alerts={user?.alerts} />
                        </div>
                    </div>
                </div>
            )}

            <div className="container">
                <div className="row">
                    <div className="d-none d-lg-block col-lg-7 col-12">
                        <div>
                            <ComponentTitle i18nKey={"main:fines"} />
                            <TabNavigation
                                activeTab={activeTab}
                                changeTab={changeTab}
                                actionButton={ActionButton}
                                tabNavigationItems={tabNavigationItems}
                                finesDisabled={disableStatistics}
                            />
                        </div>
                        {renderActiveTab(activeTab)}
                    </div>

                    {/*mobile view for card display on mobile*/}
                    <div className={"col-12 d-md-none mb-3"} onClick={() => history.push('/certifications')}>
                        <ComponentTitle i18nKey={"main:certifications"}>
                            <div className="icon-arrow" style={{ marginRight: 15 }} />
                        </ComponentTitle>
                    </div>

                    <MobileLicenseView card={card} />
                    <div className={"col-lg-5 col-12"}>
                        <ComponentTitle i18nKey={"main:vehicles"} />
                        <div
                            style={{
                                height: "62px",
                                alignItems: "center",
                                borderBottom: `1px solid ${Colors.borderGray}`,
                            }}
                            className="d-none d-lg-flex"
                        >
                            <div className="p-mob-only">
                                <Text
                                    i18nKey={"main:list"}
                                    style={{ color: Colors.gray }}
                                />
                            </div>
                        </div>
                        <VehicleList
                            history={history}
                            vehicles={(user && user.vehicles) || []}
                        />
                    </div>
                </div>
            </div>

        </>
    );
};

const DonorCard = (props) => {

    const { donorFeature } = props

    const isDonor = useSelector(s => s?.card?.personalInfo?.donor)

    const [modal, setModal] = useState(false)

    if (!donorFeature) return null;

    if (isDonor === undefined || isDonor === true) return null;

    return (
        <>
            {
                modal &&
                <OrganDonorModal
                    toggle={setModal}
                />
            }
            <SubHeaderContainer clickable action={() => setModal(!modal)}>
                <DonorDashboardCard />
            </SubHeaderContainer>
        </>
    )

}

MainPage.propTypes = {};

export default MainPage;
