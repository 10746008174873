import React from "react";

import { Colors } from "../../Colors";
import "../../styles/header-svg.css";
import { bool } from "prop-types";

const SubHeader = (props) => {

    const scrollLeft = () => {
        const container = document.getElementById('charts-container')
        container.scrollLeft -= 150
    }

    const scrollRight = () => {
        const container = document.getElementById('charts-container')
        container.scrollLeft += 150
    }

    const { withControls, landing, overflow } = props

    return (
        <div
            style={{ ...subHeaderStyle, marginBottom: props.noMargin ? 0 : '25px' }}
            role={"region"}
            aria-roledescription={"metricas de estatus de perfil en sistema"}
            className={`subHeader ${props.className}`}
        >
            <div className="container p-0 px-lg-3" style={subHeaderWrapperStyle(landing && withControls)}>
                {
                    withControls && <div
                        className={`d-none ${withControls && 'd-lg-flex'}`} style={controlWrapper}>
                        <button
                            style={buttonStyle}
                            onClick={() => scrollLeft()}
                            className="dashboard-caroussel-control"
                        >
                            <div className="caret-left-white" />
                        </button>
                    </div>
                }
                <div
                    id={`${landing ? 'charts-container' : ''}`}
                    style={{
                        overflow: overflow ? 'auto' : 'none',
                        scrollbarColor: 'transparent transparent',
                    }}
                    className={`d-flex justify-content-between ${landing && 'p-0 p-lg-3 m-0 mx-lg-3'}`}
                >
                    {props.children}
                </div>
                {withControls && <div
                    className={`d-none ${withControls && 'd-lg-flex'}`} style={controlWrapper}>
                    <button
                        style={buttonStyle}
                        onClick={() => scrollRight()}
                        className="dashboard-caroussel-control"
                    >
                        <div className="caret-right-white" />
                    </button>
                </div>}
            </div>
        </div>
    );
};

SubHeader.propTypes = {
    landing: bool,
};

SubHeader.defaultProps = {
    landing: false,
};

const buttonStyle = {
    height: 20,
    width: 20,
    border: 'none',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

const controlWrapper = {
    // justifyContent: 'space-between',
    // position: 'absolute',
    zIndex: 1
}

const subHeaderWrapperStyle = landing => {
    if (landing)
        return {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    else
        return {
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center'
        }
}

const subHeaderStyle = {
    width: "100%",
    overflowX: "none",
    background: Colors.lightGray,
    marginBottom: "25px",
    display: "flex",
    alignItems: "flex-start",
    marginLeft: 0,
    marginRight: 0,
    padding: '15px',
    paddingBottom: "20px",
};

export default SubHeader;
