import React, {useEffect, useState} from "react";

import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import Text from "../../../components/ui/Text";
import PageTitle from "../../../components/ui/PageTitle";
import SubHeader from "../../../layout/SubHeader";
import WizardFooter from "../../../components/widgets/WizardFooter";
import {Colors} from "../../../Colors";

import Antilles from "../../../assets/images/insurance/Antilles.svg";
import Antilles_c from "../../../assets/images/insurance/Antilles_Color.svg";
import Asc from "../../../assets/images/insurance/ASC.svg";
import Asc_c from "../../../assets/images/insurance/ASC_Color.svg";
import Guardian from "../../../assets/images/insurance/Guardian.svg";
import Guardian_c from "../../../assets/images/insurance/Guardian_Color.svg";
import Integrand from "../../../assets/images/insurance/Integrand.svg";
import Integrand_c from "../../../assets/images/insurance/Integrand_Color.svg";
import MapfrePan from "../../../assets/images/insurance/MapfrePan.svg";
import MapfrePan_c from "../../../assets/images/insurance/MapfrePan_Color.svg";
import MapfrePraico from "../../../assets/images/insurance/MapfrePraico.svg";
import MapfrePraico_c from "../../../assets/images/insurance/MapfrePraico_Color.svg";
import Multinational from "../../../assets/images/insurance/Multinational.svg";
import Multinational_c from "../../../assets/images/insurance/Multinational_Color.svg";
import PointGuard from "../../../assets/images/insurance/PointGuard.svg";
import PointGuard_c from "../../../assets/images/insurance/PointGuard_Color.svg";
import SegurosMultiples from "../../../assets/images/insurance/SegurosMultiples.svg";
import SegurosMultiples_c from "../../../assets/images/insurance/SegurosMultiples_Color.svg";
import TripleS from "../../../assets/images/insurance/TripleS.svg";
import TripleS_c from "../../../assets/images/insurance/TripleS_Color.svg";
import Universal from "../../../assets/images/insurance/Universal.svg";
import Universal_c from "../../../assets/images/insurance/Universal_Color.svg";
import Qbe from "../../../assets/images/insurance/QBE.svg";
import Qbe_c from "../../../assets/images/insurance/QBE_Color.svg";

import {setInsuranceCompany} from "../../../redux/vehicleRenewal/vehicleRenewal.combine";

import "./insurance.css";
import VehicleDetails from "../../VehicleDetails";
import {FontWeight} from "../../../components/ui/FontSize";
import Card from "../../../components/ui/Card";
import * as Utils from "../../../core/Utils";

const data = [
    {
        id: 1,
        alt: "Antilles",
        url: Antilles,
        url_c: Antilles_c,
        code: "AIC",
        description: "Antilles Insurance Company",
        naiccode: 10308,
    },
    {
        id: 2,
        alt: "Asc",
        url: Asc,
        url_c: Asc_c,
        code: "ASC",
        description: "Asociación de Suscripción Conjunta",
        naiccode: 10899,
    },
    {
        id: 3,
        alt: "Guardian",
        url: Guardian,
        url_c: Guardian_c,
        code: "GUC",
        description: "Guardian Insurance Company, Inc.",
        naiccode: 17779,
    },
    // {
    //     id: 4,
    //     alt: "Integrand",
    //     url: Integrand,
    //     url_c: Integrand_c,
    //     code: "IAC",
    //     description: "INTEGRAND Assurance Company",
    //     naiccode: 26778,
    // },
    // {
    //     id: 5,
    //     alt: "Mapfre Pan",
    //     url: MapfrePan,
    //     url_c: MapfrePan_c,
    // },
    {
        id: 6,
        alt: "Mapfre Praico",
        url: MapfrePraico,
        url_c: MapfrePraico_c,
        code: "MPIC",
        description: "MAPFRE PRAICO Insurance Company",
        naiccode: 43052,
    },
    {
        id: 7,
        alt: "Multinational",
        url: Multinational,
        url_c: Multinational_c,
        code: "MIC",
        description: "Multinational Insurance Company",
        naiccode: 14153,
    },
    {
        id: 8,
        alt: "PointGuard",
        url: PointGuard,
        url_c: PointGuard_c,
        code: "PGIC",
        description: "Point Guard Insurance Company, Inc.",
        naiccode: 15324,
    },
    {
        id: 9,
        alt: "Seguros Multiples",
        url: SegurosMultiples,
        url_c: SegurosMultiples_c,
        code: "CSM",
        description: "Cooperativa de Seguros Múltiples de PR",
        naiccode: 18163,
    },
    {
        id: 10,
        alt: "Triple S",
        url: TripleS,
        url_c: TripleS_c,
        code: "TSP",
        description: "Triple-S Propiedad, Inc.",
        naiccode: 40568,
    },
    {
        id: 11,
        alt: "Universal",
        url: Universal,
        url_c: Universal_c,
        code: "UIC",
        description: "Universal Insurance Company",
        naiccode: 31704,
    },
    // {
    //     id: 12,
    //     alt: "QBE",
    //     url: Qbe,
    //     url_c: Qbe_c,
    //     code: "UIC",
    //     description: "QBE Optima Insurance Company",
    //     naiccode: 31704,
    // },
];

const Insurance = () => {
    let dispatch = useDispatch();
    let vehicleRenewal = useSelector((state) => state.vehicleRenewal);
    let vehicle = vehicleRenewal?.vehicle;
    const [insurance, chooseInsurance] = useState("");
    const [privateInsurance, setPrivateInsurance] = useState(true);

    const history = useHistory();

    const changeInsurance = (i) => {
        if (insurance == i) {
            chooseInsurance("");
        } else {
            chooseInsurance(i);
        }
    };

    const onNext = () => {
        let {code, naiccode} = data[insurance];
        dispatch(
            setInsuranceCompany({code, naiccode}, () => {
                history.push("/pay/fines");
            })
        );
    };

    useEffect(() => {
        Utils.scrollToDiv('insuranceInfo', true, true);
    }, []);

    return (
        <>
            <SubHeader>
                <div className="container">
                    <PageTitle i18nKey="insurance:pageTitle"/>
                    <div className="d-none d-lg-flex">
                        <hr/>
                    </div>
                    <Text
                        i18nKey="insurance:subTitle"
                        className="text"
                        style={{color: Colors.gray}}
                    />
                </div>
            </SubHeader>

            <div
                id="insuranceInfo"
                className="d-flex justify-content-center mb-4"
                style={{flexDirection: "column", alignItems: "center"}}
            >
                {vehicle && (
                    <>
                        <Text
                            className="text-md"
                            style={{fontWeight: FontWeight.bold}}
                        >{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</Text>
                        <Text
                            className="text-md"
                            style={{
                                fontWeight: FontWeight.bold,
                                color: Colors.gray,
                            }}
                        >
                            ({vehicle.plate})
                        </Text>
                    </>
                )}
            </div>

            {privateInsurance && (
                <div className="container" style={{marginBottom: '20px', padding: 0}}>
                    <Card
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div aria-hidden={true} className="caret-left" style={{marginRight: '10px'}}/>
                        <Text i18nKey="insurance:back"/>
                    </Card>
                </div>
            )}

            <div className="container mb-4" style={{padding: 0}}>
                <div className="d-flex flex-wrap insurance">
                    {data.map((i) => (
                        <span
                            className="col-6 col-md-3 insuranceButton hoverable"
                            style={{
                                padding: 0,
                                backgroundColor:
                                    insurance === i.id
                                        ? Colors.lightGray
                                        : null,
                            }}
                        >
                            <button
                                className="insurance-button"
                                style={insuranceBoxStyle}
                                onClick={() => changeInsurance(i.id)}
                            >
                                <img
                                    src={insurance === i.id ? i.url_c : i.url}
                                    alt={i.alt}
                                    className="insurance-img"
                                />
                            </button>
                        </span>
                    ))}
                </div>
            </div>

            <div className="container">
                <WizardFooter
                    disabled={insurance === ""}
                    handleNext={onNext}
                    hideBack
                />
            </div>
        </>
    );
};

const insuranceBoxStyle = {
    width: "100%",
    backgroundColor: "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
};

export default Insurance;
