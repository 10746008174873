import React from "react";

import ArtOnlyPage from "../../components/widgets/ArtOnlyPage";

import GeneralErrorArt from "../../assets/images/page-general-error-art.svg";
import {useTranslation} from "react-i18next";

const GeneralError = () => {
    const { t } = useTranslation();
    return (
        <ArtOnlyPage
            showAppStoreInfo = {true}
            artSrc={GeneralErrorArt}
            alt={t("generalError:imageAlt")}
            text={t("generalError:text")}
        />
    );
};

export default GeneralError;
