import React from "react";

import Button from "../../components/ui/Button";
import Text from "../../components/ui/Text";
import { FontSize } from "../../components/ui/FontSize";

import cescoArt from "../../assets/images/cesco-art.svg";
import { Colors } from "../../Colors";
import ComponentTitle from "../../components/ui/ComponentTitle";
import { useTranslation } from "react-i18next";

const CescoDetails = (props) => {
    const { t } = useTranslation();
    const { address, hours, geoLocation } = props.selectedCesco;
    const { line1, line2, line3, city, state, zipCode } = address;

    return (
        <>
            <div
                style={CescoDetailsStyle}
                className="row"
                role="text"
                aria-label={`${line1} ${line2} ${city} ${state} ${zipCode}`}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        wordBreak: "break-word",
                    }}
                >
                    <address style={grayText}>
                        <Text style={grayText} className="text">
                            {line1}
                        </Text>
                        <br />
                        <Text style={grayText} className="text">
                            {line2}
                        </Text>
                        <br />
                        {/*{address.line3 && (<Text style={grayText}>{address.line3}</Text><br/>)}*/}
                        <Text style={grayText} className="text">
                            {city}
                        </Text>
                        ,&nbsp;
                        <Text style={grayText} className="text">
                            {state}
                        </Text>
                        &nbsp;
                        <Text style={grayText} className="text">
                            {zipCode}
                        </Text>
                    </address>

                    <Text
                        style={{
                            marginTop: "1rem",
                            color: Colors.gray,
                            textTransform: "uppercase",
                            fontSize: "14px",
                        }}
                        i18nKey={"cescos:hours"}
                    />
                    {hours && hours.length > 0 && (
                        <Text style={{ marginBottom: "15px" }} className="text">
                            {hours.map((h) => (
                                <>
                                    {h.days}
                                    &nbsp;&nbsp;
                                    <Text
                                        style={{
                                            fontWeight: "bold",
                                            color: Colors.darkGray,
                                        }}
                                        className="text"
                                    >
                                        {h.times}
                                    </Text>
                                    <br />
                                </>
                            ))}
                        </Text>
                    )}
                    {props.noModal || (
                        <Button
                            style={{ margin: "1rem 0", width: "150px" }}
                            onClick={() => props.toggle()}
                            disabled={false}
                            className="btn-primary"
                        >
                            {t("cescos:services")}
                        </Button>
                    )}
                </div>

                <div className="d-none d-md-flex">
                    <img
                        aria-hidden="true"
                        src={cescoArt}
                        alt=""
                        style={{ alignSelf: "flex-end", width: "325px" }}
                    />
                </div>
            </div>

            {props.noModal || (
                <div
                    style={{ marginTop: "2rem" }}
                    aria-label={t("cesco:mapAria")}
                >
                    <iframe
                        aria-hidden={true}
                        width="100%"
                        height="590"
                        frameborder="0"
                        style={{ border: 0, marginBottom: "1rem" }}
                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCMniiTGCcL_v_f3IX2P5vhGrltL7KCoKs
            &q=${geoLocation.latitude},${geoLocation.longitude}&zoom=14`}
                        allowfullscreen
                    ></iframe>
                </div>
            )}
        </>
    );
};

const CescoDetailsStyle = {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem",
    paddingBottom: 0,
    borderBottom: `6px solid ${Colors.lightGray}`,
    margin: 0,
};

const grayText = {
    color: Colors.gray,
};

export default CescoDetails;
