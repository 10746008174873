import React from 'react';
import {useDispatch, useSelector} from "react-redux";

const Loading = () => {
    const loading = useSelector((state)=> state?.loadingState?.loading);

    const menuWrapperStyle = {
        display: "flex",
        justifyContent: "center"
    };

    if (loading)
        return (
            <div style={{zIndex: 99999}}
                 className={`u-fullscreen u-fullscreen--showed u-fullscreen__overlay-cesco-loading`}>

                <div className="u-fullscreen__container" style={menuWrapperStyle}>

                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    return null;

};

export default Loading;
