import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ElectronicTagModal from "../../components/ElectronicTag/ElectronicTagModal";
import AutoExpresoFines from "../../components/ui/AutoExpresoFines";
import Checkbox from "../../components/ui/Checkbox";
import ClickableRow from "../../components/ui/ClickableRow";
import ComponentTitle from "../../components/ui/ComponentTitle";
import PayWithAmountButton from "../../components/ui/PayWithAmountButton";
import FineList from "../../components/widgets/FineList";
import FinesControlRowMobile from "../../components/widgets/FinesControlRowMobile";
import VehicleList from "../../components/widgets/VehicleList";
import AlertGroup from "../../components/widgets/alerts/AlertGroup";
import VehicleCertifications from "../../components/widgets/certifications/VehicleCertifications";
import * as Api from "../../core/APICaller";
import * as Utils from "../../core/Utils";
import SubHeader from "../../layout/SubHeader";
import { FETCH_VEHICLE_FINES, fineSelectAll, resetCart } from "../../redux/fines/actions";
import { caller } from "../../redux/loading/loadingState.combine";
import { selectFines } from "../../redux/payment/payment.combine";
import { fetchVehicleRenewalEligibility, setVehicleNotiReasons } from "../../redux/vehicleRenewal/vehicleRenewal.combine";
import VehicleIconRow from "./VehicleIconRow";
import VehicleInformation from "./VehicleInformation";

import electronicTagIcon from '../../assets/images/electronic-tag/MarbeteElectronico.svg';
import stickerTagIcon from '../../assets/images/electronic-tag/icon_MarbeteElectronico_MarbeteRegular.svg';
import electronicTagIconWhite from '../../assets/images/electronic-tag/MarbeteElectronico_White.svg';
import electronicTagExpiredIcon from '../../assets/images/electronic-tag/icon_MarbeteElectronico_Expirado.svg'

import { useTranslation } from "react-i18next";
import RevalidationModal from "../../components/ElectronicTag/RevalidationModal";
import UnbindSuccessModal from "../../components/ElectronicTag/UnbindSuccessModal";
import { getTagPreboardingData, setElectronicTagVehicle, setTagPreboardingRevalidationModal } from "../../redux/electronicTag/electronicTag.combine";
import ServicesRow from "./ServicesRow";
import PreboardingAlert from "./PreboardingAlert";
import VehicleNotiEligibilityModal from "../../components/widgets/VehicleNotiEligibilityModal";
import { vehicleRenewalInventorySerialNumber } from "../../core/api-resources/electronicTag";
import _ from "lodash";
import FineControlRow from "../../components/widgets/FinesControlRow";

import autoexpresoIcon from '../../assets/images/noti-eligibility/Autoexpreso.svg';
import { autoexpresoEligibility, autoexpresoFinesSummary } from "../../core/api-resources/autoexpreso";

const VehicleDetails = (props) => {
    const [loading, setLoading] = useState(true);
    const [vehicle, setVehicle] = useState(Utils.emptyObjArray(5));
    const [otherVehicles, setOtherVehicles] = useState(Utils.emptyObjArray(5));
    const [fines, setFines] = useState(Utils.emptyObjArray(5));
    const [all, setAll] = useState(false);

    const [preboardedVehicle, setPreboardedVehicle] = useState(false);
    const [electronicTagModal, setElectronicTagModal] = useState(false);

    const [statusModal, setStatusModal] = useState(false);

    const [revalidationModal, setRevalidationModal] = useState(false);
    const [revalidationPending, setRevalidationPending] = useState(false);
    const [revalidationError, setRevalidationError] = useState(false);

    const [unbindSuccess, setUnbindSuccess] = useState(false);

    const [expiredVehicleTag, setExpiredVehicleTag] = useState(false)

    const [serialNumber, setSerialNumber] = useState(null)

    const [autoexpresoEligibilityData, setAutoexpresoEligibilityData] = useState(null)

    const autoexpresoFeature = useSelector(s => s?.features?.autoExpreso?.enabled)

    const dispatch = useDispatch();
    const { vehicleId } = useParams();
    const { t } = useTranslation()

    let config = useSelector((state) => state.config);
    const user = useSelector((state) => state?.login?.user);
    let userFines = useSelector((state) => state.fines);
    let card = useSelector((state) => state.card);
    const allVehicles = useSelector((state) => state?.login?.user?.vehicles);

    const preBoardingEnabled = useSelector(s => s?.features?.preBoarding?.enabled)
    const preBoardingRegistrationEnabled = useSelector(s => s?.features?.preBoarding?.preBoardingRegistrationEnabled)

    const unpaidTolls = card?.license?.unpaidTolls

    const vehicleWithTagPreboarding = allVehicles.filter(v => v.id === vehicle.id)
    const pendingElectronicTag = vehicleWithTagPreboarding?.length > 0 && vehicleWithTagPreboarding[0].tagPreboarding?.status === "pending"

    let history = useHistory();

    const showRevalidationModal = useSelector(s => s.electronicTag?.showRevalidationModal)

    const vehicleTagType = vehicle?.tag?.type

    useEffect(() => {
        const expiredTag = Utils.isTimestampExpired(vehicle?.tag?.expirationDate)
        setExpiredVehicleTag(expiredTag)
    }, [vehicle?.tag?.expirationDate])

    const fetchVehicleDetails = () => {
        let others = [];
        if (user)
            others = user?.vehicles?.filter(v => v.id !== vehicleId);
        setOtherVehicles(others);
        dispatch(
            caller(
                Api.fetchVehicleInfo(
                    vehicleId,
                    onVehicleFetchSuccess,
                    onVehicleFetchError
                )
            )
        );
        if (autoexpresoFeature) {
            fetchAutoexpresoSummary()
        }
    }

    const fetchAutoexpresoSummary = async () => {
        const eligible = await autoexpresoEligibility(
            vehicleId,
            data => setAutoexpresoEligibilityData(data),
            error => setAutoexpresoEligibilityData(null)
        )
    }

    const onVehicleFetchSuccess = (data) => {
        let { vehicle, fines } = data;
        dispatch({
            type: FETCH_VEHICLE_FINES,
            payload: fines,
        });
        // setFines(fines);
        setVehicle(vehicle);
        setLoading(false);
    };

    const onVehicleFetchError = () => {
        setFines([]);
    };

    useEffect(() => {
        if (showRevalidationModal) {
            setElectronicTagModal(true)
            dispatch(setTagPreboardingRevalidationModal(false))
            dispatch(getTagPreboardingData())
        }
    }, [showRevalidationModal])

    useEffect(() => {
        dispatch(resetCart());
        fetchVehicleDetails()
    }, [vehicleId]);

    useEffect(() => {
        // Fetch serial number if vehicle tag type is DIGITAL
        if (vehicleTagType !== "DIGITAL") return

        if (vehicleId) fetchSerialNumber()
    }, [vehicleId, vehicleTagType]);

    // Handle digital tag alert
    useEffect(() => {
        const vehicleFromEntity = user.vehicles.filter(v => v.id === vehicleId)[0]
        // Merges vehicle from entity (redux) with preboarding data and vehicle from `fetchVehicleDetails`
        const vehicleWithPreboarding = _.merge(_.cloneDeep(vehicleFromEntity), vehicle)

        if (!!vehicleWithPreboarding?.tagPreboarding) {
            // setVehicle(vehicleWithPreboarding)
            setPreboardedVehicle(true)
            return
        }

        setPreboardedVehicle(false)
    }, [vehicleId, user, vehicle])

    // const renewalTag = () => {
    //     dispatch(fetchVehicleRenewalEligibility(vehicle, (eligibility) => {
    //         if (eligibility.eligible) {
    //             let agreement = eligibility.userAgreements[0];
    //             if (agreement.accepted)
    //                 history.push("/renewal/insurance");
    //             else
    //                 history.push("/renewal/disclosure")
    //         }
    //     }));
    // };

    const autoexpresoBanner = useMemo(() => {

        if (!autoexpresoFeature) return

        // if (!vehicle?.unpaidTolls || vehicle?.unpaidTolls === 0) return;

        // Solo debe aparecer cuando existan multas de AutoExpreso
        if (!autoexpresoEligibilityData?.countFines || autoexpresoEligibilityData?.countFines === 0) return;

        return <AutoExpresoFines />

    }, [vehicle?.unpaidTolls, autoexpresoEligibilityData])

    const RenderPreboardingServicesRow = () => {
        return (
            <ClickableRow
                text={t("vehicleDetails:electronicTag.title")}
                icon={vehicleTagType === "DIGITAL" ? electronicTagIcon : stickerTagIcon}
                action={() => {
                    dispatch(setElectronicTagVehicle({ ...vehicle, serialNumber: serialNumber }))
                    history.push('/electronic-tag')
                }}
            />
        )
    }

    const AutoexpresoServiceRow = () => {

        if (!autoexpresoFeature) return null

        // if (!autoexpresoEligible) return null

        return (
            <ClickableRow
                text={t("vehicleDetails:autoexpreso.title")}
                icon={autoexpresoIcon}
                action={() => {
                    history.push(`/autoexpreso/${vehicleId}`)
                }}
                fines={autoexpresoEligibilityData?.countFines}
            />
        )
    }


    const RenderPreboardingAlert = () => {

        if (vehicleTagType === "DIGITAL" || preboardedVehicle || !preBoardingEnabled || !preBoardingRegistrationEnabled) {
            return null
        }

        return (
            <PreboardingAlert
                vehicle={vehicle}
                pending={pendingElectronicTag}
            />
        )
    }

    const fetchSerialNumber = async () => {
        await vehicleRenewalInventorySerialNumber(
            vehicleId,
            data => setSerialNumber(data?.payload?.serialNumber),
            error => setSerialNumber(null)
        )
    }

    return (
        <>

            {
                electronicTagModal &&
                <ElectronicTagModal
                    toggle={setElectronicTagModal}
                    vehicleId={vehicleId}
                    setUnbindSuccess={setUnbindSuccess}
                    action={() => {
                        setElectronicTagModal(false)
                        setStatusModal(true)
                    }}
                    revalidationAction={(res) => {
                        setElectronicTagModal(false)
                        setRevalidationModal(true)

                        if (res?.status === 'pending') {
                            setRevalidationPending(true)
                        }

                        if (res?.status === "error") {
                            setRevalidationError(true)
                        }
                    }}
                />
            }

            {
                revalidationModal &&
                <RevalidationModal
                    toggle={setRevalidationModal}
                    vehicleId={vehicleId}
                    pending={revalidationPending}
                    error={revalidationError}
                />
            }

            {
                statusModal &&
                <UnbindSuccessModal
                    toggle={setStatusModal}
                    vehicleId={vehicleId}
                    success={unbindSuccess}
                />
            }

            <SubHeader>
                <VehicleIconRow {...vehicle} loading={loading} preboardedVehicle={preboardedVehicle} />
            </SubHeader>
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <RenderPreboardingAlert />

                        <AlertGroup
                            alerts={vehicle.alerts}
                            colorFill
                            vehicle={vehicle}
                        />
                    </div>
                </div>
                <div className="row">
                    <div style={left} className="col-lg-5 col-12">

                        <ServicesRow>
                            <RenderPreboardingServicesRow />
                            <AutoexpresoServiceRow />
                        </ServicesRow>

                        <div style={contentBlock}>
                            <ComponentTitle
                                i18nKey={"vehicle:certificationsTitle"}
                            />
                            <VehicleCertifications vehicle={vehicle} />
                        </div>

                        <div className="d-lg-none">
                            {autoexpresoBanner}
                            <FinesControlRowMobile
                                i18nKey={"vehicle:fines"}
                                all={all}
                                disabled={vehicle?.unpaidFines === 0}
                                loading={loading}
                            />

                            <FineList
                                fines={userFines.workingFines}
                                loading={loading}
                                noIcon
                            />
                        </div>

                        <div style={contentBlock}>
                            <ComponentTitle
                                i18nKey={"vehicle:vehicleInformation"}
                            />
                            <VehicleInformation
                                loading={loading}
                                vehicle={vehicle}
                            />
                        </div>

                        {otherVehicles && otherVehicles.length >= 1 && (
                            <div style={contentBlock}>
                                <ComponentTitle i18nKey={"vehicleDetails:otherVehicles"} />
                                <VehicleList vehicles={otherVehicles} />
                            </div>
                        )}
                    </div>
                    <div className="d-none d-lg-block col-lg-7 col-12">
                        <ComponentTitle
                            style={{ fontWeight: "bold" }}
                            i18nKey={"vehicle:fines"}
                        />

                        <FineControlRow
                            vehicle={vehicle}
                            loading={loading}
                        />

                        {autoexpresoBanner}

                        <FineList
                            fines={userFines.workingFines}
                            loading={loading}
                            noIcon
                        />
                    </div>
                </div>
            </div>
        </>
    );
};


const left = {
    display: "flex",
    flexDirection: "column",
};

const contentBlock = {
    marginBottom: "25px",
};

export default VehicleDetails;
