import React from "react";
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import _ from "lodash";

const Text = props => {
    const {t} = useTranslation();

    let bundle = {key: props.i18nKey, data: {}};
    if (!_.isEmpty(props.i18nKeyWithData))
        bundle = props.i18nKeyWithData;

    return (
        <span style={{...props.style}}
              className={props.className}>{!_.isEmpty(bundle.key) ? t(bundle.key, bundle.data) : props.children}</span>
    );
};

// React.Children.map(props.children,()=>("sdf"))


Text.propTypes = {
    i18nKey: PropTypes.string,
    i18nKeyWithData: PropTypes.object,
    style: PropTypes.any

};
export default Text
