export const CHANGE_MENU_STATE  = "CHANGE_MENU_STATE";

export const changeMenuState = state =>{
    return {
        type:CHANGE_MENU_STATE,
        payload:state
    }
} ;

export function menu(state = {open:false}, action) {
    if (action.type === CHANGE_MENU_STATE) {
        return {open:action.payload};
    } else {
        return state;
    }
}
