import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import ElectronicTagValidationLayout from "../../../components/ElectronicTag/ElectronicTagValidationLayout"
import UnlinkModal from "../../../components/ElectronicTag/UnlinkModal"

const ElectronicTagUnlink = () => {

    // useEffect(() => {
    //     if (!vehicle || !preBoardingRegistrationEnabled) history.push('/landing')
    // }, [vehicle])

    const vehicle = useSelector(s => s.electronicTag.vehicle)
    const [unlinkModal, setUnlinkModal] = useState(true)

    const { t } = useTranslation()

    return (
        <div>
            {
                unlinkModal && <UnlinkModal toggle={setUnlinkModal} />
            }
            <ElectronicTagValidationLayout
                vehicle={vehicle}
                subHeaderText={
                    <div className="text lh-md">
                        <span>{t("electronicTag:subHeader.0")}</span>
                        <strong>{t("electronicTag:subHeader.1")}</strong>
                        <br />
                        <span>{t("electronicTag:subHeader.2")}</span>
                        <strong>{t("electronicTag:subHeader.3")}</strong>
                        <span>{t("electronicTag:subHeader.4")}</span>
                    </div>
                }

            />
        </div>
    )
}

export default ElectronicTagUnlink