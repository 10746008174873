import React from "react";
import PropTypes from 'prop-types'
import Text from "./Text";
import { Colors } from "../../Colors";

const PageTitle = props => {
    return (
        <div style={PageTitleStyle} className="page-t">
            <div
                style={{
                    width: "4px",
                    height: "16px",
                    backgroundColor: Colors.blue,
                    marginRight: "6px",
                    marginBottom: "6px",
                    justifySelf: 'flex-end',
                    ...props.style
                }}
                className="page-title-margin"
            />
            <Text
                className="text-lg page-title"
                style={{
                    fontWeight: "bold",
                    letterSpacing: "0.03rem",
                }}
                i18nKey={props.i18nKey}
            />
        </div>
    );
};

const PageTitleStyle = {
    display: "flex",
};

PageTitle.propTypes = {
    i18nKey: PropTypes.string
}

export default PageTitle;
