import React from "react";
import { Colors } from "../../Colors";
import { FontWeight } from "./FontSize";
import PropTypes from "prop-types";
const FineTotalBadge = props => {
    return (
        <span
            className="badge badge-sm badge-primary rounded-circle"
            style={{
                backgroundColor: props.red ? Colors.error : Colors.primary,
                marginRight: "15px",
                fontWeight: FontWeight.bold,
                height: "24px",
                width: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "13px"
            }}
            role="text"
            aria-label={props.amount}
        >
            {props.amount}
        </span>
    );
};

FineTotalBadge.propsTypes = {
    amount: PropTypes.number
};
FineTotalBadge.defaulProps = {
    amount: 0
};

export default FineTotalBadge;
