import React from 'react';
import ComponentTitle from "../ui/ComponentTitle";
import Checkbox from "../ui/Checkbox";
import { Colors } from "../../Colors";
import Text from "../ui/Text";
import { formatCurrencyLabel } from "../../core/Utils";
import Button from "../ui/Button";
import { useDispatch, useSelector } from "react-redux";

import cancelIconGray from "../../assets/images/icon_Cancelar_BtMultas_Gray.svg";
import cancelIcon from "../../assets/images/icon_Cancelar_BtMultas.svg";
import AutoExpresoFines from '../ui/AutoExpresoFines';
import { fineSelectAll } from '../../redux/fines/actions';
import { selectFines } from '../../redux/payment/payment.combine';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { autoexpresoFlow } from '../../redux/app/app.combine';

const FinesControlRowMobile = (props) => {
    let userFines = useSelector((state) => state.fines);

    const { i18nKey, disabled, loading, noSelectAll, customDeselectAll, payAll, autoexpreso, vehicle, payAllDisabled, payDisabled } = props

    const dispatch = useDispatch()
    const history = useHistory()

    const selectAllClick = (checked) => {
        dispatch(fineSelectAll(checked));
    };

    const deselectAll = () => {
        if (customDeselectAll) {
            customDeselectAll()
        } else {
            dispatch(fineSelectAll());
        }
    };

    const handlePayFines = () => {

        if (autoexpreso) {
            dispatch(autoexpresoFlow())
            history.push(`/pay/fines/autoexpreso/${vehicle.id}`);
            return
        }

        if (!loading) {
            let selectedFines = userFines.cart.fines;
            dispatch(
                selectFines(selectedFines, () => {
                    history.push("/pay/fines");
                })
            );
        }
    };

    return (
        <>
            {!noSelectAll && (
                <ComponentTitle
                    style={{ fontWeight: "bold" }}
                    i18nKey={i18nKey}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: 15
                        }}
                    >
                        <Checkbox
                            checked={userFines.cart.all}
                            ariaLabel={"profile:selectAllArial"}
                            disabled={disabled}
                            handleCheckbox={selectAllClick}
                            label={"profile:selectAll"}
                        />
                    </div>
                </ComponentTitle>
            )}

            {
                props?.unpaidTolls > 0 &&
                <AutoExpresoFines />
            }

            <div style={{
                height: 50,
                width: '100%',
                backgroundColor: disabled ? Colors.disabledGray : Colors.darkGreen,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 15,
                color: disabled ? Colors.disabledText : 'white'
            }}>
                <div>
                    <button style={deselectButtonStyle} onClick={() => deselectAll()} className="mr-2">
                        <img src={disabled ? cancelIconGray : cancelIcon} aria-hidden="true" alt="" style={{ width: 20, height: 20 }} />
                    </button>
                    <Text i18nKey="main:fines" className="text font-weight-medium" /><span className="text mr-1">:</span>
                    <Text className="text font-weight-medium">{userFines?.cart?.fines?.length}</Text>
                </div>
                <div>
                    <Text i18nKey="TOTAL" className="text font-weight-medium" /><span className="text mr-1">:</span>
                    <Text className="text font-weight-medium">
                        {formatCurrencyLabel(userFines?.cart?.sumAmount)}
                    </Text>
                </div>
            </div>
            {
                <div className='d-flex'>
                    {
                        payAll &&
                        <>
                            <Button
                                onClick={payAll}
                                disabled={payAllDisabled}
                                className="btn btn-primary"
                                style={{ width: '100%', borderRadius: 0, height: 50 }}
                            >
                                <span
                                    style={{ fontSize: 15, fontWeight: 600, letterSpacing: 1 }}
                                >
                                    <Text i18nKey={"profile:payAll"} />
                                </span>
                            </Button>
                            <div style={{ width: 2, height: 50, backgroundColor: 'white' }} />
                        </>
                    }

                    <Button
                        onClick={handlePayFines}
                        disabled={userFines.cart.fines.length === 0 || payDisabled}
                        style={{ width: '100%', borderRadius: 0, height: 50 }}
                        className="btn btn-primary"
                    >
                        <span
                            style={{ fontSize: 15, fontWeight: 600, letterSpacing: 1 }}
                        >
                            {
                                payAll ?
                                    <Text i18nKey={"profile:paySelected"} /> :
                                    <Text i18nKey={"profile:payFines"} />
                            }
                        </span>
                    </Button>
                </div>
            }


        </>
    );
};


FinesControlRowMobile.propTypes = {
    i18nKey: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    noSelectAll: PropTypes.bool,
    customDeselectAll: PropTypes.func,
    payAll: PropTypes.func,
    autoexpreso: PropTypes.bool
};

const deselectButtonStyle = {
    backgroundColor: 'transparent',
    border: 0,
    padding: 0
}

export default FinesControlRowMobile;