import React from 'react';
import iconWarning from "../../assets/images/icon_Warning_White.svg";
import Text from "../../components/ui/Text";
import {Colors} from "../../Colors";

const InputNotificationPopup = (props) => {
    const {message} = props
    if (!message) return null;

    return (
        <div className="card col-12 mb-3" style={inputNotificationStyle}>
            <div
                className="card-body"
                style={{
                    backgroundColor: Colors.error,
                    borderRadius: "0.3125rem",
                    display: "flex",
                    alignItems: "center",
                    padding: 18
                }}>
                <img src={iconWarning} style={{height: "25px", marginRight: "10px"}} alt="" aria-hidden='true'/>
                <Text className="text" i18nKey={message}/>
            </div>
        </div>
    );
};


const inputNotificationStyle = {
    backgroundColor: "transparent",
    color: "#fff",
    border: "none",
    left: 0,
    zIndex: 100,
    padding: 0,
    display: "flex",
};


export default InputNotificationPopup;