import React from 'react'
import Lottie from "react-lottie"
import successAnimation from '../checkmark-blue-animation.json'
import errorAnimation from '../error-animation.json'
import PropTypes from 'prop-types'

import artCompleted from './electronic-tag-completed.svg'

const lottieSuccess = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
}

const lottieError = {
    loop: false,
    autoplay: true,
    animationData: errorAnimation,
}

const ElectronicTagResultAnimation = (props) => {

    const { error } = props

    const renderOption = () => {
        if (error) return lottieError

        return lottieSuccess
    }

    return (
        <div>
            <div style={{ position: 'relative' }}>
                <img src={artCompleted} alt="" />
                <div style={{ position: 'absolute', right: 40, bottom: 0 }}>
                    <Lottie
                        options={renderOption()}
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        </div>
    )
}

ElectronicTagResultAnimation.propTypes = {
    error: PropTypes.bool
}

export default ElectronicTagResultAnimation