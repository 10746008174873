import React from "react";

import { useTranslation } from "react-i18next";

import WizardPayment from "../../../components/widgets/WizardPayment";
import MobileWizardPayment from "../../../components/widgets/MobileWizardPayment";

const PayFinesWizard = ({ step, error }) => {
    const { t } = useTranslation();

    return (
        <>
            <div style={{ width: "100%" }} className="d-none d-lg-flex">
                <WizardPayment
                    step={step}
                    error={error}
                    labels={[
                        t("payFines:wizard.stepOne"),
                        t("payFines:wizard.stepTwo"),
                        t("payFines:wizard.stepThree"),
                    ]}
                />
            </div>
            <div style={{ width: "100%" }} className="d-flex d-lg-none">
                <MobileWizardPayment
                    step={step}
                    error={error}
                    labels={[
                        t("payFines:wizard.stepOne"),
                        t("payFines:wizard.stepTwo"),
                        t("payFines:wizard.stepThree"),
                    ]}
                />
            </div>
        </>
    );
};

export default PayFinesWizard;
