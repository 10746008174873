import React, {useEffect, useState} from "react";

import PaymentFineListItem from "../../components/widgets/PaymentFineListItem";
import PaymentFineListItemMobile from "../../components/widgets/PaymentFineListItemMobile";

import TotalSummary from "../../components/widgets/TotalSummary";
import WizardFooter from "../../components/widgets/WizardFooter";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import ComponentTitle from "../../components/ui/ComponentTitle";
import Pagination from "react-js-pagination";
import PayFinesWizard from "./components/PayFinesWizard";
import RenewalWizard from "../Renewal/components/RenewalWizard";

import {calcCart, fineRemove, resetCart} from "../../redux/fines/actions";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import InformationRow from "../../components/ui/InformationRow";
import FineTotalBadge from "../../components/ui/FineTotalBadge";

import noFinesIcon from "../../assets/images/icon_Badge_Renovacion_Excelente.svg";
import Text from "../../components/ui/Text";
import SubHeader from "../../layout/SubHeader";

import {useTranslation} from "react-i18next";
import {resetFlow} from "../../redux/app/app.combine";

const Payment = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {cart} = useSelector((state) => state?.fines);
    const flow = useSelector((state) => state?.flow);

    const PAGE_SIZE = 5;
    // const [finess, setFines] = useState([]);
    const [displayFines, setDisplayFines] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // const [pages, setPages] = useState(1);
    const [confirmationModal, toggleConfirmation] = useState(false);
    const [fineToRemove, setFineToRemove] = useState("");

    const {t} = useTranslation();

    useEffect(() => {
        let {fines} = cart;
        if (!flow) dispatch(calcCart());
        setDisplayFines(fines.slice(0, PAGE_SIZE));
    }, []);

    useEffect(() => {
        let {fines} = cart;
        // if (fines.length === 0) history.push("/landing");
        pageEvent(currentPage);
    }, [cart.fines]);

    const handleRemoveFine = (fine) => {
        toggleConfirmation(true);
        setFineToRemove(fine);
    };

    const removeFine = () => {
        dispatch(fineRemove(fineToRemove, true));
        toggleConfirmation(false);
    };

    const arrayPage = (arr, page = 1) => {
        return arr.slice(PAGE_SIZE * (page - 1), PAGE_SIZE * page);
    };

    const pageEvent = (p) => {
        setDisplayFines(arrayPage(cart.fines, p));
        setCurrentPage(p);
    };

    const cancelAction = () => {
        dispatch(resetCart());
        if (flow && flow.startsWith("RENEWAL")) {
            dispatch(resetFlow());
        }
        history.push("/landing");
    };

    let {paymentTotal} = cart;

    return (
        <>
            <SubHeader>
                {flow === "RENEWAL" ? (
                    <RenewalWizard step={2}/>
                ) : (
                    <PayFinesWizard step={1}/>
                )}
            </SubHeader>

            {confirmationModal ? (
                <ConfirmationModal
                    action={removeFine}
                    toggle={toggleConfirmation}
                    text={t("payFines:confirmationModal")}
                    title={t("payFines:confirmationModalTitle")}
                />
            ) : null}
            <div className="container">
                <div className="row">
                    <div
                        className="col-lg-5 col-12"
                        style={{marginBottom: "1rem"}}
                    >
                        <ComponentTitle
                            i18nKey={"payFines:total"}
                            amount={cart?.fines.length}
                        >
                            <FineTotalBadge amount={cart?.fines.length}/>
                        </ComponentTitle>
                        <TotalSummary
                            finesTotal={paymentTotal?.finesTotal}
                            finesPenalties={paymentTotal?.finesPenalties}
                            finesCredits={paymentTotal?.finesCredits}
                            total={paymentTotal?.total}
                            renewal={
                                flow === "RENEWAL" || flow === "RENEWAL_VEHICLE"
                            }
                        />
                    </div>

                    <div
                        className="col-lg-7 col-12"
                        style={{paddingRight: 0}}
                    >
                        <ComponentTitle i18nKey={"payFines:fineList"}/>
                        <table className="table">
                            <tbody>
                            {displayFines.length > 0 ? (
                                displayFines.map((f, i) => (
                                    <React.Fragment key={i}>
                                        <div className="d-none d-lg-block">
                                            <PaymentFineListItem
                                                fine={f}
                                                handleRemoveFine={
                                                    handleRemoveFine
                                                }
                                                renewal={
                                                    flow === "RENEWAL" ||
                                                    flow ===
                                                    "RENEWAL_VEHICLE"
                                                }
                                            />
                                        </div>
                                        <div className="d-lg-none">
                                            <PaymentFineListItemMobile
                                                fine={f}
                                                handleRemoveFine={
                                                    handleRemoveFine
                                                }
                                                renewal={
                                                    flow === "RENEWAL" ||
                                                    flow ===
                                                    "RENEWAL_VEHICLE"
                                                }
                                            />
                                        </div>
                                    </React.Fragment>
                                ))
                            ) : (
                                <div style={noFines}>
                                    <img
                                        src={noFinesIcon}
                                        aria-hidden="true"
                                        alt=""
                                        style={{
                                            marginBottom: "20px",
                                            opacity: "0.5",
                                        }}
                                    />
                                    <Text i18nKey="payFines:noFines"/>
                                </div>
                            )}
                            </tbody>
                        </table>
                        {cart.fines.length > PAGE_SIZE &&
                        displayFines.length > 0 ? (
                            <nav style={{marginTop: 20}}>
                                <Pagination
                                    innerClass={
                                        "pagination justify-content-center"
                                    }
                                    activePage={currentPage}
                                    itemsCountPerPage={PAGE_SIZE}
                                    totalItemsCount={cart.fines.length}
                                    pageRangeDisplayed={3}
                                    onChange={pageEvent}
                                    itemClass={"page-item"}
                                    linkClass={"page-link"}
                                />
                                {/*<small className="text-muted">{currentPage} of {pages}</small>*/}
                            </nav>
                        ) : null}
                    </div>
                    <div className="container">
                        <WizardFooter
                            cancelAction={cancelAction}
                            disabled={!cart.fines.length > 0}
                            handleNext={() => history.push("/pay/method")}
                        />
                    </div>
                </div>
            </div>
            <InformationRow textKey="payFines:footer.text"/>
        </>
    );
};

const noFines = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    width: "100%",
    height: "auto",
    padding: "60px",
    textAlign: "center",
};

export default Payment;
