import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Colors } from '../../Colors'
import ComponentTitle from '../../components/ui/ComponentTitle'
import PageTitle from '../../components/ui/PageTitle'
import Text from '../../components/ui/Text'
import FormCertifications from '../../components/widgets/FormCertifications'
import CoursesList from './components/CoursesList'

const Course = () => {

    const user = useSelector(state => state?.login?.user);

    const history = useHistory()

    const { i18n } = useTranslation();

    const handleSubmit = async () => {

        if (user) {
            history.push('/courses/form')

            setTimeout(() => {
                history.push('/landing')
            }, 500)
        } else {

            let form = document.getElementById('courses-form')
            form.submit()
        }
    }

    return (
        <>
            <div className="container p-0 p-lg-3">
                <div className="p-mob-only">
                    <PageTitle
                        i18nKey={"courses:title"}
                        style={{ marginBottom: "40px" }}
                    />
                </div>
            </div>

            <div className="container p-0 p-lg-3">
                <ComponentTitle
                    i18nKey={"courses:list"}
                />

                <CoursesList action={handleSubmit} isAuth={user !== null} />
            </div>

            {
                !user &&
                <div className="container p-0 p-lg-3">
                    <div
                        style={{
                            display: "flex",
                            direction: "row",
                            justifyContent: "space-between",
                            marginTop: "20px",
                        }}
                    >
                        <ComponentTitle i18nKey={"certifications:formsTitle"} />
                        <a
                            href={process.env.REACT_APP_FORMULARIOS_LINK}
                            style={{
                                color: Colors.primary,
                                fontSize: "16px",
                                marginRight: "15px",
                            }}
                            // target="_blank"
                        >
                            <Text
                                i18nKey={"certifications:otherForms"}
                                className="text"
                            />
                        </a>
                    </div>

                    <FormCertifications />
                </div>

            }

            <form
                id="courses-form"
                name="courses-form"
                action={process.env.REACT_APP_RECIPROCIDAD_LINK}
                method="post"
                // target="_blank"
            >
                <input
                    type="hidden"
                    name="language"
                    value={i18n.language == "en-US" ? 'en' : 'es'}
                />
            </form>

        </>

    )
}

export default Course