import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import MessageListItem from "./MessageListItem";
import MessageDetails from "./MessageDetails";
import ErrorRefresh from "../../components/ui/ErrorRefresh";
import PageTitle from "../../components/ui/PageTitle";
import { Colors } from "../../Colors";
import {useDispatch} from "react-redux";
import Text from "../../components/ui/Text";
import ScrollToTopButton from "../../components/ui/ScrollToTopButton";

import noMessagesArt from "../../assets/images/art-no-messages.svg";
import {caller} from "../../redux/loading/loadingState.combine";
import * as Api from "../../core/api-resources/profile";
import * as Utils from "../../core/Utils";


const Messages = () => {
    const [loading, toggleLoading] = useState(true);
    const [allMessages, changeAllMessages] = useState([]);
    const [messages, changeMessages] = useState([]);
    const [selectedId, changeSelectedId] = useState("");
    const [selectedMessage, changeSelectedMessage] = useState("");
    const [filter, toggleFilter] = useState("");
    const [error, toggleError] = useState(false);
    let dispatch = useDispatch();

    useEffect(() => {
        fetchMessages();
    }, []);

    useEffect(() => {
        if (messages?.length > 0) {
            selectMessage(messages[0]);
        }
    }, [messages]);

    const fetchMessages = () => {
        toggleLoading(true);
        toggleError(false);
        dispatch(
            caller(
                Api.fetchNotificationHistory(
                    onMessageFetchSuccess,
                    onMessageFetchError
                )
            )
        );

    };

    const onMessageFetchSuccess = (data) => {
        Utils.generateIdForUI(data);
        changeAllMessages(data);
        changeMessages(data);
        toggleLoading(false);
    };

    const onMessageFetchError = () => {
        toggleLoading(false);
        toggleError(true);
    };

    const selectMessage = (message) => {
        changeSelectedId(message.id);
        changeSelectedMessage(message);
        // send "seen" true to db
    };

    const filterMessages = (filterBy) => {
        const filteredMessages = allMessages.filter((m) => m.type === filterBy);
        if (filteredMessages.length > 0 && filterBy !== filter) {
            toggleFilter(filterBy);
            changeMessages(filteredMessages);
        } else {
            toggleFilter("");
            changeMessages(allMessages);
        }
    };

    return (
        <>
            <div className="container">
                <div style={MessagesHeader}>
                    <div
                        style={{
                            borderBottom: `solid 1px ${Colors.borderGray}`,
                            width: "100%",
                            paddingBottom: "25px",
                            marginBottom: "25px",
                        }}
                    >
                        <PageTitle i18nKey={"messages:title"} />
                    </div>
                </div>

                {messages && messages.length > 0 ? (
                    <div className="row">
                        <div
                            className="col-lg-5 col-12 shadow mb-5 bg-white rounded"
                            style={MessageList}
                        >
                            {!error &&
                                messages.map((message) => (
                                    <MessageListItem
                                        key={message.id}
                                        message={message}
                                        selectMessage={selectMessage}
                                        selectedId={selectedId}
                                        loading={loading}
                                    />
                                ))}
                            {error && <ErrorRefresh refresh={fetchMessages} />}
                        </div>

                        {selectedMessage !== "" && (
                            <div className="col-lg-7 col-12" id="message">
                                <MessageDetails
                                    selectedMessage={selectedMessage}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="row">
                        <div style={ArtWrapper}>
                            <img
                                src={noMessagesArt}
                                className="no-messages"
                                aria-hidden={true}
                                alt=""
                            />
                            <Text
                                i18nKey="messages:noIconAlt"
                                style={{ marginTop: "20px" }}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="container d-flex d-md-none" style={scrollToTopStyle}>
                <ScrollToTopButton  />
            </div>
        </>
    );
};

const scrollToTopStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
}

const MessagesHeader = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
};

const MessageList = {
    marginBottom: "4rem",
    paddingRight: 0,
    maxHeight: '355px',
    overflowY: "auto",
    padding: 0,
};

const ArtWrapper = {
    flexDirection: "column",
    width: "100%",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

export default Messages;
