import React, {useEffect, useState} from "react";
import SvgHeaderImg from "./SvgHeaderImg";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";
import * as Utils from "../core/Utils";
import IdentityRow from "../components/widgets/IdentityRow";
import IdentityRowMobile from "../components/widgets/IdentityRowMobile";
import ContactInformationModal from "../components/widgets/ContactInformationModal";
import { usePageTracking } from "../analytics/tracking";

const Layout = (props) => {

    usePageTracking()

    const history = useHistory();
    const user = useSelector((state) => state?.login?.user);
    let [contactInfoModal, setContactInfoModal] = useState(false);
    let {pathname} = history.location;
    let {children, subHeader} = props;
    let needIdentity = (!(pathname.endsWith("error") || pathname.endsWith("maintenance")) || "/pay/error" === pathname)
        && subHeader
        && !_.isEmpty(user)

    useEffect(() => {
        // Math.floor(Math.random() * (max - min + 1) + min);
        if (!user?.principal) return;
        let seen = JSON.parse(sessionStorage.getItem("contact-seen")) || false;
        if (!user.principal.validEmail && seen === false) {
            /*          setTimeout(() => {
                          sessionStorage.setItem("contact-seen", "true");
                          setContactInfoModal(true);
                      }, Math.floor(Math.random() * (20 - 5 + 1) + 5) * 1000)*/
        }
    }, [user]);

    return (
        <>
            <div style={{marginRight: 0, marginLeft: 0}}
                 className=" row position-relative cesco-header-gradient space-top-2 space-bottom-2 p-mob-only">
                {needIdentity && (
                    <ContactInformationModal showModal={contactInfoModal}/>
                )}
                <div className="container">
                    {needIdentity && (
                        <div className="row">
                            <div className="d-none d-lg-block">
                                <IdentityRow
                                    user={Utils.fromEntity(user)}
                                />
                            </div>
                            <div className="d-lg-none col-12">
                                <IdentityRowMobile
                                    user={Utils.fromEntity(user)}
                                />
                            </div>
                        </div>
                    )}
                    <figure
                        className="position-absolute right-0 bottom-0 left-0"
                        aria-hidden={true}
                    >
                        <SvgHeaderImg/>
                    </figure>
                </div>
            </div>

            {children}
        </>
    );
    // };
};
Layout.propTypes = {
    subHeader: PropTypes.bool,
};
Layout.defaultProps = {
    subHeader: false,
};

export default Layout;
