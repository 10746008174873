import React, {Component} from 'react';

const radius = 175;
const diameter = Math.round(Math.PI * radius * 2);
const getOffset = (val = 0) => Math.round((100 - Math.min(val, 100)) / 100 * diameter);

export class BaseCircleChart extends Component {
    static defaultProps = {
        progress: 0,
        animate: true,
        animationDuration: '1s',
        showPercentage: true,
        showPercentageSymbol: true,
        progressColor: 'rgb(76, 154, 255)',
        bgColor: '#ecedf0',
        textColor: '#6b778c',
        size: '100',
        lineWidth: '25',
        percentSpacing: 10,
        textStyle: {font: 'bold 4rem Helvetica, Arial, sans-serif'}
    };

    get text() {
        const {progress, showPercentage, textColor, textStyle, percentSpacing, showPercentageSymbol} = this.props;
        if (!showPercentage) return;

        return (
            <text style={textStyle} fill={textColor} x={radius} y={radius} textAnchor="middle"
                  dominantBaseline="central">
                {progress}{showPercentageSymbol && <tspan dx={percentSpacing}>%</tspan>}
            </text>
        );
    }

    render() {
        const {progress, size, bgColor, progressColor, lineWidth, animate, animationDuration, roundedStroke, responsive, onAnimationEnd} = this.props;
        const strokeDashoffset = getOffset(progress);
        const transition = animate ? `stroke-dashoffset ${animationDuration} ease-out` : undefined;
        const strokeLinecap = roundedStroke ? 'round' : 'butt';
        const svgSize = responsive ? '100%' : size;

        return (
            <div className="circle-center-container">
                <div style={{
                    width: svgSize,
                    height: svgSize,
                    position: "absolute",
                    textAlign: 'center',
                }}>{this.props.children}</div>
                <svg width={svgSize} height={svgSize} viewBox="-25 -25 400 400">
                    <circle stroke={bgColor} cx="175" cy="175" r="175" strokeWidth={lineWidth} fill="none"/>
                    <circle stroke={progressColor} transform="rotate(-90 175 175)" cx="175" cy="175" r="175"
                            strokeDasharray="1100" strokeWidth={lineWidth} strokeDashoffset="1100"
                            strokeLinecap={strokeLinecap} fill="none" style={{strokeDashoffset, transition}}
                            onTransitionEnd={onAnimationEnd}/>
                </svg>
            </div>
        );
    }
}

