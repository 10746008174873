import { create } from 'apisauce'
import { fetchEntity, fetchImage } from "./api-resources/entity";
import { fullPageLoadingOff, showError } from "../redux/loading/loadingState.combine";
import _ from "lodash";
import { setAndClearSessionTimeoutId } from "../redux/login/login.combine";
import packageJson from '../../package.json'
let store;

export function configureStore(storeObj) {
    store = storeObj;
}

export const version = packageJson.version;
const env = process.env.REACT_APP_API_BASE;
// define the api
export const api = create({
    baseURL: env,
    headers: {
        'Accept': 'application/json; charset=utf-8',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        "X-APP-VERSION": `${version}`,
        "Accept-Language": "es",
        "Access-Control-Expose-Headers": "Authorization",
    },
    timeout: 55000
});

//change to localStorage if you want to use multiple tabs
let storageRef = sessionStorage;

api.addRequestTransform(request => {
    request.headers["Accept-Language"] = _.defaultTo(localStorage.getItem("i18nextLng"), "es");
});

api.addMonitor(response => {
    console.debug(response);
    if (response.config && response.config.url !== "/logout")
        expiredSessionChecker();
});

async function authenticate(credentials, onSuccess, onFailure) {
    let { dateSegmented } = credentials;
    let payload = {
        licenseOrPlate: credentials.license,
        last4ssn: credentials.ssn,
        monthOfBirth: dateSegmented.month,
        dayOfBirth: dateSegmented.dayOfMonth,
        yearOfBirth: dateSegmented.year
    };

    await api.get('/token');
    let recaptcha = store.getState().recaptcha;
    let config = { headers: { 'recaptcha-token': recaptcha.token } };
    let response = await api.post('/auth', payload, config);
    if (response.ok) {
        // setAuthToken(response.headers);
        api.withCredentials = true;
        // synchrolog.indentify(licenseOrPlate);

        let user = await fetchEntity();
        if (user) {
            user.image = await fetchImage();
        }
        onSuccess(user);

    } else if (onFailure) onFailure(response.problem);
}

async function logout(redirect = true, removeSession = true) {
    let user = store.getState()?.login?.user;
    let logoutInProgress = store.getState()?.login?.logoutInProgress
    if (removeSession)
        localStorage.removeItem("session_open");
    if (!_.isEmpty(user) || logoutInProgress) {
        try {
            await api.post("/logout", {})
        } catch (e) {
            console.error("Error at logout")
        }
        store.dispatch(setAndClearSessionTimeoutId(undefined));
    }

    if (redirect)
        window.location = "/";
}

//security
export function handleResponse(response, onSuccess = null, onError = null, allow404) {
    try {
        let { status } = response;
        if (response.ok) {
            localStorage.setItem("live", "true");
            if (onSuccess)
                onSuccess(response.data);
            return response.data;
        } else {
            console.error(`status err: ${status}`);
            onErrorHandler(onError, status, response, allow404)
        }
    } catch (e) {
        console.error(e);
        let { status } = response
        onErrorHandler(onError, status, response, allow404)
    }
}

function onErrorHandler(onError, status, response, allow404) {
    store.dispatch(fullPageLoadingOff());

    // Only if 404 allowed
    if (allow404 && status === 404) return;

    if (onError)
        onError(response);
    else if (status) {
        if (store && status !== 401) store.dispatch(showError());
        //todo: define behaviours

        switch (status) {
            case 401:
                window.location = `/`;
                break;
            case 404:
                localStorage.setItem("live", "false");
                window.location = `/maintenance`;
                break;
            default:
            //empty body

        }
    }
}

function expiredSessionChecker() {
    let user = store.getState()?.login?.user;
    let profileId = store.getState()?.profile?.id;
    let sessionTimeoutId = store.getState()?.login?.sessionTimeoutId;
    let other_sessions = localStorage.getItem("session_open");
    if (sessionTimeoutId) {
        store.dispatch(setAndClearSessionTimeoutId(undefined));
        console.log("clear timeout")
    }

    let sessionHandler = () => {
        console.log("executing session expired")
        store.dispatch(showError("Su sesión ha expirado"))
        logout(true).finally();
    };

    if (!_.isEmpty(user)) {
        console.log("added timeout")
        let id = setTimeout(sessionHandler, 15 * 1000 * 60);
        store.dispatch(setAndClearSessionTimeoutId(id));

    }
    console.error(`other sessions  ${other_sessions} === ${profileId}  = ${other_sessions === profileId}`)

    if (other_sessions && profileId && profileId !== other_sessions) {
        console.error("*****************************")
        console.error(`other session opened, must logout`)
        console.error("*****************************")
        // logout(true,false).finally();
        window.location = "/";
    }

}

export * from "./api-resources/entity";
export * from "./api-resources/payment";
export * from "./api-resources/profile";
export * from "./api-resources/reports";
export * from "./api-resources/config";
export * from "./api-resources/renewal";
export * from "./api-resources/vehiceRenewal";
export * from "./api-resources/agreements";
export * from "./api-resources/validateIdc";
export * from "./api-resources/autoexpreso";

export {
    authenticate,
    logout
}
