import React from 'react';
import SubHeader from "../../../layout/SubHeader";
import PageTitle from "../../../components/ui/PageTitle";
import Text from "../../../components/ui/Text";
import {Colors} from "../../../Colors";
import WizardFooter from "../../../components/widgets/WizardFooter";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import EmailValidationComponent from "../../../components/widgets/EmailValidationComponent";

const PaymentEmailValidation = () => {
    const profile = useSelector((state) => state?.profile);

    const {t} = useTranslation();

    const history = useHistory();

    return (
        <>
            <SubHeader>
                <div className="container">
                    <PageTitle
                        style={{fontWeight: "bold"}}
                        i18nKey={"emailValidation:title"}
                    />
                    <div className="d-none d-lg-flex">
                        <hr/>
                    </div>
                    <Text
                        i18nKey="emailValidation:subTitle"
                        className="text"
                        style={{color: Colors.gray}}
                    />
                </div>
            </SubHeader>

            <EmailValidationComponent/>

            <div className="container">
                <WizardFooter
                    disabled={!profile?.validEmail}
                    nextText={t("paymentEmailValidation:continue")}
                    hideBack
                    handleNext={() => history.push('/pay/method')}
                />
            </div>
        </>
    );
};

export default PaymentEmailValidation;
