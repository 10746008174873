import * as Api from "../../core/APICaller";
import { caller, LOADING_END, LOADING_START, showError } from "../loading/loadingState.combine";
import { handleResponse } from "../../core/APICaller";
import { SELECT_FINES } from "../payment/payment.combine";
import { RESET_STATE } from "../app/app.combine";

export const FETCH_ALL_FINES = "FETCH_ALL_FINES";
export const FETCH_CARD_FINES = "FETCH_CARD_FINES";
export const ADD_FINE_TO_PAY = "ADD_FINE_TO_PAY";
export const ADD_AUTOEXPRESO_FINE_TO_PAY = "ADD_AUTOEXPRESO_FINE_TO_PAY";
export const REMOVE_FINE_TO_PAY = "REMOVE_FINE_TO_PAY";
export const REMOVE_AUTOEXPRESO_FINE_TO_PAY = "REMOVE_AUTOEXPRESO_FINE_TO_PAY";
export const TOGGLE_ALL_FINE_TO_PAY = "TOGGLE_ALL_FINE_TO_PAY";
export const FILTER_LICENSE = "FILTER_LICENSE";
export const FILTER_VEHICLE = "FILTER_VEHICLE";
export const CALC_CART_TOTAL = "CALC_CART_TOTAL";
export const FETCH_VEHICLE_FINES = "FETCH_VEHICLE_FINES";
export const APPLY_CHECKED_FINES = "APPLY_CHECKED_FINES";
export const RESET_CART = "RESET_CART";
export const FETCH_AUTOEXPRESO_FINES = "FETCH_AUTOEXPRESO_FINES";
export const DESELECT_ALL_AUTOEXPRESO_FINES = "DESELECT_ALL_AUTOEXPRESO_FINES";


export const fetchAllFines = (onFineFetchSuccess, onFineFetchError) => async (dispatch) => {
    dispatch({ type: LOADING_START });
    let fines = handleResponse(await Api.fetchFines(), onFineFetchSuccess, onFineFetchError);
    dispatch({ type: LOADING_END });
    dispatch({
        type: FETCH_ALL_FINES,
        payload: fines
    })
};

export const fetchCardFines = (onCardFetchSuccess) => async (dispatch) => {
    dispatch({ type: LOADING_START });
    let card = handleResponse(await Api.fetchCardInfo(), onCardFetchSuccess);
    dispatch({ type: LOADING_END });

    dispatch({
        type: FETCH_CARD_FINES,
        payload: card
    })
};

export const fetchAutoexpresoFinesAction = (vehicleId, currentPage, pageSize, onSuccess, onError) => async (dispatch, getState) => {
    if (!vehicleId) throw new Error('No vehicleId provided to fetchAutoexpresoFines action')

    dispatch({ type: LOADING_START });
    let response = await Api.autoexpresoFinesFetch(vehicleId, currentPage, pageSize, onSuccess, onError)
    dispatch({ type: LOADING_END });

    dispatch({
        type: FETCH_AUTOEXPRESO_FINES,
        payload: response?.content
    })
};

// Pay all autoexpreso fines summary calculation
export const payAllSummary = (vehicleId, onSuccess, onError) => async (dispatch) => {

    if (!vehicleId) throw new Error('No vehicleId provided to payAllSummary action')

    dispatch({ type: LOADING_START });
    let response = await Api.autoexpresoPayAllSummary(vehicleId, onSuccess, onError)
    dispatch({ type: LOADING_END });

    let data = {
        paymentTotal: {
            finesTotal: response?.finesTotal,
            finesPenalties: response?.finesPenalties,
            finesCredits: response?.finesCredits,
            total: response?.total,
            fines: response?.finesIds,
            tollAmount: response?.tollAmount,
            finesCount: response?.finesCount
        },
        vehicleId: vehicleId
    };
    dispatch({ type: CALC_CART_TOTAL, payload: data });
};

// Pay selected autoexpreso fines summary calculation
export const paySelectionSummary = (vehicleId, payload, onSuccess, onError) => async (dispatch) => {

    if (!vehicleId) throw new Error('No vehicleId provided to payAllSummary action')

    dispatch({ type: LOADING_START });
    let response = await Api.autoexpresoFinesSummary(vehicleId, payload, onSuccess, onError)
    dispatch({ type: LOADING_END });

    let data = {
        paymentTotal: {
            finesTotal: response?.finesTotal,
            finesPenalties: response?.finesPenalties,
            finesCredits: response?.finesCredits,
            total: response?.total,
            fines: payload?.selectedFines,
            tollAmount: response?.tollAmount,
            finesCount: response?.finesCount
        },
        vehicleId: vehicleId
    };
    dispatch({ type: CALC_CART_TOTAL, payload: data });
};


export const removeAllAutoexpresoFines = () => {
    return {
        type: DESELECT_ALL_AUTOEXPRESO_FINES
    }
}

export const fineSelectAll = (checked) => {
    return {
        type: TOGGLE_ALL_FINE_TO_PAY,
        payload: {
            on: checked
        }
    }
};

export const applyCheckedFines = () => {
    return {
        type: APPLY_CHECKED_FINES,
        payload: null
    }
};

export const filterLicense = (checked) => {
    return {
        type: FILTER_LICENSE,
        payload: { on: checked }
    }
};
export const filterVehicle = (checked) => {
    return {
        type: FILTER_VEHICLE,
        payload: { on: checked }
    }
};

export const fineSelected = (fine) => {
    fine.checked = true;
    return {
        type: ADD_FINE_TO_PAY,
        payload: fine
    }
};

export const fineSelectedAutoexpreso = (fine) => {
    fine.checked = true;
    return {
        type: ADD_AUTOEXPRESO_FINE_TO_PAY,
        payload: fine
    }
};

export const fineRemoveAutoexpreso = (fine) => async (dispatch, getState) => {
    fine.checked = false;
    dispatch({ type: LOADING_START });
    dispatch({
        type: REMOVE_AUTOEXPRESO_FINE_TO_PAY,
        payload: fine
    })
    dispatch({ type: LOADING_END });
};


export const fineRemove = (fine, reCalc = false) => async (dispatch, getState) => {
    fine.checked = false;
    dispatch({ type: LOADING_START });
    dispatch({
        type: REMOVE_FINE_TO_PAY,
        payload: fine
    })
    if (reCalc)
        dispatch(calcCart())
    dispatch({ type: LOADING_END });
};

export const resetCart = () => {
    return {
        type: RESET_CART
    }
};

export const setFinesForRenewal = (renewalCharges) => (dispatch) => {
    dispatch(resetCart());
    let { fines } = renewalCharges;
    if (renewalCharges && fines) {
        for (const fine of fines) {
            dispatch(fineSelected(fine))
        }
    }

    let payload = {
        paymentTotal: {
            finesTotal: renewalCharges.finesTotal,
            finesPenalties: renewalCharges.finesPenalties,
            finesCredits: renewalCharges.finesCredits,
            total: renewalCharges.total
        },
    };
    dispatch({ type: CALC_CART_TOTAL, payload });

};

export const calcCart = () => async (dispatch, getState) => {

    const store = getState();
    let { fines: cartFies } = store.fines.cart;
    if (cartFies?.length > 0) {
        let tickets = cartFies.map(f => f.id);

        dispatch({ type: LOADING_START });
        let data = handleResponse(await Api.calculateFees({ tickets: tickets }), null, showError());
        dispatch({ type: LOADING_END });
        let {
            finesCredits,
            finesPenalties,
            finesTotal,
            total,
            fines
        } = data;
        let payload = {
            paymentTotal: {
                finesTotal: finesTotal,
                finesPenalties: finesPenalties,
                finesCredits: finesCredits,
                total: total,
                fines: fines
            },
        };
        dispatch({ type: CALC_CART_TOTAL, payload });
    }
};


