import React from "react";
import Text from "./Text";
import iconMultasAutoExpreso from "../../assets/images/icon-multas-autoexpreso.svg";
import { Colors } from "../../Colors";
import { useHistory, useParams } from "react-router-dom";
import { FontWeight } from "./FontSize";

const AutoExpresoFines = () => {

    const history = useHistory()
    const { vehicleId } = useParams();

    return (
        <button
            className="btn d-flex align-items-center auto-expreso-fines justify-content-between mb-3 mb-md-0"
            onClick={() => history.push(`/autoexpreso/${vehicleId}`)}
            style={{
                backgroundColor: Colors.orangeDark,
                color: 'white',
                cursor: 'pointer',
                borderRadius: 0,
                width: '100%'
            }}>
            <div className="d-flex align-items-center">
                <img
                    src={iconMultasAutoExpreso}
                    alt="logo"
                    style={{ width: 20, border: '2px solid white', borderRadius: '100%' }}
                    className="mr-2"
                />
                <div>
                    <Text className="text" i18nKey="main:autoExpreso" style={{ fontWeight: FontWeight.medium }} />
                    <Text className="text">&nbsp;<a
                        target="_blank"
                        style={{ textDecoration: 'underline', fontWeight: FontWeight.medium }}
                    >
                        AutoExpreso.
                    </a>
                    </Text>
                </div>
            </div>
            <div>
                <div className="caret-right-white" />
            </div>
        </button>
    )

}

export default AutoExpresoFines