import React from "react";
import PropTypes from 'prop-types'
import Text from "../../components/ui/Text";

import brandIcon from "../../assets/images/vehicle-details/icon-brand.svg";
import brandBikeIcon from "../../assets/images/vehicle-details/icon_Marca_Motora.svg";
import brandCarreton from "../../assets/images/vehicle-details/icon_Marca_Carreton.svg";
import colorIcon from "../../assets/images/vehicle-details/icon_Color.svg";
import expirationIcon from "../../assets/images/vehicle-details/icon-expiration.svg";
import modelIcon from "../../assets/images/vehicle-details/icon-model.svg";
import plateIcon from "../../assets/images/vehicle-details/icon-plate.svg";
import yearIcon from "../../assets/images/vehicle-details/icon-year.svg";
import digitalTagIcon from "../../assets/images/digital-tag-icon.svg";

import VehiclePageTitle from "./VehiclePageTitle";
import { Colors } from "../../Colors";
import { FontSize } from "../../components/ui/FontSize";
import * as Utils from "../../core/Utils";

import { useTranslation } from 'react-i18next'

import preRegisteredVehicle from "../../assets/images/vehicle-details/pre-registered-header.svg";
import preRegisteredBike from '../../assets/images/vehicle-details/icon_Preregistro_Motora.svg'

import pendingVehicle from '../../assets/images/vehicle-details/icon_Preregistro_Pendiente.svg'
import pendingBike from '../../assets/images/vehicle-details/icon_Preregistro_Motora_Pendiente.svg'

import electronicTagVehicle from '../../assets/images/electronic-tag/icon_Marca_MarbeteDigital.svg'
import electronicTagBike  from  '../../assets/images/electronic-tag/icon_Marca_Motora_MarbeteDigital.svg'

const SubHeaderIconRow = (props) => {
    const { make, model, color, year, plate, tag, loading, preboardedVehicle, type } = props;

    const { t } = useTranslation()

    const status = props?.tagPreboarding?.status
    const pending = status === "pending"
    const tagType = props?.tag?.type

    const item = (key, icon, value) => (
        <div style={IconRowItem} role="text" aria-label={`${t(`vehicle:header.${key}.text`)} ${value}`}>
            <img src={icon} alt={`profile:header.${key}.alt`} />
            <Text i18nKey={`vehicle:header.${key}.text`} style={ItemTextSm} />
            <Text
                style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    textAlign: "center",
                }}
            >
                {value}
            </Text>
        </div>
    );

    const renderMakeIcon = () => {

        const isDigital = tagType === "DIGITAL"

        switch (type) {
            case "ARRASTRE":
            case "CARRETON":
                return brandCarreton
            case "MOTOCICLETA":
                if (isDigital) {
                    return electronicTagBike
                }
                if (pending) {
                    return pendingBike
                }
                if (preboardedVehicle) {
                    return preRegisteredBike
                }
                return brandBikeIcon
            default:
                // Defaults to VEHICLE type
                if (isDigital) {
                    return electronicTagVehicle
                }
                if (pending) {
                    return pendingVehicle
                }
                if (preboardedVehicle) {
                    return preRegisteredVehicle
                }
                return brandIcon
        }
    }

    return (
        <div className="container p-0">
            <VehiclePageTitle
                year={year}
                make={make}
                plate={plate}
                loading={loading}
                model={model}
            />
            <div style={SubHeaderIconRowStyle} className="sub-header-icon-row">
                {/* {item(
                    "expiration",
                    preboardedVehicle ? digitalTagIcon : expirationIcon,
                    Utils.format(tag?.expirationDate, "DD.MMM.YYYY")
                )} */}
                {item(
                    "expiration",
                    expirationIcon,
                    Utils.format(tag?.expirationDate, "DD.MMM.YYYY")
                )}
                {item(
                    "make",
                    renderMakeIcon(),
                    make
                )}
                {item("model", modelIcon, model)}
                {item("color", colorIcon, color)}
                {item("year", yearIcon, year)}
                {item("plate", plateIcon, plate)}
            </div>
        </div>
    );
};

SubHeaderIconRow.propTypes = {
    preboardedVehicle: PropTypes.bool,
    loading: PropTypes.bool
}

const SubHeaderIconRowStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: "1rem",
    overflowX: "auto",
};

const IconRowItem = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
    paddingRight: "50px",
};

const ItemTextSm = {
    fontSize: "13px",
    color: Colors.gray,
    textTransform: "uppercase",
    marginTop: FontSize.sm,
    textAlign: "center",
    whiteSpace: "nowrap",
};

export default SubHeaderIconRow;
