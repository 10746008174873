import React from "react";
import "./toggleSwitch.css";

const ToggleSwitch = (props) => {
    // i = index || Required in case of rendering more than a single ToggleSwitch
    // component on a same page

    const { i, state, setState } = props;

    return (
        <div
            style={{
                ...radioWrapper,
                backgroundColor: state
                    ? "rgba(78, 155, 99, 0.301)"
                    : "lightgray",
                width: "50px",
                ...props.style,
                cursor: "pointer",
            }}
        >
            <input
                type="radio"
                className="false"
                id={`radio-false-${i}`}
                checked={!state}
                onClick={() => setState(false)}
                role="switch"
                aria-checked="false"
            />
            <label
                for={`radio-false-${i}`}
                style={{ cursor: "pointer" }}
            ></label>

            <input
                type="radio"
                className="true"
                id={`radio-true-${i}`}
                checked={state}
                onClick={() => setState(true)}
                role="switch"
                aria-checked="true"
            />
            <label
                for={`radio-true-${i}`}
                style={{ cursor: "pointer" }}
            ></label>
        </div>
    );
};

const radioWrapper = {
    width: "50px",
    height: "25px",
    verticalAlign: "middle",
    borderRadius: "30px",
    position: "relative",
};

ToggleSwitch.defaultProps = {
    i: 1,
};

export default ToggleSwitch;
