import React, { useEffect, useState } from "react";
import PaymentHistoryList from "./PaymentHistoryList";
import PaymentHistoryModal from "./PaymentHistoryModal";
import * as Api from "../../core/APICaller";
import ErrorRefresh from "../../components/ui/ErrorRefresh";
import PageTitle from "../../components/ui/PageTitle";
import { emptyObjArray } from "../../core/Utils";
import { useHistory, useLocation } from 'react-router-dom'
import ErrorModal from "../../components/ui/ErrorModal";
import { useTranslation } from "react-i18next";

const PaymentHistory = props => {
    const [showModal, setShowModal] = useState(false);
    const [selectedTransactionId, setSelectedTransactionId] = useState("false");
    const [selectedTransaction, setSelectedTransaction] = useState(false);
    const [receipts, setReceipts] = useState(emptyObjArray(3));
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [detailError, setDetailError] = useState(false);

    const { t } = useTranslation()

    let history = useHistory();

    useEffect(() => {
        fetchPaymentHistory();
    }, []);

    const fetchPaymentHistory = () => {
        setLoading(true);
        setError(false);
        Api.fetchPaymentHistory(onPaymentHistorySuccess, onPaymentHistoryError);
    };

    const onPaymentHistorySuccess = paymentHistories => {
        let receipts = (paymentHistories && paymentHistories.receipts) || [];
        setReceipts(receipts);
        setLoading(false);
    };

    const onPaymentHistoryError = () => {
        setError(true);
    };

    const toggleModal = () => {
        setShowModal(!showModal);
        showModal === true && history.push('/payment-history')
    }

    const closeDetailError = () => {
        setDetailError(false)
        history.push('/payment-history')
    }

    const handleHistoryDetailError = () => {
        setDetailError(true)
    }

    const chooseTransaction = async (transactionId, autoexpreso) => {
        if (!transactionId) return

        if (autoexpreso) {
            await Api.fetchPaymentHistoryDetailAutoexpreso(transactionId,
                (transaction) => {
                    setSelectedTransactionId(transactionId);
                    setSelectedTransaction(transaction);
                    toggleModal();
                }, handleHistoryDetailError);
            return
        }

        await Api.fetchPaymentHistoryDetail(transactionId,
            (transaction) => {
                setSelectedTransactionId(transactionId);
                setSelectedTransaction(transaction);
                toggleModal();
            }, handleHistoryDetailError);
    };

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    let transactionId = query.get('id')
    let autoexpreso = query.get('autoexpreso')

    useEffect(() => {
        if (transactionId) {
            chooseTransaction(transactionId, autoexpreso)
        }
    }, [transactionId])

    return (
        <div className="container" style={{ padding: 0 }}>
            <div className="p-mob-only">
                <PageTitle i18nKey={"paymentHistory:title"} style={{ marginBottom: "2rem" }} />
            </div>
            {showModal && (
                <PaymentHistoryModal
                    toggle={toggleModal}
                    selectedTransactionId={selectedTransactionId}
                    data={selectedTransaction}
                />
            )}
            {!error && (
                <PaymentHistoryList
                    chooseTransaction={chooseTransaction}
                    receipts={receipts}
                    loading={loading}
                    small={window.innerWidth < 992}
                />
            )}
            {detailError && (
                <ErrorModal
                    actionText={t("paymentHistory:continue")}
                    action={closeDetailError}
                    toggle={closeDetailError}
                >
                    <span>{t("paymentHistory:detailError")}</span>
                </ErrorModal>
            )}
            {error && <ErrorRefresh refresh={fetchPaymentHistory} />}
        </div>
    );
};

export default PaymentHistory;
