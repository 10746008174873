import React from 'react'
import PropTypes from 'prop-types'
import payAllIcon from '../../assets/images/icon_Checkbox_PagarTodos.svg'
import { useTranslation } from 'react-i18next'

const PayAllButton = ({ action, disabled }) => {

    const { t } = useTranslation()

    return (
        <div className="pay-all-link" style={{ opacity: disabled ? 0.5 : 1 }}>
            <img src={payAllIcon} style={{ width: 20, height: 20 }} alt="" aria-hidden={true} />
            <a
                onClick={disabled ? null : action}
                className="ml-2"
                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
            >{t("profile:payAll")}</a>
        </div>
    )
}

PayAllButton.propTypes = {
    action: PropTypes.func.isRequired
}

export default PayAllButton