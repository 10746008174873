import React, {useEffect, useState} from "react";

import Skeleton from "react-skeleton-loader";

import Text from "../../components/ui/Text";
import {FontSize, FontWeight} from "../../components/ui/FontSize";
import {Colors} from "../../Colors";
import FineTypeIcon from "../../components/ui/FineTypeIcon";
import {useTranslation} from "react-i18next";
import {format, formatCurrencyLabel} from "../../core/Utils";
import _ from "lodash";

const ModalFineListItem = (props) => {
    const {date, ticket, city, lawNo, law, amount, type, plate} = props.data;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);
    const {t} = useTranslation();

    return (
        <div style={fineListItemStyle} className="alert-padding">
            <div style={fineRow}
                 role="text"
                 aria-label={`${t("paymentHistory:modal.aria.fineListItem")}, ${format(date, "DD/MMMM/YYYY")}. ${_.defaultTo(city, "-")}. total: ${formatCurrencyLabel(amount)}`}>
                <div className="col-1" style={noPadding} aria-hidden="true">
                    {!loading
                        ? (<FineTypeIcon type={type}/>)
                        : (<Skeleton width="1rem"/>)}
                </div>

                <div className="col-4" style={alignCenter}>
                    <Text className="text">
                        {!loading ? format(date, "DD.MMM.YY") : <Skeleton/>}
                    </Text>
                </div>

                <div className="col-4" style={alignCenter}>
                    {!loading ? <Text className="text">{_.defaultTo(city, "-")}</Text> : <Skeleton/>}
                </div>

                <div className="col-3" style={total}>
                    {/*<Text
                        className={`badge badge-success badge-pill`}
                        style={{
                            width: "35px",
                            fontSize: "12px",
                            fontWeight: FontWeight.medium,
                            marginRight: "10px",
                            backgroundColor: Colors.primary
                        }}
                    >
                        30%
                    </Text>*/}
                    <Text style={amountStyle} className="text">
                        {!loading
                            ? (formatCurrencyLabel(amount))
                            : (<Skeleton width="1rem"/>)}
                    </Text>
                </div>
            </div>

            <div style={fineRow}>
                <div style={{...itemCol, paddingLeft: 0}}
                     className="col-5"
                     role="text"
                     aria-label={`${t("paymentHistory:modal.aria.fineNum")} ${ticket}`}>
                    <Text style={smallText} i18nKey={"paymentHistory:modal.fineItem.fineNum"}/>
                    {!loading ? <Text className="text">{ticket}</Text> : <Skeleton/>}
                </div>

                <div style={itemCol}
                     className="col-7"
                     role="text"
                     aria-label={`${t("paymentHistory:modal.fineItem.law")}: ${lawNo}`}>
                    <Text style={smallText} i18nKey={"paymentHistory:modal.fineItem.law"}/>
                    <Text className="text">{_.defaultTo(law, "-")}</Text>
                </div>
            </div>

            {type === "VEHICLE" && (<div style={fineRow}
                 role="text"
                 aria-label={`${t("paymentHistory:modal.fineItem.vehicle")}: ${"placeholder"}`}>
                <div style={{...itemCol, paddingLeft: 0}} className="col-11">
                    <Text style={smallText} i18nKey={"paymentHistory:modal.fineItem.vehicle"}/>
                    <Text className="text">{plate}</Text>
                </div>
            </div>)}
        </div>
    );
};

const alignCenter = {
    display: "flex",
    alignItems: "center",
};

const fineListItemStyle = {
    display: "flex",
    flexDirection: "column",
    borderBottom: `1px solid ${Colors.lightGray}`,
};

const fineRow = {
    display: "flex",
    marginBottom: "15px",
};

const itemCol = {
    display: "flex",
    flexDirection: "column",
};

const total = {
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
};

const noPadding = {
    padding: 0,
};

const smallText = {
    fontSize: "13px",
    color: Colors.gray,
};

const amountStyle = {
    color: Colors.primary,
    fontWeight: FontWeight.bold,
    textAlign: "right",
}

export default ModalFineListItem;
