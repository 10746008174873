import React from "react";
import { useState } from "react";
import { Colors } from "../../Colors";
import "./style.css";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const RegistrationSupport = () => {
    const [succes, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const [loading, setLoading] = useState(false);

    const { search } = useLocation();
    const qs = queryString.parse(search);

    const baseUrl = "https://prits-itsm-api.azurewebsites.net/api/v1/";
    const functionName = "support/cesco-digital/registro";
    const key = "bbwDkVjUSm_uexgvoJyxKA3PJLH5zW5dMIDdF5CM41CbAzFuWmJMnQ==";

    const {
        register,
        watch,
        formState: { errors },
        handleSubmit,
        control,
        reset,
    } = useForm({
        defaultValues: {
            name: qs.name || "",
            email: qs.email || "",
            telephone: qs.telephone || "",
            vehicle: qs.vehicle || "",
            plate: qs.plate || "",
            reason: qs.reason || "",
            tagNumber: qs.tagNumber || "",
        },
    });

    const requiredMsg = "Este campo es requerido";
    const invalidFormatMsg = "Formato inválido";

    const onSubmit = (data) => submitFunction(data);

    async function submitFunction(data) {
        setLoading(true);

        // Remove any empty key/value pairs from the Object
        Object.keys(data).forEach((key) => {
            if (
                data[key] === "" ||
                data[key] === null ||
                data[key] === undefined
            ) {
                delete data[key];
            }
        });

        await postData(data)
            .then((response) => {
                if (response.status !== 201) {
                    console.error(
                        `There was an error. Status Code: ${response.status}`
                    );
                }

                setLoading(false);
                return response.json();
            })
            .then((data) => {
                console.log(data?.errors);
                if (data?.errors?.response_status?.status_code === 4000) {
                    setSuccess(false);
                    setError(true);
                    console.log("ERROR: ", data?.error);
                } else {
                    setError(false);
                    setSuccess(data?.data?.ticketId);
                    reset();
                }
            })
            .catch((e) => {
                setSuccess(false);
                setError(true);
                console.log("ERROR: ", e);
            });

        setLoading(false);
    }

    async function postData(data = {}) {
        const response = await fetch(`${baseUrl}${functionName}?code=${key}`, {
            method: "POST",
            mode: "cors",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        return response;
    }

    return (
        <div id="top">
            <div class="support-form__center">
                <div class="support-form__container">
                    {succes && (
                        <SuccessModal
                            ticketNumber={succes}
                            setModal={setSuccess}
                        />
                    )}

                    {error && <ErrorDialog />}

                    <div class="support-form__title">
                        Formulario de apoyo para Marbete Digital
                    </div>
                    <form
                        action=""
                        id="supportForm"
                        method="post"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div class="support-form__subtitle">
                            Información de contacto
                        </div>
                        <div class="support-form__user-details">
                            <div class="support-form__input-box">
                                <label class="support-form__details" for="name">
                                    Nombre{" "}
                                    <span
                                        class="support-form__required"
                                        aria-label="required"
                                    >
                                        *
                                    </span>
                                </label>

                                <input
                                    {...register("name", {
                                        required: requiredMsg,
                                        pattern: {
                                            value: /^[A-Za-z\sáéíóúñÑÁÉÍÓÚ]{2,100}$/,
                                            message: invalidFormatMsg,
                                        },
                                    })}
                                    readOnly
                                />

                                <InputError message={errors?.name?.message} />
                            </div>

                            <div class="support-form__input-box">
                                <label
                                    class="support-form__details"
                                    for="telephone"
                                >
                                    Teléfono{" "}
                                </label>

                                <Controller
                                    render={({ field }) => (
                                        <InputMask
                                            mask="(999)999-9999"
                                            value={field.value}
                                            onChange={field.onChange}
                                            {...register("telephone", {
                                                // pattern: {
                                                //     value: /^\(\d{3}\)\d{3}-\d{4}/,
                                                //     message: invalidFormatMsg,
                                                // },
                                            })}
                                            type="text"
                                        />
                                    )}
                                    control={control}
                                    name="telephone"
                                />

                                <InputError
                                    message={errors?.telephone?.message}
                                />
                            </div>
                            <div class="support-form__input-box">
                                <label
                                    class="support-form__details"
                                    for="email"
                                >
                                    Correo electrónico{" "}
                                    <span
                                        class="support-form__required"
                                        aria-label="required"
                                    >
                                        *
                                    </span>
                                </label>
                                <small>
                                    <p>
                                        De necesitar modificarlo, deberá hacerlo
                                        a través de la aplicación de CESCO
                                        Digital.
                                    </p>
                                </small>
                                <input
                                    {...register("email", {
                                        required: requiredMsg,
                                        maxLength: 50,
                                        pattern: {
                                            value: /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/,
                                        },
                                    })}
                                    type="email"
                                    readOnly
                                />

                                <InputError message={errors?.email?.message} />
                            </div>
                        </div>

                        <div class="support-form__subtitle">Detalles</div>

                        <div class="support-form__user-details">
                            <div
                                class="support-form__input-box"
                                style={{ width: "100%" }}
                            >
                                <label
                                    class="support-form__details"
                                    for="vehicle"
                                >
                                    Vehículo{" "}
                                    <span
                                        class="support-form__required"
                                        aria-label="required"
                                    >
                                        *
                                    </span>
                                </label>

                                <input
                                    {...register("vehicle", {
                                        required: requiredMsg,
                                        pattern: {
                                            value: /^[A-Za-z0-9\s-]{2,100}$/,
                                            message: invalidFormatMsg,
                                        },
                                    })}
                                    readOnly
                                />

                                <InputError
                                    message={errors?.vehicle?.message}
                                />
                            </div>

                            <div class="support-form__input-box">
                                <label
                                    class="support-form__details"
                                    for="plate"
                                >
                                    Tablilla{" "}
                                    <span
                                        class="support-form__required"
                                        aria-label="required"
                                    >
                                        *
                                    </span>
                                </label>

                                {/* Length should be no less than 3 characters */}
                                <input
                                    {...register("plate", {
                                        required: requiredMsg,
                                        pattern: {
                                            value: /^[A-Z{0-9}]{6,9}$/,
                                            message: invalidFormatMsg,
                                        },
                                    })}
                                    readOnly
                                />

                                <InputError message={errors?.plate?.message} />
                            </div>

                            <div class="support-form__input-box">
                                <label
                                    class="support-form__details"
                                    for="plate"
                                >
                                    Número de sello{" "}
                                    <span
                                        class="support-form__required"
                                        aria-label="required"
                                    >
                                        *
                                    </span>
                                </label>

                                <input
                                    {...register("tagNumber", {
                                        required: requiredMsg,
                                        pattern: {
                                            value: /^\d{8}$/,
                                            message: invalidFormatMsg,
                                        },
                                    })}
                                    readOnly
                                />

                                <InputError
                                    message={errors?.tagNumber?.message}
                                />
                            </div>

                            <div
                                class="support-form__select"
                                style={{ width: "100%" }}
                            >
                                <label
                                    class="support-form__details"
                                    for="reason"
                                >
                                    Situación
                                    <span
                                        class="support-form__required"
                                        aria-label="required"
                                    >
                                        *
                                    </span>
                                </label>

                                <select
                                    {...register("reason", {
                                        required: requiredMsg,
                                    })}
                                    style={{ pointerEvents: "none" }}
                                    className="custom-select"
                                >
                                    <option selected value="">
                                        Seleccione una opción
                                    </option>
                                    <option value="BadRequest">
                                        El número ingresado no es válido.
                                    </option>
                                    <option value="AlreadyRegistered">
                                        Este sello ya ha sido registrado como
                                        marbete digital.
                                    </option>
                                    <option value="ProfileNotFound">
                                        Servicio no disponible en estos
                                        momentos. Intente más tarde.
                                    </option>
                                    <option value="UnknownError">
                                        Servicio no disponible en estos
                                        momentos. Intente más tarde.
                                    </option>
                                    <option value="PlateMismatch">
                                        Este sello está registrado a otra
                                        tablilla en AutoExpreso.
                                    </option>
                                    <option value="InvalidTagType">
                                        Este sello no es elegible para ser
                                        registrado como marbete digital.
                                    </option>
                                    <option value="BAD_REQUEST">
                                        El número ingresado no es válido.
                                    </option>
                                    <option value="UNKNOWN_ERROR">
                                        Servicio no disponible en estos
                                        momentos. Intente más tarde.
                                    </option>
                                    <option value="SERIAL_MISMATCH">
                                        El número de serie provisto no coincide
                                        con los datos en sistema.
                                    </option>
                                    <option value="MISMATCH">
                                        El número de serie provisto no coincide
                                        con los datos en sistema.
                                    </option>
                                    <option value="USED">
                                        El sello ya ha sido registrado como
                                        marbete digital en DAVID+.
                                    </option>
                                    <option value="OTHER">
                                        El número de serie no pudo ser validado
                                        por error en sistema.
                                    </option>
                                    <option value="TagInfoNotFound">
                                        El número de serie no pudo ser validado
                                        porque no se encontraron datos de número
                                        de serie.
                                    </option>
                                    <option value="TAG_OUT_OF_RANGE">
                                        El número de sello no está en el rango aceptable.
                                    </option
                                    ><option value="TAG_NOT_FOUND">
                                        El número de sello no pudo ser encontrado.
                                    </option>
                                </select>

                                <InputError
                                    message={errors?.subcategoryName?.message}
                                />
                            </div>

                            <div class="support-form__text-box">
                                <label
                                    class="support-form__details"
                                    for="description"
                                >
                                    Descripción del problema
                                </label>

                                <textarea
                                    {...register("description", {
                                        maxLength: 500,
                                    })}
                                    id="description"
                                    cols="30"
                                    rows="10"
                                ></textarea>

                                <InputError
                                    message={errors?.description?.message}
                                />
                            </div>
                        </div>

                        <input
                            type="submit"
                            style={{
                                backgroundColor: Colors.primary,
                                color: "white",
                                opacity:
                                    loading ||
                                    !qs.name ||
                                    !qs.vehicle ||
                                    !qs.plate ||
                                    !qs.tagNumber
                                        ? 0.7
                                        : 1,
                            }}
                            className="w-100 rounded p-3"
                            disabled={
                                loading ||
                                !qs.name ||
                                !qs.vehicle ||
                                !qs.plate ||
                                !qs.tagNumber
                            }
                            value={loading ? "Enviando" : "Someter"}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};

const InputError = ({ message }) => {
    if (!message) return null;

    return (
        <p className="m-0" style={{ color: Colors.error }}>
            <small>{message}</small>
        </p>
    );
};

const SuccessDialog = (props) => {
    return (
        <div
            style={{ backgroundColor: Colors.primary, color: "white" }}
            className="p-3 rounded mb-3"
        >
            <span>
                La forma fué sometida exitosamente. Su número de ticket es:{" "}
                {props?.ticketNumber}
            </span>
        </div>
    );
};

const SuccessModal = (props) => {
    return (
        <Modal
            isOpen={true}
            size="md"
            style={{ padding: "15px" }}
            toggle={() => props?.setModal(false)}
            role="dialog"
            aria-modal={true}
            autoFocus={true}
        >
            <ModalHeader
                style={{ backgroundColor: Colors.lightGray }}
                toggle={() => props?.setModal(false)}
            >
                <span className="text">Formulario sometido</span>
            </ModalHeader>

            <ModalBody
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    margin: "10px 0",
                }}
            >
                <span>
                    El formulario fué sometido exitosamente. Su número de ticket
                    es:
                    <span
                        style={{
                            fontWeight: "bold",
                            color: Colors.primary,
                            marginLeft: 5,
                        }}
                    >
                        {props?.ticketNumber}
                    </span>
                </span>
            </ModalBody>
        </Modal>
    );
};

const ErrorDialog = () => {
    return (
        <div
            style={{ backgroundColor: Colors.error, color: "white" }}
            className="p-3 rounded mb-3"
        >
            <span>Lo sentimos. Ocurrió un error sometiendo la forma.</span>
        </div>
    );
};

export default RegistrationSupport;
