import React from 'react'
import StarIcon from '../assets/images/star-icon.svg'

    export const renderInsuranceStars = (type) => {

        if (!type) return

        const img = <img src={StarIcon} style={{ width: 20 }} alt="" aria-hidden />

        switch (type) {
            case 1:
                return <div>{img}</div>
            case 2:
                return <div>{img} {img}</div>
            case 3:
                return <div>{img} {img} {img}</div>
        }
    }
