import React from "react";

import Text from "../../components/ui/Text";
import {FontSize} from "../../components/ui/FontSize";

import appStoreBadge from "../../pages/Login/images/appstore-badge.svg";
import playStoreBadge from "../../pages/Login/images/play-store-badge.png";
import AppStoresBadges from "../ui/AppStoresBadges";
import {useTranslation} from "react-i18next";
import {Colors} from "../../Colors";
import {Link} from "react-router-dom";

const ArtOnlyPage = props => {
    const {t} = useTranslation();

    const {text,showAppStoreInfo} = props

    return (
        <div style={ArtOnlyPageStyle} className="container">
            <img
                src={props.artSrc}
                style={{marginTop: "1rem", marginBottom: "20px"}}
                alt={props.alt}
                className="art-only-page-image"
            />
                <Text
                    style={{
                        fontSize: FontSize.xl,
                        marginTop: "2rem",
                        textAlign: 'center'
                    }}
                >

                    {text}</Text>

            {showAppStoreInfo && <>
                <Text
                        style={{marginTop: "0.5rem", color: Colors.gray, textAlign: 'center'}}
                        i18nKey={"artOnlyPage:appsCaption"}
                    />
                    <AppStoresBadges/>
                    <div style={{display: "flex"}}>
                        <Text
                            style={{color: Colors.gray}}
                            i18nKey={"artOnlyPage:goHomeCaption"}
                        />
                        &nbsp;
                        <Link to="/landing" style={{color: Colors.primary}} className="link">
                            {t("artOnlyPage:homeButton")}
                        </Link>
                    </div>
            </>}
        </div>
    );
};

const ArtOnlyPageStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};

export default ArtOnlyPage;
