import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import Text from "../../components/ui/Text";
import Button from "./Button";
import iconCancel from "../../assets/images/icon-cancel-green.svg";
import { Colors } from "../../Colors";
import PropTypes from 'prop-types'
import { useTranslation } from "react-i18next";

const ConfirmationModal = (props) => {
    const { toggle, action, text, title, children, actionDisabled } = props;

    const { t } = useTranslation()

    return (
        <Modal
            isOpen={true}
            size="sm"
            style={{ padding: "15px" }}
            toggle={() => toggle && toggle(false)}
            role="dialog"
            aria-modal={true}
            autoFocus={true}
        >
            <ModalHeader
                style={{ backgroundColor: Colors.lightGray }}
                toggle={() => toggle && toggle(false)}
            >
                <Text className="text">{title}</Text>
            </ModalHeader>
            <ModalBody
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    margin: "10px 0",
                }}
            >
                {
                    children ? children : <Text>{text}</Text>
                }
            </ModalBody>

            <ModalFooter
                style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: Colors.lightGray,
                }}
            >
                <button
                    onClick={() => toggle(false)}
                    disabled={false}
                    style={noStyle}
                >
                    <img
                        src={iconCancel}
                        aria-hidden={true}
                        alt=""
                        style={{ marginRight: "4px" }}
                    />
                    No
                </button>
                <Button
                    className="btn-cesco"
                    onClick={() => action()}
                    disabled={actionDisabled ? actionDisabled : false}
                    style={{ width: "90px" }}
                >
                   {t("main:yes") }
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const noStyle = {
    backgroundColor: "transparent",
    border: "none",
    color: Colors.primary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
    width: "90px",
};

ConfirmationModal.propTypes = {
    toggle: PropTypes.func,
    action: PropTypes.func,
    text: PropTypes.string,
    title: PropTypes.string
}

export default ConfirmationModal;
