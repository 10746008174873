import {api, handleResponse} from "../APICaller";

const URL = {
    sendValidationCode: "/profile/validationCode",
    validateCode: "/profile/validateCode",
    saveEmail: "/profile/edit/email",
    savePhone: "/profile/update",
    profileEntity: `/profile`,
    resetPasscode: `/profile/resetPasscode`,
    manageProfile: "/profile/manage",
    updateProfile: "/profile/update",
    messages: `/profile/notificationHistory`,
    unreadNotifications: `/profile/unreadNotifications`,
    inbox: `/profile/notificationHistory`
};

export function fetchNotificationHistory(onSuccess, onError) {
    return api
        .get(URL.inbox)
        .then((response) => handleResponse(response, onSuccess, onError));
}

export async function updateProfile(payload, onSuccess, onError) {
    return handleResponse(await api
        .post(URL.updateProfile, payload), onSuccess, onError);
}

export async function fetchProfile(onSuccess, onError) {
    return handleResponse(await api.get(URL.profileEntity), onSuccess, onError);
}

export async function saveEmail(email, onSuccess, onError) {
    return handleResponse(await api
        .post(URL.saveEmail, email), onSuccess, onError);
}

export async function savePhone(phoneNumber, onSuccess, onError) {
    return handleResponse(await api
        .post(URL.savePhone, {'phone': phoneNumber}), onSuccess, onError);
}

export async function sendValidationCode(onSuccess, onError) {
    return handleResponse(await api
        .get(URL.sendValidationCode), onSuccess, onError);
}

export async function submitValidationCode(code, onSuccess, onError) {
    return handleResponse(await api
        .post(URL.validateCode, {code}), onSuccess, onError);
}
