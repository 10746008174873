import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Colors } from '../../Colors'
import numSelloArt from '../../assets/images/electronic-tag/Autoexpreso_NumSello.svg'
import Text from '../ui/Text'
import PropTypes from 'prop-types'
import PreboardingResultAnimation from '../../assets/animations/PreboardingResult'
import Button from '../ui/Button'
import ElectronicTagResultAnimation from '../../assets/animations/ElectronicTagResult'

const UnlinkModal = (props) => {

    const { toggle } = props
    const { t } = useTranslation()

    const [result, setResult] = useState(false)

    const Pill = (props) => {

        const { text, gray } = props

        return (
            <div
                className={`px-2 py-1 mr-3 `}
                style={{
                    borderRadius: 100,
                    backgroundColor: gray ? Colors.lightGray : Colors.yellow
                }}
            >
                <small>
                    <strong>{text || "000"}</strong>
                </small>
            </div>
        )
    }

    return (
        <Modal
            isOpen={true}
            size="md"
            toggle={() => toggle && toggle(false)}
        >
            <ModalHeader
                toggle={() => toggle && toggle(false)}
            >
                <Text i18nKey="Desvincular Sello Lorem" className="text" />
            </ModalHeader>

            <ModalBody
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 0
                }}
            >
                {
                    result ?
                        <div className='d-flex flex-column align-items-center justfy-content-center p-3'>
                                <ElectronicTagResultAnimation />
                            <div className='px-5 py-3 text-center'>
                                <strong>
                                    Marbete electronico anterior fue desvinculado y su nuevo marbete electronico fue registrado exitosamente.
                                </strong>
                            </div>
                        </div>
                        :
                        <div>
                            <div className='d-flex flex-column justify-content-center align-items-center p-3'>
                                <div className='mb-2'>
                                    <strong>Numero de Marbete Electronico Anterior</strong>
                                </div>
                                <Pill
                                    gray
                                    text={1231234} />
                            </div>
                            <hr />
                            <div className='d-flex flex-column justify-content-center align-items-center p-3'>
                                <div className='mb-2 text-center'>
                                    <strong>Número actualizado de Marbete Electrónico</strong>
                                </div>
                                <Pill text={1231234} />
                                <div className='my-3 text-center'>
                                    <span>El nuevo numero para el marbete electronico esta correcto?</span>
                                </div>
                            </div>
                        </div>
                }
            </ModalBody>
            <ModalFooter
                style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: Colors.lightGray,
                }}
            >
                {
                    result ?
                        <div>
                            <Button
                                className="btn-primary"
                                onClick={() => toggle()}
                                disabled={false}
                            >
                                Finalizar
                            </Button>
                        </div>
                        :
                        <div>
                            <button
                                onClick={() => toggle(false)}
                                disabled={false}
                                className='btn btn-outline'
                            >
                                No
                            </button>
                            <Button
                                className="btn-primary"
                                onClick={() => setResult(true)}
                                disabled={false}
                                style={{ width: "90px" }}
                            >
                                Yes
                            </Button>
                        </div>
                }

            </ModalFooter>
        </Modal>
    )
}

UnlinkModal.propTypes = {
    toggle: PropTypes.func
}

export default UnlinkModal